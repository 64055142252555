import { Grid, Typography } from "@mui/material";
import AddCardIcon from "@mui/icons-material/AddCard";

const IncVsExp = () => {
  return (
    <Grid
      style={{
        marginTop: "10px",

        color: "#000",
      }}
      container
      spacing={2}
    >
      <Grid
        item
        xs={10}
        sm={6}
        md={4}
        sx={{ backgroundColor: "#fff", padding: "10px", marginLeft: "17px" }}
      >
        <Typography
          gutterBottom
          variant="subtitle1"
          style={{ fontSize: "20px" }}
        >
          Total Earnings
        </Typography>
        <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
          <AddCardIcon />
        </Typography>
        <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
          $301
        </Typography>
      </Grid>
      <Grid
        item
        xs={10}
        sm={6}
        md={4}
        sx={{ backgroundColor: "#fff", padding: "10px", marginLeft: "10px" }}
      >
        <Typography
          gutterBottom
          variant="subtitle1"
          style={{ fontSize: "20px" }}
        >
          Total Expenses
        </Typography>
        <Typography variant="body2" gutterBottom style={{ textAlign: "right" }}>
          <AddCardIcon />
        </Typography>
        <Typography variant="body2" style={{ fontSize: "16px" }} color="blue">
          $201
        </Typography>
      </Grid>
    </Grid>
  );
};

export default IncVsExp;
