import { Box, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import styles from "./HeaderShift.module.css";
export default function HeaderShift() {
  const [selection, setSelection] = useState(0);

  const handleChange = (event, newValue) => {
    setSelection(newValue);
  };

  return (
    <Box className={styles.top}>
      <Box
        sx={{
          width: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: "10px",
        }}
        className={styles.box}
      >
        <p
          style={{
            marginRight: "10px",
            fontSize: "18px",
            color: "#333",
            fontWeight: 540,
          }}
        >
          Employee
        </p>
        <Select
          value={selection}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected === 0) {
              return <MenuItem value={0}>All</MenuItem>; // Placeholder text
            }
            return selected;
          }}
          sx={{
            width: "145px",
            margin: ".4rem",
            padding: "1.5px",
            border: "none",
            color: "#000 ",
            bgcolor: "#eff1f1",
            "& .MuiSelect-select": {
              padding: 0,
            },
          }}
          IconComponent={(props) => (
            <ExpandMoreIcon {...props} style={{ color: "black" }} />
          )}
        >
          <MenuItem value={0}>All</MenuItem>
          <MenuItem value={1}>Open Tickets</MenuItem>
          <MenuItem value={2}>Pending Tickets</MenuItem>
          <MenuItem value={3}>Resolved Tickets</MenuItem>
          <MenuItem value={4}>Closed Tickets</MenuItem>
        </Select>
      </Box>
      <Box
        sx={{
          width: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: "10px",
        }}
        className={styles.box}
      >
        <p
          style={{
            marginRight: "10px",
            fontSize: "18px",
            color: "#333",
            fontWeight: 540,
          }}
        >
          Department
        </p>
        <Select
          value={selection}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected === 0) {
              return <MenuItem value={0}>All</MenuItem>; // Placeholder text
            }
            return selected;
          }}
          sx={{
            width: "145px",
            margin: ".4rem",
            padding: "1.5px",
            border: "none",
            color: "#000 ",
            bgcolor: "#eff1f1",
            "& .MuiSelect-select": {
              padding: 0,
            },
          }}
          IconComponent={(props) => (
            <ExpandMoreIcon {...props} style={{ color: "black" }} />
          )}
        >
          <MenuItem value={0}>All</MenuItem>
          <MenuItem value={1}>Marketing</MenuItem>
          <MenuItem value={2}>Sales</MenuItem>
          <MenuItem value={3}>Human Resources</MenuItem>
          <MenuItem value={4}>Public Relations</MenuItem>
          <MenuItem value={4}>Research</MenuItem>
          <MenuItem value={4}>Finance</MenuItem>
        </Select>
      </Box>
      <Box
        sx={{
          width: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: "10px",
        }}
        className={styles.box}
      >
        <Select
          value={selection}
          onChange={handleChange}
          displayEmpty
          // Use renderValue to show placeholder text when value is empty
          renderValue={(selected) => {
            if (selected === 0) {
              return <MenuItem value={0}>Weekly View</MenuItem>; // Placeholder text
            }
            return selected;
          }}
          sx={{
            width: "145px",
            margin: ".4rem",
            padding: "1.5px",
            border: "none",
            color: "#000 ",
            bgcolor: "#eff1f1",
            "& .MuiSelect-select": {
              padding: 0,
            },
          }}
          IconComponent={(props) => (
            <ExpandMoreIcon {...props} style={{ color: "black" }} />
          )}
        >
          <MenuItem value={0}>Weekly View</MenuItem>
          <MenuItem value={1}>Monthly View</MenuItem>
        </Select>
      </Box>
    </Box>
  );
}
