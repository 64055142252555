import React, { useState } from "react";
import StatusAlert from "../../../../StatusAlertList/StatusAlert";
import { Box, Button, Grid, Slide, Stack, Typography } from "@mui/material";

import ReactQuill from "react-quill";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Discussion = () => {
  const [summary, setSummary] = useState("");
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />

      <Box
        sx={{
          padding: { xs: "10px", sm: "20px", md: "30px" }, // Responsive padding
          margin: { xs: "10px", sm: "20px" }, // Responsive margin
          backgroundColor: "white",
          borderRadius: "8px", // Rounded corners for better appearance
          boxShadow: 2, // Subtle shadow for depth
        }}
      >
        <Typography
          component="div"
          sx={{
            ml: 2,
            flex: 1,
            color: "black",
            fontSize: { xs: "16px", sm: "18px", md: "20px" }, // Responsive font size
            fontWeight: 500, // Added font weight for emphasis
            textAlign: { xs: "center", sm: "left" }, // Center title on small screens
          }}
        >
          Discussion
        </Typography>

        <hr
          style={{
            color: "gray",
            marginTop: "10px",
            marginBottom: "20px",
            border: "none",
            borderTop: "1px solid #eee", // Improved HR appearance
          }}
        />

        <Grid
          container
          spacing={2}
          sx={{
            padding: "20px",
            "@media (max-width: 600px)": {
              padding: "10px",
            },
          }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Stack
              direction="column"
              sx={{
                width: "100%",
                "@media (min-width: 600px)": {
                  flexDirection: "column",
                },
              }}
            >
              {/* <CustomToolbar style={{ color: 'black' }} /> Render custom emoji toolbar */}
              <ReactQuill
                value={summary}
                onChange={setSummary}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  color: "black",
                  borderBlockColor: "black",
                  //minHeight: "200px", // Set your desired minimum height
                  "@media (max-width: 600px)": {
                    minHeight: "150px", // Smaller min height for small screens
                  },
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          autoFocus
          color="inherit"
          sx={{
            backgroundColor: "blue",
            "&:hover": { backgroundColor: "orange" },
            textAlign: "center",
            margin: "25px",
          }}
        >
          Save
        </Button>
      </Box>
    </>
  );
};

export default Discussion;
