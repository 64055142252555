import * as React from "react";

import styles from "./NoticeBoard.module.css";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
export default function NoticeBoardHeader({
  searchValue,
  setSearchValue,
  setEndDate,

  setStartDate,
}) {
  const [selectionDuration, setSelectionDuration] = useState("");
  const [customRangeOpen, setCustomRangeOpen] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const formatDateRange = (startDate, endDate) => {
    setStartDate(dayjs(startDate).format("YYYY-MM-DD"));
    setEndDate(dayjs(endDate).format("YYYY-MM-DD"));
    return `${dayjs(startDate).format("DD-MM-YYYY")} To ${dayjs(endDate).format(
      "DD-MM-YYYY"
    )}`;
  };
  const handleCustomRangeSubmit = () => {
    if (customStartDate && customEndDate) {
      const formattedRange = formatDateRange(customStartDate, customEndDate);
      setSelectionDuration(formattedRange);
    }
    setCustomRangeOpen(false);
  };
  const handleChangeDuration = (event) => {
    const value = event.target.value;
    const today = dayjs().format("YYYY-MM-DD");
    let formattedRange = "";

    switch (value) {
      case 0: // Today
        const yesterday = dayjs(today).subtract(1, "day").format("YYYY-MM-DD");
        formattedRange = formatDateRange(yesterday, today);
        break;
      // Add cases for other options if needed
      case 1: // Last 30 Days
        const thirtyDaysAgo = dayjs().subtract(30, "day").format("YYYY-MM-DD");
        formattedRange = formatDateRange(thirtyDaysAgo, today);
        break;
      case 2: // This Month
        const startOfMonth = dayjs().startOf("month").format("YYYY-MM-DD");
        formattedRange = formatDateRange(startOfMonth, today);
        break;
      case 3: // Last Month
        const startOfLastMonth = dayjs()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        const endOfLastMonth = dayjs()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        formattedRange = formatDateRange(startOfLastMonth, endOfLastMonth);
        break;
      case 4: // Last 90 Days
        const ninetyDaysAgo = dayjs().subtract(90, "day").format("YYYY-MM-DD");
        formattedRange = formatDateRange(ninetyDaysAgo, today);
        break;
      case 5: // Last 6 Months
        const sixMonthsAgo = dayjs().subtract(6, "month").format("YYYY-MM-DD");
        formattedRange = formatDateRange(sixMonthsAgo, today);
        break;
      case 6: // Last 1 Year
        const oneYearAgo = dayjs().subtract(1, "year").format("YYYY-MM-DD");
        formattedRange = formatDateRange(oneYearAgo, today);
        break;
      case 7: // Custom Range
        setCustomRangeOpen(true);
        return;
      default:
        formattedRange = ""; // Handle other cases if necessary
    }

    setSelectionDuration(formattedRange);
  };
  return (
    <Box className={styles.top}>
      <Box
        sx={{
          width: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className={styles.box}
      >
        <p
          style={{
            marginRight: "10px",
            fontSize: "18px",
            color: "#333",
            fontWeight: 540,
          }}
        >
          Duration
        </p>
        <Select
          value={selectionDuration}
          onChange={handleChangeDuration}
          displayEmpty
          // Use renderValue to show placeholder text when value is empty
          renderValue={(selected) => {
            if (selected === "") {
              return (
                <span style={{ padding: "10px", color: "gray" }}>
                  Start Date To End Date
                </span>
              ); // Placeholder text
            }
            return selected; // Show selected value
          }}
          // Apply styles conditionally
          sx={{
            width: "200px",
            margin: ".4rem",

            border: "none",
            color: "#000 ",
            bgcolor: "#eff1f1 ",
            "& .MuiSelect-select": {
              padding: "8px 5px",
              border: "none",
            },
          }}
          IconComponent={(props) => (
            <ExpandMoreIcon {...props} style={{ color: "transparent" }} />
          )}
        >
          {/* <MenuItem value="">Start Date To End Date</MenuItem> */}
          <MenuItem value={0}>Today</MenuItem>
          <MenuItem value={1}>Last 30 Days</MenuItem>
          <MenuItem value={2}>This Month</MenuItem>
          <MenuItem value={3}>Last Month</MenuItem>
          <MenuItem value={4}>Last 90 Days</MenuItem>
          <MenuItem value={5}>Last 6 Months</MenuItem>
          <MenuItem value={6}>Last 1 Year</MenuItem>
          <MenuItem value={7}>Custom Range</MenuItem>
        </Select>
        <Dialog
          open={customRangeOpen}
          onClose={() => setCustomRangeOpen(false)}
        >
          <DialogContent sx={{ bgcolor: "#fff", color: "#000" }}>
            <Stack direction="column">
              <Typography variant="p" gutterBottom style={{ color: "black" }}>
                Start Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker"]}
                  onChange={(newValue) => setCustomStartDate(newValue)}
                  sx={{ marginTop: "-10px" }}
                >
                  <DatePicker
                    // label="Start Date"
                    value={customStartDate}
                    onChange={(newValue) => setCustomStartDate(newValue)}
                    sx={{
                      bgcolor: "#fff",
                      color: "#000",
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        color: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: "black",
                      },
                    }}
                  >
                    {/* TextField component now becomes a child of DatePicker */}
                    {(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        // Add any additional TextField props if needed
                      />
                    )}
                  </DatePicker>
                </DemoContainer>
              </LocalizationProvider>
            </Stack>
            <br />
            <br />
            <Stack direction="column">
              <Typography variant="p" gutterBottom style={{ color: "black" }}>
                End Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker"]}
                  onChange={(newValue) => setCustomEndDate(newValue)}
                  sx={{ marginTop: "-10px" }}
                >
                  <DatePicker
                    // label="End Date"
                    value={customEndDate}
                    onChange={(newValue) => setCustomEndDate(newValue)}
                    sx={{
                      border: "1px solid black",
                      bgcolor: "#fff",
                      color: "#000",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        color: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: "black",
                      },
                    }}
                  >
                    {/* TextField component now becomes a child of DatePicker */}
                    {(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        // Add any additional TextField props if needed
                      />
                    )}
                  </DatePicker>
                </DemoContainer>
              </LocalizationProvider>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ bgcolor: "#fff", color: "#000" }}>
            <Button onClick={() => setCustomRangeOpen(false)}>Cancel</Button>
            <Button
              onClick={handleCustomRangeSubmit}
              disabled={!customStartDate || !customEndDate}
              sx={{ bgcolor: "#fff", color: "#000" }}
            >
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Box
        sx={{
          width: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className={styles.box}
      >
        <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  sx={{
                    height: "40px",
                    color: "#000",
                    padding: 0,
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#000",
                    },
                  }}
                >
                  <SearchIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
            placeholder="Start typing to search"
            sx={{
              width: "260px",
              height: "40px",
              margin: ".4rem",

              border: ".1px solid gray",
              borderRadius: "5px",
              color: "#000 ",
              bgcolor: "#eff1f1",

              boxSizing: "border-box",
            }}
            value={searchValue} // Bind the input value
            onChange={handleInputChange} // Handle the input change
          />
        </FormControl>
      </Box>
    </Box>
  );
}
