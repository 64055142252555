import {
  Box,
  Grid,
 
  TextField,
 
  Menu,
  
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";

import Slide from "@mui/material/Slide";

import MenuItem from "@mui/material/MenuItem";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";


import StatusAlert from "../../../../StatusAlertList/StatusAlert";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Documents() {
  const [open, setOpen] = React.useState(false);

  const [award, setAward] = React.useState("");
  const [givenTo, setGivenTo] = React.useState("");
  const [date, setDate] = React.useState(null);
  const [awardError, setAwardError] = React.useState(false);

  const [summary, setSummary] = useState("");
  const [photo, setPhoto] = useState("");

  const [updateGivenTo, setUpdateGivenTo] = React.useState("");
  const [updateAward, setUpdateAward] = React.useState("");
  const [updateSummary, setUpdateSummary] = React.useState("");
  const [updatePhoto, setUpdatePhoto] = React.useState("");

  const [updateDate, setUpdateDate] = React.useState("");
  const [updateGivenToError, setUpdateGivenToError] = React.useState("");
  const [updateAwardError, setUpdateAwardError] = React.useState("");
  const [updateDateError, setUpdateDateError] = React.useState("");
  const [data, SetData] = useState([]);
  const [appreciationData, setAppreciationData] = useState([]);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [update, setUpdate] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getData();
  }, []);



  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };
  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getTaxSettings"
    );
    results = await results.json();
    setAppreciationData(results);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      name: "",
      label: "Given To", // to rename column
    },
    {
      name: "",
      label: "Award Name",
    },
    {
      name: "",
      label: "Given On",
    },
    {
      name: "id", // Assuming this is the name of the column containing the ID
      label: "Actions",
      cellStyle: { textAlign: "right" },
      options: {
        customBodyRender: (value, tableMeta) => (
          <>
            <div>
              <Button
                id="basic-button"
                aria-controls={openDropMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDropMenu ? "true" : undefined}
                onClick={handleClick}
              >
                <div onClick={() => handleOpenMenu(value)}>
                  <MoreHorizTwoToneIcon />
                </div>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDropMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => handleEdit(value)}
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    <EditNoteIcon /> Edit
                  </div>
                </MenuItem>
                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => handleDelete(value)}
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteForeverIcon /> Delete
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </>
        ),
      },
    },
  ];
  async function handleEdit(id) {
    const result = appreciationData.filter((item) => item.id === id);
    SetData(result[0]);
    console.log(result, "fetching data");
    handleClickUpdate();
  }
  useEffect(() => {
    setUpdateGivenTo(data.givenTo);
  }, [data.givenTo]);

  useEffect(() => {
    setUpdateAward(data.award);
  }, [data.award]);
  useEffect(() => {
    setUpdateAwardError(data.awardError);
  }, [data.awardError]);
  useEffect(() => {
    setUpdateDate(data.date);
  }, [data.date]);
  useEffect(() => {
    setUpdatePhoto(data.photo);
  }, [data.photo]);
  useEffect(() => {
    setUpdateSummary(data.summary);
  }, [data.summary]);
  useEffect(() => {
    setUpdateGivenToError(data.givenToError);
  }, [data.givenToError]);

  async function UpdateData(id) {
    const formData = new FormData();

    formData.append("givenTo", updateGivenTo);
    formData.append("date", updateDate);
    formData.append("dateError", updateDateError);
    formData.append("award", updateAward);
    formData.append("photo", updatePhoto);
    formData.append("summary", updateSummary);
    formData.append("givenToError", updateGivenToError);
    formData.append("awardError", updateAwardError);
    try {
      let response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/updateTaxSettings/${data.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }
      console.log(response.json(), "this is json response");
      handleUpdateClose();
    
      showAlert("  Updated successfully", "success");
      // setAppreciationData(updatedData);
    } catch (error) {
      console.error(error);
      showAlert("Failed to update business location", "error");
    }
  }

  async function handleDelete(id) {
    const response = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/deleteTaxSettings/" + id,
      {
        method: "DELETE",
      }
    );
    console.log(response, "response");
    
    if (response.ok) {
      getData();
      showAlert("Tax Data Deleted Successfully!", "warning");
    } else {
      showAlert("Tax Data Deleted failed!", "error");
    }
  }

  // Save Lead Sourse
  const item = {
    award,
    givenTo,
    photo,
    summary,
    date,
  };
  async function SaveData() {
    try {
      const response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/addTaxSettings`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(item),
        }
      );
      console.log(response);
      // Check if the response is okay

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      // Read response as text for debugging

      // Try parsing the response as JSON

      showAlert("Tax Data Added Successfully!", "success");
      getData();
      setOpen(false);
    } catch (error) {
      console.error("Fetch error:", error);
      showAlert("Failed to add tax data", "error");
    }
  }



  const handleDateChange = (newDate) => {
    setDate(newDate); // Update the date state
    console.log(newDate); // Log the new date to the console
  };

  const handleChange = (value) => {
    setSummary(value); // Update the state with the new value
    // console.log(value); // Print the value to the console
  };

  const handlePhoto = (e) => {
    const file = e.target.files[0];
    setPhoto(file.name);
    console.log(file.name); // You can also upload the file to a server if needed
  };

  const handleSave = () => {
    const list = { award, givenTo, date, summary, photo };
    console.log(list);
    if (award === "") {
      setAwardError(true);
    } else {
      const list = { award, givenTo, date, summary, photo };
      console.log(list);
      setAwardError(false); // Reset the error state
      handleClose(); // Close the dialog
    }
  };
  const handleCancel = () => {};
  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />
      <React.Fragment>
        <Box
          sx={{
            backgroundColor: "white",
          }}
        >
          <Typography
            sx={{
              // flex: 1,
              color: "black",
              fontSize: "20px",
              padding: "20px",
              borderBottom: "1px solid gray",
            }}
            component="div"
          >
            Add Documents
          </Typography>

          <Grid container sx={{ padding: "20px" }}>
            <Grid
              item
              xs={11}
              sm={11}
              md={11}
             
            >
              <Typography variant="p" gutterBottom style={{ color: "black" }}>
                File Name
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="---"
                    type="email"
                    onChange={(e) => setAward(e.target.value)}
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ padding: "20px" }}>
            <Typography variant="p" gutterBottom style={{ color: "black" }}>
              Upload File
            </Typography>
            <label
              htmlFor="file-upload"
              style={{
                cursor: "pointer",
                color: "black",
                border: "1px solid gray",
                borderRadius: "4px",
                padding: "10px",
                width: "100%",
                boxSizing: "border-box",
                minHeight: "100px", // Set your desired minimum height
                backgroundColor: "#fff", // Change background color to light gray
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Choose a File
            </label>
            <input
              onChange={handlePhoto} // Call handleFileChange when file changes
              type="file"
              id="file-upload"
              style={{ display: "none" }}
            />
            {photo && <div style={{ color: "black" }}>{photo}</div>}
          </Grid>

          <Grid container sx={{ padding: "10px 10px 20px 10px" }}>
            <Grid item sx={11} className="messageFooter">
              <Box className="response" onClick={handleSave}>
                Save
              </Box>
              <Box className="cancel" onClick={handleCancel}>
                Cancel
              </Box>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    </>
  );
}

export default Documents;
