import React from "react";

import SettingPageLayout from "./SettingPageLayout";
import AssetsSettings from "./AssetsSettings/AssetsSettings";

function MainAssetsSettings() {
  return (
    <SettingPageLayout>
      <AssetsSettings />
    </SettingPageLayout>
  );
}

export default MainAssetsSettings;
