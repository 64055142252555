import React, { useContext, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { ColorModeContext, themeSettings, tokens } from "../../theme";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MessageIcon from "@mui/icons-material/Message";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useLocation, Link, useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MenuIcon from "@mui/icons-material/Menu";
import LocationPath from "./LocationPath";
import { useLocalStorageContext } from "../../Context/LocalProvider";

function Topbar({ toggleSidebar }) {
  const theme = useTheme();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { removeData } = useLocalStorageContext();
  //get the breakpoint when viewport size is down to tablet size
  const breakpoint = theme.breakpoints.down("md");
  //check if down to tablet size
  const isDown = useMediaQuery(breakpoint);

  // const user = JSON.stringify(localStorage.getItem('user-info'));

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    removeData();
    navigate("/login");
  };

  // Dynamic styles based on color mode

  // Click handler for notification icon
  const handleNotificationClick = () => {
    console.log("Notification icon clicked");
  };

  // Click handler for settings icon
  const handleSettingsClick = () => {
    console.log("messages icon clicked");
  };

  // Click handler for profile icon

  const handleNoteClick = () => {
    console.log("note icon clicked");
  };

  const handleCreateClick = () => {
    console.log("Create icon clicked");
  };

  return (
    <Box
      display="flex"
      height="100%"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        borderBottom: "1px solid gray",

        background: "#141b2d",

        paddingInline: "1rem",
      }}
    >
      {/* Menu icon */}
      {isDown && (
        <IconButton onClick={toggleSidebar}>
          <MenuIcon />
        </IconButton>
      )}
      {/* SEARCH BAR */}

      {!isDown && <LocationPath location={location} />}
      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={handleNoteClick}>
          <NoteAltIcon />
        </IconButton>
        <IconButton onClick={handleCreateClick}>
          <AddCircleIcon />
        </IconButton>

        <IconButton onClick={handleNotificationClick}>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleSettingsClick}>
          <MessageIcon />
        </IconButton>

        <IconButton onClick={handleProfileClick}>
          <PersonOutlinedIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ zIndex: 5000 }}
        >
          <MenuItem onClick={handleClose}>
            <PersonOutlinedIcon /> Profile
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <LogoutIcon />
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
}

export default Topbar;
