import { Box, Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const ViewMoreKnowledge = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuId, setOpenMenuId] = useState(null);
  const openDropMenu = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const location = useLocation();
  const rowData = location.state;

  const {
    id,
    created_at,
    title,
    description,
    user_type,
    article,
    file,
    for: forUser,
  } = rowData || {};
  const date = new Date(created_at);

  // Extract the day, month, and year from the Date object
  const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if necessary
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
  const year = date.getFullYear();

  // Format the date as "DD-MM-YYYY"
  const formattedDate = `${day}-${month}-${year}`;
  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };

  async function handleDelete() {
    const response = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/DeleteKnowledagebase/${id}`,
      {
        method: "DELETE",
      }
    );
    const result = await response.json(); // Assigning to a new variable
    navigate("/notice");
  }
  const handleClosePage = () => {
    navigate("/knowledge");
  };
  const convertToPlainText = (htmlContent) => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(htmlContent, "text/html");
    return parsedHtml.body.textContent || "";
  };
  const convertToUserType = (value) => {
    if (value === "1") return "Client";
    if (value === "2") return "Employee";
    if (value === "3") return "App User";
    return "Unknown"; // Fallback for invalid values
  };
  const convertToMainUserType = (value) => {
    if (value === "1") return "Driver";
    if (value === "2") return "Owner";
    if (value === "3") return "Dhaba";
    if (value === "4") return "Mechanic";
    if (value === "5") return "Crane Owner";
    if (value === "6") return "Crane Operator";

    return "Unknown"; // Fallback for invalid values
  };
  return (
    <Box
      sx={{
        color: "#333",
        padding: "20px",
        fontSize: "20px",
        fontWeight: 550,
      }}
    >
      <Box
        sx={{
          color: "#333",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>Knowledge Base</Box>
        <Box
          onClick={handleClosePage}
          sx={{
            color: "#333",
            display: "flex",
            alignItems: "center",
            border: "1px solid #333",
            padding: "2px 4px",
            borderRadius: "4px",
            cursor: "pointer",
            bgcolor: "#899",
          }}
        >
          <HighlightOffIcon /> Close
        </Box>
      </Box>

      <Box sx={{ color: "#333", bgcolor: "#fff", height: "75vh" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid rgb(187, 186, 186) ",
          }}
        >
          <Box sx={{ padding: "20px" }}>Knowledge Base Details</Box>
          <Box sx={{ padding: "15px" }}>
            {" "}
            <div style={{ paddingRight: "20px" }}>
              <Button
                id="basic-button"
                aria-controls={openDropMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDropMenu ? "true" : undefined}
                onClick={handleClick}
              >
                <div onClick={() => handleOpenMenu()}>
                  <MoreHorizTwoToneIcon />
                </div>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDropMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleCloseMenu}>
                  <div
                    // onClick={() => handleEdit()}
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    <EditNoteIcon /> Edit
                  </div>
                </MenuItem>
                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => handleDelete()}
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteForeverIcon /> Delete
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </Box>
        </Box>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                sx={{ color: "#333", padding: "10px 20px", fontSize: "15px" }}
              >
                Article Heading
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography
                variant="body1"
                sx={{ color: "gray", padding: "10px 0", fontSize: "15px" }}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                sx={{ color: "#333", padding: "10px 20px", fontSize: "15px" }}
              >
                Article Category
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography
                variant="body1"
                sx={{ color: "gray", padding: "10px 0", fontSize: "15px" }}
              >
                {article}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                sx={{ color: "#333", padding: "10px 20px", fontSize: "15px" }}
              >
                Date
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography
                variant="body1"
                sx={{ color: "gray", padding: "10px 0", fontSize: "15px" }}
              >
                {formattedDate}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                sx={{ color: "#333", padding: "10px 20px", fontSize: "15px" }}
              >
                To
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography
                variant="body1"
                sx={{ color: "gray", padding: "10px 0", fontSize: "15px" }}
              >
                {convertToUserType(forUser)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                sx={{ color: "#333", padding: "10px 20px", fontSize: "15px" }}
              >
                User Type
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography
                variant="body1"
                sx={{ color: "gray", padding: "10px 0", fontSize: "15px" }}
              >
                {convertToMainUserType(user_type)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                sx={{ color: "#333", padding: "10px 20px", fontSize: "15px" }}
              >
                Description
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography
                variant="body1"
                sx={{ color: "gray", padding: "10px 0", fontSize: "15px" }}
              >
                {convertToPlainText(description)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                sx={{ color: "#333", padding: "10px 20px", fontSize: "15px" }}
              >
                File
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography
                variant="body1"
                sx={{ color: "gray", padding: "10px 0", fontSize: "15px" }}
              >
                {file ? (
                  <a
                    href={`https://rptpl.roadpilot.co.in/public/${file}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ cursor: "pointer" }}
                  >
                    <em>Pdf</em>
                  </a>
                ) : (
                  "No file available"
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                sx={{ color: "#333", padding: "10px 20px", fontSize: "15px" }}
              >
                Read By
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography
                variant="body1"
                sx={{ color: "gray", padding: "10px 0", fontSize: "15px" }}
              >
                ---
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewMoreKnowledge;
