import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import FieldDash from './FieldDash/FieldDash';
import FieldTimeline from './FieldTimeline/FieldTimeline';
import FieldSett from './FieldSett/FieldSett';
import FieldTasks from './FieldTasks/FieldTasks';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import TeamMem from './TeamMem/TeamMem';




function OnFieldTeam() {

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#FFFFFF', // Set white background color
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.15),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
      '&::placeholder': {
        color: theme.palette.common.black, // Set black color for the placeholder text
      },
    },
  }));
  
  

  //for tabs start
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={"div"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  //tabs end
  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Live Tracking" style={{color:"white"}}{...a11yProps(0)} />
          <Tab label="Timeline" style={{color:"white"}}{...a11yProps(1)} />
          <Tab label="Dashboard" style={{color:"white"}}{...a11yProps(2)} />
          <Tab label="Tasks" style={{color:"white"}}{...a11yProps(3)} />
          <Tab label="Team members" style={{color:"white"}}{...a11yProps(4)} />
          <Tab label="Settings" style={{color:"white"}}{...a11yProps(5)} />
        </Tabs>

    </Box>
    <CustomTabPanel value={value} index={0}>
    <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3562.6962717628603!2d80.92190367521856!3d26.75406687674198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfb53706b7d85%3A0xa2330944dc4c56e4!2sRoad%20Pilot%20technologies%20pvt%20ltd!5e0!3m2!1sen!2sin!4v1712893020203!5m2!1sen!2sin"
    style={{ border: "0",height:"550px", width: "100%" }}
    allowFullScreen=""
    loading="lazy"
    >
    </iframe>
  </CustomTabPanel>
  <CustomTabPanel value={value} index={1}>
  <FieldTimeline></FieldTimeline>
  </CustomTabPanel>
  <CustomTabPanel value={value} index={2}>
  <FieldDash></FieldDash>
  </CustomTabPanel>
  <CustomTabPanel value={value} index={3}>
  <FieldTasks></FieldTasks>
  </CustomTabPanel>
  <CustomTabPanel value={value} index={4}>
  <TeamMem></TeamMem>
  </CustomTabPanel>
  <CustomTabPanel value={value} index={5}>
  <FieldSett></FieldSett>
  </CustomTabPanel>
  
  </div>
  )
}

export default OnFieldTeam;
