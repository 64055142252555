import React, { useState } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import AdmOverview from "./AdminOverview/AdmOverview";
import AdmProject from "./AdminProj/AdmProject";
import AdmUsers from "./AdminUsers/AdminUsers";
import AdminHr from "./AdminHr/AdminHr";
import AdmTicket from "./AdminTicket/AdminTicket";
import AdmFinance from "./AdminFinance/AdminFinance";

function AdminDashboard() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{ width: "100%", height: "100vh", overflowY: "auto" }}
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const tabs = [
    { label: "Overview", component: <AdmOverview /> },
    { label: "Project", component: <AdmProject /> },
    { label: "Users", component: <AdmUsers /> },
    { label: "HR", component: <AdminHr /> },
    { label: "Ticket", component: <AdmTicket /> },
    { label: "Finance", component: <AdmFinance /> },
  ];

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderBottom: 1, borderColor: "divider", flexGrow: 1 }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Overview" style={{ color: "white" }} {...a11yProps(0)} />
          <Tab label="Project" style={{ color: "white" }} {...a11yProps(1)} />
          <Tab label="Users" style={{ color: "white" }} {...a11yProps(2)} />
          <Tab label="HR" style={{ color: "white" }} {...a11yProps(3)} />
          <Tab label="Ticket" style={{ color: "white" }} {...a11yProps(4)} />
          <Tab label="Finance" style={{ color: "white" }} {...a11yProps(5)} />
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.component}
        </CustomTabPanel>
      ))}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default AdminDashboard;
