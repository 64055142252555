import LeaveSettings from "./LeaveSettings/LeaveSettings";
import SettingPageLayout from "./SettingPageLayout";

function MainLeaveSettings() {
  return (
    <SettingPageLayout>
      <LeaveSettings />
    </SettingPageLayout>
  );
}

export default MainLeaveSettings;
