const SidebarIcons = ({ body, open, setOpen }) => {
  return (
    <div
      onClick={() => {
        setOpen((open) => !open);
      }}
    >
      {/* {open && <span> {body}</span>} */}
      {body}
    </div>
  );
};

export default SidebarIcons;
