import {
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  Menu,
  Stack,
  Select,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import AddIcon from "@mui/icons-material/Add";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { useNavigate } from "react-router-dom";

import StatusAlert from "../../StatusAlertList/StatusAlert";
import ReactQuill from "react-quill";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function KnowledgeBase() {
  const [name, setName] = React.useState("");
  const [radioButton, setRadioButton] = useState(false);

  const [uploadFile, setUploadFile] = useState(null);

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");

  const [articalData, setArticalData] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("Client");
  const [data, setData] = useState([]);
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  useEffect(() => {
    if (selectedRadio === "App User") {
      setRadioButton(true);
    } else {
      setRadioButton(false);
    }
  }, [selectedRadio]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleFileChange = (event) => {
    setUploadFile(event.target.files[0]);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseCategory = () => {
    setOpenCategory(false);
  };
  const handleUpdateEdit = () => {
    setOpenEdit(true);
  };

  const handleCancelCat = () => {
    setOpenCategory(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleCancelUpdate = () => {
    setOpenEdit(false);
  };
  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [description, setDescription] = useState("");

  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleViewMore = async () => {
    const result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/GetKnowledagebaseById/${openMenuId}`,
      {
        method: "POST",
      }
    );
    const rowData = await result.json();

    navigate(`/knowledge/${openMenuId}`, { state: rowData });

    // setOpenDialog(true);
  };

  const [updateName, setUpdateName] = React.useState("");
  const [updateRadio, setUpdateRadio] = React.useState("");

  const [updateDescription, setUpdateDescription] = React.useState("");
  const [updateCategory, setUpdateCategory] = useState(""); // Initialize state for the date
  const [updateType, setUpdateType] = useState("");
  const [userType, setUserType] = useState("");
  const [articalCategory, setArticalCategory] = useState([]);

  const [openMenuId, setOpenMenuId] = useState(null);
  let valueToSend;

  if (selectedRadio === "Client") {
    valueToSend = 1;
  } else if (selectedRadio === "Employee") {
    valueToSend = 2;
  } else if (selectedRadio === "App User") {
    valueToSend = 3;
  }
  const userInfoString = localStorage.getItem("user-info");

  // Parse the string into an object
  const userInfo = JSON.parse(userInfoString);
  const handleAllData = () => {
    setOpen(false);
    setName("");
    setDescription("");
    setUploadFile("");
    setSelectedRadio("");
    setSelectCategory("");
  };
  // Access the empid
  const empid = userInfo?.value?.empid;
  const item = {
    title: name,
    description: description,
    file: uploadFile,
    for: valueToSend,
    user_type: userType,
    article_id: selectCategory,
    creator_id: empid,
  };

  async function SaveData() {
    const formData = new FormData();

    // Append each item to the FormData object
    for (const key in item) {
      if (item[key] !== undefined && item[key] !== null) {
        formData.append(key, item[key]);
      }
    }

    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/AddKnowledagebase",
      {
        method: "POST",
        body: formData,
      }
    );

    if (!result.ok) {
      throw new Error(`HTTP error! status: ${result.status}`);
    }

    const response = await result.json();

    showAlert("Added successfully", "success");
    handleAllData();
    getData();
  }

  async function SaveCategory() {
    const formData = new FormData();

    formData.append("title", categoryName);
    formData.append("creator_id", empid);

    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/addKnowledgeArticles",
      {
        method: "POST",
        body: formData,
      }
    );

    if (result.ok) {
      showAlert("Added successfully", "success");

      setOpenCategory(false);
      getDataCat();
      setCategoryName("");
    }
  }

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/GetKnowledagebases"
    );
    const response = await results.json();

    // Map through response to convert notice_to values
    const updatedResponse = response.map((notice) => ({
      ...notice,
      for: convertToUserType(notice.for),
      user_type: convertToMainUserType(notice.user_type), // convert the value
    }));

    setArticalData(updatedResponse); // Store the updated response
  }

  const convertToUserType = (value) => {
    if (value === "1") return "Client";
    if (value === "2") return "Employee";
    if (value === "3") return "App User";
    return "Unknown"; // Fallback for invalid values
  };
  const convertToMainUserType = (value) => {
    if (value === "1") return "Driver";
    if (value === "2") return "Owner";
    if (value === "3") return "Dhaba";
    if (value === "4") return "Mechanic";
    if (value === "5") return "Crane Owner";
    if (value === "6") return "Crane Operator";

    return "Unknown"; // Fallback for invalid values
  };
  useEffect(() => {
    getDataCat();
  }, []);
  const handleClickOpenCategory = () => {
    setOpenCategory(true);
  };
  async function getDataCat() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getAllKnowledgeArticles"
    );
    const response = await results.json();

    // Map through response to convert notice_to values

    setArticalCategory(response); // Store the updated response
  }

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      name: "title",
      label: "Article Heading",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Article Heading
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "article",
      label: "Article Category",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Article Category
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "for",
      label: "To",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              To
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "70px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                paddingRight: "20px", // Add padding for starting point
              }}
            >
              <div style={{ paddingRight: "20px" }}>
                <Button
                  id="basic-button"
                  aria-controls={openDropMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div onClick={() => handleOpenMenu(value)}>
                    <MoreHorizTwoToneIcon />
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleViewMore(tableMeta.rowData)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <RemoveRedEyeIcon /> View More
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleEdit()}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EditNoteIcon /> Edit
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleDelete(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <DeleteForeverIcon /> Delete
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </>
        ),
      },
    },
  ];
  const columnsNew = [
    {
      name: "title",
      label: "Category Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Category Name
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "70px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                paddingRight: "20px", // Add padding for starting point
              }}
            >
              <Button variant="outlined">
                <div
                  onClick={() => handleDeleteCat(value)}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <DeleteForeverIcon /> Delete
                </div>
              </Button>
            </div>
          </>
        ),
      },
    },
  ];
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  async function handleEdit() {
    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/GetKnowledagebaseById/" +
        openMenuId,
      { method: "POST" }
    );
    const resultNew = await result.json();
    const updatedResponse = {
      ...resultNew,
      for: convertToUserType(resultNew.for),
      user_type: convertToMainUserType(resultNew.user_type), // convert the value
    };

    setData(updatedResponse);
    handleUpdateEdit();
  }

  // Update Values Start

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setUpdateName("");
    setUpdateRadio("");
    setUpdateDescription("");
    setUploadFile("");
    setUpdateCategory("");
  };

  useEffect(() => {
    setUpdateName(data.title);
  }, [data.title]);

  useEffect(() => {
    setUpdateRadio(data.for);
  }, [data.for]);

  useEffect(() => {
    setUpdateDescription(data.description);
  }, [data.description]);

  useEffect(() => {
    setUploadFile(data.file);
  }, [data.file]);
  useEffect(() => {
    setUpdateCategory(data.article);
  }, [data.article]);
  useEffect(() => {
    setUpdateCategory(data.user_type);
  }, [data.user_type]);

  useEffect(() => {
    if (updateRadio === "App User") {
      setRadioButton(true);
    } else {
      setRadioButton(false);
    }
  }, [updateRadio]);
  let valueTouser;
  if (updateRadio === "Client") {
    valueTouser = 1;
  } else if (updateRadio === "Employee") {
    valueTouser = 2;
  } else if (updateRadio === "App User") {
    valueTouser = 3;
  }
  async function UpdateData() {
    const formData = new FormData();

    formData.append("title", updateName);
    formData.append("description", updateDescription);
    formData.append("file", uploadFile);
    formData.append("for", valueTouser);
    formData.append("article_id", updateCategory);
    formData.append("user_type", updateType);

    try {
      let response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/UpdateKnowledagebase/${data.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      showAlert(" updated successfully", "success");

      handleCloseEdit();
      getData(); // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      showAlert("Failed to update business location", "error");
    }
  }

  async function handleDelete() {
    const response = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/DeleteKnowledagebase/" +
        openMenuId,
      {
        method: "DELETE",
      }
    );
    await response.json(); // Assigning to a new variable

    getData();
    showAlert(" Deleted successfully", "warning");
  }
  async function handleDeleteCat(id) {
    const response = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/deleteKnowledgeArticle/" + id,
      {
        method: "DELETE",
      }
    );
    await response.json(); // Assigning to a new variable

    getDataCat();
    showAlert(" Deleted successfully", "warning");
  }
  // end delete

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };
  const optionsNew = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: 2,
    rowsPerPageOptions: [2, 4, 6, 8, 10, 12, 14, 16],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };
  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={openEdit}
        onClose={handleCloseEdit}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            position: "absolute",
            background: "#141b2d",
            top: "0",
            backgroundColor: "white",
            maxHeight: "95%",
            margin: "auto",
            overflowX: "hidden",
            zIndex: 1200,
          },
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseEdit}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} component="div">
              Update Article
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ background: "#fff" }}>
          <Grid
            container
            spacing={2}
            style={{ color: "black", margin: "10px" }}
          >
            <Grid item xs={11} sm={5} md={5}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={updateRadio}
                  onChange={(e) => setUpdateRadio(e.target.value)}
                >
                  <FormControlLabel
                    value="Employee"
                    control={
                      <Radio
                        sx={{
                          color: "black",
                          "&.Mui-checked": { color: "#1d82fs" },
                        }}
                      />
                    }
                    label="For Employees"
                  />
                  <FormControlLabel
                    value="Client"
                    control={
                      <Radio
                        sx={{
                          color: "black",
                          "&.Mui-checked": { color: "#1d82fs" },
                        }}
                      />
                    }
                    label="For Clients"
                  />
                  <FormControlLabel
                    value="App User"
                    control={
                      <Radio
                        sx={{
                          color: "black",
                          "&.Mui-checked": { color: "#1d82f5" },
                        }}
                      />
                    }
                    label="For App Users"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {radioButton && (
              <Grid item xs={11} sm={5} md={5}>
                <Typography sx={{ color: "#000", paddingBottom: "7px" }}>
                  UserType
                </Typography>
                <Select
                  value={updateType}
                  autoFocus
                  fullWidth
                  onChange={(e) => setUpdateType(e.target.value)}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  <MenuItem value={1}>Driver</MenuItem>
                  <MenuItem value={2}>Owner</MenuItem>
                  <MenuItem value={3}>Dhaba</MenuItem>
                  <MenuItem value={4}>Mechanic</MenuItem>
                  <MenuItem value={5}>Crane Owner</MenuItem>
                  <MenuItem value={6}>Crane Operator</MenuItem>
                </Select>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ color: "black", margin: "10px" }}
          >
            <Grid item xs={10.5} sm={5.5} md={5.5}>
              <Stack direction="column" spacing={2}>
                <Typography>Article Heading </Typography>
                <TextField
                  autoFocus
                  margin="dense"
                  placeholder="Article Heading Name"
                  type="text"
                  fullWidth
                  value={updateName}
                  onChange={(e) => setUpdateName(e.target.value)}
                  inputProps={{ style: { color: "black" } }}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  sx={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black",
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      "&::placeholder": { color: "gray" },
                    },
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={10.5} sm={5.5} md={5.5}>
              <Stack direction="column" spacing={2}>
                <Typography>Article Category</Typography>
                <Select
                  value={updateCategory}
                  autoFocus
                  fullWidth
                  onChange={(e) => setUpdateCategory(e.target.value)}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  {articalCategory.map((artical) => (
                    <MenuItem value={artical.id}>{artical.title}</MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
          </Grid>

          <Grid container sx={{ padding: "20px", marginBottom: "10px" }}>
            <Stack direction="column" sx={{ width: "100%" }}>
              <Typography variant="p" gutterBottom style={{ color: "black" }}>
                Description<span style={{ color: "red" }}>*</span>
              </Typography>

              <ReactQuill
                value={updateDescription}
                onChange={setUpdateDescription}
                modules={modules}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  color: "black",
                  borderBlockColor: "black",
                }}
              />
            </Stack>
          </Grid>
          <Grid
            container
            sx={{
              padding: {
                xs: "60px 10px 20px 20px",
                sm: "40px 10px 20px 20px",
                md: "20px 10px 20px 20px",
              },
            }}
          >
            <Stack direction="column" sx={{ width: "100%" }}>
              <br></br>
              <Typography variant="p" gutterBottom style={{ color: "black" }}>
                Upload File
              </Typography>
              <label
                htmlFor="file-upload"
                style={{
                  cursor: "pointer",
                  color: "black",
                  border: "1px solid black",
                  borderRadius: "4px",
                  padding: "10px",
                  width: "100%",
                  boxSizing: "border-box",
                  minHeight: "100px", // Set your desired minimum height
                  backgroundColor: "lightgray", // Change background color to light gray
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Choose a File
              </label>
              <input
                onChange={handleFileChange}
                type="file"
                id="file-upload"
                style={{ display: "none" }}
              />
              {uploadFile && (
                <div style={{ color: "black" }}>{uploadFile.name}</div>
              )}
            </Stack>
          </Grid>

          <Grid container sx={{ padding: "0 0 20px 20px" }}>
            <Grid item sx={11} className="messageFooter">
              <Box className="response" onClick={UpdateData}>
                Update
              </Box>
              <Box className="cancel" onClick={handleCloseEdit}>
                Cancel
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>

      <List
        style={{
          background: "none",
        }}
      >
        <React.Fragment>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Box
                onClick={handleClickOpen}
                sx={{
                  backgroundColor: "#1d82f5",
                  "&:hover": { backgroundColor: "orange" },
                  textAlign: "center",
                  padding: "10px",
                  marginLeft: "10px",
                  height: "40px",
                  width: "130px",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  borderRadius: "4px",
                  fontSize: "15px",
                  fontFamily: "sans-serif",
                  cursor: "pointer",
                }}
              >
                <AddIcon />
                Add Article
              </Box>
              <Box
                onClick={handleClickOpenCategory}
                sx={{
                  backgroundColor: "#fff",
                  color: "#000",
                  "&:hover": { backgroundColor: "orange" },
                  textAlign: "center",
                  padding: "10px",
                  marginLeft: "10px",
                  height: "40px",
                  width: "210px",
                  border: "1px solid gray",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  borderRadius: "4px",
                  fontSize: "15px",
                  fontFamily: "sans-serif",
                  cursor: "pointer",
                }}
              >
                <AddIcon />
                Manage Article Category
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        sx={{
                          height: "40px",
                          color: "#000",
                          padding: 0,
                          backgroundColor: "transparent",
                          "&:hover": {
                            backgroundColor: "transparent",
                            color: "#000",
                          },
                        }}
                      >
                        <SearchIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="Start typing to search"
                  sx={{
                    width: "260px",
                    height: "40px",
                    margin: ".4rem",

                    border: ".1px solid gray",
                    borderRadius: "5px",
                    color: "#000 ",
                    bgcolor: "#eff1f1",

                    boxSizing: "border-box",
                  }}
                  value={searchValue} // Bind the input value
                  onChange={handleInputChange} // Handle the input change
                />
              </FormControl>
            </Box>
          </Stack>
          <Dialog
            fullWidth
            maxWidth="md"
            open={openCategory}
            onClose={handleCloseCategory}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                position: "absolute",
                background: "#141b2d",
                top: "0",
                backgroundColor: "white",
                maxHeight: "95%",
                margin: "auto",
                overflowX: "hidden",
                zIndex: 1200,
              },
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseCategory}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                  Article Category
                </Typography>
              </Toolbar>
            </AppBar>
            <Box
              component="form"
              sx={{
                borderRadius: 1,
                boxShadow: 1,
              }}
            >
              <ListItemText style={{ background: "white", margin: 0 }}>
                <ThemeProvider theme={getTheme()}>
                  <Box
                    sx={{
                      maxWidth: "100%",
                    }}
                  >
                    <MUIDataTable
                      title={
                        <span
                          style={{
                            fontWeight: 700,
                            fontSize: "1.5rem",
                            color: "#333",
                          }}
                        >
                          Category Lists
                        </span>
                      }
                      data={articalCategory}
                      columns={columnsNew}
                      options={optionsNew}
                    />
                  </Box>
                </ThemeProvider>
              </ListItemText>

              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px" }}
              >
                <Grid item xs={10} sm={10}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Category Name
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Enter Category Name"
                        inputProps={{
                          style: {
                            color: "black",
                            border: "1px solid black",
                            borderRadius: "5px",
                          },
                        }}
                        InputLabelProps={{ style: { color: "black" } }}
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container sx={{ padding: "10px 10px 20px 10px" }}>
                <Grid item sx={11} className="messageFooter">
                  <Box className="response" onClick={SaveCategory}>
                    Save
                  </Box>
                  <Box className="cancel" onClick={handleCancelCat}>
                    Cancel
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Dialog>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                position: "absolute",
                background: "#141b2d",
                top: "0",
                backgroundColor: "white",
                maxHeight: "95%",
                margin: "auto",
                overflowX: "hidden",
                zIndex: 1200,
              },
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                  Add New Article
                </Typography>
              </Toolbar>
            </AppBar>
            <Box sx={{ background: "#fff" }}>
              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px" }}
              >
                <Grid item xs={11} sm={5} md={5}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={selectedRadio}
                      onChange={(e) => setSelectedRadio(e.target.value)}
                    >
                      <FormControlLabel
                        value="Employee"
                        control={
                          <Radio
                            sx={{
                              color: "black",
                              "&.Mui-checked": { color: "#1d82fs" },
                            }}
                          />
                        }
                        label="For Employees"
                      />
                      <FormControlLabel
                        value="Client"
                        control={
                          <Radio
                            sx={{
                              color: "black",
                              "&.Mui-checked": { color: "#1d82fs" },
                            }}
                          />
                        }
                        label="For Clients"
                      />
                      <FormControlLabel
                        value="App User"
                        control={
                          <Radio
                            sx={{
                              color: "black",
                              "&.Mui-checked": { color: "#1d82f5" },
                            }}
                          />
                        }
                        label="For App Users"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {radioButton && (
                  <Grid item xs={11} sm={5} md={5}>
                    <Typography sx={{ color: "#000", paddingBottom: "7px" }}>
                      UserType
                    </Typography>
                    <Select
                      value={userType}
                      autoFocus
                      fullWidth
                      onChange={(e) => setUserType(e.target.value)}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={1}>Driver</MenuItem>
                      <MenuItem value={2}>Owner</MenuItem>
                      <MenuItem value={3}>Dhaba</MenuItem>
                      <MenuItem value={4}>Mechanic</MenuItem>
                      <MenuItem value={5}>Crane Owner</MenuItem>
                      <MenuItem value={6}>Crane Operator</MenuItem>
                    </Select>
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px" }}
              >
                <Grid item xs={10.5} sm={5.5} md={5.5}>
                  <Stack direction="column" spacing={2}>
                    <Typography>Article Heading </Typography>
                    <TextField
                      autoFocus
                      margin="dense"
                      placeholder="Article Heading Name"
                      type="text"
                      fullWidth
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={10.5} sm={5.5} md={5.5}>
                  <Stack direction="column" spacing={2}>
                    <Typography>Article Category</Typography>
                    <Select
                      value={selectCategory}
                      autoFocus
                      fullWidth
                      onChange={(e) => setSelectCategory(e.target.value)}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      {articalCategory.map((artical) => (
                        <MenuItem value={artical.id}>{artical.title}</MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{
                  padding: "20px",
                  marginBottom: { xs: "50px", sm: "30px", md: "10px" },
                }}
              >
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Description<span style={{ color: "red" }}>*</span>
                  </Typography>

                  <ReactQuill
                    value={description}
                    onChange={setDescription}
                    modules={modules}
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      color: "black",
                      borderBlockColor: "black",
                    }}
                  />
                </Stack>
              </Grid>
              <Grid
                container
                sx={{
                  padding: {
                    xs: "60px 10px 20px 20px",
                    sm: "40px 10px 20px 20px",
                    md: "20px 10px 20px 20px",
                  },
                }}
              >
                <Stack direction="column" sx={{ width: "100%" }}>
                  <br></br>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Upload File
                  </Typography>
                  <label
                    htmlFor="file-upload"
                    style={{
                      cursor: "pointer",
                      color: "black",
                      border: "1px solid black",
                      borderRadius: "4px",
                      padding: "10px",
                      width: "100%",
                      boxSizing: "border-box",
                      minHeight: "100px", // Set your desired minimum height
                      backgroundColor: "lightgray", // Change background color to light gray
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Choose a File
                  </label>
                  <input
                    onChange={handleFileChange}
                    type="file"
                    id="file-upload"
                    style={{ display: "none" }}
                  />
                  {uploadFile && (
                    <div style={{ color: "black" }}>{uploadFile.name}</div>
                  )}
                </Stack>
              </Grid>

              <Grid container sx={{ padding: "0 0 20px 20px" }}>
                <Grid item sx={11} className="messageFooter">
                  <Box className="response" onClick={SaveData}>
                    Save
                  </Box>
                  <Box className="cancel" onClick={handleCancel}>
                    Cancel
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Dialog>
        </React.Fragment>

        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <Box
              sx={{
                maxWidth: "100%",
              }}
            >
              <MUIDataTable
                title={
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "1.5rem",
                      color: "#333",
                    }}
                  >
                    Knowledge Base Lists
                  </span>
                }
                data={articalData}
                columns={columns}
                options={options}
              />
            </Box>
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default KnowledgeBase;
