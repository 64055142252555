import React, { useState } from "react";
import "./Assests.css"; // Make sure to import your CSS file

const ViewMoreAssets = () => {
  const [showEnterButton, setShowEnterButton] = useState(false);

  const handleEllipsisHover = () => {
    setShowEnterButton(true);
  };

  const handleEllipsisLeave = () => {
    setShowEnterButton(false);
  };

  return (
    <div className="details-container">
      <div className="header">
        <h1>Asset Info</h1>
        <div
          className="ellipsis-container"
          onMouseEnter={handleEllipsisHover}
          onMouseLeave={handleEllipsisLeave}
        >
          <span className="ellipsis">...</span>
          {showEnterButton && <button className="enter-button">Enter</button>}
        </div>
      </div>
      <hr />
      <div className="details">
        <div className="detail">
          <strong>Asset Name</strong>
          <p>Omnis Eos</p>
        </div>
        <div className="detail">
          <strong>Asset Type</strong>
          <p>Two-Wheeler</p>
        </div>
        <div className="detail">
          <strong>Status</strong>
          <span className="status non-functional">
            <span className="status-dot"></span> Non Functional
          </span>
        </div>
        <div className="detail">
          <strong>Serial Number</strong>
          <p>LAOYDMA1</p>
        </div>
        <div className="detail">
          <strong>Value</strong>
          <p></p>
        </div>
        <div className="detail">
          <strong>Location</strong>
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default ViewMoreAssets;
