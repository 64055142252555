import React from "react";
import { Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { categoryType } from "../../../utils/customers";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditNoteIcon from "@mui/icons-material/EditNote";
const Profile = ({ state }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuId, setOpenMenuId] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    console.log(event.currentTarget, "event target");
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };

  const keys = Object.keys(state);
  const handleEdit = () => {};
  const handleConverted = () => {};
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h3"
            style={{ color: "black", marginBottom: "1rem" }}
          >
            Profile Info
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div
            style={{
              color: "black",
              textAlign: "end",
            }}
          >
            <Button
              id="basic-button"
              aria-controls={openDropMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openDropMenu ? "true" : undefined}
              onClick={handleClick}
            >
              <Typography onClick={() => handleOpenMenu()}>
                <MoreHorizIcon />
              </Typography>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openDropMenu}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleCloseMenu}>
                <div onClick={() => handleEdit()} className="MenuItemValue">
                  <EditNoteIcon /> Edit
                </div>
              </MenuItem>

              <MenuItem onClick={handleCloseMenu} className="MenuItemValue">
                <div onClick={() => handleConverted()}>Converted</div>
              </MenuItem>
            </Menu>
          </div>
          {/* <Typography style={{ color: "black", textAlign: "right" }}>
            <MoreHorizIcon />
          </Typography> */}
        </Grid>
        {keys.map((key) => {
          let value = state[key];
          if (key === "role") value = categoryType[value];
          return (
            <React.Fragment key={key}>
              <Grid item xs={12} sm={6}>
                <Typography style={{ color: "gray" }}>{key}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography style={{ color: "black" }}>{value}</Typography>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </div>
  );
};

export default Profile;
