import { Grid, createTheme, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";

import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import Slide from "@mui/material/Slide";

import MUIDataTable from "mui-datatables";
import { Paper, styled } from "@mui/material";
import { Pie, PieChart } from "recharts";

import { DesignationApis } from "../../../Data/axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function TaskReport() {
  const [open, setOpen] = React.useState(false);
  const [designationList, setDesignationList] = useState([]);
  const [name, setName] = React.useState("");

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const [parent, setParent] = React.useState("");

  const [updateName, setUpdateName] = React.useState("");
  const [updateParentDesignation, setUpdateParentDesignation] =
    React.useState("");

  const [data, SetData] = useState([]);
  const [designationData, setDesignationData] = useState([]);

  const [update, setUpdate] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
    setUpdateName("");
    setUpdateParentDesignation("");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName("");
    setParent("");
  };

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const results = await DesignationApis.getAll();
    setDesignationData(results);
  }

  const columns = [
    {
      name: "",
      label: "Code",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Code
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },

    {
      name: "",
      label: "Task",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Task
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Project",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Project
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Due Date",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Due Date
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Assigned To",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Assigned To
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
  ];
  const fetchDataDesignation = async () => {
    try {
      const response = await fetch(
        "https://rptpl.roadpilot.co.in/public/api/getDesignation"
      );
      const data = await response.json();

      setDesignationList(data);
    } catch (err) {
      throw new Error("Failed to fetch data. Please try again later.");
    }
  };
  useEffect(() => {
    fetchDataDesignation();
  }, []);

  async function handleEdit(id) {
    const result = designationData.filter((item) => item.id === id);

    SetData(result[0]);

    handleClickUpdate();
  }
  useEffect(() => {
    setUpdateName(data.designation);
  }, [data.designation]);

  useEffect(() => {
    setUpdateParentDesignation(data.parent);
  }, [data.parent]);

  async function updateDesignation() {
    const creator_name = localStorage.getItem("empid");
    const formData = new FormData();

    formData.append("designation", updateName);
    formData.append("parent", updateParentDesignation);
    formData.append("creator", creator_name);
    try {
      await DesignationApis.update(data.id, formData);

      handleUpdateClose();

      getData();
      setName("");
      setParent("");
      showAlert("  Updated successfully", "success");
    } catch (error) {
      console.error(error);
      showAlert("Failed to update ", "error");
    }
  }

  async function handleDelete(id) {
    await DesignationApis.deletebyId(id);

    getData();
    showAlert(" Deleted Successfully!", "warning");
  }

  async function AddDepartment() {
    const creator_name = localStorage.getItem("empid");
    const formData = new FormData();

    formData.append("designation", name);
    formData.append("parent", parent);

    formData.append("creator", creator_name);

    await DesignationApis.add(formData);

    showAlert(" Added successfully", "success");

    getData();
    handleClose();
  }

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Item
            style={{
              height: "280px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              backgroundColor: "#fff",
              color: "#000",
            }}
          >
            <PieChart width={400} height={240}>
              <Pie
                data={[
                  { name: "Incomplete", value: 30 },
                  { name: "To Do", value: 15 },
                  { name: "Doing", value: 40 },
                  { name: "Completed", value: 15 },
                ]}
                dataKey="value"
                label={({ name, percent }) =>
                  `${name} (${(percent * 100).toFixed(0)}%)`
                }
                labelStyle={{
                  fill: "#000", // Set label color to black
                  fontSize: "14px", // Adjust font size
                }}
                outerRadius={80}
                fill="#8884d8"
              />
            </PieChart>
          </Item>
        </Grid>
      </Grid>
      <List>
        <Divider />
        <ListItemText style={{ background: "white", margin: 0, width: "100%" }}>
          <ThemeProvider theme={getTheme()}>
            <MUIDataTable
              title={
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    color: "#333",
                  }}
                >
                  Task Report Lists
                </span>
              }
              data={designationData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default TaskReport;
