import * as React from "react";

import styles from "./Department.module.css";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

export default function HeaderDepartment() {
  return (
    <Box className={styles.top}>
      <Box
        sx={{
          width: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className={styles.box}
      >
        <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  sx={{
                    height: "40px",
                    color: "#000",
                    padding: 0,
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#000",
                    },
                  }}
                >
                  <SearchIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
            placeholder="Start typing to search"
            sx={{
              width: "260px",
              height: "40px",
              margin: ".4rem",

              border: ".1px solid gray",
              borderRadius: "5px",
              color: "#000 ",
              bgcolor: "#eff1f1",

              boxSizing: "border-box",
            }}
          />
        </FormControl>
      </Box>
    </Box>
  );
}
