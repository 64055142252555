import React, { createContext, useState, useEffect, useContext } from "react";
import {
  setItemWithExpiry,
  getItemWithExpiry,
} from "../utils/localStorageUtils";
import { useNavigate } from "react-router-dom";

export const LocalStorageContext = createContext();
const key = "user-info";

export const LocalProvider = ({ children }) => {
  const [data, setData] = useState(getItemWithExpiry(key));

  const navigate = useNavigate();
  useEffect(() => {
    const storedData = getItemWithExpiry(key);

    if (storedData) {
      setData(storedData);
      // navigate("/rptpl")
    } else {
      setData(null);
    }
  }, []);

  const saveData = (value, delay) => {
    setItemWithExpiry(key, value, delay);
    setData(value);
  };
  const removeData = () => {
    localStorage.removeItem(key);
    setData(null);
  };
  return (
    <LocalStorageContext.Provider value={{ data, saveData, removeData }}>
      {children}
    </LocalStorageContext.Provider>
  );
};

export const useLocalStorageContext = () => {
  return useContext(LocalStorageContext);
};
