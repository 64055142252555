import React from "react";

const ViewMoreJobs = () => {
  return (
    <div style={{ background: "#fff", height: "500px", color: "#000" }}>
      ViewMoreJobs
    </div>
  );
};

export default ViewMoreJobs;
