import {
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  Menu,
  Stack,
  Select,
  FormControl,
  OutlinedInput,
  TextareaAutosize,
  Checkbox,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AddIcon from "@mui/icons-material/Add";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { styled } from "@mui/material/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
} from "@mui/material";

import {
  BusinessSettingsApi,
  clientApis,
  clientInvoiceApis,
  financeSettingsApi,
} from "../../../Data/axios";
import config from "../../../Data/AuthUser";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import StatusAlert from "../../../StatusAlertList/StatusAlert";
import HeaderInvoices from "./HeaderInvoices";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Invoices() {
  const [startDate, setStartDate] = React.useState(null);
  const [dueDate, setDueDate] = React.useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [invoiceValue, setInvoiceValue] = useState(null);
  const [invoiceStatus, setInvoiceStatus] = useState(null);
  const [uploadFileMain, setUploadFileMain] = useState(null);
  const [payDetail, setPayDetail] = useState(null);
  const [about, setAbout] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [inputDescription, setInputDescription] = useState("");
  const [openClient, setOpenClient] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [departments, setDepartments] = useState([]);
  const [invoicePrefix, setInvoicePrefix] = useState("");
  const [invoiceSeparator, setInvoiceSeparator] = useState("");
  const [invoiceDigits, setInvoiceDigits] = useState("");
  const [optionsValue, setOptionsValue] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [hide, setHide] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [inputValue, setInputValue] = useState("");

  // Function to handle input change
  const handleInputChangeInvoice = (event) => {
    setInputValue(event.target.value);
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const handleClickNew = () => {
    setHide((prev) => !prev);
  };

  const StyledCheckBoxOutlineBlankIcon = styled(CheckBoxOutlineBlankIcon)({
    fontSize: "1.5rem", // Set the size you want
  });

  const StyledCheckBoxIcon = styled(CheckBoxIcon)({
    fontSize: "1.5rem", // Set the size you want
  });
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const [detailValue, setDetailValue] = useState("");
  const location = useLocation();
  const openMenuIdNew = location.state;
  useEffect(() => {
    getDataPass();
  }, []);
  const getDataPass = async () => {
    const rowData = await clientApis.clientViewMoreId(openMenuIdNew);
    setDetailValue(rowData);
  };

  const { client, app_user } = detailValue || {};

  const { user_id, company_address, company_name } = client || {};
  const { username, image, id } = app_user || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await financeSettingsApi.getAll();
        const settings = response[0];
        setInvoicePrefix(settings.invoice_prefix || "");
        setInvoiceSeparator(settings.invoice_start_separator || "");
        setInvoiceDigits(settings.Invoice_digits || "");
      } catch (error) {
        console.error("Error fetching finance settings:", error);
      }
    };

    fetchData();
  }, []);
  const generateExample = (prefix, separator, digits) => {
    const digitCount = parseInt(digits, 10);
    if (isNaN(digitCount) || digitCount < 1) {
      return "";
    }
    const number = String("0".repeat(digitCount - 1) + "1").slice(-digitCount);
    return `${prefix}${separator}${number}`;
  };
  const [driverId, setDriverId] = useState(""); // State for driver_id input
  const [optionsNew, setOptionsNew] = useState([]); // State for options fetched from API
  const [loadingNew, setLoadingNew] = useState(false); // Loading state for API request
  const [driverSelections, setDriverSelections] = useState([]); // State to store driver data for each row

  // Function to handle changes in the driver selection for a specific row
  const handleDriverChange = (rowIndex, selectedDriver) => {
    const updatedSelections = [...driverSelections]; // Copy the current state
    updatedSelections[rowIndex] = selectedDriver; // Update the driver for the specific row
    setDriverSelections(updatedSelections); // Save the updated selections
  };
  useEffect(() => {
    fetchClientDriver();
  }, [user_id]);
  const fetchClientDriver = async () => {
    const response = await clientInvoiceApis.clientDriverById(user_id);
    setOptionsValue(response.data);
  };
  useEffect(() => {
    if (driverId.length > 17) {
      setLoadingNew(true);
      fetchOptionsByDriverId(driverId)
        .then((response) => {
          setOptionsNew([response]); // Ensure response is an array before setting options
          setLoadingNew(false);
        })
        .catch((error) => {
          console.error("Error fetching options:", error);
          setOptionsNew([]);
          setLoadingNew(false);
        });
    } else {
      setOptionsNew([]); // Clear options if input is too short
    }
  }, [driverId]);

  // Mock API function (replace with actual API)
  const fetchOptionsByDriverId = async (driverId) => {
    // Example API call based on driver_id
    // const response = await fetch(
    //   `https://rptpl.roadpilot.co.in/public/api/getDriverDataForPortal/${driverId}`,
    //   {
    //     method: "POST",
    //   }
    // );
    // const data = await response.json();
    const response = await clientInvoiceApis.driverById(driverId);
    return response.user; // Adjust based on your API response structure
  };

  const handleCloseClient = () => {
    setOpenClient(false);
  };
  const handleAddClient = () => {
    setOpenClient(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUploadFile(file);
  };
  // const handleFileChangeMain = (e) => {
  //   setUploadFileMain(e.target.files[0]);
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Event handler to update the date state and log the date to the console
  const handleDateChange = (newDate) => {
    // setDate(newDate); // Update the date state
  };

  const navigate = useNavigate();
  const [invoiceData, setInvoiceData] = useState([]);
  const [currencyValue, setCurrencyValue] = useState([]);
  const [businessLocation, setBusinessLocation] = useState([]);
  const [currencyItem, setCurrencyItem] = useState([]);
  const [optionItem, setOptionItem] = useState(null);
  const [locationValue, setLocationValue] = useState(null);
  const [taxType, setTaxType] = useState(null);
  const [update, setUpdate] = React.useState(false);
  const [shippingAddress, setShippingAddress] = useState("");
  const [note, setNote] = useState("");
  const [terms, setTerms] = useState("");
  const [selectedRow, setSelectedRow] = React.useState({
    keys: [],
    values: [],
    open: false,
  });

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };
  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };

  const [rows, setRows] = useState([
    {
      // description: "",
      quantity: "",
      unit: "1",
      unitPrice: "",
      tax: [],
      amount: "0.00",
    },
  ]);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [discountValue, setDiscountValue] = useState("");
  const [discountType, setDiscountType] = useState("2");
  const taxOptions = [
    { id: 1, label: "GST: 10%", rate: 10 },
    { id: 2, label: "CGST: 18%", rate: 18 },
    { id: 3, label: "VAT: 5%", rate: 5 },
  ];
  const [descriptions, setDescriptions] = useState([]); // State to store descriptions for each row
  const [files, setFiles] = useState([]);
  const handleDescriptionChange = (index, value) => {
    console.log(`Row description ${index} changed to ${value}`);
    const newDescriptions = [...descriptions];
    newDescriptions[index] = value;
    setDescriptions(newDescriptions);
  };
  const [items, setItems] = useState([]); // State to store descriptions for each row

  const handleItemsChange = (index, value) => {
    console.log(`Row ${index} changed to ${value}`);
    const newItems = [...items];
    newItems[index] = value;
    setItems(newItems);
  };
  const handleFileChangeMain = (index, event) => {
    const newFiles = [...files];
    newFiles[index] = event.target.files[0];
    setFiles(newFiles);
  };

  // Function to update values in the table
  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    // Update amount when quantity, unitPrice, or tax changes
    if (field === "quantity" || field === "unitPrice" || field === "tax") {
      const quantity = parseFloat(updatedRows[index].quantity) || 0;
      const unitPrice = parseFloat(updatedRows[index].unitPrice) || 0;
      const selectedTaxes = updatedRows[index].tax.map((taxId) => {
        return taxOptions.find((tax) => tax.id === taxId)?.rate || 0;
      });
      const tax = selectedTaxes.reduce(
        (acc, rate) => acc + (quantity * unitPrice * rate) / 100,
        0
      );
      const amount = quantity * unitPrice + tax;
      updatedRows[index].amount = amount.toFixed(2);
    }

    setRows(updatedRows);
  };

  // Function to add a new row

  const handleAddressChange = (event) => {
    setShippingAddress(event.target.value);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };
  const handleTermsChange = (event) => {
    setTerms(event.target.value);
  };
  const addRow = () => {
    setRows([
      ...rows,
      {
        // description: "",
        quantity: "",
        unit: "1",
        unitPrice: "",
        tax: [],
        amount: "",
      },
    ]);
  };
  const removeRow = (index) => {
    const updatedRows = rows.filter((row, i) => i !== index); // Remove row at given index
    setRows(updatedRows);
  };

  // Calculate the subtotal for all rows
  useEffect(() => {
    const subtotalValue = rows.reduce(
      (acc, row) => acc + parseFloat(row.amount || 0),
      0
    );
    setSubtotal(subtotalValue);
  }, [rows]);

  // Calculate the total based on subtotal, discount, and taxes
  useEffect(() => {
    let discountAmount = 0;
    if (discountType === "percent") {
      discountAmount = (subtotal * discountValue) / 100;
    } else {
      discountAmount = parseFloat(discountValue) || 0;
    }
    const totalValue = subtotal - discountAmount;
    setTotal(totalValue.toFixed(2));
  }, [subtotal, discountValue, discountType]);

  const handleViewMore = () => {
    const rowData = invoiceData.filter((row) => row.id === openMenuId);

    navigate(`/hr/employees/${openMenuId}`, { state: rowData[0] });
    // setOpenDialog(true);
  };
  const handleDailogClosing = () => {
    setSelectedRow({ ...selectedRow, open: false });
  };
  const handleStartDateChange = (newStartDate) => {
    setStartDate(newStartDate);
  };
  const handleDueDateChange = (newStartDate) => {
    setDueDate(newStartDate);
  };
  // function renderValue(option) {
  //   if (!option) {
  //     return null;
  //   }
  //   console.log(option, "option idside value ");
  //   const urlImageValue = `${config.apiImageUrl}${option.image}`;
  //   return (
  //     <React.Fragment>
  //       <ListItemDecorator>
  //         <Avatar
  //           size="sm"
  //           src={
  //             optionsValue.find(
  //               (o) => o.app_user.image === option.app_user.image
  //             )?.urlImageValue
  //           }
  //         />
  //       </ListItemDecorator>
  //       {option.label}
  //     </React.Fragment>
  //   );
  // }

  const [updateMember, setUpdateMember] = React.useState("");
  const [updateLeaveType, setUpdateLeaveType] = React.useState("");

  const [updateDuration, setUpdateDuration] = React.useState("");
  const [updateDate, setUpdateDate] = useState(null); // Initialize state for the date
  const [updateMemberError, setUpdateMemberError] = React.useState(false);
  const [updateReason, setUpdateReason] = useState("");
  const [updateFileName, setUpdateFileName] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [data, SetData] = useState([]);
  const userDataNew = localStorage.getItem("user-info");
  let creator;
  if (userDataNew) {
    // Parse the JSON string into an object
    const parsedData = JSON.parse(userDataNew);

    // Access the name property from the parsed object
    creator = parsedData.value.empid;
  } else {
    console.error("No user data found in localStorage.");
  }
  const [openMenuId, setOpenMenuId] = useState(null);

  const arrayValue = [];
  rows.map((row, index) => {
    // const driverData = driverSelections[index];

    const obj = {
      // item: driverData ? driverData.username : "",
      item: items[index] || "",
      description: descriptions[index] || "",
      quality_type: row.unit,
      quantity: row.quantity,
      unit_price: row.unitPrice,
      amount: row.amount,
      tax: row.tax,
      file: files[index] || null, // Access file for the specific row
    };

    return arrayValue.push(obj); // Push the constructed object into arrayValue
  });

  const updatedArray = arrayValue.map((item) => ({
    ...item,
    tax: item.tax.map(String), // Convert tax array to strings
  }));
  const timeConverter = (value) => {
    const date = new Date(value);

    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    const formattedDate = date
      .toLocaleString("en-GB", options)
      .replace(",", "");
    return formattedDate.replace(/\//g, "-");
  };
  const concatenatedValue = `${generateExample(
    invoicePrefix,
    invoiceSeparator,
    invoiceDigits
  )}${inputValue}`;
  const item = {
    client_id: user_id,
    file: uploadFile,
    authorized_by: "1202",
    invoice_to: invoiceValue,
    save_status: invoiceStatus,
    paid_on: "2024-09-30",
    paid_date: "2024-09-30",
    invoice_number: concatenatedValue,
    Invoice_date: timeConverter(startDate),
    due_date: timeConverter(dueDate),
    currency: currencyItem,
    shipping_address: shippingAddress,
    discount_id: discountType,
    payment_details: payDetail,
    calcuated_tax: taxType,
    created_by: creator,
    note: note,
    terms: terms,
    discount: discountValue,
    total_amount: total,
    paid_amount: total,
    Generated_by: locationValue,
    items: updatedArray,
  };
  async function SaveDate() {
    await clientInvoiceApis.add(item);
    showAlert(" Added successfully", "success");
    getData();
    setOpen(false);
    clearAllData();
  }
  const clearAllData = () => {
    setAnchorEl1(null);
    setDiscountValue("");
    setDescriptions([]);
    setItems([]);
    setLocationValue("");
    setUploadFile(null);
    setUploadFileMain(null);
    setStartDate(null);
    setDueDate(null);
    setInputValue("");
    setCurrencyItem([]);
    setTaxType(null);
    setShippingAddress("");
    setPayDetail(null);
    setInputValue("");
    setInvoiceStatus(null);
    setInvoiceValue(null);
    setNote("");
    setTerms("");
    setInputDescription("");
    setRows([
      {
        quantity: "",
        unit: "1",
        unitPrice: "",
        tax: [],
        amount: "0.00",
      },
    ]);
  };
  // ends Here

  // Table Starts Here

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const response = await clientInvoiceApis.getAll();

    setInvoiceData(response.invoices);
  }
  useEffect(() => {
    SaveDate();
  }, [invoiceStatus]);

  useEffect(() => {
    currencyData();
  }, []);

  async function currencyData() {
    const response = await financeSettingsApi.getAllCurrency();

    setCurrencyValue(response);
  }
  useEffect(() => {
    businessData();
  }, []);

  async function businessData() {
    const response = await BusinessSettingsApi.getAll();

    setBusinessLocation(response);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      name: "",
      label: "Code",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Code
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "invoice_number",
      label: "Invoice",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Invoice
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value || " "}
            </div>
          );
        },
      },
    },
    {
      name: "client_id",
      label: "Client",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Client
            </th>
          );
        },
        customBodyRender: (value) => {
          const imageUrl = `${config.apiImageUrl}${image}`;
          return (
            <div
              key={id}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={imageUrl}
                alt="requester"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: "10px",
                }}
              />
              <div>
                <div style={{ fontWeight: "bold" }}>{company_name || ""}</div>
                <div>{username || ""}</div>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "total_amount",
      label: "Total",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Total
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[1];

          const fullRecord = invoiceData.find(
            (item) => item.invoice_number === ticketId
          );

          if (!fullRecord) {
            return <div>Loading...</div>;
          }

          return (
            <div
              key={fullRecord.id}
              style={{
                display: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>Total:</div>
                <div>Rs.{value || ""}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <div style={{ color: "green" }}>Paid:</div>
                <div>Rs.{fullRecord.paid_amount || ""}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ color: "red" }}>Unpaid: </div>
                <div>Rs.{value - fullRecord.paid_amount}</div>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "Invoice_date",
      label: " Invoice Date",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Invoice Date
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value || " "}
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value === 1 ? (
                <div
                  style={{
                    color: "red",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <FiberManualRecordIcon sx={{ fontSize: "15px" }} />
                  Unpaid
                </div>
              ) : (
                <div
                  style={{
                    color: "green",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <FiberManualRecordIcon sx={{ fontSize: "15px" }} />
                  Paid
                </div>
              )}
            </div>
          );
        },
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "30px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Actions
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <div
            key={value}
            style={{
              display: "flex",
              justifyContent: "flex-end", // Aligns content to the left
              alignItems: "center",
            }}
          >
            <div>
              <Button
                id="basic-button"
                aria-controls={openDropMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDropMenu ? "true" : undefined}
                onClick={handleClick}
              >
                <div onClick={() => handleOpenMenu(value)}>
                  <MoreHorizTwoToneIcon />
                </div>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDropMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => handleViewMore(tableMeta.rowData)}
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    <RemoveRedEyeIcon /> View More
                  </div>
                </MenuItem>

                <MenuItem onClick={handleCloseMenu}>
                  <div
                    // onClick={() => handleDelete(value)}
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteForeverIcon /> Delete
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </div>
        ),
      },
    },
  ];
  useEffect(() => {
    setUpdateMember(data.member);
  }, [data.member]);

  useEffect(() => {
    setUpdateDate(data.date);
  }, [data.date]);

  useEffect(() => {
    setUpdateDuration(data.duration);
  }, [data.duration]);

  useEffect(() => {
    setUpdateMemberError(data.managerError);
  }, [data.managerError]);

  useEffect(() => {
    setUpdateFileName(data.fileName);
  }, [data.fileName]);

  useEffect(() => {
    setUpdateReason(data.reason);
  }, [data.reason]);

  useEffect(() => {
    setUpdateLeaveType(data.leaveType);
  }, [data.leaveType]);

  useEffect(() => {
    setUpdateStatus(data.status);
  }, [data.status]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDesig, setOpenDialogDesig] = useState(false);
  const [newDepartment, setNewDepartment] = useState("");
  const [newParentDepartment, setNewParentDepartment] = useState("");
  const [newParentDesignation, setNewParentDesignation] = useState("");

  const [newDesignation, setNewDesignation] = useState("");
  const handleAddClick = () => {
    setOpenDialog(true); // Open dialog when Add button is clicked
  };
  const handleAddClickDesig = () => {
    setOpenDialogDesig(true); // Open dialog when Add button is clicked
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setNewDepartment("");
    setNewParentDepartment(""); // Reset input field
  };
  const handleDialogCloseDesig = () => {
    setOpenDialogDesig(false);
    setNewDesignation("");
    setNewParentDesignation("");
  };
  const Senior = "Senior";

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 25, 100],
    onChangeRowsPerPage: (newRowsPerPage) => {
      setRowsPerPage(newRowsPerPage);
    },
    tableBody: {
      style: {
        maxHeight: `${rowsPerPage * 50}px`,
        overflow: "auto",
        marginLeft: "5px",
        transform: "translateZ(0)",
        backfaceVisibility: "hidden",
        boxShadow: "none",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };
  const [anchorEl1, setAnchorEl1] = useState(null);

  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />
      <HeaderInvoices />
      <List
        style={{
          background: "none",
          padding: 5,
          marginTop: "10px",
        }}
      >
        <React.Fragment>
          <Stack direction="row" spacing={2} sx={{ marginBottom: "5px" }}>
            <Box
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#1d82f5",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                padding: "10px",
                marginLeft: "2px",
                height: "40px",
                width: "130px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: "4px",
                fontSize: "15px",
                fontFamily: "sans-serif",
                cursor: "pointer",
                marginBottom: "5px",
              }}
            >
              <AddIcon />
              Add Invoice
            </Box>
          </Stack>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                position: "absolute",
                background: "#141b2d",
                top: "0",
                backgroundColor: "white",
                maxHeight: "95%",
                margin: "auto",
                overflowX: "hidden",
                zIndex: 1200,
              },
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                  New Invoice
                </Typography>
              </Toolbar>
            </AppBar>
            <Box sx={{ background: "#fff" }}>
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "20px",
                  padding: { xs: "10px", sm: "20px" },
                }}
              >
                <Grid item xs={12} sm={5.5} md={4}>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Invoice Number
                    </Typography>
                    <OutlinedInput
                      value={inputValue} // Set value from state
                      onChange={handleInputChangeInvoice} // Handle value change
                      id="outlined-adornment-weight"
                      sx={{ border: "1px solid black", color: "#000" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <Typography
                            variant="p"
                            gutterBottom
                            sx={{
                              color: "black",

                              display: "flex",
                              alignItems: "center",
                              paddingTop: "5px",
                            }}
                          >
                            {generateExample(
                              invoicePrefix,
                              invoiceSeparator,
                              invoiceDigits
                            )}
                          </Typography>
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                        border: "1px solid black",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3.5}>
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Invoice Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={startDate}
                          onChange={(date) => handleStartDateChange(date)}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={3.5}>
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Due Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={dueDate}
                          onChange={(date) => handleDueDateChange(date)}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  color: "#000",
                  padding: "20px",
                }}
              >
                <Grid item xs={12} sm={5} md={3}>
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Currency
                    </Typography>
                    <Select
                      value={currencyItem}
                      autoFocus
                      onChange={(e) => setCurrencyItem(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <span style={{ color: "gray" }}>
                              Select currency type
                            </span>
                          );
                        }

                        const selectedCurrency = currencyValue.find(
                          (item) => item.is_default === selected
                        );
                        return selectedCurrency
                          ? selectedCurrency.currency_code
                          : selected;
                      }}
                    >
                      {currencyValue.map((item) => (
                        <MenuItem value={item.is_default} key={item.id}>
                          {item.currency_code}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Calculate Tax
                    </Typography>
                    <Select
                      value={taxType}
                      autoFocus
                      onChange={(e) => setTaxType(parseInt(e.target.value, 10))}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <span style={{ color: "gray" }}>
                              Select calculated tax
                            </span>
                          );
                        }
                        return selected === 1
                          ? "After Discount"
                          : "Before Discount";
                      }}
                    >
                      <MenuItem value={1}>After Discount</MenuItem>
                      <MenuItem value={2}>Before Discount</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  color: "#000",
                  padding: "20px",
                }}
              >
                <Grid item xs={12} sm={6} md={6}>
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Billing Address
                    </Typography>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={1}
                      maxRows={2} // Set a max number of rows
                      style={{
                        maxHeight: "200px",
                        resize: "none",
                        padding: "10px",
                      }} // Limit the height
                      placeholder="e.g. 132 Street, Gomti Nagar, Lucknow"
                      value={company_address}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Shipping Address
                    </Typography>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={1}
                      maxRows={2} // Set a max number of rows
                      style={{
                        maxHeight: "200px",
                        resize: "none",
                        padding: "10px",
                      }} // Limit the height
                      placeholder="e.g. 132 Street, Gomti Nagar, Lucknow"
                      // Step 3: Bind the value and onChange handler
                      value={shippingAddress}
                      onChange={handleAddressChange}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  color: "#000",
                  padding: "20px",
                }}
              >
                <Grid item xs={11} sm={3}>
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Client
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          value={username}
                          // onChange={(e) => setEmail(e.target.value)}
                          aria-readonly
                          fullWidth
                          placeholder="eg@example.com"
                          type="email"
                          inputProps={{ style: { color: "black" } }}
                          InputLabelProps={{ style: { color: "black" } }}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Payment Details
                    </Typography>
                    <Select
                      value={payDetail}
                      autoFocus
                      onChange={(e) =>
                        setPayDetail(parseInt(e.target.value, 10))
                      }
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <span style={{ color: "gray" }}>
                              Select payment mode
                            </span>
                          );
                        }
                        return selected === 1 ? "Offline Mode" : "Online Mode";
                      }}
                    >
                      <MenuItem value={1}>Offline Mode</MenuItem>
                      <MenuItem value={2}>Online Mode</MenuItem>
                    </Select>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Generated By
                    </Typography>
                    <Select
                      value={locationValue}
                      autoFocus
                      onChange={(e) => setLocationValue(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <span style={{ color: "gray" }}>
                              Select business location
                            </span>
                          );
                        }
                        return selected;
                      }}
                    >
                      {businessLocation.map((item) => (
                        <MenuItem value={item.location} key={item.id}>
                          {item.location}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  color: "#000",
                  padding: "20px",
                  marginLeft: "0px",
                }}
              >
                <TableContainer
                  component={Paper}
                  style={{ backgroundColor: "#f9f9f9" }}
                >
                  <Table aria-label="finance table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            color: "#333",
                            width: "20%",
                          }}
                        >
                          Description
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            color: "#333",
                            width: "20%",
                          }}
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            color: "#333",
                            width: "20%",
                          }}
                        >
                          Unit Price
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            color: "#333",
                            width: "20%",
                          }}
                        >
                          Tax
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            color: "#333",
                            width: "20%",
                          }}
                        >
                          Amount
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <React.Fragment key={index}>
                          <TableRow
                            style={{ backgroundColor: "#fff" }}
                            key={index}
                          >
                            <TableCell sx={{ borderTop: "1px solid #333" }}>
                              <Select
                                labelId="department-select-label"
                                id={`department-select-${index}`}
                                value={items[index] || ""}
                                onChange={(e) =>
                                  handleItemsChange(index, e.target.value)
                                }
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: 60 * 3 + 8,
                                    },
                                  },
                                }}
                                sx={{
                                  border: "1px solid black",
                                  boxSizing: "border-box",
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "black !important",
                                    "&:hover fieldset": {
                                      borderColor: "black !important",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black !important",
                                    },
                                  },
                                  color: "black",
                                  width: "100%",
                                }}
                                renderValue={(selected) => {
                                  if (selected.length === 0) {
                                    return (
                                      <span style={{ color: "gray" }}>
                                        Select Client's Drivers
                                      </span>
                                    );
                                  }
                                  return selected;
                                }}
                              >
                                {optionsValue && optionsValue.length === 0 ? (
                                  <MenuItem disabled>
                                    No drivers available
                                  </MenuItem>
                                ) : (
                                  optionsValue?.map((option) => {
                                    const imageUrl = `${config.apiImageUrl}${option?.app_user?.image}`;
                                    return (
                                      <MenuItem
                                        key={option.corp_driver?.id}
                                        value={option.app_user?.username}
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "start",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            src={
                                              imageUrl ||
                                              "/defaultDriverImage.png"
                                            } // Fallback to default image
                                            alt={
                                              option?.app_user?.username ||
                                              "Driver"
                                            }
                                            style={{
                                              width: 30,
                                              height: 30,
                                              borderRadius: "50%",
                                              marginRight: 10,
                                            }}
                                          />
                                          {/* Driver Name and Info */}
                                          <span>
                                            {option?.app_user?.username || ""}
                                          </span>
                                        </Box>
                                        <div>
                                          {option.corp_driver?.driver_id || ""}
                                        </div>
                                      </MenuItem>
                                    );
                                  })
                                )}
                              </Select>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ borderTop: "1px solid #333" }}
                            >
                              <TextField
                                type="number"
                                value={row.quantity}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "quantity",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                placeholder="1"
                                inputProps={{ style: { color: "black" } }}
                                sx={{
                                  border: "1px solid black",
                                  borderRadius: "5px",
                                  width: "100%",
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "black !important",
                                    "&:hover fieldset": {
                                      borderColor: "black !important",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black !important",
                                    },
                                  },
                                  color: "black",
                                }}
                              />
                              <FormControl
                                fullWidth
                                sx={{ marginLeft: "150px" }}
                              >
                                <Select
                                  value={row.unit}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "unit",
                                      e.target.value
                                    )
                                  }
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                  sx={{
                                    border: "1px solid gray",
                                    height: "30px",
                                    width: "80px",
                                    boxSizing: "border-box",
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "black !important",
                                      "&:hover fieldset": {
                                        borderColor: "black !important",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "black !important",
                                      },
                                    },
                                    color: "black",
                                  }}
                                >
                                  <MenuItem value="1">Days</MenuItem>
                                  <MenuItem value="2">Pcs</MenuItem>
                                  <MenuItem value="3">Km</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ borderTop: "1px solid #333" }}
                            >
                              <TextField
                                type="number"
                                value={row.unitPrice}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "unitPrice",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                placeholder="0.00"
                                inputProps={{ style: { color: "black" } }}
                                sx={{
                                  border: "1px solid black",
                                  borderRadius: "5px",
                                  width: "100%",
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "black !important",
                                    "&:hover fieldset": {
                                      borderColor: "black !important",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black !important",
                                    },
                                  },
                                  color: "black",
                                }}
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ borderTop: "1px solid #333" }}
                            >
                              <FormControl fullWidth>
                                <Select
                                  multiple
                                  value={row.tax}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "tax",
                                      e.target.value
                                    )
                                  }
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                  sx={{
                                    border: "1px solid black",
                                    boxSizing: "border-box",
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "black !important",
                                      "&:hover fieldset": {
                                        borderColor: "black !important",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "black !important",
                                      },
                                    },
                                    color: "black",
                                  }}
                                  renderValue={(selected) =>
                                    selected.length === 0 ? (
                                      <span style={{ color: "gray" }}>
                                        Select Tax Option
                                      </span>
                                    ) : (
                                      selected
                                        .map(
                                          (id) =>
                                            taxOptions.find(
                                              (option) => option.id === id
                                            )?.label
                                        )
                                        .join(", ")
                                    )
                                  }
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                  }}
                                >
                                  {taxOptions.map((taxOption) => (
                                    <MenuItem
                                      key={taxOption.id}
                                      value={taxOption.id}
                                    >
                                      <Checkbox
                                        checked={
                                          row.tax.indexOf(taxOption.id) > -1
                                        }
                                        sx={{
                                          color: "#fff",
                                          "&.Mui-checked": {
                                            color: "#fff",
                                          },
                                        }}
                                      />
                                      <ListItemText primary={taxOption.label} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ borderTop: "1px solid #333" }}
                            >
                              <TextField
                                value={row.amount}
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                                inputProps={{ style: { color: "black" } }}
                                sx={{
                                  border: "1px solid black",
                                  borderRadius: "5px",
                                  width: "100%",
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "black !important",
                                    "&:hover fieldset": {
                                      borderColor: "black !important",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black !important",
                                    },
                                  },
                                  color: "black",
                                }}
                              />
                            </TableCell>
                            <TableCell
                              width="10%"
                              sx={{
                                padding: "10px",
                                borderTop: "1px solid #333",
                              }}
                            >
                              <IconButton onClick={() => removeRow(index)}>
                                <RemoveCircleOutlineIcon color="error" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <TableRow key={index}>
                            <TableCell
                              colSpan={4}
                              sx={{ borderBottom: "none" }}
                            >
                              <TextField
                                multiline
                                value={descriptions[index] || ""} // Use the correct description for each row
                                rows={1}
                                placeholder="Enter Description"
                                onChange={(e) =>
                                  handleDescriptionChange(index, e.target.value)
                                } // Update description for the row
                                fullWidth
                                variant="outlined"
                                inputProps={{ style: { color: "black" } }}
                                sx={{
                                  border: "1px solid black",
                                  borderRadius: "5px",
                                  width: "100%",
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "black !important",
                                    "&:hover fieldset": {
                                      borderColor: "black !important",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black !important",
                                    },
                                  },
                                  color: "black",
                                }}
                              />
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{ borderBottom: "none" }}
                            >
                              <label
                                htmlFor={`file-upload-main-${index}`}
                                style={{
                                  cursor: "pointer",
                                  color: "black",
                                  border: "1px solid black",
                                  borderRadius: "4px",
                                  padding: "10px",
                                  width: "100%",
                                  boxSizing: "border-box",
                                  minHeight: "55px",
                                  backgroundColor: "#fff",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                Choose a File
                              </label>
                              <input
                                onChange={(e) => handleFileChangeMain(index, e)}
                                type="file"
                                id={`file-upload-main-${index}`}
                                style={{ display: "none" }}
                              />
                              {files[index] && (
                                <div style={{ color: "black" }}>
                                  {files[index].name}{" "}
                                </div>
                              )}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Box
                onClick={addRow}
                sx={{
                  marginTop: "10px",
                  // width: "150px",
                  height: "60px",
                  color: "#1d82f5",
                  cursor: "pointer",
                  fontWeight: "550",
                  fontSize: "15px",
                  display: "flex",
                  paddingLeft: "30px",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <AddCircleOutlineIcon /> Add Item
              </Box>
              <Box
                sx={{
                  display: "flex",
                  paddingLeft: { lg: "4px", md: "4px", xs: "3px" },
                  backgroundColor: "#f9f9f9",
                  padding: "16px",
                  borderRadius: "4px",
                  marginTop: "10px",
                  marginBottom: "15px",
                }}
              >
                <Table width="100%" sx={{ textAlign: "right", fontSize: 14 }}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        width="50%"
                        sx={{
                          border: 0,
                          display: {
                            lg: "table-cell",
                            md: "table-cell",
                            xs: "none",
                          },
                        }}
                      ></TableCell>
                      <TableCell width="50%" sx={{ padding: 0, border: 0 }}>
                        <Table width="100%">
                          <TableBody>
                            <TableRow>
                              <TableCell
                                sx={{
                                  borderTop: " 0px",
                                  color: "#000",
                                  fontSize: "15px",
                                  fontWeight: "550",
                                }}
                              >
                                Sub Total
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderTop: 0,
                                  color: "#000",
                                  fontSize: "15px",
                                  textAlign: "right",
                                  fontWeight: "550",
                                }}
                              >
                                Rs. {subtotal.toFixed(2)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "15px",
                                  fontWeight: "550",
                                  borderBottom: "none",
                                }}
                              >
                                Discount
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "10px",
                                  color: "#000",
                                  fontSize: "15px",
                                  textAlign: "right",
                                  fontWeight: "550",
                                  borderBottom: "none",
                                }}
                              >
                                <Table width="100%">
                                  <TableBody>
                                    <TableRow
                                      sx={{
                                        display: "flex",
                                        justifyContent: "right",
                                        alignItems: "center",
                                      }}
                                    >
                                      <TableCell
                                        width="100%"
                                        sx={{
                                          borderBottom: "none",
                                          marginLeft: "150px",
                                        }}
                                      >
                                        <TextField
                                          type="number"
                                          value={discountValue}
                                          onChange={(e) =>
                                            setDiscountValue(e.target.value)
                                          }
                                          fullWidth
                                          placeholder="0"
                                          variant="outlined"
                                          inputProps={{
                                            style: {
                                              color: "black",
                                              padding: "10px",
                                            },
                                          }}
                                          sx={{
                                            border: "1px solid black",
                                            borderRadius: "5px",
                                            textAlign: "right",
                                            // padding: "10px",
                                            width: "100px",
                                            "& .MuiOutlinedInput-root": {
                                              borderColor: "black !important",
                                              "&:hover fieldset": {
                                                borderColor: "black !important",
                                              },
                                              "&.Mui-focused fieldset": {
                                                borderColor: "black !important",
                                              },
                                            },
                                            color: "black",
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                          marginRight: "100px",
                                        }}
                                      >
                                        <Select
                                          value={discountType}
                                          onChange={(e) =>
                                            setDiscountType(e.target.value)
                                          }
                                          inputProps={{
                                            style: {
                                              color: "black",
                                              padding: "0px",
                                            },
                                          }}
                                          sx={{
                                            backgroundColor: "#fff",
                                            color: "#000",
                                            height: "40px",
                                            maxWidth: "100px",
                                            border: "1px solid gray",
                                          }}
                                        >
                                          <MenuItem value="1">%</MenuItem>
                                          <MenuItem value="2">Amount</MenuItem>
                                        </Select>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderTop: 0,
                                          color: "#000",
                                          fontSize: "15px",
                                          fontWeight: "550",
                                          borderBottom: "none",
                                          display: "flex",
                                        }}
                                      >
                                        Rs.{discountValue}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                backgroundColor: "#E0E0E0",
                                fontSize: 16,
                                fontWeight: 500,
                                color: "#000",
                              }}
                            >
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "15px",
                                  fontWeight: "550",
                                }}
                              >
                                Total
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "15px",
                                  fontWeight: "550",
                                  textAlign: "right",
                                }}
                              >
                                Rs. {total}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Grid
                container
                spacing={2}
                sx={{
                  color: "#000",
                  padding: "20px",
                }}
              >
                <Grid item xs={12} sm={6} md={6}>
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Note for the recipient
                    </Typography>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={1}
                      maxRows={2} // Set a max number of rows
                      style={{
                        maxHeight: "200px",
                        resize: "none",
                        padding: "10px",
                      }} // Limit the height
                      placeholder="e.g. 132 Street, Gomti Nagar, Lucknow"
                      value={note}
                      onChange={handleNoteChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Terms and Conditions
                    </Typography>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={1}
                      maxRows={2} // Set a max number of rows
                      style={{
                        maxHeight: "200px",
                        resize: "none",
                        padding: "10px",
                      }} // Limit the height
                      placeholder="e.g. 132 Street, Gomti Nagar, Lucknow"
                      value={terms}
                      onChange={handleTermsChange}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ padding: "20px", marginTop: { xs: "10px" } }}
              >
                <Stack direction="column" sx={{ width: "100%" }}>
                  <br></br>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Add File
                  </Typography>
                  <label
                    htmlFor="file-upload"
                    style={{
                      cursor: "pointer",
                      color: "black",
                      border: "1px solid black",
                      borderRadius: "4px",
                      padding: "10px",
                      width: "100%",
                      boxSizing: "border-box",
                      minHeight: "100px", // Set your desired minimum height
                      backgroundColor: "#fff", // Change background color to light gray
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Choose a File
                  </label>
                  <input
                    onChange={handleFileChange}
                    type="file"
                    id="file-upload"
                    style={{ display: "none" }}
                  />
                  {uploadFile && (
                    <div style={{ color: "black" }}>{uploadFile.name}</div>
                  )}
                </Stack>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={3.5}>
                  <Checkbox
                    {...label}
                    onClick={handleClickNew}
                    icon={<StyledCheckBoxOutlineBlankIcon />}
                    checkedIcon={<StyledCheckBoxIcon />}
                    sx={{
                      color: "primary.main",
                      paddingLeft: "15px",
                      "&.Mui-checked": {
                        color: "#1d82f5",
                      },
                    }}
                  />
                  <span style={{ fontSize: "1rem", color: "#000" }}>
                    <span>I have received the payment</span>
                  </span>
                </Grid>

                {hide && (
                  <Grid item xs={12} sm={3.5}>
                    <Stack direction="column">
                      <Typography
                        variant="p"
                        gutterBottom
                        style={{ color: "black" }}
                      >
                        Payment Gateway
                      </Typography>
                      <Select
                        value={about}
                        autoFocus
                        onChange={(e) => setAbout(e.target.value)}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          border: "1px solid black",
                          boxSizing: "border-box",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black !important",
                            "&:hover fieldset": {
                              borderColor: "black !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black !important",
                            },
                          },
                          color: "black",
                        }}
                      >
                        <MenuItem disabled value={Senior}>
                          {" "}
                          Offline Payment
                        </MenuItem>
                      </Select>
                    </Stack>
                  </Grid>
                )}
                {hide && (
                  <Grid item xs={8} sm={3.5}>
                    <Stack direction="column">
                      <Typography
                        variant="p"
                        gutterBottom
                        style={{ color: "black" }}
                      >
                        Transaction Id
                        <span style={{ color: "red", fontSize: "15px" }}>
                          *
                        </span>
                      </Typography>

                      <TextField
                        fullWidth
                        // onChange={(e) => setMobile(e.target.value)}
                        placeholder="Enter the Transaction Id of payments"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Stack>
                  </Grid>
                )}
              </Grid>
              <Grid container sx={{ padding: "10px 0 20px 38px" }}>
                <Grid item xs={11} className="messageFooter">
                  <Box className="response">
                    <div>
                      <Box
                        id="basic-button"
                        aria-controls={open1 ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open1 ? "true" : undefined}
                        onClick={handleClick1}
                      >
                        Save
                      </Box>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl1}
                        open={open1}
                        onClose={handleClose1}
                        sx={{ zIndex: 3000 }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          value="closed"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setInvoiceStatus(1);
                            setInvoiceValue(0);
                          }}
                        >
                          <SaveOutlinedIcon fontSize="small" color="gray" />{" "}
                          &nbsp; Save as Draft
                        </MenuItem>
                        <MenuItem
                          value="open"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setInvoiceStatus(2);
                            setInvoiceValue(0);
                          }}
                        >
                          {" "}
                          <SaveOutlinedIcon
                            fontSize="small"
                            sx={{ color: "gray" }}
                          />{" "}
                          &nbsp; Save
                        </MenuItem>
                        <MenuItem
                          value="pending"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setInvoiceStatus(3);
                            setInvoiceValue(0);
                          }}
                        >
                          {" "}
                          <SendOutlinedIcon
                            fontSize="small"
                            sx={{ color: "gray" }}
                          />{" "}
                          &nbsp; Save & Send
                        </MenuItem>
                        <MenuItem
                          value="resolved"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setInvoiceStatus(4);
                            setInvoiceValue(1);
                          }}
                        >
                          {" "}
                          <DoneAllOutlinedIcon
                            color="success"
                            fontSize="small"
                          />{" "}
                          &nbsp; Save & Mark as Send
                        </MenuItem>
                      </Menu>
                    </div>
                  </Box>
                  <Box className="cancel" onClick={handleClose}>
                    Cancel
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Dialog>
        </React.Fragment>

        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <Box
              sx={{
                maxWidth: "100%",
              }}
            >
              <MUIDataTable
                title={
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "1.5rem",
                      color: "#333",
                    }}
                  >
                    Invoice Lists
                  </span>
                }
                data={invoiceData}
                columns={columns}
                options={options}
              />
            </Box>
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default Invoices;
