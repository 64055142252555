import { Grid, Paper, styled, Typography } from "@mui/material";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
const TicketInfo = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <Grid
      container
      spacing={3}
      sx={{
        padding: "15px",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <Grid item xs={12} sm={6} md={4} lg={2.3}>
        <Item sx={{ backgroundColor: "#fff", color: "#333" }}>
          <Typography
            gutterBottom
            variant="subtitle1"
            style={{ fontSize: "18px", textAlign: "left", paddingLeft: "20px" }}
          >
            Total Tickets
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textAlign: "right" }}
          >
            <LocalActivityIcon sx={{ color: "#a7adad " }} />
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontSize: "16px",
              color: "#1d82f5",
              fontWeight: 550,
              textAlign: "left",
              paddingLeft: "20px",
            }}
          >
            275
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2.3}>
        <Item sx={{ backgroundColor: "#fff", color: "#333" }}>
          <Typography
            gutterBottom
            variant="subtitle1"
            style={{ fontSize: "18px", textAlign: "left", paddingLeft: "20px" }}
          >
            Open Tickets
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textAlign: "right" }}
          >
            <LocalActivityIcon sx={{ color: "#a7adad " }} />
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontSize: "16px",
              color: "#1d82f5",
              fontWeight: 550,
              textAlign: "left",
              paddingLeft: "20px",
            }}
          >
            42
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2.3}>
        <Item sx={{ backgroundColor: "#fff", color: "#333" }}>
          <Typography
            gutterBottom
            variant="subtitle1"
            style={{ fontSize: "18px", textAlign: "left", paddingLeft: "20px" }}
          >
            Closed Tickets
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textAlign: "right" }}
          >
            <LocalActivityIcon sx={{ color: "#a7adad " }} />
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontSize: "16px",
              color: "#1d82f5",
              fontWeight: 550,
              textAlign: "left",
              paddingLeft: "20px",
            }}
          >
            140
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2.3}>
        <Item sx={{ backgroundColor: "#fff", color: "#333" }}>
          <Typography
            gutterBottom
            variant="subtitle1"
            style={{ fontSize: "18px", textAlign: "left", paddingLeft: "20px" }}
          >
            Pending Tickets
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textAlign: "right" }}
          >
            <LocalActivityIcon sx={{ color: "#a7adad " }} />
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontSize: "16px",
              color: "#1d82f5",
              fontWeight: 550,
              textAlign: "left",
              paddingLeft: "20px",
            }}
          >
            140
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2.3}>
        <Item sx={{ backgroundColor: "#fff", color: "#333" }}>
          <Typography
            gutterBottom
            variant="subtitle1"
            style={{ fontSize: "18px", textAlign: "left", paddingLeft: "20px" }}
          >
            Resolved Tickets
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ textAlign: "right" }}
          >
            <LocalActivityIcon sx={{ color: "#a7adad " }} />
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontSize: "16px",
              color: "#1d82f5",
              fontWeight: 550,
              textAlign: "left",
              paddingLeft: "20px",
            }}
          >
            12
          </Typography>
        </Item>
      </Grid>
    </Grid>
  );
};

export default TicketInfo;
