import {
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  Menu,
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogTitle,
  DialogActions,
  Select,
  FormLabel,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import AddIcon from "@mui/icons-material/Add";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { useNavigate } from "react-router-dom";
import TicketInfo from "./TicketInfo";
import StatusAlert from "../../StatusAlertList/StatusAlert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TicketHeader from "./TicketHeader";
import config from "../../Data/AuthUser";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Tickets() {
  const [name, setName] = React.useState("");
  const [priorityTicket, setPriorityTicket] = React.useState("");
  const [selectedGroup, setSelectedGroup] = React.useState("");
  const [selectedType, setSelectedType] = React.useState("");
  const [selectedAgent, setSelectedAgent] = React.useState("");
  const [selectedChannel, setSelectedChannel] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [statusValue, setStatusValue] = React.useState("");
  const [requesterId, setRequesterId] = React.useState("");
  const [requester, setRequester] = useState("Client");

  const [uploadFile, setUploadFile] = useState(null);

  const [tagTicket, setTagTicket] = useState("");

  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const handleChange = (event) => {
    setRequester(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleFileChange = (event) => {
    setUploadFile(event.target.files[0]);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);
  const [update, setUpdate] = React.useState(false);

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };
  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleViewMore = () => {
    const rowData = employeeData.filter((row) => row.id === openMenuId);

    navigate(`/tickets/${openMenuId}`, { state: rowData[0] });
    // setOpenDialog(true);
  };

  const [selection, setSelection] = useState("open");
  const handleChangeSelected = (event, newValue) => {
    setSelection(newValue);
  };
  // Function Starts Here

  const [updateMember, setUpdateMember] = React.useState("");
  const [updateLeaveType, setUpdateLeaveType] = React.useState("");

  const [updateDuration, setUpdateDuration] = React.useState("");
  const [updateDate, setUpdateDate] = useState(null); // Initialize state for the date
  const [updateMemberError, setUpdateMemberError] = React.useState(false);
  const [updateReason, setUpdateReason] = useState("");
  const [updateFileName, setUpdateFileName] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [data, SetData] = useState([]);

  const [openMenuId, setOpenMenuId] = useState(null);
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  const statusType = ["Open", "Closed", "Assigned", "Resolved"];

  const item = {
    ticket_priority_id: priorityTicket,
    assing_group: selectedGroup,
    agent: selectedAgent,
    ticket_channel: selectedChannel,
    subject: subject,
    requester: requester,
    file: uploadFile,
    tags: tagTicket,
    requester_id: requesterId,
    status: "1",
  };

  async function SaveDate() {
    // Create a new FormData object
    const formData = new FormData();

    // Append each item to the FormData object
    for (const key in item) {
      if (item[key] !== undefined && item[key] !== null) {
        formData.append(key, item[key]);
      }
    }

    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/createtickets",
      {
        method: "POST",
        body: formData,
      }
    );

    result = await result.json();

    showAlert("Added successfully", "success");

    getData();
    setOpen(false);
  }

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getTickets"
    );

    const response = await results.json();
    setEmployeeData(response);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  function formatDate(isoString) {
    const date = new Date(isoString);

    // Get the date components
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    // Get the time components
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'

    return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
  }

  const columns = [
    {
      name: "id",
      label: "Ticket",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Ticket
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value || " "}
            </div>
          );
        },
      },
    },
    {
      name: "subject",
      label: "Ticket Subject",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Ticket Subject
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "requester_name",
      label: "Requestor Name",
      options: {
        customHeadRender: ({ index }) => (
          <th
            key={index}
            style={{
              textAlign: "center",
              borderBottom: "1px solid #ddd",
            }}
          >
            Requestor Name
          </th>
        ),
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0];
          const fullRecord = employeeData.find((item) => item.id === ticketId);

          if (!fullRecord) {
            return <div>Loading...</div>;
          }

          const imageUrl = `${config.apiImageUrl}${fullRecord.requester_Image}`;
          const requesterId = fullRecord.requester_id;

          return (
            <div
              key={value}
              style={{
                display: "flex",
              }}
            >
              <img
                src={imageUrl}
                alt="requester"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: "10px",
                }}
              />
              <div>
                <div style={{ fontWeight: "bold" }}>{value || "Unknown"}</div>
                <div style={{ fontSize: "12px", color: "#888" }}>
                  ID: {requesterId || "N/A"}
                </div>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Requested On",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Requested On
            </th>
          );
        },
        customBodyRender: (value) => {
          const formattedDate = formatDate(value);
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {formattedDate}
            </div>
          );
        },
      },
    },
    {
      name: "agent || priority",
      label: "Others",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Others
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>
                Agent:{" "}
                {(value || " ").charAt(0).toUpperCase() +
                  (value || " ").slice(1)}
              </p>
              <br />

              <p> Priority:{"high"}</p>
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Select
                value={selection}
                onChange={handleChangeSelected}
                displayEmpty
                // Use renderValue to show placeholder text when value is empty
                renderValue={(selected) => {
                  if (selected === "open") {
                    return (
                      <MenuItem
                        value="open"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                        <FiberManualRecordIcon
                          fontSize="small"
                          sx={{ color: "red" }}
                        />{" "}
                        &nbsp; Open
                      </MenuItem>
                    ); // Placeholder text
                  }
                  return selected; // Show selected value
                }}
                sx={{
                  // padding: "5px",
                  width: "130px",
                  // margin: ".4rem",

                  border: "none",
                  color: "#000 ",
                  bgcolor: "#fff",
                  "& .MuiSelect-select": {
                    padding: 0,
                  },
                }}
              >
                <MenuItem
                  value="open"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {" "}
                  <FiberManualRecordIcon
                    fontSize="small"
                    sx={{ color: "red" }}
                  />{" "}
                  &nbsp; Open
                </MenuItem>
                <MenuItem
                  value="pending"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {" "}
                  <FiberManualRecordIcon
                    fontSize="small"
                    sx={{ color: "yellow" }}
                  />{" "}
                  &nbsp; Pending
                </MenuItem>
                <MenuItem
                  value="resolved"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {" "}
                  <FiberManualRecordIcon
                    color="success"
                    fontSize="small"
                  />{" "}
                  &nbsp; Resolved
                </MenuItem>
                <MenuItem
                  value="closed"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <FiberManualRecordIcon fontSize="small" color="primary" />{" "}
                  &nbsp; Closed
                </MenuItem>
              </Select>
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "30px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Actions
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <div
            key={value}
            style={{
              display: "flex",
              justifyContent: "flex-end", // Aligns content to the left
              alignItems: "center",
            }}
          >
            <div>
              <Button
                id="basic-button"
                aria-controls={openDropMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDropMenu ? "true" : undefined}
                onClick={handleClick}
              >
                <div onClick={() => handleOpenMenu(value)}>
                  <MoreHorizTwoToneIcon />
                </div>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDropMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => handleViewMore(tableMeta.rowData)}
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    <RemoveRedEyeIcon /> View More
                  </div>
                </MenuItem>

                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => handleDelete(value)}
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteForeverIcon /> Delete
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </div>
        ),
      },
    },
  ];

  async function handleEdit(id) {
    let result = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/businesslocationget/" + id
    );
    result = await result.json();

    SetData(result);
    handleClickUpdate();
  }

  // Update Values Start

  useEffect(() => {
    setUpdateMember(data.member);
  }, [data.member]);

  useEffect(() => {
    setUpdateDate(data.date);
  }, [data.date]);

  useEffect(() => {
    setUpdateDuration(data.duration);
  }, [data.duration]);

  useEffect(() => {
    setUpdateMemberError(data.managerError);
  }, [data.managerError]);

  useEffect(() => {
    setUpdateFileName(data.fileName);
  }, [data.fileName]);

  useEffect(() => {
    setUpdateReason(data.reason);
  }, [data.reason]);

  useEffect(() => {
    setUpdateLeaveType(data.leaveType);
  }, [data.leaveType]);

  useEffect(() => {
    setUpdateStatus(data.status);
  }, [data.status]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDesig, setOpenDialogDesig] = useState(false);
  const [newDepartment, setNewDepartment] = useState("");
  const [newParentDepartment, setNewParentDepartment] = useState("");
  const [newParentDesignation, setNewParentDesignation] = useState("");

  const [newDesignation, setNewDesignation] = useState("");
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://rptpl.roadpilot.co.in/public/api/getDepartments"
      );
      const data = await response.json();

      setDepartments(data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchDataDesignation = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://rptpl.roadpilot.co.in/public/api/getDesignation"
      );
      const data = await response.json();

      setDesignations(data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDataDesignation();
  }, []);

  const handleAddClick = () => {
    setOpenDialog(true); // Open dialog when Add button is clicked
  };
  const handleAddClickDesig = () => {
    setOpenDialogDesig(true); // Open dialog when Add button is clicked
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setNewDepartment("");
    setNewParentDepartment(""); // Reset input field
  };
  const handleDialogCloseDesig = () => {
    setOpenDialogDesig(false);
    setNewDesignation("");
    setNewParentDesignation("");
  };
  async function AddDepartment() {
    const creator_name = localStorage.getItem("empid");
    let result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/addDepartment?department=${newDepartment}&parent=${newParentDesignation}&creator_id=${creator_name}`,
      {
        method: "POST",
      }
    );

    result = await result.json();

    showAlert(" Added successfully", "success");

    fetchData();
    handleDialogClose();
  }
  async function AddDesignation() {
    const creator_name = localStorage.getItem("empid");
    let result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/addDesignation?designation=${newDesignation}&parent=${newParentDesignation}&creator=${creator_name}`,
      {
        method: "POST",
      }
    );

    result = await result.json();

    showAlert(" Added successfully", "success");

    fetchDataDesignation();
    handleDialogCloseDesig();
  }

  async function UpdateData() {
    const formData = new FormData();

    formData.append("member", updateMember);
    formData.append("memberError", updateMemberError);
    formData.append("fileName", updateFileName);
    formData.append("date", updateDate);
    formData.append("reason", updateReason);
    formData.append("duration", updateDuration);
    formData.append("leaveType", updateLeaveType);
    formData.append("status", updateStatus);
    try {
      let response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/updateBusinessLocation/${data.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      showAlert("Leave updated successfully", "success");

      handleUpdateClose();
      getData(); // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      showAlert("Failed to update business location", "error");
    }
  }

  async function handleDelete(id) {
    const response = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/deleteTickets/${openMenuId}`,
      {
        method: "DELETE",
      }
    );
    await response.json(); // Assigning to a new variable

    getData();
    showAlert(" Deleted successfully", "warning");
  }

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });
  const handleCancel = () => {};
  // Table Ends Here
  const priorityType = ["Low", "Medium", "High", "Urgent"];
  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />
      <TicketHeader />
      <TicketInfo />

      <List
        style={{
          background: "none",
          padding: 5,
          marginBottom: "5px",
        }}
      >
        <React.Fragment>
          <Box
            onClick={handleClickOpen}
            // className={styles.addButton}
            sx={{
              backgroundColor: "#1d82f5",
              "&:hover": { backgroundColor: "orange" },
              textAlign: "center",
              padding: "10px",
              marginLeft: "10px",
              height: "40px",
              width: "130px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              borderRadius: "4px",
              fontSize: "15px",
              fontFamily: "sans-serif",
              cursor: "pointer",
            }}
          >
            <AddIcon /> Create Ticket
          </Box>

          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                position: "absolute",
                background: "#141b2d",
                top: "0",
                backgroundColor: "white",
                maxHeight: "95%",
                margin: "auto",
                overflowX: "hidden",
                zIndex: 1200,
              },
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                  Ticket Details
                </Typography>
              </Toolbar>
            </AppBar>
            <Box sx={{ background: "#fff" }}>
              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px" }}
              >
                <Grid item xs={8} sm={3.5} md={3.5}>
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      sx={{
                        color: "black",
                        padding: "4px",
                        borderRadius: "4px",
                      }}
                    >
                      Requester
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={requester}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Client"
                        control={
                          <Radio
                            sx={{
                              color: "black",
                              "&.Mui-checked": { color: "gray" },
                            }}
                          />
                        }
                        label="Client"
                      />
                      <FormControlLabel
                        value="Employee"
                        control={
                          <Radio
                            sx={{
                              color: "black",
                              "&.Mui-checked": { color: "gray" },
                            }}
                          />
                        }
                        label="Employee"
                      />
                      <FormControlLabel
                        value="App User"
                        control={
                          <Radio
                            sx={{
                              color: "black",
                              "&.Mui-checked": { color: "gray" },
                            }}
                          />
                        }
                        label="App User"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={8} sm={3.5} md={3.5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Requester Name
                  </Typography>

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                        <Select
                          labelId="department-select-label"
                          id="department-select"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          displayEmpty
                          sx={{
                            border: "1px solid black",
                            boxSizing: "border-box",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            color: "black",
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>---</em>
                          </MenuItem>
                          {departments.map((department) => (
                            <MenuItem
                              key={department.id}
                              value={department.department}
                            >
                              {department.department}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px" }}
              >
                <Grid item xs={8} sm={5} md={5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Assign Group
                  </Typography>

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                        <Select
                          labelId="designation-select-label"
                          id="designation-select"
                          value={selectedGroup}
                          onChange={(e) => setSelectedGroup(e.target.value)}
                          displayEmpty
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleAddClickDesig}
                                edge="end"
                                sx={{
                                  color: "#000",
                                  backgroundColor: "#fff",
                                  "&:hover": {
                                    backgroundColor: "#fff",
                                    color: "#000",
                                  },
                                }}
                              >
                                Add
                              </IconButton>
                            </InputAdornment>
                          }
                          sx={{
                            border: "1px solid black",
                            boxSizing: "border-box",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            color: "black",
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>---</em>
                          </MenuItem>
                          {designations.map((item) => (
                            <MenuItem key={item.id} value={item.designation}>
                              {item.designation}
                            </MenuItem>
                          ))}
                        </Select>

                        {/* Dialog for adding new department */}
                        <Dialog
                          fullWidth
                          maxWidth="md"
                          open={openDialogDesig}
                          onClose={handleDialogCloseDesig}
                          TransitionComponent={Transition}
                          PaperProps={{
                            style: {
                              position: "absolute",
                              background: "#141b2d",
                              top: "0",
                              backgroundColor: "white",
                              maxHeight: "95%",
                              margin: "auto",
                              overflowX: "hidden",
                              zIndex: 1200,
                            },
                          }}
                        >
                          <Box sx={{ background: "#fff", color: "#000" }}>
                            <DialogTitle>
                              <span
                                style={{
                                  background: "blue",
                                  color: "#fff",
                                  padding: "15px",
                                  borderRadius: "5px",
                                }}
                              >
                                Add New Designation
                              </span>
                            </DialogTitle>
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                marginTop: "10px",
                                padding: { xs: "10px", sm: "20px" },
                              }}
                            >
                              <Grid item xs={12} sm={6} md={5.5}>
                                <Stack direction="column" spacing={2}>
                                  <Typography>Group Name </Typography>
                                  <TextField
                                    autoFocus
                                    margin="dense"
                                    placeholder="Designation Name"
                                    type="text"
                                    fullWidth
                                    value={newDesignation}
                                    onChange={(e) =>
                                      setNewDesignation(e.target.value)
                                    }
                                    inputProps={{ style: { color: "black" } }}
                                    InputLabelProps={{
                                      style: { color: "black" },
                                    }}
                                    sx={{
                                      border: "1px solid black",
                                      borderRadius: "5px",
                                      "& .MuiOutlinedInput-root": {
                                        borderColor: "black",
                                        "&:hover fieldset": {
                                          borderColor: "black",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "black",
                                        },
                                      },
                                      "& .MuiOutlinedInput-input": {
                                        "&::placeholder": { color: "gray" },
                                      },
                                    }}
                                  />
                                </Stack>
                              </Grid>

                              <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                                <DialogActions>
                                  <Button
                                    onClick={handleDialogCloseDesig}
                                    color="primary"
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={AddDesignation}
                                    color="primary"
                                    sx={{
                                      background: "blue",
                                      color: "#fff",
                                      "&:hover": {
                                        background: "#000",
                                      },
                                    }}
                                  >
                                    Add
                                  </Button>
                                </DialogActions>
                              </Grid>
                            </Grid>
                          </Box>
                        </Dialog>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8} sm={5} md={5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Agent
                  </Typography>

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                        <Select
                          labelId="department-select-label"
                          id="department-select"
                          value={selectedAgent}
                          onChange={(e) => setSelectedAgent(e.target.value)}
                          displayEmpty
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleAddClick}
                                edge="end"
                                sx={{
                                  color: "#000",
                                  backgroundColor: "#fff",
                                  "&:hover": {
                                    backgroundColor: "#fff",
                                    color: "#000",
                                  },
                                }}
                              >
                                Add
                              </IconButton>
                            </InputAdornment>
                          }
                          sx={{
                            border: "1px solid black",
                            boxSizing: "border-box",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            color: "black",
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>---</em>
                          </MenuItem>
                          {departments.map((department) => (
                            <MenuItem
                              key={department.id}
                              value={department.department}
                            >
                              {department.department}
                            </MenuItem>
                          ))}
                        </Select>

                        {/* Dialog for adding new department */}
                        <Dialog
                          fullWidth
                          maxWidth="md"
                          open={openDialog}
                          onClose={handleDialogClose}
                          TransitionComponent={Transition}
                          PaperProps={{
                            style: {
                              position: "absolute",
                              background: "#141b2d",
                              top: "0",
                              backgroundColor: "white",
                              maxHeight: "95%",
                              margin: "auto",
                              overflowX: "hidden",
                              zIndex: 1200,
                            },
                          }}
                        >
                          <Box sx={{ background: "#fff", color: "#000" }}>
                            <DialogTitle>
                              <span
                                style={{
                                  background: "blue",
                                  color: "#fff",
                                  padding: "15px",
                                  borderRadius: "5px",
                                }}
                              >
                                Add New Ticket Agent
                              </span>
                            </DialogTitle>
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                padding: { xs: "10px", sm: "20px" },
                              }}
                            >
                              <Grid item xs={12} sm={6} md={6}>
                                <Stack direction="column" spacing={2}>
                                  <Typography>Assign Group </Typography>
                                  <Select
                                    value={newParentDepartment}
                                    autoFocus
                                    fullWidth
                                    onChange={(e) =>
                                      setNewParentDepartment(e.target.value)
                                    }
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    sx={{
                                      border: "1px solid black",
                                      boxSizing: "border-box",
                                      "& .MuiOutlinedInput-root": {
                                        borderColor: "black !important",
                                        "&:hover fieldset": {
                                          borderColor: "black !important",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "black !important",
                                        },
                                      },
                                      color: "black",
                                    }}
                                  >
                                    <MenuItem disabled value="">
                                      <em>---</em>
                                    </MenuItem>
                                    {departments.map((department) => (
                                      <MenuItem
                                        key={department.id}
                                        value={department.department}
                                      >
                                        {department.department}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </Stack>
                              </Grid>

                              <Grid item xs={12} sm={6} md={6}>
                                <Stack direction="column" spacing={2}>
                                  <Typography>Agent </Typography>
                                  <Select
                                    value={newParentDepartment}
                                    autoFocus
                                    fullWidth
                                    onChange={(e) =>
                                      setNewParentDepartment(e.target.value)
                                    }
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    sx={{
                                      border: "1px solid black",
                                      boxSizing: "border-box",
                                      "& .MuiOutlinedInput-root": {
                                        borderColor: "black !important",
                                        "&:hover fieldset": {
                                          borderColor: "black !important",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "black !important",
                                        },
                                      },
                                      color: "black",
                                    }}
                                  >
                                    <MenuItem disabled value="">
                                      <em>---</em>
                                    </MenuItem>
                                    {departments.map((department) => (
                                      <MenuItem
                                        key={department.id}
                                        value={department.department}
                                      >
                                        {department.department}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </Stack>
                              </Grid>

                              <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                                <DialogActions>
                                  <Button
                                    onClick={handleDialogClose}
                                    color="primary"
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={AddDepartment}
                                    color="primary"
                                    sx={{
                                      background: "blue",
                                      color: "#fff",
                                      "&:hover": {
                                        background: "#000",
                                      },
                                    }}
                                  >
                                    Add
                                  </Button>
                                </DialogActions>
                              </Grid>
                            </Grid>
                          </Box>
                        </Dialog>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px" }}
              >
                <Grid item xs={11} sm={11} md={11}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Ticket Subject
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <br></br>
                      <TextField
                        fullWidth
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        placeholder="e.g.hindi, english"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container sx={{ padding: "20px" }}>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Description<span style={{ color: "red" }}>*</span>
                  </Typography>

                  <ReactQuill
                    value={description}
                    onChange={setDescription}
                    modules={modules}
                    theme="snow"
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      color: "black",
                      borderBlockColor: "black",
                    }}
                  />
                </Stack>
              </Grid>
              <Grid container sx={{ padding: "20px" }}>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <br></br>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Add File
                  </Typography>
                  <label
                    htmlFor="file-upload"
                    style={{
                      cursor: "pointer",
                      color: "black",
                      border: "1px solid black",
                      borderRadius: "4px",
                      padding: "10px",
                      width: "100%",
                      boxSizing: "border-box",
                      minHeight: "100px", // Set your desired minimum height
                      backgroundColor: "lightgray", // Change background color to light gray
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Choose a File
                  </label>
                  <input
                    onChange={handleFileChange}
                    type="file"
                    id="file-upload"
                    style={{ display: "none" }}
                  />
                  {uploadFile && (
                    <div style={{ color: "black" }}>{uploadFile.name}</div>
                  )}
                </Stack>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ color: "black", padding: "20px" }}
              >
                <Grid item xs={8} sm={3.5} md={3.5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Priority
                  </Typography>

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                        <Select
                          labelId="department-select-label"
                          id="department-select"
                          value={priorityTicket}
                          onChange={(e) => setPriorityTicket(e.target.value)}
                          displayEmpty
                          sx={{
                            border: "1px solid black",
                            boxSizing: "border-box",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            color: "black",
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>---</em>
                          </MenuItem>
                          {priorityType.map((type) => (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8} sm={3.5} md={3.5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Type
                  </Typography>

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                        <Select
                          labelId="designation-select-label"
                          id="designation-select"
                          value={selectedType}
                          onChange={(e) => setSelectedType(e.target.value)}
                          displayEmpty
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleAddClickDesig}
                                edge="end"
                                sx={{
                                  color: "#000",
                                  backgroundColor: "#fff",
                                  "&:hover": {
                                    backgroundColor: "#fff",
                                    color: "#000",
                                  },
                                }}
                              >
                                Add
                              </IconButton>
                            </InputAdornment>
                          }
                          sx={{
                            border: "1px solid black",
                            boxSizing: "border-box",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            color: "black",
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>---</em>
                          </MenuItem>
                          {designations.map((item) => (
                            <MenuItem key={item.id} value={item.designation}>
                              {item.designation}
                            </MenuItem>
                          ))}
                          {/* {designations.map((item) => (
                            <MenuItem key={item.id} value={item.designation}>
                              {item.designation}
                            </MenuItem>
                          ))} */}
                        </Select>

                        {/* Dialog for adding new department */}
                        <Dialog
                          fullWidth
                          maxWidth="md"
                          open={openDialogDesig}
                          onClose={handleDialogCloseDesig}
                          TransitionComponent={Transition}
                          PaperProps={{
                            style: {
                              position: "absolute",
                              background: "#141b2d",
                              top: "0",
                              backgroundColor: "white",
                              maxHeight: "95%",
                              margin: "auto",
                              overflowX: "hidden",
                              zIndex: 1200,
                            },
                          }}
                        >
                          <Box sx={{ background: "#fff", color: "#000" }}>
                            <DialogTitle>
                              <span
                                style={{
                                  background: "blue",
                                  color: "#fff",
                                  padding: "15px",
                                  borderRadius: "5px",
                                }}
                              >
                                Add New Ticket Type
                              </span>
                            </DialogTitle>
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                marginTop: "10px",
                                padding: { xs: "10px", sm: "20px" },
                              }}
                            >
                              <Grid item xs={11} sm={11} md={11}>
                                <Stack direction="column" spacing={2}>
                                  <Typography>Ticket Type </Typography>
                                  <TextField
                                    autoFocus
                                    margin="dense"
                                    placeholder="Designation Name"
                                    type="text"
                                    fullWidth
                                    value={newDesignation}
                                    onChange={(e) =>
                                      setNewDesignation(e.target.value)
                                    }
                                    inputProps={{ style: { color: "black" } }}
                                    InputLabelProps={{
                                      style: { color: "black" },
                                    }}
                                    sx={{
                                      border: "1px solid black",
                                      borderRadius: "5px",
                                      "& .MuiOutlinedInput-root": {
                                        borderColor: "black",
                                        "&:hover fieldset": {
                                          borderColor: "black",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "black",
                                        },
                                      },
                                      "& .MuiOutlinedInput-input": {
                                        "&::placeholder": { color: "gray" },
                                      },
                                    }}
                                  />
                                </Stack>
                              </Grid>

                              <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                                <DialogActions>
                                  <Button
                                    onClick={handleDialogCloseDesig}
                                    color="primary"
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={AddDesignation}
                                    color="primary"
                                    sx={{
                                      background: "blue",
                                      color: "#fff",
                                      "&:hover": {
                                        background: "#000",
                                      },
                                    }}
                                  >
                                    Add
                                  </Button>
                                </DialogActions>
                              </Grid>
                            </Grid>
                          </Box>
                        </Dialog>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8} sm={3.5} md={3.5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Channel Name
                  </Typography>

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                        <Select
                          labelId="department-select-label"
                          id="department-select"
                          value={selectedChannel}
                          onChange={(e) => setSelectedChannel(e.target.value)}
                          displayEmpty
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleAddClick}
                                edge="end"
                                sx={{
                                  color: "#000",
                                  backgroundColor: "#fff",
                                  "&:hover": {
                                    backgroundColor: "#fff",
                                    color: "#000",
                                  },
                                }}
                              >
                                Add
                              </IconButton>
                            </InputAdornment>
                          }
                          sx={{
                            border: "1px solid black",
                            boxSizing: "border-box",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            color: "black",
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>---</em>
                          </MenuItem>

                          {departments.map((department) => (
                            <MenuItem
                              key={department.id}
                              value={department.department}
                            >
                              {department.department}
                            </MenuItem>
                          ))}
                        </Select>

                        {/* Dialog for adding new department */}
                        <Dialog
                          fullWidth
                          maxWidth="md"
                          open={openDialog}
                          onClose={handleDialogClose}
                          TransitionComponent={Transition}
                          PaperProps={{
                            style: {
                              position: "absolute",
                              background: "#141b2d",
                              top: "0",
                              backgroundColor: "white",
                              maxHeight: "95%",
                              margin: "auto",
                              overflowX: "hidden",
                              zIndex: 1200,
                            },
                          }}
                        >
                          <Box sx={{ background: "#fff", color: "#000" }}>
                            <DialogTitle>
                              <span
                                style={{
                                  background: "blue",
                                  color: "#fff",
                                  padding: "15px",
                                  borderRadius: "5px",
                                }}
                              >
                                Add Ticket Channel
                              </span>
                            </DialogTitle>
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                padding: { xs: "10px", sm: "20px" },
                              }}
                            >
                              <Grid item xs={11} sm={11} md={11}>
                                <Stack direction="column" spacing={2}>
                                  <Typography>Ticket Channel </Typography>
                                  <Select
                                    value={newParentDepartment}
                                    autoFocus
                                    fullWidth
                                    onChange={(e) =>
                                      setNewParentDepartment(e.target.value)
                                    }
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    sx={{
                                      border: "1px solid black",
                                      boxSizing: "border-box",
                                      "& .MuiOutlinedInput-root": {
                                        borderColor: "black !important",
                                        "&:hover fieldset": {
                                          borderColor: "black !important",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "black !important",
                                        },
                                      },
                                      color: "black",
                                    }}
                                  >
                                    <MenuItem disabled value="">
                                      <em>---</em>
                                    </MenuItem>
                                    {/* {departments.map((department) => (
                                      <MenuItem
                                        key={department.id}
                                        value={department.department}
                                      >
                                        {department.department}
                                      </MenuItem>
                                    ))} */}
                                  </Select>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Box>
                        </Dialog>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px" }}
              >
                <Grid item xs={11} sm={11} md={11}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Tags
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <br></br>
                      <TextField
                        fullWidth
                        value={tagTicket}
                        onChange={(e) => setTagTicket(e.target.value)}
                        placeholder="e.g.hindi, english"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px" }}
              >
                <Grid item xs={11} sm={11} md={11}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Requester Id
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <br></br>
                      <TextField
                        fullWidth
                        value={requesterId}
                        onChange={(e) => setRequesterId(e.target.value)}
                        placeholder="e.g. 123, 234..."
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8} sm={3.5} md={3.5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Status
                  </Typography>

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                        <Select
                          labelId="department-select-label"
                          id="department-select"
                          value={statusValue}
                          onChange={(e) => setStatusValue(e.target.value)}
                          displayEmpty
                          sx={{
                            border: "1px solid black",
                            boxSizing: "border-box",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            color: "black",
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>---</em>
                          </MenuItem>

                          {statusType.map((department) => (
                            <MenuItem key={department} value={department}>
                              {department}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{
                  padding: {
                    xs: "50px 10px 20px 10px",
                    sm: "30px 10px 20px 10px",
                    md: "20px 10px 20px 10px",
                  },
                }}
              >
                <Grid item xs={11} className="messageFooter">
                  <Box className="response" onClick={SaveDate}>
                    Save
                  </Box>
                  <Box className="cancel" onClick={handleCancel}>
                    Cancel
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Dialog>
        </React.Fragment>

        <Divider />
        <ListItemText
          style={{
            background: "white",
            marginTop: "10px",

            borderRadius: "10px",
          }}
        >
          <ThemeProvider theme={getTheme()}>
            <Box
              sx={{
                maxWidth: "100%",
              }}
            >
              <MUIDataTable
                title={
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "1.5rem",
                      color: "#333",
                    }}
                  >
                    Ticket Lists
                  </span>
                }
                data={employeeData}
                columns={columns}
                options={options}
              />
            </Box>
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default Tickets;
