import React, { useEffect, useState } from "react";
import styles from "./flexlayout.module.css";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CardItem from "../component/CardItem";
import {
  CheckCircle,
  HourglassBottom,
  PictureAsPdfRounded,
} from "@mui/icons-material";
import ActivityComponent from "../component/ActivityComponent";
import TurnLeftIcon from "@mui/icons-material/TurnLeft";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import logo from "../../assets/mainfavicon.png";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import AmountView from "./AmountView";
import LineChartView from "./LineChartView";
import BarChartView from "./BarChartView";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import Checkbox from "@mui/material/Checkbox";

import Modal from "@mui/material/Modal";
import ReactQuill from "react-quill";
import { DriverApis } from "../../Data/axios";
import StatusAlert from "../../StatusAlertList/StatusAlert";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const label = { inputProps: { "aria-label": "Checkbox demo" } };
function FlexLayout({ rowData }) {
  const [open, setOpen] = React.useState(false);
  const [descriptionReason, setDescriptionReason] = React.useState("");
  const [openSuspend, setOpenSuspend] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openLicFront, setOpenLicFront] = React.useState(false);
  const handleOpenLicFront = () => setOpenLicFront(true);
  const handleCloseLicFront = () => setOpenLicFront(false);
  const [openLicBack, setOpenLicBack] = React.useState(false);
  const handleOpenLicBack = () => setOpenLicBack(true);
  const handleCloseLicBack = () => setOpenLicBack(false);
  const [openChar, setOpenChar] = React.useState(false);
  const handleOpenChar = () => setOpenChar(true);
  const handleCloseChar = () => setOpenChar(false);

  const [openAdharBack, setOpenAdharBack] = React.useState(false);
  const handleOpenAdharBack = () => setOpenAdharBack(true);
  const handleCloseAdharBack = () => setOpenAdharBack(false);
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  const {
    user,
    driver_data,
    doc,
    jobs_applied_count,
    jobs_accepted_count,
    jobs_rejected_count,
    convoy_created_count,
  } = rowData;

  const { user_id } = user;
  const {
    adhar_front,
    adhar_back,
    license_front,
    license_back,
    character_certificate,
  } = doc;
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();
  const mapSrc =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345093746!2d144.9559283153187!3d-37.81720997975195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf0727db9a3e05c81!2sFederation%20Square!5e0!3m2!1sen!2sau!4v1614597179205!5m2!1sen!2sau";
  const activities = [
    {
      title: "Earned 500 points",
      text: "Earned 500 points",
      icon: <PictureAsPdfRounded />,
      color: "orange",
      iconText: "",
    },
    {
      title: "Joined the program",
      text: "Earned 500 points",
      icon: <PictureAsPdfRounded />,
      color: "red",
      iconText: "",
    },
    {
      title: "9 invoices has been paid",
      text: "Earned 500 points",
      icon: <PictureAsPdfRounded />,
      color: "green",
      iconText: "",
    },
    {
      title: "order #423232",
      text: "Earned 500 points",
      icon: <PictureAsPdfRounded />,
      color: "yellow",
      iconText: "",
    },
  ];

  const items = [
    {
      number: jobs_applied_count,
      text: "Job Applied",
      icon: <CheckCircle />,
      color: "orange",
    },
    {
      number: jobs_rejected_count,
      text: "Job Rejected",
      icon: <HourglassBottom />,
      color: "violet",
    },
    {
      number: jobs_accepted_count,
      text: "Job Accepted",
      icon: <HourglassBottom />,
      color: "green",
    },
    {
      number: convoy_created_count,
      text: "Convoy Created",
      icon: <CheckCircle />,
      color: "skyblue",
    },
  ];
  const customers = [
    {
      customer: "Driver 1",
      payment: "Rs 6,321",
    },
    {
      customer: "Dhaba 2",
      payment: "Rs 2,321",
    },
    {
      customer: "Dhaba 3",
      payment: "Rs 4,321",
    },
    {
      customer: "Dhaba 4",
      payment: "Rs 3,321",
    },
  ];
  const handleSuspend = () => {
    setOpenSuspend(true);
  };
  const handleSuspendSave = async () => {
    const itemStatus = { description: descriptionReason };
    const formData = new FormData();

    for (const key in itemStatus) {
      if (itemStatus[key] !== undefined && itemStatus[key] !== null) {
        formData.append(key, itemStatus[key]);
      }
    }
    try {
      await DriverApis.suspendReason(user_id, formData);

      showAlert("Suspended successfully", "warning");
      handleCloseSuspend();
      setDescriptionReason("");

      setTimeout(() => {
        navigate("/Users/Drivers");
      }, 1500);
    } catch (error) {
      showAlert("Failed to suspend. Please try again.", "error");
    }
  };

  const handleCloseSuspend = () => {
    setOpenSuspend(false);
  };
  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />
      <Dialog
        open={openSuspend}
        onClose={handleCloseSuspend}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            marginTop: "30px",
            top: 0,
            position: "absolute",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            bgcolor: "#1d82F5",
            color: "#fff",
            textAlign: "center",
            width: "100%",
          }}
        >
          {"Do, you want to Suspend!!"}
        </DialogTitle>
        <DialogContent sx={{ bgcolor: "#fff", color: "#000", height: "250px" }}>
          <DialogContentText id="alert-dialog-description">
            <Grid container sx={{ padding: "20px" }}>
              <Stack direction="column" sx={{ width: "100%" }}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Suspend's reason<span style={{ color: "red" }}>*</span>
                </Typography>

                <ReactQuill
                  value={descriptionReason}
                  onChange={setDescriptionReason}
                  modules={modules}
                  theme="snow"
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    color: "black",
                    borderBlockColor: "black",
                  }}
                />
              </Stack>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#fff", color: "#000" }}>
          <Button onClick={handleCloseSuspend} variant="outline">
            Cancel
          </Button>
          <Button onClick={handleSuspendSave} autoFocus variant="outline">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Box className={styles.box}>
        <Box className={styles.left}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",

              padding: ".8rem",
            }}
          >
            <Box>
              <img
                src={logo}
                alt="RoadPilot"
                style={{ width: "80px", height: "80px" }}
              />
            </Box>
            <Box sx={{ color: "#000" }}>
              <Typography sx={{ padding: ".4rem" }}> Driver</Typography>
              <button
                style={{
                  border: "1px solid gray",
                  color: user.membership === "1" ? "green" : "red",
                  bgcolor: "gray",
                  fontSize: ".7rem",
                  padding: ".4rem",
                  margin: ".3rem, 0",
                  borderRadius: ".4rem",
                }}
              >
                {user.membership === "1" ? "Standard" : "Inactive"}{" "}
              </button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              color: "#000",
              padding: ".8rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#000",
              }}
            >
              <Box
                sx={{
                  bgcolor: "green",
                  width: "20px",
                  height: "20px",
                  borderRadius: "5px",
                  margin: "5px",
                }}
              >
                <StarBorderIcon />
              </Box>

              <Box>
                <h3>
                  {/* {driver_data.rating !== null ? driver_data.rating : "No Rating"} */}
                </h3>
                <p>Rating</p>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#000",
              }}
            >
              <Box
                sx={{
                  bgcolor: "orange",
                  width: "20px",
                  height: "20px",
                  borderRadius: "5px",
                  margin: "5px",
                }}
              >
                <TurnLeftIcon />
              </Box>

              <Box>
                <h3>
                  {/* {driver_data.experiance !== null
                  ? driver_data.experiance
                  : "No Experience"} */}
                </h3>
                <p>Experience</p>
              </Box>
            </Box>
          </Box>
          <Box sx={{ color: "black", marginTop: "1rem", paddingLeft: "1rem" }}>
            <h3>Details</h3>
            <p>Contact: +{user.phone}</p>
            <p>Email: {user.email}</p>
            <p>
              Membership:
              {user.membership === "1" ? (
                <span>Standard</span>
              ) : (
                <span>Inactive</span>
              )}{" "}
            </p>
            <p>Country: {user.state}</p>
          </Box>
          <Box sx={{ color: "black", marginTop: "1rem", paddingLeft: "1rem" }}>
            <h3>Documents</h3>
            <div style={{ marginBottom: "10px" }}>
              <Button onClick={handleOpen} variant="outlined">
                Aadhar Card Front
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ color: "#fff" }}
                  >
                    {user.username}
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <img
                      src={`https://mobile.roadpilot.co.in/public/${adhar_front}`}
                      style={{ width: "100%", height: "100%" }}
                      alt="documents of users"
                      onError={(e) => {
                        e.target.src = "fallback-image-url";
                      }}
                    />
                  </Typography>
                </Box>
              </Modal>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <Button onClick={handleOpenAdharBack} variant="outlined">
                Aadhar Card Back
              </Button>
              <Modal
                open={openAdharBack}
                onClose={handleCloseAdharBack}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ color: "#fff" }}
                  >
                    {user.username}
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <img
                      src={`https://mobile.roadpilot.co.in/public/${adhar_back}`}
                      alt="documents of users"
                      style={{ width: "100%", height: "100%" }}
                      onError={(e) => {
                        e.target.src = "fallback-image-url";
                      }}
                    />
                  </Typography>
                </Box>
              </Modal>
            </div>{" "}
            <div style={{ marginBottom: "10px" }}>
              <Button onClick={handleOpenLicFront} variant="outlined">
                {" "}
                Driving Licence Front
              </Button>
              <Modal
                open={openLicFront}
                onClose={handleCloseLicFront}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ color: "#fff" }}
                  >
                    {user.username}
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <img
                      src={`https://mobile.roadpilot.co.in/public/${license_front}`}
                      alt="documents of users"
                      style={{ width: "100%", height: "100%" }}
                      onError={(e) => {
                        e.target.src = "fallback-image-url";
                      }}
                    />
                  </Typography>
                </Box>
              </Modal>
            </div>{" "}
            <div style={{ marginBottom: "10px" }}>
              <Button onClick={handleOpenLicBack} variant="outlined">
                Driving Licence Back
              </Button>
              <Modal
                open={openLicBack}
                onClose={handleCloseLicBack}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ color: "#fff" }}
                  >
                    {user.username}
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <img
                      src={`https://mobile.roadpilot.co.in/public/${license_back}`}
                      alt="documents of users"
                      style={{ width: "100%", height: "100%" }}
                      onError={(e) => {
                        e.target.src = "fallback-image-url";
                      }}
                    />
                  </Typography>
                </Box>
              </Modal>
            </div>
            {/* <button
            style={{
              border: "1px solid gray",
              color: "gray",
              fontSize: ".7rem",
              padding: ".4rem",
              margin: ".3rem",
              borderRadius: ".4rem",
            }}
          > */}{" "}
            <div style={{ marginBottom: "10px" }}>
              <Button onClick={handleOpenChar} variant="outlined">
                Character
              </Button>
              <Modal
                open={openChar}
                onClose={handleCloseChar}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ color: "#fff" }}
                  >
                    {user.username}
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <img
                      src={`https://mobile.roadpilot.co.in/public/${character_certificate}`}
                      alt="documents of users"
                      style={{ width: "100%", height: "100%" }}
                      onError={(e) => {
                        e.target.src = "fallback-image-url";
                      }}
                    />
                  </Typography>
                </Box>
              </Modal>
            </div>
            {/* <button
            style={{
              border: "1px solid orange",
              color: "orange",
              fontSize: ".7rem",
              padding: ".4rem",
              margin: ".3rem",
              borderRadius: ".4rem",
            }}
          >
            {" "}
            Insurance
          </button> */}
            <button
              style={{
                border: "1px solid white",
                color: "#fff",
                fontSize: ".7rem",
                display: "block",
                margin: ".3rem",
                borderRadius: ".4rem",
                padding: ".5rem 5rem",
                background: "blue",
                marginBottom: "2rem",
              }}
            >
              {" "}
              Verify{" "}
            </button>
            <Button
              sx={{
                border: "1px solid white",
                color: "#000",
                fontSize: ".7rem",
                padding: ".3rem 1rem",
                margin: ".3rem",
                background: "green",
                borderRadius: ".4rem",
              }}
              variant="outlined"
            >
              {" "}
              Edit{" "}
            </Button>
            <Button
              sx={{
                border: "1px solid red",
                color: "red",
                fontSize: ".7rem",
                padding: ".3rem 1rem",
                margin: ".3rem",
                borderRadius: ".4rem",
              }}
              variant="outlined"
              onClick={handleSuspend}
            >
              {" "}
              Suspend{" "}
            </Button>
          </Box>
        </Box>
        <Box className={styles.main}>
          <Box className={styles.mainTop}>
            {items.map((item, index) => (
              <CardItem key={index + item.number} item={item} />
            ))}
          </Box>
          <Box className={styles.mainContent}>
            <Box className={styles.mainLeft}>
              <Box sx={{ bgcolor: "#fff" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "black",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      color: "black",
                    }}
                  >
                    <AccountBalanceWalletIcon />
                    <Box>
                      <h3>Rs 1600.49</h3>
                      <p>Total amount</p>
                    </Box>
                  </Box>
                  <Box>
                    <AmountView />{" "}
                    <button
                      style={{
                        background: "lightgreen",
                        color: "#000",
                        borderRadius: "10px",
                        padding: ".2rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      5% <NorthEastIcon />
                    </button>
                  </Box>
                </Box>
                <Box>
                  <LineChartView />
                </Box>
              </Box>
              <Box sx={{ bgcolor: "#fff" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: ".5rem",
                    color: "#000",
                  }}
                >
                  <p>Top Timeline</p>
                  <MoreHorizIcon />
                </Box>
                <Box>
                  <BarChartView />
                </Box>
              </Box>
            </Box>
            <Box className={styles.mainRight}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "black",
                  padding: ".5rem",
                }}
              >
                <h3>Driver</h3>
                <MoreHorizIcon />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "space-around",
                  alignItems: "start",
                  width: "100%",
                }}
              >
                <Box sx={{ width: "40%", paddingLeft: ".5rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      color: "black",
                    }}
                  >
                    <h3>Rs 1,321</h3>{" "}
                    <button
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "red",
                        color: "black",
                        borderRadius: "5px",
                        height: "20px",
                      }}
                    >
                      2% <SouthEastIcon />
                    </button>
                  </Box>
                  <Box sx={{ color: "black" }}>Total amount</Box>
                </Box>
                <Box
                  sx={{
                    width: "60%",
                    paddingRight: ".5rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {customers.map((customer) => (
                    <Box
                      key={customer}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: "#fff",
                      }}
                    >
                      <p style={{ color: "black" }}>
                        <Checkbox
                          {...label}
                          sx={{
                            color: "black",
                            "&.Mui-checked": {
                              color: "black",
                            },
                          }}
                        />
                        {customer.customer}
                      </p>
                      <p style={{ color: "black" }}>{customer.payment}</p>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box sx={{ marginTop: "1rem" }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3562.2307210351855!2d80.90256777378463!3d26.76891447673311!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfb53706b7d85%3A0xa2330944dc4c56e4!2sRoad%20Pilot%20technologies%20pvt%20ltd!5e0!3m2!1sen!2sin!4v1721454886412!5m2!1sen!2sin"
                  title="Road Pilot"
                  width="100%"
                  height="300"
                  style={{ border: "0" }}
                  allowfullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.right}>
          <Typography variant="h6" sx={{ color: "#000", fontWeight: "700" }}>
            User Activity Timeline
          </Typography>
          {activities.map((activity, i) => (
            <ActivityComponent
              key={activity.title + i}
              activity={activity}
              color={activity.color}
            />
          ))}
        </Box>
      </Box>
    </>
  );
}

export default FlexLayout;
