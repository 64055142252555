import {
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  Menu,
  Stack,
  Select,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";

import AddIcon from "@mui/icons-material/Add";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";

import { useNavigate } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import ReactQuill from "react-quill";
import StatusAlert from "../../../StatusAlertList/StatusAlert";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function InterSchedule() {
  const [empid, setEmpid] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState(false);
  const [password, setPassword] = React.useState(false);
  const [designation, setDesignation] = React.useState(false);

  const [mobile, setMobile] = React.useState(false);
  const [country, setCountry] = React.useState(false);

  const [reportingTo, setReportingTo] = React.useState(false);

  const [language, setLanguage] = React.useState(false);
  const [userRole, setUserRole] = React.useState(false);
  const [address, setAddress] = React.useState(false);

  const [about, setAbout] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openCategory, setOpenCategory] = React.useState(false);
  const [openTax, setOpenTax] = React.useState(false);
  const [openSubCat, setOpenSubCat] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [departments, setDepartments] = useState([]);

  const [notify, setNotify] = useState(false);
  const [reminder, setReminder] = useState(false);
  const [designations, setDesignations] = useState([]);

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const handleNotify = () => {
    setNotify(!notify);
  };
  const handleReminder = () => {
    setReminder(!reminder);
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const handleCloseCategory = () => {
    setOpenCategory(false);
  };
  const handleAddCategory = () => {
    setOpenCategory(true);
  };
  const handleCloseTax = () => {
    setOpenTax(false);
  };
  const handleAddTax = () => {
    setOpenTax(true);
  };
  const handleCloseSubCat = () => {
    setOpenSubCat(false);
  };
  const handleAddSubCat = () => {
    setOpenSubCat(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // setFileName(file.name); // Update file name
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChange = (newDate) => {
    // setDate(newDate); // Update the date state
  };

  const item = {
    empid,
    name,
    email,
    password,
    designation,
    reportingTo,
    mobile,
    country,
    language,
    userRole,
    address,
    about,
  };

  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [update, setUpdate] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({
    keys: [],
    values: [],
    open: false,
  });

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };
  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleViewMore = () => {
    const rowData = employeeData.filter((row) => row.id === openMenuId);

    navigate(`/hr/employees/${openMenuId}`, { state: rowData[0] });
    // setOpenDialog(true);
  };
  const handleDailogClosing = () => {
    setSelectedRow({ ...selectedRow, open: false });
  };

  const [updateMember, setUpdateMember] = React.useState("");
  const [updateLeaveType, setUpdateLeaveType] = React.useState("");

  const [updateDuration, setUpdateDuration] = React.useState("");
  const [updateDate, setUpdateDate] = useState(null); // Initialize state for the date
  const [updateMemberError, setUpdateMemberError] = React.useState(false);
  const [updateReason, setUpdateReason] = useState("");
  const [updateFileName, setUpdateFileName] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [data, SetData] = useState([]);

  const [openMenuId, setOpenMenuId] = useState(null);

  async function SaveDate() {
    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/register",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      }
    );

    result = await result.json();

    showAlert(" Added successfully", "success");

    getData();
    setOpen(false);
  }
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getAllEmployees"
    );

    const response = await results.json();
    setEmployeeData(response);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      name: "",
      label: "Candidate",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Candidate
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Interviewer",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Interviewer
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Schedule Date and Time",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Schedule Date and Time
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Interview Round",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Interview Round
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "30px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Actions
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <div
            key={value}
            style={{
              display: "flex",
              justifyContent: "flex-end", // Aligns content to the left
              alignItems: "center",
              // paddingLeft: "20px", // Add padding for starting point
            }}
          >
            <div>
              <Button
                id="basic-button"
                aria-controls={openDropMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDropMenu ? "true" : undefined}
                onClick={handleClick}
              >
                <div onClick={() => handleOpenMenu(value)}>
                  <MoreHorizTwoToneIcon />
                </div>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDropMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => handleViewMore(tableMeta.rowData)}
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    <RemoveRedEyeIcon /> View More
                  </div>
                </MenuItem>

                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => handleDelete(value)}
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteForeverIcon /> Delete
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </div>
        ),
      },
    },
  ];

  async function handleEdit(id) {
    let result = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/businesslocationget/" + id
    );
    result = await result.json();

    SetData(result);
    handleClickUpdate();
  }

  // Update Values Start

  useEffect(() => {
    setUpdateMember(data.member);
  }, [data.member]);

  useEffect(() => {
    setUpdateDate(data.date);
  }, [data.date]);

  useEffect(() => {
    setUpdateDuration(data.duration);
  }, [data.duration]);

  useEffect(() => {
    setUpdateMemberError(data.managerError);
  }, [data.managerError]);

  useEffect(() => {
    setUpdateFileName(data.fileName);
  }, [data.fileName]);

  useEffect(() => {
    setUpdateReason(data.reason);
  }, [data.reason]);

  useEffect(() => {
    setUpdateLeaveType(data.leaveType);
  }, [data.leaveType]);

  useEffect(() => {
    setUpdateStatus(data.status);
  }, [data.status]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDesig, setOpenDialogDesig] = useState(false);
  const [newDepartment, setNewDepartment] = useState("");
  const [newParentDepartment, setNewParentDepartment] = useState("");
  const [newParentDesignation, setNewParentDesignation] = useState("");

  const [newDesignation, setNewDesignation] = useState("");
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://rptpl.roadpilot.co.in/public/api/getDepartments"
      );
      const data = await response.json();

      setDepartments(data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchDataDesignation = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://rptpl.roadpilot.co.in/public/api/getDesignation"
      );
      const data = await response.json();

      setDesignations(data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDataDesignation();
  }, []);

  const handleAddClick = () => {
    setOpenDialog(true); // Open dialog when Add button is clicked
  };
  const handleAddClickDesig = () => {
    setOpenDialogDesig(true); // Open dialog when Add button is clicked
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setNewDepartment("");
    setNewParentDepartment(""); // Reset input field
  };
  const handleDialogCloseDesig = () => {
    setOpenDialogDesig(false);
    setNewDesignation("");
    setNewParentDesignation("");
  };
  async function AddDepartment() {
    const creator_name = localStorage.getItem("empid");
    let result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/addDepartment?department=${newDepartment}&parent=${newParentDesignation}&creator_id=${creator_name}`,
      {
        method: "POST",
      }
    );

    result = await result.json();

    showAlert(" Added successfully", "success");

    fetchData();
    handleDialogClose();
  }
  async function AddDesignation() {
    const creator_name = localStorage.getItem("empid");
    let result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/addDesignation?designation=${newDesignation}&parent=${newParentDesignation}&creator=${creator_name}`,
      {
        method: "POST",
      }
    );

    result = await result.json();

    showAlert(" Added successfully", "success");

    fetchDataDesignation();
    handleDialogCloseDesig();
  }

  async function UpdateData() {
    const formData = new FormData();

    formData.append("member", updateMember);
    formData.append("memberError", updateMemberError);
    formData.append("fileName", updateFileName);
    formData.append("date", updateDate);
    formData.append("reason", updateReason);
    formData.append("duration", updateDuration);
    formData.append("leaveType", updateLeaveType);
    formData.append("status", updateStatus);
    try {
      let response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/updateBusinessLocation/${data.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      showAlert("Leave updated successfully", "success");

      handleUpdateClose();
      getData(); // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      showAlert("Failed to update business location", "error");
    }
  }

  // Editing Ends Here

  // Delete

  async function handleDelete(id) {
    const response = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/businesslocationdelete/" + id,
      {
        method: "DELETE",
      }
    );
    const result = await response.json(); // Assigning to a new variable

    getData();
    showAlert("Deleted successfully", "warning");
  }

  // end delete
  const Trainee = "Trainee";

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };
  const StyledCheckBoxOutlineBlankIcon = styled(CheckBoxOutlineBlankIcon)({
    fontSize: "1.5rem", // Set the size you want
  });

  const StyledCheckBoxIcon = styled(CheckBoxIcon)({
    fontSize: "1.5rem", // Set the size you want
  });
  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />

      <List
        style={{
          background: "none",
          padding: 5,
          marginTop: "10px",
        }}
      >
        <React.Fragment>
          <Stack direction="row" spacing={2} sx={{ marginBottom: "5px" }}>
            <Button
              variant="contained"
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                marginLeft: "10px",
              }}
            >
              <AddIcon />
              Add Interview Schedule
            </Button>
          </Stack>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                position: "absolute",
                background: "#141b2d",
                top: "0",
                backgroundColor: "white",
                maxHeight: "95%",
                margin: "auto",
                overflowX: "hidden",
                zIndex: 1200,
              },
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                  New Interview Schedule
                </Typography>
              </Toolbar>
            </AppBar>
            <Box sx={{ background: "#fff" }}>
              <Grid
                container
                spacing={2}
                sx={{
                  color: "#000",
                  margin: "10px",
                }}
              >
                <Grid item xs={11} sm={6} md={3.5}>
                  <Stack direction="column" spacing={2}>
                    <Typography>Job </Typography>
                    <Select
                      value={about}
                      autoFocus
                      fullWidth
                      onChange={(e) => setAbout(e.target.value)}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={Trainee}>Marketing</MenuItem>
                    </Select>
                  </Stack>
                </Grid>

                <Grid item xs={11} sm={6} md={3.5}>
                  <Stack direction="column" spacing={2}>
                    <Typography>Candidate </Typography>
                    <Select
                      value={about}
                      autoFocus
                      fullWidth
                      onChange={(e) => setAbout(e.target.value)}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={Trainee}>Murya</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xs={11} sm={6} md={4}>
                  <Stack direction="column" spacing={2}>
                    <Typography>Interviewer </Typography>
                    <Select
                      value={about}
                      autoFocus
                      fullWidth
                      onChange={(e) => setAbout(e.target.value)}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={Trainee}>Murya</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  color: "#000",
                  margin: "10px",
                }}
              >
                <Grid item xs={11} sm={6} md={2.5}>
                  <Stack direction="column" spacing={2}>
                    <Typography>Round </Typography>
                    <Select
                      value={about}
                      autoFocus
                      fullWidth
                      onChange={(e) => setAbout(e.target.value)}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={Trainee}>HR Round</MenuItem>
                    </Select>
                  </Stack>
                </Grid>

                <Grid item xs={11} sm={6} md={2.5}>
                  <Stack direction="column" spacing={2}>
                    <Typography>Interview Type </Typography>
                    <Select
                      value={about}
                      autoFocus
                      fullWidth
                      onChange={(e) => setAbout(e.target.value)}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={Trainee}>In Person</MenuItem>
                      <MenuItem value={Trainee}>Video</MenuItem>
                      <MenuItem value={Trainee}>Phone</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xs={11} sm={5} md={3}>
                  <Stack direction="column" spacing={1}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Start On
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={startDate}
                          //onChange={(date) => handleStartDateChange(date)}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid item xs={11} md={3} sm={5}>
                  <Stack direction="column" spacing={1}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Start Time
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["TimePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DemoItem>
                          <TimePicker
                            value={startDate}
                            //onChange={handleDateChange}
                            sx={{
                              border: "1px solid black",
                              borderRadius: "5px",
                              "& .MuiOutlinedInput-root": {
                                borderColor: "black !important",
                                color: "black !important",
                                "&:hover fieldset": {
                                  borderColor: "black !important",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black !important",
                                },
                              },
                              "& .MuiSvgIcon-root": {
                                color: "black",
                              },
                            }}
                          >
                            {(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                // Add any additional TextField props if needed
                              />
                            )}
                          </TimePicker>
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              </Grid>

              <Grid container sx={{ margin: "10px" }} spacing={2}>
                <Grid item xs={11} md={11} sm={11}>
                  <Stack direction="column" spacing={2}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Comment for Interviewer
                    </Typography>
                    {/* <CustomToolbar style={{ color: 'black' }} /> Render custom emoji toolbar */}
                    <ReactQuill
                      value={address}
                      onChange={() => {}}
                      // modules={modules}
                      // theme="snow"
                      style={{
                        // border: '1px solid black',
                        // borderRadius: '4px',
                        //padding: '10px',
                        width: "100%",
                        boxSizing: "border-box",
                        color: "black",
                        borderBlockColor: "black",
                        // minHeight: '100px' // Set your desired minimum height
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  color: "#000",
                  margin: "10px",
                }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Checkbox
                    {...label}
                    onClick={handleNotify}
                    icon={<StyledCheckBoxOutlineBlankIcon />}
                    checkedIcon={<StyledCheckBoxIcon />}
                    sx={{
                      color: "primary.main",
                      "&.Mui-checked": {
                        color: "blue",
                      },
                    }}
                  />
                  <span style={{ fontSize: "1rem" }}>
                    <span>Notify Candidate</span>
                  </span>
                </Grid>
                {notify && (
                  <Grid container sx={{ margin: "10px" }} spacing={2}>
                    <Grid item xs={11} md={11} sm={11}>
                      <Stack direction="column" spacing={2}>
                        <Typography
                          variant="p"
                          gutterBottom
                          style={{ color: "black" }}
                        >
                          Comment for Candidate
                        </Typography>
                        {/* <CustomToolbar style={{ color: 'black' }} /> Render custom emoji toolbar */}
                        <ReactQuill
                          value={address}
                          onChange={() => {}}
                          // modules={modules}
                          // theme="snow"
                          style={{
                            // border: '1px solid black',
                            // borderRadius: '4px',
                            //padding: '10px',
                            width: "100%",
                            boxSizing: "border-box",
                            color: "black",
                            borderBlockColor: "black",
                            // minHeight: '100px' // Set your desired minimum height
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  color: "#000",
                  margin: "10px",
                }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Checkbox
                    {...label}
                    onClick={handleReminder}
                    icon={<StyledCheckBoxOutlineBlankIcon />}
                    checkedIcon={<StyledCheckBoxIcon />}
                    sx={{
                      color: "primary.main",
                      "&.Mui-checked": {
                        color: "blue",
                      },
                    }}
                  />
                  <span style={{ fontSize: "1rem" }}>
                    <span>Send Reminder</span>
                  </span>
                </Grid>
                {reminder && (
                  <Grid
                    container
                    sx={{
                      margin: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    spacing={2}
                  >
                    <Grid item xs={11} sm={5.5} md={3.5}>
                      <Stack direction="column" spacing={2}>
                        <Typography>Reminder before</Typography>
                        <TextField
                          autoFocus
                          margin="dense"
                          placeholder="e.g. 2 "
                          type="text"
                          fullWidth
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          inputProps={{ style: { color: "black" } }}
                          InputLabelProps={{
                            style: { color: "black" },
                          }}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6} sm={4} md={1.5}>
                      <Stack direction="column" spacing={5}>
                        <Typography> </Typography>
                        <Select
                          value={about}
                          autoFocus
                          fullWidth
                          onChange={(e) => setAbout(e.target.value)}
                          displayEmpty
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          sx={{
                            border: "1px solid black",
                            boxSizing: "border-box",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            color: "black",
                          }}
                        >
                          <MenuItem disabled value="">
                            ---
                          </MenuItem>
                          <MenuItem value={Trainee}>Days</MenuItem>
                          <MenuItem value={Trainee}>Hours</MenuItem>
                          <MenuItem value={Trainee}>Minutes</MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Button
                variant="contained"
                autoFocus
                color="inherit"
                onClick={SaveDate}
                sx={{
                  backgroundColor: "blue",
                  "&:hover": { backgroundColor: "orange" },
                  textAlign: "center",
                  margin: "25px",
                }}
              >
                Save
              </Button>
            </Box>
          </Dialog>
        </React.Fragment>

        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <Box
              sx={{
                maxWidth: "100%",
              }}
            >
              <MUIDataTable
                title={
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "1.5rem",
                      color: "#333",
                    }}
                  >
                    Interview Lists
                  </span>
                }
                data={employeeData}
                columns={columns}
                options={options}
              />
            </Box>
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default InterSchedule;
