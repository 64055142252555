import SecuritySettings from "./SecuritySettings/SecuritySettings";
import SettingPageLayout from "./SettingPageLayout";

function MainSecuritySettings() {
  return (
    <SettingPageLayout>
      <SecuritySettings />
    </SettingPageLayout>
  );
}

export default MainSecuritySettings;
