import React from "react";

import CompanySettings from "./CompanySettings/CompanySettings";
import SettingPageLayout from "./SettingPageLayout";

function MainCompanySettings({ isDown }) {
  return (
    <SettingPageLayout>
      <CompanySettings isDown={isDown} />
    </SettingPageLayout>
  );
}

export default MainCompanySettings;
