import {
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  Menu,
  Stack,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { format } from "date-fns";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";

import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import StatusAlert from "../../../StatusAlertList/StatusAlert";

import { getUserInfo } from "../../../Data/UserData";
import HeaderDriverLeave from "./HeaderDriverLeave";
import { driverLeaveApis } from "../../../Data/axios";
import config from "../../../Data/AuthUser";
import ReactQuill from "react-quill";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DriverLeaveRequest() {
  const [radioButton, setRadioButton] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [uploadFile, setUploadFile] = useState(null);
  const [descriptionRemark, setDescriptionRemark] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openRemark, setOpenRemark] = React.useState(false);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");

  const [name, setName] = React.useState("");

  const [price, setPrice] = React.useState("");
  const [discount, setDiscount] = React.useState("");
  const [call, setCall] = React.useState("");
  const [color, setColor] = useState("#ff0000");
  const [post, setPost] = React.useState("");

  const [validity, setValidity] = React.useState("");

  const [userType, setUserType] = useState("");
  const [visibility, setVisibility] = useState("");

  const [articalData, setArticalData] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("Client");

  const [statusType, setStatusType] = useState([]);
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  useEffect(() => {
    if (selectedRadio === "App User") {
      setRadioButton(true);
    } else {
      setRadioButton(false);
    }
  }, [selectedRadio]);
  function formatDate(isoString) {
    const date = new Date(isoString);

    // Get the date components
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    // Get the time components
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'

    return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
  }

  const handleFileChange = (event) => {
    setUploadFile(event.target.files[0]);
  };

  const handleUpdateDetails = () => {
    setOpenRemark(true);
  };
  const navigate = useNavigate();

  const [description, setDescription] = useState("");

  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleViewMore = async () => {
    // const rowData = await driverLeaveApis.leaveViewMoreId(openMenuId);
    navigate(`/driver/leave_requests/${openMenuId}`, { state: openMenuId });
  };

  const [openMenuId, setOpenMenuId] = useState(null);
  const userInfoString = localStorage.getItem("user-info");
  // Parse the string into an object
  const userInfo = JSON.parse(userInfoString);
  const handleAllData = () => {
    setOpen(false);
    setName("");
    setDescription("");
    setUploadFile("");
    setSelectedRadio("");
    setSelectCategory("");
  };

  const item = {
    membership: name,
    price: price,
    discount: discount,
    calls: call,
    jobspost: post,
    validity: validity,
    role: userType,
    color: color,
    access: visibility,
  };

  async function SaveData() {
    const formData = new FormData();
    // Append each item to the FormData object
    for (const key in item) {
      if (item[key] !== undefined && item[key] !== null) {
        formData.append(key, item[key]);
      }
    }

    // let result = await fetch(
    //   "https://rptpl.roadpilot.co.in/public/api/addMemberShip",
    //   {
    //     method: "POST",
    //     body: formData,
    //   }
    // );

    // if (!result.ok) {
    //   throw new Error(`HTTP error! status: ${result.status}`);
    // }

    // const response = await result.json();

    showAlert("Added successfully", "success");
    handleAllData();
    getData();
  }
  const itemDetails = {
    status: statusType,
    approved_by: getUserInfo(),
    approved_at: format(new Date(), "yyyy-MM-dd HH:mm:ss 'UTC'"),
    note: descriptionRemark,
  };
  async function SaveRemark() {
    const formData = new FormData();
    // Append each item to the FormData object
    for (const key in itemDetails) {
      if (itemDetails[key] !== undefined && itemDetails[key] !== null) {
        formData.append(key, itemDetails[key]);
      }
    }
    try {
      await driverLeaveApis.remarkById(openMenuId, formData);

      showAlert("Updated successfully", "success");

      handleCloseRemark();
      getData();
    } catch {
      showAlert("Failed to update", "warning");
    }
  }
  // ends Here

  // Table Starts Here

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const response = await driverLeaveApis.getAll();
    setArticalData(response);
  }

  const leaveStatus = (value) => {
    if (value === "0")
      return <span style={{ color: "red", fontSize: "15px" }}>Rejected</span>;
    if (value === "1")
      return <span style={{ color: "gray", fontSize: "15px" }}>Applied</span>;
    if (value === "2")
      return <span style={{ color: "green", fontSize: "15px" }}>Approved</span>;
    return "Unknown";
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      name: "driver_id",
      label: "Driver's Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Driver's Name
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0];
          console.log(ticketId, tableMeta.rowData, "tabel data got");

          const fullRecord = articalData.find(
            (item) => item.driver_id === ticketId
          );

          if (!fullRecord) {
            return <div>Loading...</div>;
          }
          console.log(fullRecord, "all full record got");

          const imageUrl = `${config.apiImageUrl}${fullRecord.app_user_driver.image}`;

          return (
            <div
              key={value}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={imageUrl}
                alt="requester"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: "10px",
                }}
              />
              <div>
                <div style={{ fontWeight: "bold" }}>
                  {fullRecord.app_user_driver.username || ""}
                </div>
                <div style={{ color: "gray" }}>{value || ""}</div>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "owner_id",
      label: "Client's Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Client's Name
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[1];

          const fullRecord = articalData.find(
            (item) => item.owner_id === ticketId
          );

          if (!fullRecord) {
            return <div>Loading...</div>;
          }

          const imageUrl = `${config.apiImageUrl}${fullRecord.owner.image}`;

          return (
            <div
              key={value}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={imageUrl}
                alt="requester"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: "10px",
                }}
              />
              <div>
                <div style={{ fontWeight: "bold" }}>
                  {fullRecord.owner.username || ""}
                </div>
                <div style={{ color: "gray" }}>{value || ""}</div>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "driver_id",
      label: "Requested Date",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Requested Date
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0];
          const formattedDate = (date) => format(new Date(date), "dd-MM-yyyy");
          const fullRecord = articalData.find(
            (item) => item.driver_id === ticketId
          );

          if (!fullRecord) {
            return <div>Loading...</div>;
          }

          return (
            <div
              key={value}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div>From: {formattedDate(fullRecord.start_date) || ""}</div>
                <div>To: {formattedDate(fullRecord.end_date) || ""}</div>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Requested At",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Requested At
            </th>
          );
        },
        customBodyRender: (value) => {
          const formattedDate = formatDate(value);
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {formattedDate}
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {leaveStatus(value)}
            </td>
          );
        },
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "70px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <td
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                paddingRight: "20px", // Add padding for starting point
              }}
            >
              <div style={{ paddingRight: "20px" }}>
                <Button
                  id="basic-button"
                  aria-controls={openDropMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div onClick={() => handleOpenMenu(value)}>
                    <MoreHorizTwoToneIcon />
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleViewMore(tableMeta.rowData)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <RemoveRedEyeIcon /> View More
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleRemark()}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EditNoteIcon /> Remark
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </td>
          </>
        ),
      },
    },
  ];
  async function handleRemark() {
    handleUpdateDetails();
  }
  // Update Values Start
  const handleCloseRemark = () => {
    setOpenRemark(false);
    setStatusType([]);
    setDescriptionRemark("");
  };
  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 25, 100],
    onChangeRowsPerPage: (newRowsPerPage) => {
      setRowsPerPage(newRowsPerPage);
    },
    tableBody: {
      style: {
        maxHeight: `${rowsPerPage * 50}px`,
        overflow: "auto",
        marginLeft: "5px",
        transform: "translateZ(0)",
        backfaceVisibility: "hidden",
        boxShadow: "none",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />
      <HeaderDriverLeave />
      <Dialog
        fullWidth
        maxWidth="md"
        open={openRemark}
        onClose={handleCloseRemark}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            position: "absolute",
            background: "#141b2d",
            top: "0",
            backgroundColor: "white",
            maxHeight: "95%",
            margin: "auto",
            overflowX: "hidden",
            zIndex: 1200,
          },
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseRemark}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} component="div">
              Add Remark
            </Typography>
          </Toolbar>
        </AppBar>

        <Box sx={{ background: "#fff" }}>
          <Grid
            container
            spacing={4}
            style={{ color: "black", padding: "20px" }}
          >
            <Grid item xs={11} sm={4} md={3}>
              <Stack direction="column">
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Status
                </Typography>
                <Select
                  value={statusType}
                  autoFocus
                  onChange={(e) => setStatusType(parseInt(e.target.value, 10))}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return (
                        <span style={{ color: "gray" }}>
                          Select Status Type
                        </span>
                      );
                    }
                    if (selected === 0) {
                      return "Rejected";
                    }
                    if (selected === 2) {
                      return "Approved";
                    }
                    return "Unknown";
                    // return selected === 0 ? "Rejected" : "Approved";
                  }}
                >
                  <MenuItem value={0}>Rejected</MenuItem>
                  <MenuItem value={2}>Approved</MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={11} sm={7.5} md={8.5}>
              <Stack direction="column" sx={{ width: "100%" }}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Remark<span style={{ color: "red" }}>*</span>
                </Typography>

                <ReactQuill
                  value={descriptionRemark}
                  onChange={setDescriptionRemark}
                  modules={modules}
                  theme="snow"
                  style={{
                    width: "100%",
                    height: "70px",
                    boxSizing: "border-box",
                    color: "black",
                    borderBlockColor: "black",
                  }}
                />
              </Stack>
            </Grid>
          </Grid>

          <Grid container sx={{ padding: "50px 0 30px 20px" }}>
            <Grid item xs={11} className="messageFooter">
              <Box className="response" onClick={SaveRemark}>
                Save
              </Box>
              <Box className="cancel" onClick={handleCloseRemark}>
                Cancel
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>

      <Divider />
      <ListItemText style={{ background: "white", margin: 0 }}>
        <ThemeProvider theme={getTheme()}>
          <Box
            sx={{
              maxWidth: "100%",
            }}
          >
            <MUIDataTable
              title={
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    color: "#333",
                  }}
                >
                  Leave Requests
                </span>
              }
              data={articalData}
              columns={columns}
              options={options}
            />
          </Box>
        </ThemeProvider>
      </ListItemText>
    </>
  );
}

export default DriverLeaveRequest;
