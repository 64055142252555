import {
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  Menu,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";

import AddIcon from "@mui/icons-material/Add";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useLocation } from "react-router-dom";
import StatusAlert from "../../../StatusAlertList/StatusAlert";
import { clientContactApis } from "../../../Data/axios";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function ContactClients() {
  const [name, setName] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [updatePhone, setUpdatePhone] = useState("");
  const [updateTitle, setUpdateTitle] = useState("");
  const [updateAddress, setUpdateAddress] = useState("");
  const [updateEmail, setUpdateEmail] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [contactData, setContactData] = useState([]);
  const [data, setData] = useState([]);
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };

  const [updateName, setUpdateName] = React.useState("");
  const [openMenuId, setOpenMenuId] = useState(null);

  const userInfoString = localStorage.getItem("user-info");

  // Parse the string into an object
  const userInfo = JSON.parse(userInfoString);
  const handleAllData = () => {
    setOpen(false);
  };
  // Access the empid
  const empid = userInfo?.value?.empid;
  const location = useLocation();
  const rowData = location.state;

  const { client } = rowData || {};

  const { user_id } = client || {};
  const item = {
    user_id: user_id,

    phone_number: phone,
    email: email,
    name: name,
    relation: title,
    address: address,
    created_by: empid,
  };

  async function SaveData() {
    const formData = new FormData();
    // Append each item to the FormData object
    for (const key in item) {
      if (item[key] !== undefined && item[key] !== null) {
        formData.append(key, item[key]);
      }
    }
    let result = await clientContactApis.add(formData);

    if (!result) {
      throw new Error(`HTTP error! status: ${result.status}`);
    }

    showAlert("Added successfully", "success");
    handleAllData();
    getData();
  }

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const response = await clientContactApis.getAll(user_id);
    setContactData(response); // Store the updated response
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      name: "relation",
      label: "Title",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Title
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Name
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Email
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value || " "}
            </div>
          );
        },
      },
    },
    {
      name: "phone_number",
      label: "Phone",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Phone
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value || " "}
            </div>
          );
        },
      },
    },
    {
      name: "created_by",
      label: "Created",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Created
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value || " "}
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "70px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                paddingRight: "20px", // Add padding for starting point
              }}
            >
              <div style={{ paddingRight: "20px" }}>
                <Button
                  id="basic-button"
                  aria-controls={openDropMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div onClick={() => handleOpenMenu(value)}>
                    <MoreHorizTwoToneIcon />
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleEdit()}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EditNoteIcon /> Edit
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleDelete(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <DeleteForeverIcon /> Delete
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </>
        ),
      },
    },
  ];
  const handleUpdateEdit = () => {
    setOpenEdit(true);
  };
  async function handleEdit() {
    let result = await clientContactApis.editById(openMenuId);
    setData(result);
    handleUpdateEdit();
  }

  // Update Values Start

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  useEffect(() => {
    setUpdateName(data.name);
  }, [data.name]);

  useEffect(() => {
    setUpdateEmail(data.email);
  }, [data.email]);

  useEffect(() => {
    setUpdatePhone(data.phone_number);
  }, [data.phone_number]);

  useEffect(() => {
    setUpdateAddress(data.address);
  }, [data.address]);
  useEffect(() => {
    setUpdateTitle(data.relation);
  }, [data.relation]);

  async function UpdateData() {
    const formData = new FormData();
    console.log(data.id, "id of contact got");

    formData.append("relation", updateTitle);
    formData.append("phone_number", updatePhone);

    formData.append("address", updateAddress);
    formData.append("email", updateEmail);
    formData.append("name", updateName);
    formData.append("updated_by", empid);

    try {
      await clientContactApis.updateById(data.id, formData);

      showAlert(" updated successfully", "success");

      handleCloseEdit();
      getData(); // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      showAlert("Failed to update business location", "error");
    }
  }

  async function handleDelete() {
    await clientContactApis.deleteById(openMenuId);
    getData();
    showAlert(" Deleted successfully", "warning");
  }

  // end delete

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 25, 100],
    onChangeRowsPerPage: (newRowsPerPage) => {
      setRowsPerPage(newRowsPerPage);
    },
    tableBody: {
      style: {
        maxHeight: `${rowsPerPage * 50}px`,
        overflow: "auto",
        marginLeft: "5px",
        transform: "translateZ(0)",
        backfaceVisibility: "hidden",
        boxShadow: "none",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={openEdit}
        onClose={handleCloseEdit}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            position: "absolute",
            background: "#141b2d",
            top: "0",
            backgroundColor: "white",
            maxHeight: "95%",
            margin: "auto",
            overflowX: "hidden",
            zIndex: 1200,
          },
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseEdit}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} component="div">
              Update Contact
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ background: "#fff" }}>
          <Grid
            container
            spacing={2}
            style={{ color: "black", margin: "10px" }}
          >
            <Grid item xs={10.5} sm={3.5} md={3.5}>
              <Stack direction="column" spacing={2}>
                <Typography>Title </Typography>
                <TextField
                  autoFocus
                  margin="dense"
                  placeholder="e.g. Manager"
                  type="text"
                  fullWidth
                  value={updateTitle}
                  onChange={(e) => setUpdateTitle(e.target.value)}
                  inputProps={{ style: { color: "black" } }}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  sx={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black",
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      "&::placeholder": { color: "gray" },
                    },
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={10.5} sm={3.5} md={3.5}>
              <Stack direction="column" spacing={2}>
                <Typography>Contact Name</Typography>
                <TextField
                  autoFocus
                  margin="dense"
                  placeholder="e.g. Ramu"
                  type="text"
                  fullWidth
                  value={updateName}
                  onChange={(e) => setUpdateName(e.target.value)}
                  inputProps={{ style: { color: "black" } }}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  sx={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black",
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      "&::placeholder": { color: "gray" },
                    },
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={10.5} sm={3.5} md={3.5}>
              <Stack direction="column" spacing={2}>
                <Typography>Email </Typography>
                <TextField
                  autoFocus
                  margin="dense"
                  placeholder="e.g.abc@gmail.com"
                  type="text"
                  fullWidth
                  value={updateEmail}
                  onChange={(e) => setUpdateEmail(e.target.value)}
                  inputProps={{ style: { color: "black" } }}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  sx={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black",
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      "&::placeholder": { color: "gray" },
                    },
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ color: "black", margin: "10px" }}
          >
            <Grid item xs={10.5} sm={3.5} md={3.5}>
              <Stack direction="column" spacing={2}>
                <Typography>Phone </Typography>
                <TextField
                  autoFocus
                  margin="dense"
                  placeholder="e.g. 1234567890"
                  type="text"
                  fullWidth
                  value={updatePhone}
                  onChange={(e) => setUpdatePhone(e.target.value)}
                  inputProps={{ style: { color: "black" } }}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  sx={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black",
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      "&::placeholder": { color: "gray" },
                    },
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={10.5} sm={6} md={6}>
              <Stack direction="column" spacing={2}>
                <Typography>Address </Typography>
                <TextField
                  autoFocus
                  margin="dense"
                  placeholder="e.g. Gomti Nagar, Lucknow"
                  type="text"
                  fullWidth
                  value={updateAddress}
                  onChange={(e) => setUpdateAddress(e.target.value)}
                  inputProps={{ style: { color: "black" } }}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  sx={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black",
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      "&::placeholder": { color: "gray" },
                    },
                  }}
                />
              </Stack>
            </Grid>
          </Grid>

          <Grid container sx={{ padding: "20px 0 30px 20px" }}>
            <Grid item xs={11} className="messageFooter">
              <Box className="response" onClick={UpdateData}>
                Save
              </Box>
              <Box className="cancel" onClick={handleCloseEdit}>
                Cancel
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>

      <List
        style={{
          background: "none",
        }}
      >
        <React.Fragment>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Box
                onClick={handleClickOpen}
                sx={{
                  backgroundColor: "#1d82f5",
                  "&:hover": { backgroundColor: "orange" },
                  textAlign: "center",
                  padding: "10px",
                  marginLeft: "2px",
                  height: "40px",
                  width: "130px",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  borderRadius: "4px",
                  fontSize: "15px",
                  fontFamily: "sans-serif",
                  cursor: "pointer",
                  marginBottom: "5px",
                }}
              >
                <AddIcon />
                Add Contact
              </Box>
            </Box>
          </Stack>

          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                position: "absolute",
                background: "#141b2d",
                top: "0",
                backgroundColor: "white",
                maxHeight: "95%",
                margin: "auto",
                overflowX: "hidden",
                zIndex: 1200,
              },
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                  Add New Contact
                </Typography>
              </Toolbar>
            </AppBar>
            <Box sx={{ background: "#fff" }}>
              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px" }}
              >
                <Grid item xs={10.5} sm={3.5} md={3.5}>
                  <Stack direction="column" spacing={2}>
                    <Typography>Title </Typography>
                    <TextField
                      autoFocus
                      margin="dense"
                      placeholder="e.g. Manager"
                      type="text"
                      fullWidth
                      onChange={(e) => setTitle(e.target.value)}
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={10.5} sm={3.5} md={3.5}>
                  <Stack direction="column" spacing={2}>
                    <Typography>Contact Name</Typography>
                    <TextField
                      autoFocus
                      margin="dense"
                      placeholder="e.g. Ramu"
                      type="text"
                      fullWidth
                      onChange={(e) => setName(e.target.value)}
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={10.5} sm={3.5} md={3.5}>
                  <Stack direction="column" spacing={2}>
                    <Typography>Email </Typography>
                    <TextField
                      autoFocus
                      margin="dense"
                      placeholder="e.g.abc@gmail.com"
                      type="text"
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px" }}
              >
                <Grid item xs={10.5} sm={3.5} md={3.5}>
                  <Stack direction="column" spacing={2}>
                    <Typography>Phone </Typography>
                    <TextField
                      autoFocus
                      margin="dense"
                      placeholder="e.g. 1234567890"
                      type="text"
                      fullWidth
                      onChange={(e) => setPhone(e.target.value)}
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={10.5} sm={6} md={6}>
                  <Stack direction="column" spacing={2}>
                    <Typography>Address </Typography>
                    <TextField
                      autoFocus
                      margin="dense"
                      placeholder="e.g. Gomti Nagar, Lucknow"
                      type="text"
                      fullWidth
                      onChange={(e) => setAddress(e.target.value)}
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid container sx={{ padding: "20px 0 30px 20px" }}>
                <Grid item xs={11} className="messageFooter">
                  <Box className="response" onClick={SaveData}>
                    Save
                  </Box>
                  <Box className="cancel" onClick={handleCancel}>
                    Cancel
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Dialog>
        </React.Fragment>

        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <Box
              sx={{
                maxWidth: "100%",
              }}
            >
              <MUIDataTable
                title={
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "1.5rem",
                      color: "#333",
                    }}
                  >
                    Contact Lists
                  </span>
                }
                data={contactData}
                columns={columns}
                options={options}
              />
            </Box>
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default ContactClients;
