import TaxSettings from "./TaxSettings/TaxSettings";
import SettingPageLayout from "./SettingPageLayout";

function MainTaxSettings({ isDown }) {
  return (
    <SettingPageLayout>
      <TaxSettings isDown={isDown} />
    </SettingPageLayout>
  );
}

export default MainTaxSettings;
