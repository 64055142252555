// export const categoryType = {
//   driver: ["Driver", "1"],
//   fleetOwner: ["Fleet Owner", "2"],
//   dhaba: ["Dhaba", "3"],
//   mechanic: ["Mechanic", "4"],
//   craneOperator: ["Crane Operator", "5"],
//   craneOwner: ["Crane Owner", "6"],
// };
export const categoryType = {
  1: "Driver",
  2: "Fleet Owner",
  3: "Dhaba",
  4: "Mechanic",
  5: "Crane Operator",
  6: "Crane Owner",
};
