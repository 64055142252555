import {
  Box,
  Grid,
  Snackbar,
  TextField,
  Alert,
  ThemeProvide,
  createTheme,
  ThemeProvider,
  Menu,
  Stack,
  Select,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";

import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import StatusAlert from "../../../StatusAlertList/StatusAlert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function Applications() {
  const [open, setOpen] = React.useState(false);

  const [name, setName] = React.useState("");

  const [parent, setParent] = React.useState("");

  const [updateName, setUpdateName] = React.useState("");
  const [updateParentDesignation, setUpdateParentDesignation] =
    React.useState("");

  const [data, SetData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [update, setUpdate] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
    setUpdateName("");
    setUpdateParentDesignation("");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName("");
    setParent("");
  };

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getDesignation"
    );
    console.log(results, "got data ");
    results = await results.json();
    setDesignationData(results);
  }

  const columns = [
    {
      name: "name",
      label: "Name",
      // options: {
      //   customBodyRender: (value) =>
      //     (value || " ").charAt(0).toUpperCase() + (value || " ").slice(1),
      // },
    },
    {
      name: "appliedon",
      label: "Applied On",
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          if (value === 1) {
            return <p style={{ color: "gray" }}>Applied</p>;
          } else if (value === "0") {
            return <p style={{ color: "red" }}> Rejected</p>;
          } else if (value === "2") {
            return <p style={{ color: "green" }}> Selected</p>;
          }
        },
      },
    },
    // {
    //   name: "id",
    //   label: "Actions",
    //   options: {
    //     customHeadRender: ({ index }) => {
    //       return (
    //         <th
    //           key={index}
    //           style={{
    //             textAlign: "right",
    //             paddingRight: "70px",
    //             borderBottom: "1px solid #ddd",
    //           }}
    //         >
    //           Actions
    //         </th>
    //       );
    //     },
    //     customBodyRender: (value) => (
    //       <>
    //         <Stack
    //           spacing={2}
    //           direction="row"
    //           sx={{
    //             justifyContent: "flex-end",
    //             "& .MuiButton-root": {
    //               margin: "0 4px",
    //             },
    //           }}
    //         >
    //           <Button variant="outlined" onClick={() => handleEdit(value)}>
    //             {" "}
    //             <EditNoteIcon /> Update
    //           </Button>
    //           <Button variant="outlined" onClick={() => handleDelete(value)}>
    //             {" "}
    //             <DeleteForeverIcon /> Delete
    //           </Button>
    //         </Stack>
    //       </>
    //     ),
    //   },
    // },
  ];

  const options = {
    //selectableRows: false,
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        // marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
        // display: "flex",
        // justifyContent: "space-around",
        // alignItems: "center",
      },
    },
    // rows: {
    //   style: {
    //     marginLeft: "5px",
    //     textAlign: "center",
    //   },
    //   selectableRowsOnClick: true,
    // },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <React.Fragment></React.Fragment>
      <List style={{ background: "none" }}>
        <StatusAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          severity={alertSeverity}
        />
        <ListItemText style={{ background: "none" }}>
          {/* <React.Fragment>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={handleClickOpen}
                sx={{
                  backgroundColor: "blue",
                  "&:hover": { backgroundColor: "orange" },
                  textAlign: "center",
                  marginLeft: "10px",
                }}
              >
                <AddIcon />
                Add Designation
              </Button>
            </Stack>
            <Dialog
              fullWidth
              maxWidth="md"
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
              PaperProps={{
                style: {
                  position: "absolute",
                  background: "#141b2d",
                  top: "0",
                  backgroundColor: "white",
                  maxHeight: "95%",
                  margin: "auto",
                  overflowX: "hidden",
                  zIndex: 1200,
                },
              }}
            >
              <Box sx={{ background: "#fff", color: "#000" }}>
                <DialogTitle>
                  <span
                    style={{
                      background: "blue",
                      color: "#fff",
                      padding: "15px",
                      borderRadius: "5px",
                    }}
                  >
                    Add New Designation
                  </span>
                </DialogTitle>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <DialogContent>
                      <Typography>Name</Typography>
                      <TextField
                        autoFocus
                        margin="dense"
                        placeholder="Department Name"
                        type="text"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{
                          style: { color: "black" },
                        }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </DialogContent>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <DialogContent>
                      <Typography>Parent</Typography>
                      <TextField
                        autoFocus
                        margin="dense"
                        placeholder="Parent Name"
                        type="text"
                        fullWidth
                        value={parent}
                        onChange={(e) => setParent(e.target.value)}
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{
                          style: { color: "black" },
                        }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </DialogContent>
                  </Grid>
                  <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Cancel
                      </Button>
                      <Button
                        onClick={AddDepartment}
                        color="primary"
                        sx={{
                          background: "blue",
                          color: "#fff",
                          "&:hover": {
                            background: "#000",
                          },
                        }}
                      >
                        Add
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </Box>
            </Dialog>
          </React.Fragment> */}
        </ListItemText>
        <Divider />
        <ListItemText style={{ background: "white", margin: 0, width: "100%" }}>
          <ThemeProvider theme={getTheme()}>
            <MUIDataTable
              title={"Applications Details"}
              data={designationData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default Applications;
