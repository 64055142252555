import RolesSettings from "./RolesSetting/RolesSettings";
import SettingPageLayout from "./SettingPageLayout";

function MainRolesSettings() {
  return (
    <SettingPageLayout>
      <RolesSettings />
    </SettingPageLayout>
  );
}

export default MainRolesSettings;
