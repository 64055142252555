import {
  Box,
  Grid,
  TextField,
  createTheme,
  ThemeProvider,
  Menu,
  MenuItem,
  CssBaseline,
  Autocomplete,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import MUIDataTable from "mui-datatables";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import EventNoteIcon from "@mui/icons-material/EventNote";
import StatusAlert from "../../StatusAlertList/StatusAlert";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./Leads.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditNoteIcon from "@mui/icons-material/EditNote";
import LeadDialog from "./LeadDialog";
import { useNavigate } from "react-router-dom";
import { categoryType } from "../../utils/customers";
import { useLocalStorageContext } from "../../Context/LocalProvider";
import { DriverApis, leadsApis } from "../../Data/axios";
import config from "../../Data/AuthUser";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Leads() {
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const { data: userData } = useLocalStorageContext();
  const [update, setUpdate] = React.useState(false);
  const [followup, setFollowup] = React.useState(false);
  const [leadFiles, setLeadFiles] = useState([]);
  const [optionsAgent, setOptionsAgent] = useState([]);
  const [selectedRow, setSelectedRow] = React.useState({
    keys: [],
    values: [],
    open: false,
  });
  const navigate = useNavigate();
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickUpdate = () => {
    setUpdate(true);
  };
  const handleClickFollowup = () => {
    setFollowup(true);
  };
  const handleUpdateClose = () => {
    setUpdate(false);
  };
  const handleUpdateCloseFollow = () => {
    setFollowup(false);
  };
  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleViewMore = (id) => {
    const rowData = leadFiles.filter((row) => row.id === openMenuId);
    navigate(`/leads/${openMenuId}`, { state: rowData[0] });
  };
  const handleConverted = async () => {
    await leadsApis.convertedbyId(openMenuId);
    getData();
  };
  const handleDailogClosing = () => {
    setSelectedRow({ ...selectedRow, open: false });
  };
  const handleChangeSelected = async (event, ticketId) => {
    const selectedValue = event.target.value;
    const itemStatus = {
      status: selectedValue,
    };
    const formData = new FormData();

    for (const key in itemStatus) {
      if (itemStatus[key] !== undefined && itemStatus[key] !== null) {
        formData.append(key, itemStatus[key]);
      }
    }
    await DriverApis.changeStatus(ticketId, formData);
    showAlert("updated successfully", "success");
    getData();
  };
  // Function Starts Here
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const [role, setRole] = React.useState([]);
  const [lead_agent, setLead_agent] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [leas_source, setLeas_source] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [updaterole, setUpdaterole] = useState("");
  const [updatelanguage, setUpdatelanguage] = useState("");
  const [updateaddress, setUpdateaddress] = useState("");
  const [updatelead_agent, setUpdatelead_agent] = useState("");
  const [updatename, setUpdatename] = useState("");
  const [updatephone, setUpdatephone] = useState("");
  const [updateemail, setUpdateemail] = useState("");
  const [updateleas_source, setUpdateleas_source] = useState("");

  const [data, setData] = useState([]);
  // follow up
  const [follow_agent, setFollow_agent] = useState("");
  const [follow_date, setFollow_date] = useState("");

  const [openMenuId, setOpenMenuId] = useState(null);
  const handleGenderChange = (event) => {
    setRole(event.target.value);
  };

  const handleGenderChangeupdate = (event) => {
    setUpdaterole(event.target.value);
  };

  const categoryKeys = Object.keys(categoryType);
  const item = {
    language,
    address,
    lead_agent,
    leas_source,
    phone,
    email,
    name,
    role,
  };

  async function Savefollowup() {
    const formData = new FormData();
    const time = new Date();
    formData.append("follow_agent", follow_agent);
    formData.append("follow_date", follow_date);
    formData.append("assigned_by", userData.empid);
    formData.append("assigned_at", time);

    try {
      await leadsApis.followUpbyId(openMenuId, formData);
      showAlert(" Followup updated successfully", "success");
      handleUpdateCloseFollow();
      getData(); // Assuming these functions are defined elsewhere in your code
    } catch {
      showAlert("Failed to update leads", "error");
    }
  }
  async function SaveDate() {
    try {
      const formData = new FormData();

      // Append each item property to the FormData object
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          formData.append(key, item[key]);
        }
      }

      await leadsApis.add(formData);
      // Assuming the API returns a success property
      showAlert("Leads Added successfully", "success");
      // getData();
      const formObject = {};
      for (const [key, value] of formData.entries()) {
        formObject[key] = value;
      }
      setLeadFiles([formObject, ...leadFiles]);
      setOpen(false);
    } catch {
      showAlert("An error occurred", "error");
    }
  }
  useEffect(() => {
    getData();
  }, []);
  async function getData() {
    const response = await leadsApis.getAll();
    // const newResponse = response.filter((item) => item.status !== "0");
    setLeadFiles(response);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  //adding follow up
  const fetchData = async () => {
    try {
      const data = await leadsApis.getUserData();
      setOptionsAgent(data.map((item) => item.name));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleFollowup = () => {
    handleClickFollowup();
  };
  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Name
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0];
          const fullRecord = leadFiles.find(
            (item) => item.user_id === ticketId
          );

          if (!fullRecord) {
            return <div>Loading...</div>;
          }

          const imageUrl = `${config.apiImageUrl}${fullRecord.image}`;

          return (
            <div
              key={value}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={imageUrl}
                alt={fullRecord?.username}
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: "10px",
                }}
              />
              <div>
                <div style={{ fontWeight: "bold" }}>{value || "Unknown"}</div>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "phone",
      label: "Phone No.",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Phone No.
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Role
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {categoryType[value]}
            </div>
          );
        },
      },
    },
    {
      name: "followedby",
      label: "Followed By",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Followed By
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "address",
      label: "Location",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Location
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "Status",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0];
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Select
                value={value}
                onChange={(event) => handleChangeSelected(event, ticketId)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === "1") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Enabled
                      </MenuItem>
                    ); // Placeholder text
                  }
                  if (selected === "2") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Disabled
                      </MenuItem>
                    ); // Placeholder text
                  }
                  return selected; // Show selected value
                }}
                sx={{
                  width: "110px",

                  border: "none",
                  color: "#000 ",
                  bgcolor: "#fff",
                  "& .MuiSelect-select": {
                    padding: 0,
                  },
                }}
              >
                <MenuItem
                  value={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Enabled
                </MenuItem>
                <MenuItem
                  value={2}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Disabled
                </MenuItem>
              </Select>
            </div>
          );
        },
      },
    },
    {
      name: "user_id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                paddingRight: "0", // Add padding for starting point
              }}
            >
              <div style={{ paddingRight: "40px" }}>
                <Button
                  id="basic-button"
                  aria-controls={openDropMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div onClick={() => handleOpenMenu(value)}>
                    <MoreHorizTwoToneIcon />
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleViewMore(value)}
                      className="MenuItemValue"
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <RemoveRedEyeIcon /> View More
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleEdit(value)}
                      className="MenuItemValue"
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EditNoteIcon /> Update
                    </div>
                  </MenuItem>

                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => {}}
                      className="MenuItemValue"
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EventNoteIcon /> Note
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu} className="MenuItemValue">
                    <div
                      onClick={() => handleFollowup(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <GroupAddIcon /> Follow up
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu} className="MenuItemValue">
                    <div
                      onClick={() => handleConverted()}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <AutorenewIcon />
                      Converted
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </>
        ),
      },
    },
  ];
  async function handleEdit() {
    const result = leadFiles.filter((lead) => lead.id === openMenuId);
    setData(result[0]);
    handleClickUpdate();
  }
  useEffect(() => {
    setUpdatename(data.name);
  }, [data.name]);

  useEffect(() => {
    setUpdatephone(data.phone);
  }, [data.phone]);

  useEffect(() => {
    setUpdateemail(data.email);
  }, [data.email]);

  useEffect(() => {
    setUpdateaddress(data.address);
  }, [data.address]);

  useEffect(() => {
    setUpdatelead_agent(data.lead_agent);
  }, [data.lead_agent]);

  useEffect(() => {
    setUpdateleas_source(data.leas_source);
  }, [data.leas_source]);

  useEffect(() => {
    setUpdatelanguage(data.language);
  }, [data.language]);

  useEffect(() => {
    setUpdaterole(data.role);
  }, [data.role]);

  async function UpdateData() {
    const formData = new FormData();
    formData.append("name", updatename);
    formData.append("email", updateemail);
    formData.append("language", updatelanguage);
    formData.append("address", updateaddress);
    formData.append("lead_agent", updatelead_agent);
    formData.append("leas_source", updateleas_source);
    formData.append("role", updaterole);
    formData.append("phone", updatephone);
    try {
      await leadsApis.updatebyId(data.id, formData);
      showAlert("Leads updated successfully", "success");
      handleUpdateClose();
      getData();
    } catch {
      showAlert("Failed to update leads", "error");
    }
  }
  const theme = createTheme({
    components: {
      MuiPickersDay: {
        styleOverrides: {
          root: {
            backgroundColor: "lightblue", // Change this to your desired color
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          root: {
            backgroundColor: "lightblue", // Change this to your desired color
          },
        },
      },
    },
  });
  async function handleDelete(id) {
    const response = await fetch(" " + id, {
      method: "DELETE",
    });
    const result = await response.json(); // Assigning to a new variable

    getData();
    showAlert(" Deleted successfully", "warning");
  }

  // end delete

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    elevation: 0,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 25, 100],
    onChangeRowsPerPage: (newRowsPerPage) => {
      setRowsPerPage(newRowsPerPage);
    },
    tableBody: {
      style: {
        maxHeight: `${rowsPerPage * 50}px`,
        overflow: "auto",
        marginLeft: "5px",
        transform: "translateZ(0)",
        backfaceVisibility: "hidden",
        boxShadow: "none",
      },
    },

    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <LeadDialog
        open={selectedRow.open}
        handleClose={handleDailogClosing}
        rowData={selectedRow}
      />
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />

      <Box sx={{ zIndex: 11000 }}>
        <Dialog
          fullWidth
          maxWidth="md"
          open={update}
          onClose={handleUpdateClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleUpdateClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                Update Lead
              </Typography>
            </Toolbar>
          </AppBar>

          <Box sx={{ backgroundColor: "white" }}>
            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Name
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="ex. abc"
                      value={updatename}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdatename(newValue);
                      }}
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Email
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Email"
                        value={updateemail}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setUpdateemail(newValue);
                        }}
                        inputProps={{
                          style: {
                            color: "black",
                            border: "1px solid black",
                            borderRadius: "5px",
                          },
                        }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8} sm={4}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Phone No.
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="0000000000"
                      type="number"
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      value={updatephone}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdatephone(newValue);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Language
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="Hindi..."
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      value={updatelanguage}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdatelanguage(newValue);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Lead Agent
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="Lead agents..."
                      type="email"
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      value={updatelead_agent}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdatelead_agent(newValue);
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Lead Source
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="lead source"
                      type="text"
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      value={updateleas_source}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdateleas_source(newValue);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={5}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Address
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="e.g.Address"
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      value={updateaddress}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdateaddress(newValue);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Category
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      required
                      select
                      value={updaterole}
                      onChange={handleGenderChangeupdate}
                      fullWidth
                      style={{
                        color: "black",
                        border: "1px solid black",
                        borderRadius: "5px",
                      }}
                      placeholder="Own"
                      inputProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        "& .MuiSelect-root": {
                          color: "black", // Set text color to black for the selected option
                        },
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black", // Set border color to black
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" }, // Set placeholder color to gray
                          color: "black", // Set text color to black
                        },
                      }}
                    >
                      {categoryKeys.map((option) => (
                        <MenuItem key={option} value={option}>
                          {categoryType[option]}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              autoFocus
              color="inherit"
              onClick={UpdateData}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                margin: "25px",
              }}
            >
              Update
            </Button>
          </Box>
        </Dialog>
      </Box>
      {/* Follow up box  */}
      <Box sx={{ zIndex: 11000 }}>
        <Dialog
          open={followup}
          fullWidth
          maxWidth="md"
          onClose={handleUpdateCloseFollow}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleUpdateCloseFollow}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                Follow Up
              </Typography>
            </Toolbar>
          </AppBar>

          <Box sx={{ backgroundColor: "white" }}>
            <Grid
              container
              spacing={2}
              style={{
                color: "black",
                margin: "10px",
              }}
            >
              <Grid item xs={8} sm={4}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Follow Agent
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Autocomplete
                      options={optionsAgent}
                      sx={{ border: "1px solid gray", borderRadius: "5px" }}
                      value={follow_agent}
                      onChange={(event, newValue) => {
                        setFollow_agent(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          placeholder="ex. abc"
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              color: "black",
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: "black",
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8} sm={4}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Follow Date
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Date"
                          PaperProps={{
                            sx: {
                              "& .MuiPickersCalendarHeader-root": {
                                backgroundColor: "lightblue", // Change this to your desired color
                              },
                              "& .MuiPickersDay-root": {
                                backgroundColor: "lightblue", // Change this to your desired color
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </ThemeProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              autoFocus
              color="inherit"
              onClick={Savefollowup}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                margin: "25px",
              }}
            >
              Update
            </Button>
          </Box>
        </Dialog>
      </Box>
      <List
        style={{
          background: "none",
          padding: 5,
        }}
      >
        <ListItemText
          style={{ background: "none", margin: 5, zIndex: 1000000 }}
        >
          <React.Fragment>
            <StatusAlert
              open={alertOpen}
              setOpen={setAlertOpen}
              message={alertMessage}
              severity={alertSeverity}
            />
            <Box
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#1d82f5",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                padding: "10px",
                height: "40px",
                width: "105px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: "4px",
                fontSize: "15px",
                fontFamily: "sans-serif",
                cursor: "pointer",
              }}
            >
              <AddIcon />
              Add Lead
            </Box>

            <Dialog
              fullWidth
              maxWidth="md"
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
              PaperProps={{
                style: {
                  position: "absolute",
                  background: "#141b2d",
                  top: "0",
                  backgroundColor: "white",
                  maxHeight: "95%",
                  margin: "auto",
                  overflowX: "hidden",
                  zIndex: 1200,
                },
              }}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} component="div">
                    Add Lead
                  </Typography>
                </Toolbar>
              </AppBar>
              <Box
                sx={{
                  backgroundColor: "white",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", margin: "10px" }}
                >
                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Name
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          placeholder="name"
                          onChange={(e) => setName(e.target.value)}
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Email
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="email.."
                          onChange={(e) => setEmail(e.target.value)}
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Phone No.
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={8}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="+91-0000000000"
                          type="number"
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          onChange={(e) => setPhone(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", margin: "10px" }}
                >
                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Language
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={8}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="Hindi..."
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          onChange={(e) => setLanguage(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Lead Agent
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={8}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="lead agent"
                          type="text"
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          onChange={(e) => setLead_agent(e.target.value)}
                          InputLabelProps={{ style: { color: "black" } }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Lead Source
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="lead source"
                          type="text"
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          onChange={(e) => setLeas_source(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", margin: "10px" }}
                >
                  <Grid item xs={8} sm={5}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Address
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="e.g.Address"
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          onChange={(e) => setAddress(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", margin: "10px" }}
                >
                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Category
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          required
                          select
                          value={role}
                          onChange={handleGenderChange}
                          fullWidth
                          style={{
                            color: "black",
                            border: "1px solid black",
                            borderRadius: "5px",
                          }}
                          placeholder="Own"
                          inputProps={{
                            style: {
                              color: "black",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          sx={{
                            "& .MuiSelect-root": {
                              color: "black", // Set text color to black for the selected option
                            },
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black", // Set border color to black
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" }, // Set placeholder color to gray
                              color: "black", // Set text color to black
                            },
                          }}
                        >
                          {categoryKeys.map((option) => (
                            <MenuItem key={option} value={option}>
                              {categoryType[option]}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Button
                  variant="contained"
                  autoFocus
                  color="inherit"
                  onClick={SaveDate}
                  sx={{
                    backgroundColor: "blue",
                    "&:hover": { backgroundColor: "orange" },
                    textAlign: "center",
                    margin: "25px",
                  }}
                >
                  Save
                </Button>
              </Box>
            </Dialog>
          </React.Fragment>
        </ListItemText>
        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <MUIDataTable
              title={
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    color: "#333",
                  }}
                >
                  Leads Lists
                </span>
              }
              data={leadFiles}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default Leads;
