import React, { useEffect, useState } from 'react'
import 'tailwindcss/tailwind.css';
import './InventoVal.css'
import MUIDataTable from "mui-datatables";
import { createTheme } from "@mui/material/styles"
import { ThemeProvider } from '@mui/styles';
function InventoVal() {

    const [users, setUsers] = useState([])
     // State to manage dropdown menu

    const columns = [
          {
              name:"id",
              label:"ID"  // to rename column
          },
        {
            name:"",
            label:"Item Name"
          },
          {
            name:"",
            label:"SKU"
          },
          {
            name:"",
            label:"Stock on Hand"
          },
          {
            name:"", //use this for status active or inactive
            label:"Inventory Asset value"
          }
    ];

    const handleStatusChange = (event, rowIndex) => {
        const newUsers = [...users];
        newUsers[rowIndex].status = event.target.value;
        setUsers(newUsers);
    };

// const handleStatusChange = (event, rowIndex) => {
//     const { value } = event.target;
//     setUsers(prevUsers => {
//         const updatedUsers = [...prevUsers];
//         updatedUsers[rowIndex].status = value;
//         return updatedUsers;
//     });
// };

    useEffect(() => {
        fetch('https://dummyjson.com/users')
            .then(res => res.json())
            .then((data) => {
                let local = data?.users?.map((user) => ({
                    ...user,
                    name: user?.firstName + " " + user?.lastName,
                    // status: "inactive"
                }))
                console.log(local)
                setUsers(local);
            })
    })

    const options = {
          filterType: 'checkbox',
        //selectableRows: false,
        elevation: 0,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 20, 30],
    };

    const getMuiTheme = () =>
        createTheme({
            typography: {
                fontFamily: "Poppins",
            },
            palette: {
                background: {
                    paper: "#1e293b",
                    default: "0f172a",
                },
                mode: "dark",
            },
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        head: {
                            padding: "10px 4px",
                        },
                        body: {
                            padding: "7px 15px",
                            color: "#e2e8f0",
                        },
                    }
                }
            }
        })

    return (
        <div className='py-10 min-h-screen grid place-items-center'>
            <br></br>
            <div className='w-10/12 max-w-4xl'>
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={"Inventory Valuation Summary List"}
                        data={users}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>

        </div>
    )
}

export default InventoVal;