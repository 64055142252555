import { useState } from "react";
import ProjectRadioSetting from "./ProjectRadioSetting";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const ProjectSettingsTab = () => {
  const [hide, setHide] = useState(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const handleClick = () => {
    setHide((prev) => !prev);
  };

  const StyledCheckBoxOutlineBlankIcon = styled(CheckBoxOutlineBlankIcon)({
    fontSize: "1.5rem", // Set the size you want
  });

  const StyledCheckBoxIcon = styled(CheckBoxIcon)({
    fontSize: "1.5rem", // Set the size you want
  });
  return (
    <Box sx={{ color: "black", maxWidth: "800px", padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Checkbox
            {...label}
            onClick={handleClick}
            icon={<StyledCheckBoxOutlineBlankIcon />}
            checkedIcon={<StyledCheckBoxIcon />}
            sx={{
              color: "primary.main",
              "&.Mui-checked": {
                color: "blue",
              },
            }}
          />
          <span style={{ fontSize: "1rem" }}>
            <span>Send Reminder</span>
          </span>
        </Grid>
        <Grid item xs={12}>
          {hide && (
            <Box
              sx={{
                flexFlow: 1,
                bgcolor: "white",
                padding: 2,
                borderRadius: 1,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ProjectRadioSetting />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <p style={{ fontSize: "1rem" }}>Remind before *</p>

                  <input
                    type="text"
                    name="days"
                    id="day"
                    style={{
                      width: "50%",
                      borderRadius: 0,
                      border: "1px solid gray",
                      padding: "8px",
                    }}
                  />
                  <span
                    style={{
                      border: "1px solid gray",
                      padding: "10px",
                      paddingBottom: "11px",
                    }}
                  >
                    Days
                  </span>
                </Grid>
              </Grid>
              <Button
                sx={{
                  backgroundColor: "blue",
                  "&:hover": {
                    backgroundColor: "darkblue",
                  },
                  color: "white",
                  marginTop: 2,
                }}
              >
                Save
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectSettingsTab;
