import ProfileSettings from "./ProfileSettings/ProfileSettings";
import SettingPageLayout from "./SettingPageLayout";

function MainProfileSettings({ isDown }) {
  return (
    <SettingPageLayout>
      <ProfileSettings isDown={isDown} />
    </SettingPageLayout>
  );
}

export default MainProfileSettings;
