import React from "react";

import SettingPageLayout from "./SettingPageLayout";
import AttendanceSettings from "./AttendanceSettings/AttendanceSettings";

function MainAttendanceSettings() {
  return (
    <SettingPageLayout>
      <AttendanceSettings />
    </SettingPageLayout>
  );
}

export default MainAttendanceSettings;
