import React from "react";
import TabsSecuritySettings from "./TabsSecuritySettings";

const SecuritySettings = () => {
  return (
    <div>
      <TabsSecuritySettings />
    </div>
  );
};

export default SecuritySettings;
