import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./LeaveSettings.css";
import { styled } from "@mui/material/styles";
import { leaveSettingsApis } from "../../../Data/axios";
import AddNewLeaveType from "./AddNewLeaveType";

// Responsive table container
const ResponsiveTableContainer = styled(Box)({
  width: "100%",
  overflowX: "auto",
});

// Helper styles for action buttons
const IconButtonWrapper = styled(IconButton)({
  marginLeft: "8px",
});

const LeaveType = () => {
  const [showForm, setShowForm] = useState(false); // To toggle form visibility
  const [editingLeaveType, setEditingLeaveType] = useState(null); // Store
  const [leaveTypes, setLeaveTypes] = useState([]);
  // [
  //   {
  //     id: 1, // Unique ID
  //     type: "Casual",
  //     allotment: "Yearly",
  //     leaves: 5,
  //     limit: "--",
  //     paidStatus: "Paid",
  //     department: [
  //       "Marketing",
  //       "Sales",
  //       "HR",
  //       "Public Relations",
  //       "Research",
  //       "Finance",
  //     ],
  //     designation: [
  //       "Trainee",
  //       "Senior",
  //       "Junior",
  //       "Team Lead",
  //       "Project Manager",
  //     ],
  //     color: "green",
  //   },
  //   {
  //     id: 2, // Unique ID
  //     type: "Sick",
  //     allotment: "Yearly",
  //     leaves: 5,
  //     limit: "--",
  //     paidStatus: "Paid",
  //     department: [
  //       "Marketing",
  //       "Sales",
  //       "HR",
  //       "Public Relations",
  //       "Research",
  //       "Finance",
  //     ],
  //     designation: [
  //       "Trainee",
  //       "Senior",
  //       "Junior",
  //       "Team Lead",
  //       "Project Manager",
  //     ],
  //     color: "red",
  //   },
  //   {
  //     id: 3, // Unique ID
  //     type: "Earned",
  //     allotment: "Yearly",
  //     leaves: 5,
  //     limit: "--",
  //     paidStatus: "Paid",
  //     department: [
  //       "Marketing",
  //       "Sales",
  //       "HR",
  //       "Public Relations",
  //       "Research",
  //       "Finance",
  //     ],
  //     designation: [
  //       "Trainee",
  //       "Senior",
  //       "Junior",
  //       "Team Lead",
  //       "Project Manager",
  //     ],
  //     color: "purple",
  //   },
  // ]
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await leaveSettingsApis.getAll();
    setLeaveTypes(result);
  };
  const handleEdit = (leaveType) => {
    setEditingLeaveType(leaveType);
    setShowForm(true); // Show form with data for editing
  };
  const handleDelete = (leaveId) => {
    // Confirm before deleting
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this leave type?"
    );

    if (confirmDelete) {
      // Update the leaveTypes state by filtering out the leave with the given id
      const updatedLeaveTypes = leaveTypes.filter(
        (leave) => leave.id !== leaveId
      );

      // Update the state
      setLeaveTypes(updatedLeaveTypes);
    }
  };
  const allotmentType = (type) => {
    if (type === 1) {
      return "Monthly";
    }
    if (type === 0) {
      return "Yearly";
    }
  };
  const statusType = (type) => {
    if (type === 1) {
      return "Unpaid";
    }
    if (type === 0) {
      return "Paid";
    }
  };
  return (
    <ResponsiveTableContainer>
      {/* <AddNewLeaveType /> */}
      <Table className="leave-table" sx={{ color: "#000" }}>
        {/* Table Head */}
        <TableHead className="leave-table-head" sx={{ color: "#000" }}>
          <TableRow sx={{ color: "#000" }}>
            <TableCell sx={{ color: "#000" }}>Leave Type</TableCell>
            <TableCell sx={{ color: "#000" }}>Leave Allotment Type</TableCell>
            <TableCell sx={{ color: "#000" }}>No. of Leaves</TableCell>
            <TableCell sx={{ color: "#000" }}>Monthly Limit</TableCell>
            <TableCell sx={{ color: "#000" }}>Leave Paid Status</TableCell>
            <TableCell sx={{ color: "#000" }}>Department</TableCell>
            <TableCell sx={{ color: "#000" }}>Designation</TableCell>
            <TableCell sx={{ color: "#000" }}>Action</TableCell>
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {leaveTypes.map((leave, index) => (
            <TableRow key={index}>
              <TableCell sx={{ color: "#000" }}>
                <Box className="leave-type-badge">
                  {/* Add colored dot based on leave type */}
                  <Box
                    className="color-indicator"
                    style={{
                      backgroundColor: leave.color_code,
                      width: "12px",
                      color: "#000",
                      height: "12px",
                      borderRadius: "50%",
                      display: "inline-block",
                      marginRight: "8px",
                    }}
                  />
                  {leave.leave_type}
                </Box>
              </TableCell>
              <TableCell sx={{ color: "#000" }}>
                {allotmentType(leave.leave_allotment_type)}
              </TableCell>
              <TableCell sx={{ color: "#000" }}>{leave.no_of_leaves}</TableCell>
              <TableCell sx={{ color: "#000" }}>
                {leave.monthly_limit}
              </TableCell>
              <TableCell sx={{ color: "#000" }}>
                {statusType(leave.leave_paid_status)}
              </TableCell>
              <TableCell sx={{ color: "#000" }}>
                {/* <div className="department-list" sx={{ color: "#000" }}>
                  {leave.department.map((dept, index) => (
                    <div key={index}>{dept}</div>
                  ))}
                </div> */}
              </TableCell>
              <TableCell sx={{ color: "#000" }}>
                {/* <div className="designation-list">
                  {leave.designation.map((desig, index) => (
                    <div key={index}>{desig}</div>
                  ))}
                </div> */}
              </TableCell>
              <TableCell>
                <IconButtonWrapper
                  color="primary"
                  onClick={() => handleEdit(leave)}
                >
                  <EditIcon />
                </IconButtonWrapper>
                <IconButtonWrapper
                  color="secondary"
                  onClick={() => handleDelete(leave.id)}
                >
                  <DeleteIcon />
                </IconButtonWrapper>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ResponsiveTableContainer>
  );
};

export default LeaveType;
