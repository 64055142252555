import SettingPageLayout from "./SettingPageLayout";
import TaskSettings from "./TaskSettings/TaskSettings";

function MainTaskSettings({ isDown }) {
  return (
    <SettingPageLayout>
      <TaskSettings />
    </SettingPageLayout>
  );
}

export default MainTaskSettings;
