import React from "react";
import FooterTime from "../../../Work/TimeSheet/FooterTime";
import HeaderLog from "./HeaderLog";

export const TimeLogSheet = () => {
  return (
    <>
      <HeaderLog />
      <FooterTime />
    </>
  );
};
