import {
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  Menu,
  Stack,
  Select,
  FormControl,
  OutlinedInput,
  TextareaAutosize,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import AddIcon from "@mui/icons-material/Add";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useNavigate } from "react-router-dom";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import StatusAlert from "../../../StatusAlertList/StatusAlert";
import ReactQuill from "react-quill";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EstimateClients() {
  const [empid, setEmpid] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState(false);
  const [password, setPassword] = React.useState(false);
  const [designation, setDesignation] = React.useState(false);
  const [description, setDescription] = useState("");
  const [mobile, setMobile] = React.useState(false);
  const [country, setCountry] = React.useState(false);

  const [reportingTo, setReportingTo] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [language, setLanguage] = React.useState(false);
  const [userRole, setUserRole] = React.useState(false);
  const [address, setAddress] = React.useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  const [about, setAbout] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openClient, setOpenClient] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [departments, setDepartments] = useState([]);

  const [designations, setDesignations] = useState([]);

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const handleCloseClient = () => {
    setOpenClient(false);
  };
  const handleAddClient = () => {
    setOpenClient(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // setFileName(file.name); // Update file name
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Event handler to update the date state and log the date to the console
  const handleDateChange = (newDate) => {
    // setDate(newDate); // Update the date state
  };

  const item = {
    empid,
    name,
    email,
    password,
    designation,
    reportingTo,
    mobile,
    country,
    language,
    userRole,
    address,
    about,
  };

  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);
  const [update, setUpdate] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({
    keys: [],
    values: [],
    open: false,
  });

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };
  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleViewMore = () => {
    const rowData = employeeData.filter((row) => row.id === openMenuId);

    navigate(`/hr/employees/${openMenuId}`, { state: rowData[0] });
    // setOpenDialog(true);
  };
  const handleDailogClosing = () => {
    setSelectedRow({ ...selectedRow, open: false });
  };
  const handleStartDateChange = (newStartDate) => {
    setStartDate(newStartDate);
  };
  const [rows, setRows] = useState([
    {
      description: "",
      quantity: "",
      unit: "Pcs",
      unitPrice: "",
      tax: [],
      amount: "0.00",
    },
  ]);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [discountValue, setDiscountValue] = useState("0.00");
  const [discountType, setDiscountType] = useState("percent");
  const taxOptions = [
    { id: 1, label: "GST: 10%", rate: 10 },
    { id: 2, label: "CGST: 18%", rate: 18 },
    { id: 3, label: "VAT: 5%", rate: 5 },
  ];

  // Function to update values in the table
  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    // Update amount when quantity, unitPrice, or tax changes
    if (field === "quantity" || field === "unitPrice" || field === "tax") {
      const quantity = parseFloat(updatedRows[index].quantity) || 0;
      const unitPrice = parseFloat(updatedRows[index].unitPrice) || 0;
      const selectedTaxes = updatedRows[index].tax.map((taxId) => {
        return taxOptions.find((tax) => tax.id === taxId)?.rate || 0;
      });
      const tax = selectedTaxes.reduce(
        (acc, rate) => acc + (quantity * unitPrice * rate) / 100,
        0
      );
      const amount = quantity * unitPrice + tax;
      updatedRows[index].amount = amount.toFixed(2);
    }

    setRows(updatedRows);
  };

  // Function to add a new row
  const addRow = () => {
    setRows([
      ...rows,
      {
        description: "",
        quantity: "",
        unit: "Pcs",
        unitPrice: "",
        tax: [],
        amount: "",
      },
    ]);
  };
  const removeRow = (index) => {
    const updatedRows = rows.filter((row, i) => i !== index); // Remove row at given index
    setRows(updatedRows);
  };

  // Calculate the subtotal for all rows
  useEffect(() => {
    const subtotalValue = rows.reduce(
      (acc, row) => acc + parseFloat(row.amount || 0),
      0
    );
    setSubtotal(subtotalValue);
  }, [rows]);

  // Calculate the total based on subtotal, discount, and taxes
  useEffect(() => {
    let discountAmount = 0;
    if (discountType === "percent") {
      discountAmount = (subtotal * discountValue) / 100;
    } else {
      discountAmount = parseFloat(discountValue) || 0;
    }
    const totalValue = subtotal - discountAmount;
    setTotal(totalValue.toFixed(2));
  }, [subtotal, discountValue, discountType]);
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  const [anchorEl1, setAnchorEl1] = useState(null);

  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const [updateMember, setUpdateMember] = React.useState("");
  const [updateLeaveType, setUpdateLeaveType] = React.useState("");

  const [updateDuration, setUpdateDuration] = React.useState("");
  const [updateDate, setUpdateDate] = useState(null); // Initialize state for the date
  const [updateMemberError, setUpdateMemberError] = React.useState(false);
  const [updateReason, setUpdateReason] = useState("");
  const [updateFileName, setUpdateFileName] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [data, SetData] = useState([]);

  const [openMenuId, setOpenMenuId] = useState(null);

  async function SaveDate() {
    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/register",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      }
    );

    result = await result.json();

    showAlert(" Added successfully", "success");

    getData();
    setOpen(false);
  }

  // ends Here

  // Table Starts Here

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getAllEmployees"
    );

    const response = await results.json();
    setEmployeeData(response);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      name: "",
      label: "Estimate",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Estimate
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Client",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Client
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Total",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Total
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Valid Till",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Valid Till
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: " Created",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Created
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Estimate Request Number",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Estimate Request Number
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: " Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "30px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Actions
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <div
            key={value}
            style={{
              display: "flex",
              justifyContent: "flex-end", // Aligns content to the left
              alignItems: "center",
            }}
          >
            <div>
              <Button
                id="basic-button"
                aria-controls={openDropMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDropMenu ? "true" : undefined}
                onClick={handleClick}
              >
                <div onClick={() => handleOpenMenu(value)}>
                  <MoreHorizTwoToneIcon />
                </div>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDropMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => handleViewMore(tableMeta.rowData)}
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    <RemoveRedEyeIcon /> View More
                  </div>
                </MenuItem>

                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => handleDelete(value)}
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteForeverIcon /> Delete
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </div>
        ),
      },
    },
  ];

  async function handleEdit(id) {
    let result = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/businesslocationget/" + id
    );
    result = await result.json();

    SetData(result);
    handleClickUpdate();
  }

  // Update Values Start

  useEffect(() => {
    setUpdateMember(data.member);
  }, [data.member]);

  useEffect(() => {
    setUpdateDate(data.date);
  }, [data.date]);

  useEffect(() => {
    setUpdateDuration(data.duration);
  }, [data.duration]);

  useEffect(() => {
    setUpdateMemberError(data.managerError);
  }, [data.managerError]);

  useEffect(() => {
    setUpdateFileName(data.fileName);
  }, [data.fileName]);

  useEffect(() => {
    setUpdateReason(data.reason);
  }, [data.reason]);

  useEffect(() => {
    setUpdateLeaveType(data.leaveType);
  }, [data.leaveType]);

  useEffect(() => {
    setUpdateStatus(data.status);
  }, [data.status]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDesig, setOpenDialogDesig] = useState(false);
  const [newDepartment, setNewDepartment] = useState("");
  const [newParentDepartment, setNewParentDepartment] = useState("");
  const [newParentDesignation, setNewParentDesignation] = useState("");

  const [newDesignation, setNewDesignation] = useState("");
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://rptpl.roadpilot.co.in/public/api/getDepartments"
      );
      const data = await response.json();

      setDepartments(data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchDataDesignation = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://rptpl.roadpilot.co.in/public/api/getDesignation"
      );
      const data = await response.json();

      setDesignations(data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDataDesignation();
  }, []);

  const handleAddClick = () => {
    setOpenDialog(true); // Open dialog when Add button is clicked
  };
  const handleAddClickDesig = () => {
    setOpenDialogDesig(true); // Open dialog when Add button is clicked
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setNewDepartment("");
    setNewParentDepartment(""); // Reset input field
  };
  const handleDialogCloseDesig = () => {
    setOpenDialogDesig(false);
    setNewDesignation("");
    setNewParentDesignation("");
  };
  async function AddDepartment() {
    const creator_name = localStorage.getItem("empid");
    let result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/addDepartment?department=${newDepartment}&parent=${newParentDesignation}&creator_id=${creator_name}`,
      {
        method: "POST",
      }
    );

    result = await result.json();

    showAlert(" Added successfully", "success");

    fetchData();
    handleDialogClose();
  }
  async function AddDesignation() {
    const creator_name = localStorage.getItem("empid");
    let result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/addDesignation?designation=${newDesignation}&parent=${newParentDesignation}&creator=${creator_name}`,
      {
        method: "POST",
      }
    );

    result = await result.json();

    showAlert(" Added successfully", "success");

    fetchDataDesignation();
    handleDialogCloseDesig();
  }

  const Marketing = "Marketing";
  const Sales = "Sales";
  const HumanResources = "Human Resource";
  const Research = "Research";
  const Finance = "Finance";
  const PublicRelations = "Public Relations";

  async function UpdateData() {
    const formData = new FormData();

    formData.append("member", updateMember);
    formData.append("memberError", updateMemberError);
    formData.append("fileName", updateFileName);
    formData.append("date", updateDate);
    formData.append("reason", updateReason);
    formData.append("duration", updateDuration);
    formData.append("leaveType", updateLeaveType);
    formData.append("status", updateStatus);
    try {
      let response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/updateBusinessLocation/${data.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      showAlert("Leave updated successfully", "success");

      handleUpdateClose();
      getData(); // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      showAlert("Failed to update business location", "error");
    }
  }

  async function handleDelete(id) {
    const response = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/businesslocationdelete/" + id,
      {
        method: "DELETE",
      }
    );
    const result = await response.json(); // Assigning to a new variable

    getData();
    showAlert("Leave Deleted successfully", "warning");
  }

  // end delete
  const Trainee = "Trainee";
  const Junior = "Junior";
  const Senior = "Senior";
  const TeamLead = "Team Lead";

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />

      <List
        style={{
          background: "none",
          padding: 5,
          marginTop: "10px",
        }}
      >
        <React.Fragment>
          <Stack direction="row" spacing={2} sx={{ marginBottom: "5px" }}>
            <Box
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#1d82f5",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                padding: "10px",
                marginLeft: "2px",
                height: "40px",
                width: "130px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: "4px",
                fontSize: "15px",
                fontFamily: "sans-serif",
                cursor: "pointer",
                marginBottom: "5px",
              }}
            >
              <AddIcon />
              Add Estimate
            </Box>
          </Stack>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                position: "absolute",
                background: "#141b2d",
                top: "0",
                backgroundColor: "white",
                maxHeight: "95%",
                margin: "auto",
                overflowX: "hidden",
                zIndex: 1200,
              },
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                  New Estimate
                </Typography>
              </Toolbar>
            </AppBar>
            <Box sx={{ background: "#fff" }}>
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "20px",
                  padding: { xs: "10px", sm: "20px" },
                }}
              >
                <Grid item xs={12} sm={5.5} md={4}>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Estimate Number
                    </Typography>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      sx={{ border: "1px solid black", color: "#000" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <Typography
                            variant="p"
                            gutterBottom
                            sx={{
                              color: "black",

                              display: "flex",
                              alignItems: "center",
                              paddingTop: "5px",
                            }}
                          >
                            EST#00
                          </Typography>
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                        border: "1px solid black",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3.5}>
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Valid Till
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={startDate}
                          onChange={(date) => handleStartDateChange(date)}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={5} md={3}>
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Currency
                    </Typography>
                    <Select
                      value={about}
                      autoFocus
                      onChange={(e) => setAbout(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem value={Trainee}>USD($)</MenuItem>
                      <MenuItem value={Junior}>EUR(€)</MenuItem>
                      <MenuItem disabled value="">
                        INR(₹)
                      </MenuItem>
                      <MenuItem value={TeamLead}>GBP(£)</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  color: "#000",
                  padding: "20px",
                }}
              >
                <Grid item xs={12} sm={4} md={3}>
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Client
                    </Typography>
                    <Select
                      value={about}
                      autoFocus
                      onChange={(e) => setAbout(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        ----
                      </MenuItem>
                      <MenuItem value={Trainee}>Raju</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Calculate Tax
                    </Typography>
                    <Select
                      value={about}
                      autoFocus
                      onChange={(e) => setAbout(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value={Senior}>
                        After Discount
                      </MenuItem>
                      <MenuItem value={Trainee}>Before Discount</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  padding: "20px",
                  marginBottom: { xs: "50px", sm: "30px", md: "10px" },
                }}
              >
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Description<span style={{ color: "red" }}>*</span>
                  </Typography>

                  <ReactQuill
                    value={description}
                    onChange={setDescription}
                    modules={modules}
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      color: "black",
                      borderBlockColor: "black",
                    }}
                  />
                </Stack>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  color: "#000",
                  padding: "20px",
                  marginLeft: "0px",
                  marginTop: "25px",
                }}
              >
                <TableContainer
                  component={Paper}
                  style={{ backgroundColor: "#f9f9f9" }}
                >
                  <Table aria-label="finance table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            color: "#333",
                            width: "20%",
                          }}
                        >
                          Description
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            color: "#333",
                            width: "20%",
                          }}
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            color: "#333",
                            width: "10%",
                          }}
                        >
                          Unit Price
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            color: "#333",
                            width: "20%",
                          }}
                        >
                          Tax
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            color: "#333",
                            width: "30%",
                          }}
                        >
                          Amount
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <React.Fragment key={index}>
                          <TableRow style={{ backgroundColor: "#fff" }}>
                            <TableCell sx={{ borderTop: "1px solid #333" }}>
                              <TextField
                                value={row.description}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "description",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                placeholder="Item Name"
                                inputProps={{ style: { color: "black" } }}
                                sx={{
                                  border: "1px solid black",
                                  borderRadius: "5px",
                                  width: "100%",
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "black !important",
                                    "&:hover fieldset": {
                                      borderColor: "black !important",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black !important",
                                    },
                                  },
                                  color: "black",
                                }}
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ borderTop: "1px solid #333" }}
                            >
                              <TextField
                                type="number"
                                value={row.quantity}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "quantity",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                placeholder="1"
                                inputProps={{ style: { color: "black" } }}
                                sx={{
                                  border: "1px solid black",
                                  borderRadius: "5px",
                                  width: "100%",
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "black !important",
                                    "&:hover fieldset": {
                                      borderColor: "black !important",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black !important",
                                    },
                                  },
                                  color: "black",
                                }}
                              />
                              <FormControl
                                fullWidth
                                sx={{ marginLeft: "150px" }}
                              >
                                <Select
                                  value={row.unit}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "unit",
                                      e.target.value
                                    )
                                  }
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                  sx={{
                                    border: "1px solid gray",
                                    height: "30px",
                                    width: "80px",
                                    boxSizing: "border-box",
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "black !important",
                                      "&:hover fieldset": {
                                        borderColor: "black !important",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "black !important",
                                      },
                                    },
                                    color: "black",
                                  }}
                                >
                                  <MenuItem value="Pcs">Pcs</MenuItem>
                                  <MenuItem value="Days">Days</MenuItem>
                                  <MenuItem value="Km">Km</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ borderTop: "1px solid #333" }}
                            >
                              <TextField
                                type="number"
                                value={row.unitPrice}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "unitPrice",
                                    e.target.value
                                  )
                                }
                                fullWidth
                                placeholder="0.00"
                                inputProps={{ style: { color: "black" } }}
                                sx={{
                                  border: "1px solid black",
                                  borderRadius: "5px",
                                  width: "100%",
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "black !important",
                                    "&:hover fieldset": {
                                      borderColor: "black !important",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black !important",
                                    },
                                  },
                                  color: "black",
                                }}
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ borderTop: "1px solid #333" }}
                            >
                              <FormControl fullWidth>
                                <Select
                                  multiple
                                  value={row.tax}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "tax",
                                      e.target.value
                                    )
                                  }
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                  sx={{
                                    border: "1px solid black",
                                    boxSizing: "border-box",
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "black !important",
                                      "&:hover fieldset": {
                                        borderColor: "black !important",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "black !important",
                                      },
                                    },
                                    color: "black",
                                  }}
                                  renderValue={(selected) =>
                                    selected.length === 0 ? (
                                      <span style={{ color: "gray" }}>
                                        Select Tax Option
                                      </span>
                                    ) : (
                                      selected
                                        .map(
                                          (id) =>
                                            taxOptions.find(
                                              (option) => option.id === id
                                            )?.label
                                        )
                                        .join(", ")
                                    )
                                  }
                                  style={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                  }}
                                >
                                  {taxOptions.map((taxOption) => (
                                    <MenuItem
                                      key={taxOption.id}
                                      value={taxOption.id}
                                    >
                                      <Checkbox
                                        checked={
                                          row.tax.indexOf(taxOption.id) > -1
                                        }
                                        sx={{
                                          color: "#fff",
                                          "&.Mui-checked": {
                                            color: "#fff",
                                          },
                                        }}
                                      />
                                      <ListItemText primary={taxOption.label} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ borderTop: "1px solid #333" }}
                            >
                              <TextField
                                value={row.amount}
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                                inputProps={{ style: { color: "black" } }}
                                sx={{
                                  border: "1px solid black",
                                  borderRadius: "5px",
                                  width: "100%",
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "black !important",
                                    "&:hover fieldset": {
                                      borderColor: "black !important",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black !important",
                                    },
                                  },
                                  color: "black",
                                }}
                              />
                            </TableCell>
                            <TableCell
                              width="10%"
                              sx={{
                                padding: "10px",
                                borderTop: "1px solid #333",
                              }}
                            >
                              <IconButton onClick={() => removeRow(index)}>
                                <RemoveCircleOutlineIcon color="error" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              colSpan={4}
                              sx={{ borderBottom: "none" }}
                            >
                              <TextField
                                multiline
                                rows={1}
                                placeholder="Enter Description (optional)"
                                fullWidth
                                variant="outlined"
                                inputProps={{ style: { color: "black" } }}
                                sx={{
                                  border: "1px solid black",
                                  borderRadius: "5px",
                                  width: "100%",
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "black !important",
                                    "&:hover fieldset": {
                                      borderColor: "black !important",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black !important",
                                    },
                                  },
                                  color: "black",
                                }}
                              />
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{ borderBottom: "none" }}
                            >
                              <label
                                htmlFor="file-upload"
                                style={{
                                  cursor: "pointer",
                                  color: "black",
                                  border: "1px solid black",
                                  borderRadius: "4px",
                                  padding: "10px",
                                  width: "100%",
                                  boxSizing: "border-box",
                                  minHeight: "55px", // Set your desired minimum height
                                  backgroundColor: "#fff", // Change background color to light gray
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                Choose a File
                              </label>
                              <input
                                onChange={handleFileChange}
                                type="file"
                                id="file-upload"
                                style={{ display: "none" }}
                              />
                              {uploadFile && (
                                <div style={{ color: "black" }}>
                                  {uploadFile.name}
                                </div>
                              )}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Box
                onClick={addRow}
                sx={{
                  marginTop: "10px",
                  // width: "150px",
                  height: "60px",
                  color: "#1d82f5",
                  cursor: "pointer",
                  fontWeight: "550",
                  fontSize: "15px",
                  display: "flex",
                  paddingLeft: "30px",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <AddCircleOutlineIcon /> Add Item
              </Box>
              <Box
                sx={{
                  display: "flex",
                  paddingLeft: { lg: "4px", md: "4px", xs: "3px" },
                  backgroundColor: "#f9f9f9",
                  padding: "16px",
                  borderRadius: "4px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Table width="100%" sx={{ textAlign: "right", fontSize: 14 }}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        width="50%"
                        sx={{
                          border: 0,
                          display: {
                            lg: "table-cell",
                            md: "table-cell",
                            xs: "none",
                          },
                        }}
                      ></TableCell>
                      <TableCell width="50%" sx={{ padding: 0, border: 0 }}>
                        <Table width="100%">
                          <TableBody>
                            <TableRow>
                              <TableCell
                                sx={{
                                  borderTop: " 0px",
                                  color: "#000",
                                  fontSize: "15px",
                                  fontWeight: "550",
                                }}
                              >
                                Sub Total
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderTop: 0,
                                  color: "#000",
                                  fontSize: "15px",
                                  textAlign: "right",
                                  fontWeight: "550",
                                }}
                              >
                                Rs. {subtotal.toFixed(2)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "15px",
                                  fontWeight: "550",
                                  borderBottom: "none",
                                }}
                              >
                                Discount
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "10px",
                                  color: "#000",
                                  fontSize: "15px",
                                  textAlign: "right",
                                  fontWeight: "550",
                                  borderBottom: "none",
                                }}
                              >
                                <Table width="100%">
                                  <TableBody>
                                    <TableRow
                                      sx={{
                                        display: "flex",
                                        justifyContent: "right",
                                        alignItems: "center",
                                      }}
                                    >
                                      <TableCell
                                        width="100%"
                                        sx={{
                                          borderBottom: "none",
                                          marginLeft: "150px",
                                        }}
                                      >
                                        <TextField
                                          type="number"
                                          value={discountValue}
                                          onChange={(e) =>
                                            setDiscountValue(e.target.value)
                                          }
                                          fullWidth
                                          placeholder="0"
                                          variant="outlined"
                                          inputProps={{
                                            style: {
                                              color: "black",
                                              padding: "10px",
                                            },
                                          }}
                                          sx={{
                                            border: "1px solid black",
                                            borderRadius: "5px",
                                            textAlign: "right",
                                            // padding: "10px",
                                            width: "100px",
                                            "& .MuiOutlinedInput-root": {
                                              borderColor: "black !important",
                                              "&:hover fieldset": {
                                                borderColor: "black !important",
                                              },
                                              "&.Mui-focused fieldset": {
                                                borderColor: "black !important",
                                              },
                                            },
                                            color: "black",
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                          marginRight: "100px",
                                        }}
                                      >
                                        <Select
                                          value={discountType}
                                          onChange={(e) =>
                                            setDiscountType(e.target.value)
                                          }
                                          inputProps={{
                                            style: {
                                              color: "black",
                                              padding: "0px",
                                            },
                                          }}
                                          sx={{
                                            backgroundColor: "#fff",
                                            color: "#000",
                                            height: "40px",
                                            maxWidth: "100px",
                                            border: "1px solid gray",
                                          }}
                                        >
                                          <MenuItem value="percent">%</MenuItem>
                                          <MenuItem value="fixed">
                                            Amount
                                          </MenuItem>
                                        </Select>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderTop: 0,
                                          color: "#000",
                                          fontSize: "15px",
                                          fontWeight: "550",
                                          borderBottom: "none",
                                          display: "flex",
                                        }}
                                      >
                                        Rs.{discountValue}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                backgroundColor: "#E0E0E0",
                                fontSize: 16,
                                fontWeight: 500,
                                color: "#000",
                              }}
                            >
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "15px",
                                  fontWeight: "550",
                                }}
                              >
                                Total
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "15px",
                                  fontWeight: "550",
                                  textAlign: "right",
                                }}
                              >
                                Rs. {total}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Grid
                container
                spacing={2}
                sx={{
                  color: "#000",
                  padding: "20px",
                }}
              >
                <Grid item xs={12} sm={6} md={6}>
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Note for the recipient
                    </Typography>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={5}
                      placeholder="e.g. 132 Street, Gomti Nagar, Lucknow"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Terms and Conditions
                    </Typography>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={5}
                      placeholder="e.g. 132 Street, Gomti Nagar, Lucknow"
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid container sx={{ padding: "10px 0 20px 38px" }}>
                <Grid item xs={11} className="messageFooter">
                  <Box className="response">
                    <div>
                      <Box
                        id="basic-button"
                        aria-controls={open1 ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open1 ? "true" : undefined}
                        onClick={handleClick1}
                        // onClick={SaveDate}
                      >
                        Save
                      </Box>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl1}
                        open={open1}
                        onClose={handleClose1}
                        sx={{ zIndex: 3000 }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          value="closed"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <SaveOutlinedIcon fontSize="small" color="gray" />{" "}
                          &nbsp; Save as Draft
                        </MenuItem>
                        <MenuItem
                          value="open"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <SaveOutlinedIcon
                            fontSize="small"
                            sx={{ color: "gray" }}
                          />{" "}
                          &nbsp; Save
                        </MenuItem>
                        <MenuItem
                          value="pending"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <SendOutlinedIcon
                            fontSize="small"
                            sx={{ color: "gray" }}
                          />{" "}
                          &nbsp; Save & Send
                        </MenuItem>
                      </Menu>
                    </div>
                  </Box>
                  <Box className="cancel" onClick={handleClose}>
                    Cancel
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Dialog>
        </React.Fragment>

        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <Box
              sx={{
                maxWidth: "100%",
              }}
            >
              <MUIDataTable
                title={
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "1.5rem",
                      color: "#333",
                    }}
                  >
                    Estimate Lists
                  </span>
                }
                data={employeeData}
                columns={columns}
                options={options}
              />
            </Box>
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default EstimateClients;
