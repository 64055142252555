import React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "./TaskSettings.css"; // Assuming your custom CSS is here
import { Checkbox, Grid, styled } from "@mui/material";

const TaskSettings = () => {
  const options = [
    "TaskCategory",
    "DueDate",
    "Label",
    "Priority",
    "Comment",
    "Timesheet",
    "HoursLogged",
    "Project",
    "AssignedTo",
    "AssignedBy",
    "MakePrivate",
    "AddFile",
    "Notes",
    "CustomFields",
    "StartDate",
    "Description",
    "Status",
    "TimeEstimate",
    "SubTask",
    "History",
    "CopyTaskLink",
  ];
  const StyledCheckBoxOutlineBlankIcon = styled(CheckBoxOutlineBlankIcon)({
    fontSize: "1.5rem", // Set the size you want
  });

  const StyledCheckBoxIcon = styled(CheckBoxIcon)({
    fontSize: "1.5rem", // Set the size you want
  });
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <div className="task-settings">
      <h2>Task Settings</h2>
      <hr></hr>
      <h2>Send Reminder</h2>
      <form className="form-grid">
        <div className="form-group large-group">
          <label>
            Send task reminder <strong>before</strong> X days of due date
          </label>
          <input type="number" defaultValue="0" />
        </div>

        <div className="form-group large-group">
          <label>Send task reminder on the day of due date</label>
          <div className="radio-group">
            <label>
              <input type="radio" name="reminder" defaultChecked /> Yes
            </label>
            <label>
              <input type="radio" name="reminder" /> No
            </label>
          </div>
        </div>

        <div className="form-group large-group">
          <label>
            Send task reminder <strong>after</strong> X days of due date
          </label>
          <input type="number" defaultValue="0" />
        </div>
        <div className="form-group small-group">
          <label>Status</label>
          <select>
            <option>Incomplete</option>
            <option>To Do</option>
            <option>Pending</option>
            <option>Completed</option>
            <option> Waiting for Approval</option>
          </select>
        </div>
        <div className="form-group small-group">
          <label>Taskboard Default Length</label>
          <input type="number" defaultValue="10" />
        </div>
      </form>

      <hr></hr>
      <div className="task-options-container">
        <h2>Sections visible to client</h2>
        <div className="options-grid">
          {options.map((option, index) => (
            <div key={index} className="option-item">
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    {...label}
                    // onClick={handleClick}
                    icon={<StyledCheckBoxOutlineBlankIcon />}
                    checkedIcon={<StyledCheckBoxIcon />}
                    sx={{
                      color: "primary.main",
                      "&.Mui-checked": {
                        color: "blue",
                      },
                    }}
                  />
                  <span style={{ fontSize: "1rem" }}>
                    <span>{option}</span>
                  </span>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
        <hr></hr>
        <button className="save-button">Save</button>
      </div>
    </div>
  );
};
export default TaskSettings;
