import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SelectMenu from "./SelectMenu";

function createData(Module, Add, View, Update, Delete) {
  return {
    Module,
    Add,
    View,
    Update,
    Delete,

    history: [
      {
        "Add Assets Type": "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {row.Module}
        </TableCell>
        <TableCell align="right">{row.Add}</TableCell>

        <TableCell align="right">{row.View}</TableCell>
        <TableCell align="right">{row.Update}</TableCell>
        <TableCell align="right">{row.Delete}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Add Assets Type</TableCell>
                    <TableCell>Edit Assets Type</TableCell>
                    <TableCell align="right">View Assets Type</TableCell>
                    <TableCell align="right">Delete Assets Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <SelectMenu menu={menu[0]} />
                    </TableCell>
                    <TableCell>
                      <SelectMenu menu={menu[0]} />
                    </TableCell>
                    <TableCell align="right">
                      <SelectMenu menu={menu[0]} />
                    </TableCell>
                    <TableCell align="right">
                      <SelectMenu menu={menu[0]} />
                    </TableCell>
                  </TableRow>
                  {/* {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.customerId}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell>
                    </TableRow>
                  ))} */}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};
const menu = [
  ["None", "All"],
  ["None", "All", "Added", "Owned", "Added and Owned"],
  ["None", "All", "Added", "Owned", "Added and Owned"],
  ["None", "All", "Added", "Owned", "Added and Owned"],
  ["None", "All"],
];
const rows = [
  createData(
    "Assets",
    <SelectMenu menu={menu[0]} />,
    <SelectMenu menu={menu[1]} />,
    <SelectMenu menu={menu[2]} />,
    <SelectMenu menu={menu[3]} />,
    <SelectMenu menu={menu[4]} />
  ),
  // createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
  // createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
  // createData("Cupcake", 305, 3.7, 67, 4.3, 2.5),
  // createData("Gingerbread", 356, 16.0, 49, 3.9, 1.5),
];

export default function PermissionTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Module</TableCell>
            <TableCell align="right">Add</TableCell>
            <TableCell align="right"> View</TableCell>
            <TableCell align="right">Update</TableCell>
            <TableCell align="right">Delete</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
