import React from "react";
import { Navigate, redirect } from "react-router-dom";
import PageLayout from "../scenes/global/PageLayout";
import { useLocalStorageContext } from "../Context/LocalProvider";

const Redirect = ({ to }) => {
  return <Navigate to={to} />;
};
const Protected = ({ cmp: Component, ...rest }) => {
  const { data } = useLocalStorageContext();

  if (!data) {
    redirect("/login");
  }

  return (
    <>
      {data ? (
        <PageLayout>
          <Component {...rest} />
        </PageLayout>
      ) : (
        <Redirect to="/login" />
      )}
    </>
  );
};
export default Protected;
