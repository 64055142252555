import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Switch,
    Paper,
    Snackbar,
    FormControlLabel,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { financeSettingsApi } from '../../../Data/axios'; 

const getEmpIdFromLocalStorage = () => {
    const userInfo = JSON.parse(localStorage.getItem("user-info"));
    return userInfo?.value?.empid || "";
};

const Alert = React.forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

function CurrencySettings() {
    const [open, setOpen] = useState(false);
    const [currencies, setCurrencies] = useState([]);
    const [formData, setFormData] = useState({
        currency_code: '',
        currency_name: '',
        currency_symbol: '',
    });
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');

    useEffect(() => {
        fetchCurrencies();
    }, []);

    const fetchCurrencies = async () => {
        try {
            const data = await financeSettingsApi.getAllCurrency();
            setCurrencies(data);
        } catch (error) {
            console.error('Error fetching currencies:', error);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({
            currency_code: '',
            currency_name: '',
            currency_symbol: '',
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleAddCurrency = async () => {
        try {
            const createdBy = getEmpIdFromLocalStorage();
            const currencyData = {
                currency_code: formData.currency_code,
                currency_name: formData.currency_name,
                currency_symbol: formData.currency_symbol,
                createdby: createdBy,
            };
            await financeSettingsApi.addCurrency(currencyData);
            setSnackMessage('Currency added successfully!');
            setSnackOpen(true);
            fetchCurrencies(); 
            handleClose();
        } catch (error) {
            console.error('Error adding currency:', error);
            setSnackMessage('Error adding currency. Please check your inputs.');
            setSnackOpen(true);
        }
    };

    const handleDeleteCurrency = async (id) => {
        try {
            await financeSettingsApi.deleteDefaultCurrency(id);
            fetchCurrencies(); 
            setSnackMessage('Currency deleted successfully!');
            setSnackOpen(true);
        } catch (error) {
            console.error('Error deleting currency:', error);
            setSnackMessage('Error deleting currency.');
            setSnackOpen(true);
        }
    };

    const handleSwitchChange = async (currency) => {
        try {
           
            await Promise.all(currencies.map(async (curr) => {
                await financeSettingsApi.updateDefaultCurrency(curr.id, { is_default: curr.id === currency.id });
            }));
            fetchCurrencies(); 
            setSnackMessage('Default currency updated successfully!');
            setSnackOpen(true);
        } catch (error) {
            console.error('Error updating default currency:', error);
            setSnackMessage('Error updating default currency.');
            setSnackOpen(true);
        }
    };

    return (
        <div style={{ backgroundColor: 'white', padding: '20px' }}>
            <Button
                variant="outlined"
                onClick={handleClickOpen}
                sx={{ backgroundColor: '#003366', color: 'white', '&:hover': { backgroundColor: '#002244' }, marginBottom: '20px' }} 
            >
                Add Currency
            </Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ backgroundColor: 'white', color: 'black' }}>Add Currency</DialogTitle>
                <DialogContent sx={{ backgroundColor: 'white', color: 'black' }}>
                    <TextField
                        label="Currency Name"
                        name="currency_name"
                        value={formData.currency_name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ style: { color: 'black' } }} 
                        inputProps={{ style: { color: 'black' } }} 
                        variant="outlined"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'black', 
                                },
                                '&:hover fieldset': {
                                    borderColor: 'black',  
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'black',  
                                },
                            },
                        }}
                    />
                    <TextField
                        label="Currency Code"
                        name="currency_code"
                        value={formData.currency_code}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ style: { color: 'black' } }} 
                        inputProps={{ style: { color: 'black' } }} 
                        variant="outlined"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'black', 
                                },
                                '&:hover fieldset': {
                                    borderColor: 'black', 
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'black', 
                                },
                            },
                        }}
                    />
                    <TextField
                        label="Currency Symbol"
                        name="currency_symbol"
                        value={formData.currency_symbol}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ style: { color: 'black' } }} 
                        inputProps={{ style: { color: 'black' } }} 
                        variant="outlined"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'black', 
                                },
                                '&:hover fieldset': {
                                    borderColor: 'black', 
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'black',  
                                },
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions sx={{ backgroundColor: 'white' }}>
                    <Button onClick={handleClose} sx={{ color: 'white', backgroundColor: 'red' }}>Cancel</Button>
                    <Button onClick={handleAddCurrency} sx={{ color: 'white', backgroundColor: '#003366' }}>Save</Button>
                </DialogActions>
            </Dialog>

            <TableContainer component={Paper} sx={{ backgroundColor: 'white' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ color: 'black' }}>Currency Code</TableCell>
                            <TableCell sx={{ color: 'black' }}>Currency Name</TableCell>
                            <TableCell sx={{ color: 'black' }}>Currency Symbol</TableCell>
                            <TableCell sx={{ color: 'black' }}>Default</TableCell>
                            <TableCell sx={{ color: 'black' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currencies.map((currency) => (
                            <TableRow key={currency.id}>
                                <TableCell sx={{ color: 'black' }}>{currency.currency_code}</TableCell>
                                <TableCell sx={{ color: 'black' }}>{currency.currency_name}</TableCell>
                                <TableCell sx={{ color: 'black' }}>{currency.currency_symbol}</TableCell>
                                <TableCell>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={currency.is_default}
                                                onChange={() => handleSwitchChange(currency)}
                                            />
                                        }
                                        label=""
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={() => handleDeleteCurrency(currency.id)}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Snackbar open={snackOpen} autoHideDuration={6000} onClose={() => setSnackOpen(false)}>
                <Alert onClose={() => setSnackOpen(false)} severity="success">
                    {snackMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default CurrencySettings;
