import SettingPageLayout from "./SettingPageLayout";
import TimeLogSettings from "./TimeLogSettings/TimeLogSettings";

function MainTImeLogSetttings() {
  return (
    <SettingPageLayout>
      <TimeLogSettings />
    </SettingPageLayout>
  );
}

export default MainTImeLogSetttings;
