import {
  Box,
  Grid,
  TextField,
  createTheme,
  ThemeProvider,
  Stack,
  Collapse,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import KeyIcon from "@mui/icons-material/Key";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import MUIDataTable from "mui-datatables";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import StatusAlert from "../../../StatusAlertList/StatusAlert";
import PermissionTable from "./PermissionTable";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function RolesSettings() {
  const [open, setOpen] = React.useState(false);

  const [tax_name, setTax_name] = React.useState("");

  const [leadSource, setLeadSource] = useState([]);

  const [permission, setPermission] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [update, setUpdate] = React.useState(false);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getData();
  }, []);
  const toggleRow = (rowId) => {
    setExpandedRows((prevExpandedRows) => {
      // Toggle the expansion state of the row
      if (prevExpandedRows.includes(rowId)) {
        return prevExpandedRows.filter((id) => id !== rowId);
      } else {
        return [...prevExpandedRows, rowId];
      }
    });
  };
  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getRoles"
    );
    results = await results.json();
    setLeadSource(results);
  }

  const columns = [
    {
      name: "role",
      label: "",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowId = tableMeta.rowIndex;

          return (
            <div>
              <span>
                {(value || " ").charAt(0).toUpperCase() +
                  (value || " ").slice(1)}
              </span>
              <div style={{ fontSize: "12px", color: "#777" }}>
                {rowId === 0 ? "Admin Member" : "0 Member"}
              </div>
            </div>
          );
        },
      },
    },

    {
      name: "id",
      label: "",
      options: {
        customHeadRender: () => null,
        customBodyRender: (value, tableMeta) => {
          const rowId = tableMeta.rowIndex; // Get the row index from tableMeta
          const isExpanded = expandedRows.includes(rowId); // Check if this row is expanded
          const rowStatus = leadSource[rowId]?.status;
          return rowId === 0 ? (
            <div style={{ textAlign: "right" }}>
              Admin permissions can not be changed
            </div>
          ) : (
            <>
              <Stack
                spacing={2}
                direction="row"
                sx={{
                  justifyContent: "flex-end",
                  "& .MuiButton-root": {
                    margin: "0 4px",
                  },
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => handleStatus(value)}
                  sx={{
                    color: rowStatus === 0 ? "#f15f5f" : "#68f15f", // Check status value
                    border: `1px solid ${
                      rowStatus === 0 ? "#f15f5f" : "#68f15f"
                    }`,
                    "&:hover": {
                      borderColor: rowStatus === 0 ? "#ff7b7b" : "#89ff89", // Hover border color
                      backgroundColor: "transparent", // Maintain transparency on hover
                    },
                    transition: "border-color 0.3s ease", // Smooth transition
                  }}
                >
                  <AutorenewIcon /> Status
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleDelete(value)}
                  sx={{
                    color: "#f15f5f",
                    border: "1px solid #f15f5f",
                    "&:hover": {
                      borderColor: "#f15f5f",
                      backgroundColor: "transparent",
                    },
                    transition: "border-color 0.3s ease",
                  }}
                >
                  <DeleteForeverIcon /> Delete
                </Button>
                <Button variant="outlined" onClick={() => toggleRow(rowId)}>
                  <KeyIcon /> Permission
                </Button>
              </Stack>

              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1, padding: 1, backgroundColor: "#f0f0f0" }}>
                  <PermissionTable />
                </Box>
              </Collapse>
            </>
          );
        },
      },
    },
  ];
  async function handleStatus(id) {
    const response = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/changestatus/" + id,
      {
        method: "POST",
      }
    );
    console.log(response, "responsestatus");
    if (response.ok) {
      setLeadSource((prevData) =>
        prevData.map((row) =>
          row.id === id ? { ...row, status: !row.status } : row
        )
      );
    }
    getData();
    handleClickUpdate();
  }
  const handlePermission = () => {
    setPermission(!permission);
  };

  async function handleDelete(id) {
    const response = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/deleteRoles/" + id,
      {
        method: "DELETE",
      }
    );
    console.log(response, "response");
    //const result = await response.json(); // Assigning to a new variable
    if (response.ok) {
      getData();
      console.log("deleted called");
      showAlert(" Deleted Successfully!", "warning");
    } else {
      showAlert(" Deleted failed!", "error");
    }
  }

  // Save Lead Sourse
  const username = localStorage.getItem("name");
  async function SaveDate() {
    try {
      const response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/addRole?role=${tax_name}&creator_id=${username}`,
        {
          method: "POST",
        }
      );
      console.log(response);
      // Check if the response is okay
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      // Read response as text for debugging

      // Try parsing the response as JSON

      showAlert(" Added Successfully!", "success");
      getData();
      setOpen(false);
    } catch (error) {
      console.error("Fetch error:", error);
      showAlert("Failed to add tax data", "error");
    }
  }

  const options = {
    filter: false,
    pagination: true,
    selectableRows: "none",
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              "&:first-of-type": {
                display: "none", // Hides the first column's header
              },
            },
          },
        },
      },
    });
  return (
    <>
      <List style={{ background: "none", padding: 5 }}>
        <StatusAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          severity={alertSeverity}
        />
        <ListItemText style={{ background: "none", margin: "5px 0" }}>
          <React.Fragment>
            <Button
              variant="contained"
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
              }}
            >
              Manage Role
            </Button>
            <Dialog
              // fullScreen
              // maxWidth="100vh"
              fullWidth
              maxWidth="sm"
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
              PaperProps={{
                style: {
                  position: "absolute",
                  background: "#141b2d",
                  top: "0",
                  backgroundColor: "white",
                  maxHeight: "95%",
                  margin: "auto",
                  overflowX: "hidden",
                  zIndex: 1200,
                },
              }}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} component="div">
                    Manage Role
                  </Typography>
                </Toolbar>
              </AppBar>
              <Box
                component="form"
                sx={{
                  mt: 3,
                  p: 2,
                  bgcolor: "#f5f5f5", // Adjusted background color for better contrast
                  borderRadius: 1,
                  boxShadow: 1,
                }}
              >
                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", margin: "10px" }}
                >
                  <Grid item xs={10} sm={10}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Role Name
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          placeholder="Enter role name"
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          onChange={(e) => setTax_name(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Button
                  variant="contained"
                  autoFocus
                  color="inherit"
                  onClick={SaveDate}
                  sx={{
                    backgroundColor: "blue",
                    "&:hover": { backgroundColor: "orange" },
                    textAlign: "center",
                    margin: "25px",
                  }}
                >
                  Save
                </Button>
              </Box>
            </Dialog>
          </React.Fragment>
        </ListItemText>
        <Divider />
        <ListItemText style={{ background: "white", margin: 0, width: "100%" }}>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              fullScreen={true}
              title={"Roles & Permissions"}
              data={leadSource}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default RolesSettings;
