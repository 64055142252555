import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import { Paper, styled } from "@mui/material";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import { Pie, PieChart } from "recharts";
import config from "../../../Data/AuthUser";
import { clientApis } from "../../../Data/axios";
const DetailsClients = () => {
  const navigate = useNavigate();
  const [detailValue, setDetailValue] = useState("");
  const location = useLocation();
  const openMenuId = location.state;
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const rowData = await clientApis.clientViewMoreId(openMenuId);
    setDetailValue(rowData);
  };
  const {
    total_drivers,
    app_user,
    client,
    active_drivers_count,
    inactive_drivers_count,
    leaveDrivers_count,
    total_earnings,
    due_invoice,
    paid_invoice,
    partial_invoice,
  } = detailValue || {};

  const {
    Status,
    address,
    created_at,
    gender,
    image,
    language,
    phone,
    username,
    pincode,
  } = app_user || {};
  const {
    allow_login,
    company_website,
    company_email,
    company_name,
    industry_type,
    tax_name,
    tax_number,
    user_id,
    state,
    company_phone,
    last_login,
  } = client || {};
  // const convertToPlainText = (htmlContent) => {
  //   const parser = new DOMParser();
  //   const parsedHtml = parser.parseFromString(htmlContent, "text/html");
  //   return parsedHtml.body.textContent || "";
  // };
  // {convertToPlainText(description)}
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleEdit = {};
  const date = new Date(created_at);

  // Extract the day, month, and year from the Date object
  const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if necessary
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
  const year = date.getFullYear();

  // Format the date as "DD-MM-YYYY"
  const formattedDate = `${day}-${month}-${year}`;

  // async function handleEdit=()=> {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const convertToMainUserType = (value) => {
    if (value === "1") return "Male";
    if (value === "2") return "Female";
    if (value === "3") return "Others";

    return "Unknown"; // Fallback for invalid values
  };

  return (
    <>
      <Box sx={{ bgcolor: "#eff1f1", height: "100%", width: "100%" }}>
        <Grid
          container
          sx={{
            width: "100%",
            paddingBottom: "15px",
            display: "flex",
            // justifyContent: "space-evenly",
            alignItems: "center",
            flex: 1,
            gap: 3,
          }}
        >
          <Grid item xs={11} sm={6} md={3.5} lg={3.5}>
            <Item
              sx={{
                backgroundColor: "#fff",
                color: "#333",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                padding: "20px",
                bgcolor: "#fff",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <img
                  src={`${config.apiImageUrl}${image}`}
                  alt="clientImage"
                  style={{ height: "70px", width: "70px", borderRadius: "7px" }}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <Box>{username}</Box>
                  <Box>{company_name}</Box>
                  <Box>{last_login}</Box>
                </Box>
              </Box>
              <Box>
                <MoreHorizTwoToneIcon />
              </Box>
              {/* <Box>
                <Button
                  id="basic-button"
                  aria-controls={openDropMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropMenu ? "true" : undefined}
                  sx={{ marginRight: "40px" }}
                  onClick={handleClick}
                >
                  <MoreHorizTwoToneIcon />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleEdit()}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EditNoteIcon /> Edit
                    </div>
                  </MenuItem>
                </Menu>
              </Box> */}
            </Item>
          </Grid>
          <Grid item xs={11} sm={6} md={2.5} lg={2.5}>
            <Item sx={{ backgroundColor: "#fff", color: "#333" }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                  paddingLeft: "20px",
                }}
              >
                Total Drivers
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                style={{ textAlign: "right" }}
              >
                <LocalActivityIcon sx={{ color: "#a7adad " }} />
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontSize: "16px",
                  color: "#1d82f5",
                  fontWeight: 550,
                  textAlign: "left",
                  paddingLeft: "20px",
                }}
              >
                {total_drivers}
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={11} sm={6} md={2.5} lg={2.5}>
            <Item sx={{ backgroundColor: "#fff", color: "#333" }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                  paddingLeft: "20px",
                }}
              >
                Total Earnings
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                style={{ textAlign: "right" }}
              >
                <LocalActivityIcon sx={{ color: "#a7adad " }} />
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontSize: "16px",
                  color: "#1d82f5",
                  fontWeight: 550,
                  textAlign: "left",
                  paddingLeft: "20px",
                }}
              >
                {total_earnings}
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={11} sm={6} md={2.5} lg={2.5}>
            <Item sx={{ backgroundColor: "#fff", color: "#333" }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                  paddingLeft: "20px",
                }}
              >
                Due Invoices
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                style={{ textAlign: "right" }}
              >
                <LocalActivityIcon sx={{ color: "#a7adad " }} />
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontSize: "16px",
                  color: "#1d82f5",
                  fontWeight: 550,
                  textAlign: "left",
                  paddingLeft: "20px",
                }}
              >
                {due_invoice}
              </Typography>
            </Item>
          </Grid>
        </Grid>
        <Grid container sx={{ display: "flex", gap: 2, height: "650px" }}>
          <Grid
            item
            xs={11}
            sm={11}
            md={7.2}
            lg={7.2}
            sx={{
              color: "#333",
              // padding: "20px",
              fontSize: "20px",
              fontWeight: 550,
              // width: "60%",
              bgcolor: "#fff",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgb(187, 186, 186) ",
              }}
            >
              <Box sx={{ padding: "10px 20px" }}>Profile Info</Box>
            </Box>
            <Box>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#333",
                      padding: "10px 20px",
                      fontSize: "15px",
                    }}
                  >
                    Full Name
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "gray",
                      padding: "10px 0",
                      fontSize: "15px",
                    }}
                  >
                    {username}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "gray",
                      padding: "10px 0",
                      fontSize: "15px",
                    }}
                  >
                    Logo
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#333",
                      padding: "10px 20px",
                      fontSize: "15px",
                    }}
                  >
                    Email
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "gray",
                      padding: "10px 0",
                      fontSize: "15px",
                    }}
                  >
                    {company_email}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#333",
                      padding: "10px 20px",
                      fontSize: "15px",
                    }}
                  >
                    Company Name
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "gray",
                      padding: "10px 0",
                      fontSize: "15px",
                    }}
                  >
                    {company_name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#333",
                      padding: "10px 20px",
                      fontSize: "15px",
                    }}
                  >
                    Time
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "gray",
                      padding: "10px 0",
                      fontSize: "15px",
                    }}
                  >
                    {formattedDate}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#333",
                      padding: "10px 20px",
                      fontSize: "15px",
                    }}
                  >
                    Mobile
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "gray",
                      padding: "10px 0",
                      fontSize: "15px",
                    }}
                  >
                    {phone}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#333",
                      padding: "10px 20px",
                      fontSize: "15px",
                    }}
                  >
                    Gender
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "gray",
                      padding: "10px 0",
                      fontSize: "15px",
                    }}
                  >
                    {convertToMainUserType(gender)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#333",
                      padding: "10px 20px",
                      fontSize: "15px",
                    }}
                  >
                    Office Phone Number
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "gray",
                      padding: "10px 0",
                      fontSize: "15px",
                    }}
                  >
                    {company_phone}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#333",
                      padding: "10px 20px",
                      fontSize: "15px",
                    }}
                  >
                    Official Website
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "gray",
                      padding: "10px 0",
                      fontSize: "15px",
                    }}
                  >
                    {company_website}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#333",
                      padding: "10px 20px",
                      fontSize: "15px",
                    }}
                  >
                    GST/VAT Number
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "gray",
                      padding: "10px 0",
                      fontSize: "15px",
                    }}
                  >
                    {tax_number}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#333",
                      padding: "10px 20px",
                      fontSize: "15px",
                    }}
                  >
                    Address
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "gray",
                      padding: "10px 0",
                      fontSize: "15px",
                    }}
                  >
                    {address}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#333",
                      padding: "10px 20px",
                      fontSize: "15px",
                    }}
                  >
                    State
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "gray",
                      padding: "10px 0",
                      fontSize: "15px",
                    }}
                  >
                    {state}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#333",
                      padding: "10px 20px",
                      fontSize: "15px",
                    }}
                  >
                    Postal code
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "gray",
                      padding: "10px 0",
                      fontSize: "15px",
                    }}
                  >
                    {pincode}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#333",
                      padding: "10px 20px",
                      fontSize: "15px",
                    }}
                  >
                    Language
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "gray",
                      padding: "10px 0",
                      fontSize: "15px",
                    }}
                  >
                    {language}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4.5}
            lg={4.5}
            sx={{
              display: "flex",
              flexDirection: "column",
              // width: "40%",
              gap: 2,
              height: "100%",
            }}
          >
            <Box
              sx={{
                height: "50%",
                width: "100%",
                bgcolor: "#fff",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Item
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      backgroundColor: "#fff",
                      color: "#000",
                    }}
                  >
                    <Typography sx={{ fontSize: "20px", fontWeight: 550 }}>
                      Drivers
                    </Typography>
                    <PieChart width={400} height={270}>
                      <Pie
                        data={[
                          {
                            name: "Active",
                            value: (active_drivers_count * 100) / 3,
                            fill: "#C70039",
                          },
                          {
                            name: "Inactive",
                            value: (inactive_drivers_count * 100) / 3,
                            fill: "#FFC300",
                          },
                          {
                            name: "Leave",
                            value: (leaveDrivers_count * 100) / 3,
                            fill: "#FF5733",
                          },
                        ]}
                        dataKey="value"
                        label={({ name, percent }) =>
                          `${name} (${(percent * 100).toFixed(0)}%)`
                        }
                        labelStyle={{
                          fill: "#333", // Set label color to black
                          fontSize: "14px", // Adjust font size
                        }}
                        outerRadius={80}
                      />
                    </PieChart>
                  </Item>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                height: "50%",
                width: "100%",
                bgcolor: "#fff",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Item
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      backgroundColor: "#fff",
                      color: "#000",
                    }}
                  >
                    <Typography sx={{ fontSize: "20px", fontWeight: 550 }}>
                      Invoices
                    </Typography>
                    <PieChart width={400} height={270}>
                      <Pie
                        data={[
                          {
                            name: "unpaid",
                            value: (due_invoice * 100) / 3,
                            fill: "#FFC300",
                          },
                          {
                            name: "paid",
                            value: (paid_invoice * 100) / 3,
                            fill: "#C70039 ",
                          },
                          {
                            name: "partial",
                            value: (partial_invoice * 100) / 3,
                            fill: "#FF5733",
                          },
                        ]}
                        dataKey="value"
                        label={({ name, percent }) =>
                          `${name} (${(percent * 100).toFixed(0)}%)`
                        }
                        labelStyle={{
                          fill: "#333", // Set label color to black
                          fontSize: "14px", // Adjust font size
                        }}
                        outerRadius={80}
                      />
                    </PieChart>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DetailsClients;
