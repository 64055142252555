import {
  createTheme,
  ThemeProvider,
  Menu,
  MenuItem,
  Stack,
  Box,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  TextField,
  Autocomplete,
  CircularProgress,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CoPresentOutlinedIcon from "@mui/icons-material/CoPresentOutlined";
import MUIDataTable from "mui-datatables";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";

import { useLocalStorageContext } from "../../../Context/LocalProvider";
import StatusAlert from "../../../StatusAlertList/StatusAlert";
import { Select } from "@mui/material";

import config from "../../../Data/AuthUser";
import { clientApis, clientDriversApis, DriverApis } from "../../../Data/axios";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReactQuill from "react-quill";
import { getUserInfo } from "../../../Data/UserData";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DriverClients() {
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [salary, setSalary] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [driverData, setDriverData] = useState([]);
  const [name, setName] = useState("");
  const [openMenuId, setOpenMenuId] = useState(null);
  const [driverId, setDriverId] = useState("");
  const [driverIdValue, setDriverIdValue] = useState("");
  const [optionsNew, setOptionsNew] = useState([]);
  const [loadingNew, setLoadingNew] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState([]);
  const [relieveId, setRelieveId] = useState([]);
  const { data: userData } = useLocalStorageContext();
  const [search, setSearch] = useState("");
  const [finalValue, setFinalValue] = useState(1);
  const navigate = useNavigate();
  const [descriptionReason, setDescriptionReason] = React.useState("");
  const [detailValue, setDetailValue] = useState("");
  const location = useLocation();
  const [remarkType, setRemarkType] = useState("");
  const openMenuIdNew = location.state;
  useEffect(() => {
    getDataPass();
  }, []);
  const handleChange = (event) => {
    setRemarkType(event.target.value);
  };
  const getDataPass = async () => {
    const rowData = await clientApis.clientViewMoreId(openMenuIdNew);
    setDetailValue(rowData);
  };
  const [openDate, setOpenDate] = React.useState(false);
  const { client, app_user } = detailValue || {};
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  const { user_id } = client || {};
  const { username } = app_user || {};

  const [date, setDate] = useState(null);
  const [dateRelieve, setDateRelieve] = useState(null);
  const handleDriverChange = (value) => {
    if (value) {
      setSelectedDriver(value.user_id);
    } else {
      setSelectedDriver(null);
    }
  };
  const handleCloseRelieve = () => {
    setOpenDate(false);
    setDateRelieve(null);
  };
  useEffect(() => {
    if (driverId && driverId.length > 11) {
      // Trigger API call when driver_id has sufficient length
      setLoadingNew(true);
      fetchOptionsByDriverId(driverId)
        .then((response) => {
          setOptionsNew([response]); // Ensure response is an array before setting options
          setLoadingNew(false);
        })
        .catch((error) => {
          console.error("Error fetching options:", error);
          setOptionsNew([]);
          setLoadingNew(false);
        });
    } else {
      setOptionsNew([]); // Clear options if input is too short
    }
  }, [driverId]);

  const fetchOptionsByDriverId = async (driverId) => {
    const data = await clientDriversApis.getDriver(driverId);

    return data.user;
  };

  const userDataNew = localStorage.getItem("user-info");
  let creator;
  if (userDataNew) {
    // Parse the JSON string into an object
    const parsedData = JSON.parse(userDataNew);

    // Access the name property from the parsed object
    creator = parsedData.value.empid;
  } else {
    console.error("No user data found in localStorage.");
  }

  useEffect(() => {
    filteredDriverValue();
  }, [search, setSearch]);

  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const filteredDriverValue = () => {
    const searchLower = search.toLowerCase();
    const filtered = driverData.filter((driver) => {
      return (
        (driver.username &&
          driver.username.toLowerCase().includes(searchLower)) ||
        (driver.phone && driver.phone.includes(search)) ||
        (driver.user_id && driver.user_id.toString().includes(search))
      );
    });
    setFilteredDrivers(filtered); // Update state with the filtered data
  };
  const item = {
    client_id: user_id,
    salary: salary,
    // allowance: allowance,
    join_date: date,
  };

  async function SaveData() {
    // Create a new FormData object
    const formData = new FormData();

    // Append each item to the FormData object
    for (const key in item) {
      if (item[key] !== undefined && item[key] !== null) {
        formData.append(key, item[key]);
      }
    }
    try {
      const result = await clientDriversApis.SavebyId(selectedDriver, formData);
      if (result === 1601) {
        showAlert("Driver is already allotted", "warning");
      } else {
        showAlert("Added successfully", "success");
        getData();
        setOpen(false);
        setName("");
        setSearch("");
      }
    } catch {
      showAlert("Failed to add. Please try again.", "error");
    }
  }

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleRelieveStatus = async (selectedValue, Id, dateRelieve) => {
    if (dateRelieve) {
      const itemStatus = { status: selectedValue };
      const formData = new FormData();

      for (const key in itemStatus) {
        if (itemStatus[key] !== undefined && itemStatus[key] !== null) {
          formData.append(key, itemStatus[key]);
        }
      }
      try {
        await clientDriversApis.changeStatus(Id, formData);

        showAlert("Status updated successfully", "success");

        setDateRelieve(null);
        getData();
      } catch (error) {
        showAlert("Failed to Change Status. Please try again.", "error");
      }
    }
  };

  const handleChangeSelected = async (event, Id, driverIdNew) => {
    const selectedValue = event.target.value;

    if (selectedValue === 0) {
      setDriverIdValue(driverIdNew);
      setRelieveId(Id);
      setOpenDate(true);
      setFinalValue(selectedValue);
    } else {
      const itemStatus = { status: selectedValue };
      const formData = new FormData();

      for (const key in itemStatus) {
        if (itemStatus[key] !== undefined && itemStatus[key] !== null) {
          formData.append(key, itemStatus[key]);
        }
      }
      try {
        await clientDriversApis.changeStatus(Id, formData);

        showAlert("Status updated successfully", "success");
        getData();
      } catch (error) {
        showAlert("Failed to Change Status. Please try again.", "error");
      }
    }
  };

  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleDateChange = (newDate) => {
    setDate(newDate); // Update the date state
  };
  const handleDateChangeRelieve = async (newDate) => {
    setDateRelieve(newDate);
    // After setting dateRelieve, call handleRelieveStatus
  };
  const handleViewMore = async () => {
    const rowData = await clientDriversApis.viewMoreDriver(openMenuId);

    navigate(`/clients/driver/${openMenuId}`, {
      state: rowData,
      clientId: user_id,
    });
  };

  const handleViewAttendence = async () => {
    const formData = new FormData();
    formData.append("client_id", user_id);

    const rowData = await clientDriversApis.viewMoreAttendance(
      openMenuId,
      formData
    );

    navigate(`/clients/driver/${openMenuId}/attendance`, { state: rowData });
  };

  const [leadFiles, setLeadFiles] = useState([]);

  useEffect(() => {
    getData();
  }, [user_id]);

  useEffect(() => {
    getDrivers();
  }, []);
  async function getDrivers() {
    const result = await DriverApis.getAll();
    setDriverData(result);
  }
  async function getData() {
    const result = await clientDriversApis.getAll(user_id);
    setLeadFiles(result?.data);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const dateValue = dateRelieve ? new Date(dateRelieve) : null;
  let formattedDate = " ";

  if (dateValue && !isNaN(dateValue)) {
    const day = String(dateValue.getDate()).padStart(2, "0");
    const month = String(dateValue.getMonth() + 1).padStart(2, "0");
    const year = dateValue.getFullYear();
    formattedDate = `${day}-${month}-${year}`;
  }

  const itemRelieve = {
    releave_date: formattedDate,
    note_to: driverIdValue,
    created_by: getUserInfo(),
    note: descriptionReason,
    note_type: remarkType,
  };

  async function handleRelieve() {
    const formData = new FormData();
    for (const key in itemRelieve) {
      if (itemRelieve[key] !== undefined && itemRelieve[key] !== null) {
        formData.append(key, itemRelieve[key]);
      }
    }
    try {
      await clientDriversApis.RelievebyId(relieveId, formData);

      setOpenDate(false);
      if (dateRelieve) {
        await handleRelieveStatus(finalValue, relieveId, dateRelieve);
      }
    } catch (error) {
      showAlert("Failed to Add Relieve. Please try again.", "error");
    }
  }
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      name: "app_user",
      label: "Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Name
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0].user_id;

          const fullRecord = leadFiles.find(
            (item) => item.app_user.user_id === ticketId
          );

          if (!fullRecord) {
            return <div>Loading...</div>;
          }

          const imageUrl = `${config.apiImageUrl}${fullRecord.app_user.image}`;

          return (
            <div
              key={value}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={imageUrl}
                alt="requester"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: "10px",
                }}
              />
              <div>
                <div style={{ fontWeight: "bold" }}>
                  {(value.username || "Unknown").charAt(0).toUpperCase() +
                    (value.username || "Unknown").slice(1)}
                </div>
                <div>{ticketId || "Unknown"}</div>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "corp_driver",
      label: "Start Date",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Start Date
            </th>
          );
        },
        customBodyRender: (value) => {
          const date = new Date(value.join_date);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          const formattedDate = `${day}-${month}-${year}`;
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {formattedDate || " "}
            </div>
          );
        },
      },
    },
    {
      name: "app_user",
      label: "Phone No.",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Phone No.
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value.phone || " "}
            </div>
          );
        },
      },
    },
    {
      name: "corp_driver",
      label: "Salary",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Salary
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value.salary || " "}
            </div>
          );
        },
      },
    },
    {
      name: "corp_driver",
      label: "Relieve",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Relieve
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value.releave_date}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value.releave_date}
            </div>
          );
        },
      },
    },
    {
      name: "corp_driver",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              key={value.id}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Select
                value={value.status}
                onChange={(event) =>
                  handleChangeSelected(event, value.id, value.driver_id)
                }
                displayEmpty
                renderValue={(selected) => {
                  if (selected === "1") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Active
                      </MenuItem>
                    ); // Placeholder text
                  }
                  if (selected === "0") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Relieve
                      </MenuItem>
                    ); // Placeholder text
                  }
                  if (selected === "2") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Leave
                      </MenuItem>
                    ); // Placeholder text
                  }
                  return selected; // Show selected value
                }}
                sx={{
                  width: "110px",

                  border: "none",
                  color: "#000 ",
                  bgcolor: "#fff",
                  "& .MuiSelect-select": {
                    padding: 0,
                  },
                }}
              >
                <MenuItem
                  value={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Active
                </MenuItem>
                <MenuItem
                  value={0}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Relieve
                </MenuItem>
                <MenuItem
                  value={2}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Leave
                </MenuItem>
              </Select>
            </div>
          );
        },
      },
    },

    {
      name: "corp_driver",
      label: "Actions",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "20px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                // paddingRight: "0px", // Add padding for starting point
              }}
            >
              <div>
                <Button
                  id="basic-button"
                  aria-controls={openDropMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div onClick={() => handleOpenMenu(value.driver_id)}>
                    <MoreHorizTwoToneIcon />
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleViewMore()}
                      className="MenuItemValue"
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <RemoveRedEyeOutlinedIcon /> &nbsp; View More
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleViewAttendence()}
                      className="MenuItemValue"
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <CoPresentOutlinedIcon />
                      &nbsp; Attendence
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </>
        ),
      },
    },
  ];

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    elevation: 0,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 25, 100],
    onChangeRowsPerPage: (newRowsPerPage) => {
      setRowsPerPage(newRowsPerPage);
    },
    tableBody: {
      style: {
        maxHeight: `${rowsPerPage * 50}px`,
        overflow: "auto",
        marginLeft: "5px",
        transform: "translateZ(0)",
        backfaceVisibility: "hidden",
        boxShadow: "none",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {},
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />

      <Dialog
        open={openDate}
        onClose={handleCloseRelieve}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            marginTop: "30px",
            top: 0,
            position: "absolute",
            height: "450px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            bgcolor: "#1d82F5",
            color: "#fff",
            textAlign: "center",
            width: "100%",
          }}
        >
          {"Do, you want to Relieve!!"}
        </DialogTitle>
        <DialogContent sx={{ bgcolor: "#fff", color: "#000" }}>
          <DialogContentText id="alert-dialog-description">
            <Grid
              container
              spacing={2}
              style={{ color: "black", marginTop: "10px", paddingLeft: "20px" }}
            >
              <Grid item xs={11} md={5.5} sm={6} sx={{ marginLeft: "10px" }}>
                <Stack direction="column">
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Choose Relieve Date
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    sx={{ textAlign: "center" }}
                  >
                    <DemoContainer
                      components={["DatePicker"]}
                      //  onChange={(e) => handleDateChangeRelieve(e.target.value)}
                      sx={{ marginTop: "-10px" }}
                    >
                      <DatePicker
                        value={dateRelieve}
                        onChange={handleDateChangeRelieve}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black !important",
                            color: "black !important",
                            "&:hover fieldset": {
                              borderColor: "black !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black !important",
                            },
                          },
                          "& .MuiSvgIcon-root": {
                            color: "black",
                          },
                        }}
                      >
                        {(params) => (
                          <TextField {...params} variant="outlined" />
                        )}
                      </DatePicker>
                    </DemoContainer>
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={1} sm={5.5} md={6} sx={{ marginLeft: "10px" }}>
                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    sx={{
                      color: "black",

                      padding: "4px",
                      borderRadius: "4px",
                    }}
                  >
                    Remark Type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={remarkType}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                            "&.Mui-checked": { color: "gray" },
                          }}
                        />
                      }
                      label="Public"
                    />
                    <FormControlLabel
                      value="2"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                            "&.Mui-checked": { color: "gray" },
                          }}
                        />
                      }
                      label="Private"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container sx={{ padding: "20px" }}>
              <Stack direction="column" sx={{ width: "100%" }}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Remark<span style={{ color: "red" }}>*</span>
                </Typography>

                <ReactQuill
                  value={descriptionReason}
                  onChange={setDescriptionReason}
                  modules={modules}
                  theme="snow"
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    color: "black",
                    borderBlockColor: "black",
                  }}
                />
              </Stack>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#fff", color: "#000" }}>
          <Button onClick={handleCloseRelieve} variant="outline">
            Cancel
          </Button>
          <Button onClick={handleRelieve} autoFocus variant="outline">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <List
        style={{
          background: "none",
          // padding: 5,
        }}
      >
        <React.Fragment>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              marginBottom: "5px",
              // marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#1d82f5",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                padding: "10px",
                marginLeft: "10px",
                height: "40px",
                width: "120px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: "4px",
                fontSize: "15px",
                fontFamily: "sans-serif",
                cursor: "pointer",
                //  marginTop: "20px",
              }}
            >
              <AddIcon />
              Add Driver
            </Box>
          </Stack>
          <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                position: "absolute",
                background: "#141b2d",
                top: "0",
                backgroundColor: "white",
                maxHeight: "95%",
                margin: "auto",
                overflowX: "hidden",
                zIndex: 1200,
              },
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                  Add New Driver
                </Typography>
              </Toolbar>
            </AppBar>
            <Box sx={{ background: "#fff" }}>
              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px", paddingLeft: "20px" }}
              >
                <Grid item xs={11} sm={6} md={4.2}>
                  <Stack direction="column">
                    <Typography>Name</Typography>

                    <Autocomplete
                      freeSolo
                      options={optionsNew || []}
                      getOptionLabel={(option) => {
                        if (typeof option === "object" && option !== null) {
                          return `${option?.username || ""}`;
                        }
                        return option;
                      }}
                      filterOptions={(options, params) => {
                        const filtered = options.filter(
                          (option) =>
                            option?.phone
                              ?.toLowerCase()
                              .includes(params.inputValue.toLowerCase()) ||
                            option?.user_id
                              ?.toLowerCase()
                              .includes(params.inputValue.toLowerCase())
                        );
                        if (params.inputValue !== "" && !filtered.length) {
                          filtered.push(params.inputValue);
                        }

                        return filtered;
                      }}
                      loading={loadingNew}
                      onInputChange={(event, value) => setDriverId(value)} //
                      onChange={(event, value) => {
                        if (typeof value === "string") {
                          handleDriverChange({ user_id: value });
                        } else if (value && typeof value === "object") {
                          handleDriverChange(value);
                        }
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.user_id === value?.user_id
                      }
                      renderOption={(props, option) => {
                        const imageUrl = `${config.apiImageUrl}${option.image}`;
                        return (
                          <li
                            {...props}
                            key={option.user_id}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            {/* Driver Image */}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={imageUrl}
                                alt={option?.username}
                                style={{
                                  width: 30,
                                  height: 30,
                                  borderRadius: "50%",
                                  marginRight: 10,
                                }}
                              />
                              {/* Driver Name and Info */}
                              <span>{option?.username || ""}</span>
                            </Box>
                            <div>{option?.user_id || ""}</div>
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search by Driver ID or Mobile Number"
                          variant="outlined"
                          fullWidth
                          sx={{
                            border: 1,
                            borderRadius: 1,
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { color: "black" },
                            endAdornment: (
                              <>
                                {loadingNew && <CircularProgress size={20} />}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={11} sm={4.2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Client
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        value={username}
                        // onChange={(e) => setEmail(e.target.value)}
                        aria-readonly
                        fullWidth
                        placeholder="ramu"
                        type="email"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px", paddingLeft: "20px" }}
              >
                <Grid item xs={11} sm={5} md={4.2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Salary
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={salary}
                        onChange={(e) => setSalary(e.target.value)}
                        placeholder="e.g.Rs 30,000"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4.2}
                  sm={4.2}
                  sx={{ paddingLeft: { xs: 0, sm: 2, md: 4 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={date}
                          onChange={handleDateChange}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                        >
                          {(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        </DatePicker>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              </Grid>

              <Grid container sx={{ padding: "10px 0 20px 38px" }}>
                <Grid item xs={11} className="messageFooter">
                  <Box className="response" onClick={SaveData}>
                    Save
                  </Box>
                  <Box className="cancel" onClick={handleClose}>
                    Cancel
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Dialog>
        </React.Fragment>
        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <MUIDataTable
              title={
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    color: "#333",
                  }}
                >
                  Driver Lists
                </span>
              }
              data={leadFiles}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default DriverClients;
