import React, { useState } from "react";
import SelectOption from "./SelectOption";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const ViewProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const rowData = location.state;
  // const handleCloseButton = () => {
  //   navigate("/rptpl");
  // };
  return (
    <Box sx={{ bgcolor: "#FFFFF7" }}>
      {/* <button
        style={{
          border: "1px solid black",
          color: "black",
          fontSize: ".7rem",
          padding: ".4rem",
          margin: ".3rem",
          background: "lightgray",
          borderRadius: ".4rem",
          float: "left",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => handleCloseButton()}
      >
        <span style={{ fontSize: "1rem", padding: "0 .3rem" }}>Close</span>{" "}
        <HighlightOffIcon />
      </button> */}
      <SelectOption rowData={rowData} />
    </Box>
  );
};

export default ViewProfile;
