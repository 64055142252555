import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GroupIcon from '@mui/icons-material/Group';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import './admOverview.css';
import HelpIcon from '@mui/icons-material/Help';
import { Placeholder } from 'react-bootstrap';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

function AdmOverview() {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (
        <Box display="flex" flexDirection="column" >
            <Box sx={{ flexGrow: 1 }}>
              

                    {/* first col  */}


                    <Grid item xs={12} md={6}>


                    <Grid container spacing={1}>

                    {/* first column */}
                    <Grid item xs={12} md={6}>

                        {/* first row */}
                        <Grid container spacing={1} >


                            <Grid item xs={12} sm={6}>
                                <Item style={{ backgroundColor: "#fff" }}>
                                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                        Total Users
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                        <GroupIcon />
                                    </Typography>
                                    <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                        275
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Item style={{ backgroundColor: "#fff" }}>
                                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                        Total Employees
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                        <GroupIcon />
                                    </Typography>
                                    <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                        42
                                    </Typography>
                                </Item>
                            </Grid>
                        </Grid>
                        <div style={{ marginBottom: '10px' }}></div>

                        {/* second row */}
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Item style={{ backgroundColor: "#fff" }}>
                                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                        Total Projects
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                        <LocalActivityIcon />
                                    </Typography>
                                    <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                        140
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Item style={{ backgroundColor: "#fff" }}>
                                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                        Due Invoice <HelpIcon />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                        <LocalActivityIcon />
                                    </Typography>
                                    <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                        140
                                    </Typography>
                                </Item>
                            </Grid>
                        </Grid>
                        <div style={{ marginBottom: '10px' }}></div>

                        {/* third row */}
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Item style={{ backgroundColor: "#fff" }}>
                                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                        Pending Tasks<HelpIcon />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                        <LocalActivityIcon />
                                    </Typography>
                                    <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                        275
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Item style={{ backgroundColor: "#fff" }}>
                                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                        Today Attendance
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                        <LocalActivityIcon />
                                    </Typography>
                                    <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                        20/31
                                    </Typography>
                                </Item>
                            </Grid>
                        </Grid>
                        <div style={{ marginBottom: '10px' }}></div>

                        {/* fourth row */}
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Item style={{  backgroundColor: "#fff" }}>
                                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                        Income <HelpIcon />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                                        <SignalCellularAltIcon />
                                    </Typography>
                                    <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                                        <Placeholder>-Not Enough Data-</Placeholder>
                                    </Typography>
                                </Item>
                            </Grid>
                        </Grid>
                        <div style={{ marginBottom: '10px' }}></div>

                        {/* fifth row */}
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Item style={{  backgroundColor: "#fff" }}>
                                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                        Pending Leaves <HelpIcon />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                                        <SignalCellularAltIcon />
                                    </Typography>
                                    <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                                        <Placeholder>-Not Enough Data-</Placeholder>
                                    </Typography>
                                </Item>
                            </Grid>
                        </Grid>
                        <div style={{ marginBottom: '10px' }}></div>
                        {/* sixth row */}
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Item style={{  backgroundColor: "#fff" }}>
                                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                        Pending Tasks <HelpIcon />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                                        <SignalCellularAltIcon />
                                    </Typography>
                                    <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                                        <Placeholder>-Not Enough Data-</Placeholder>
                                    </Typography>
                                </Item>
                            </Grid>
                        </Grid>
                        <div style={{ marginBottom: '10px' }}></div>
                        {/* seventh row */}
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Item style={{  backgroundColor: "#fff" }}>
                                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                        Project Activity Timeline <HelpIcon />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                                        <SignalCellularAltIcon />
                                    </Typography>
                                    <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                                        <Placeholder>-Not Enough Data-</Placeholder>
                                    </Typography>
                                </Item>
                            </Grid>
                        </Grid>

                    </Grid>


                    {/* second col  */}
                    
                    <Grid item xs={12} md={6}>

                    {/* first row */}
                    <Grid container spacing={1} >   


                        <Grid item xs={12} sm={6}>
                        <Item style={{ backgroundColor: "#fff" }}>
                                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                            Total Jobs
                                        </Typography>
                                        <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                            <LocalActivityIcon></LocalActivityIcon>
                                        </Typography>
                                        <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                            275
                                        </Typography>
                                    </Item>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <Item style={{ backgroundColor: "#fff" }}>
                                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                            Total Convoy
                                        </Typography>
                                        <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                            <LocalActivityIcon></LocalActivityIcon>
                                        </Typography>
                                        <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                            275
                                        </Typography>
                                    </Item>
                        </Grid>
                    </Grid>

                    <div style={{ marginBottom: '10px' }}></div>

                    {/* second row */}
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                        <Item style={{ backgroundColor: "#fff" }}>
                                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                            Hours Logged
                                        </Typography>
                                        <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                            <LocalActivityIcon></LocalActivityIcon>
                                        </Typography>
                                        <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                            9 hours
                                        </Typography>
                                    </Item>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <Item style={{ backgroundColor: "#fff" }}>
                                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                            Crane bookings
                                        </Typography>
                                        <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                            <LocalActivityIcon></LocalActivityIcon>
                                        </Typography>
                                        <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                            275
                                        </Typography>
                                    </Item>
                        </Grid>
                    </Grid>
                    <div style={{ marginBottom: '10px' }}></div>

                    {/* third row */}
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                        <Item style={{ backgroundColor: "#fff" }}>
                                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                            Unresolved Tickets
                                        </Typography>
                                        <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                            <LocalActivityIcon></LocalActivityIcon>
                                        </Typography>
                                        <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                            275
                                        </Typography>
                                    </Item>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <Item style={{ backgroundColor: "#fff" }}>
                                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                            Total Companies
                                        </Typography>
                                        <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                            <LocalActivityIcon></LocalActivityIcon>
                                        </Typography>
                                        <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                            105
                                        </Typography>
                                    </Item>
                        </Grid>
                    </Grid>
                    <div style={{ marginBottom: '10px' }}></div>

                    {/* fourth row */}
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Item style={{  backgroundColor: "#fff" }}>
                                <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                    Timesheet <HelpIcon />
                                </Typography>
                                <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                                    <SignalCellularAltIcon />
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                                    <Placeholder>-Not Enough Data-</Placeholder>
                                </Typography>
                            </Item>
                        </Grid>
                    </Grid>

                    <div style={{ marginBottom: '10px' }}></div>
                        {/* fifth row */}
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Item style={{  backgroundColor: "#fff" }}>
                                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                        Open Tickets <HelpIcon />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                                        <SignalCellularAltIcon />
                                    </Typography>
                                    <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                                        <Placeholder>-Not Enough Data-</Placeholder>
                                    </Typography>
                                </Item>
                            </Grid>
                        </Grid>

                        <div style={{ marginBottom: '10px' }}></div>
                        {/* sixth row */}
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Item style={{  backgroundColor: "#fff" }}>
                                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                        Pending Followup <HelpIcon />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                                        <SignalCellularAltIcon />
                                    </Typography>
                                    <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                                        <Placeholder>-Not Enough Data-</Placeholder>
                                    </Typography>
                                </Item>
                            </Grid>
                        </Grid>

                        <div style={{ marginBottom: '10px' }}></div>
                        {/* seventh row */}
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Item style={{  backgroundColor: "#fff" }}>
                                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                        User Activity Timeline <HelpIcon />
                                    </Typography>
                                    <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                                        <SignalCellularAltIcon />
                                    </Typography>
                                    <Typography variant="body2" style={{ textAlign: "center", fontSize: "16px", color: "#4d4f5c" }}>
                                        <Placeholder>-Not Enough Data-</Placeholder>
                                    </Typography>
                                </Item>
                            </Grid>
                        </Grid>

                    </Grid>


          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default AdmOverview;
