import * as React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

export default function BusinessDiolog({ rowData, open, handleClose }) {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={false}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {rowData.keys.map((key) => (
                    <TableCell
                      key={key}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        fontSize: "18px",
                      }}
                    >
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {rowData.values.map((value, index) => (
                    <TableCell
                      key={index}
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        fontSize: "18px",
                      }}
                    >
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ marginTop: "0", paddingTop: "0" }}>
        <Button
          onClick={handleClose}
          color="secondary"
          autoFocus
          style={{ fontSize: "18px" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
