import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import FieldSett from "../../../OnFieldTeam/FieldSett/FieldSett";

import ProfileView from "./ProfileView";

import Immigration from "./Immigration";
import { styled } from "@mui/material/styles";

import Project from "../../../Work/Projects/Project";

import Appreciation from "../../Appreciation/Appreciation";
import ShiftRoaster from "../../ShiftRoaster/ShiftRoaster";
import Documents from "./Documents";
import EmergencyContacts from "./EmergencyContacts";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import Tasks from "../../../Work/Tasks/Tasks";
import LeaveEmployee from "./LeaveEmployee";
import { TimeLogSheet } from "./TimeLogSheet";
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    padding: "0",

    maxWidth: 30,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => (
  <Tab disableRipple {...props} sx={{ width: "10px", padding: "0" }} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  margin: theme.spacing(0),
  padding: theme.spacing(0),
  color: "#C0B476",
  "&.Mui-selected": {
    color: "#000000",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "red",
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Employeeview() {
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { id } = useParams();
  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      navigate(`/hr/employees/${id}?tab=project`, { state });
    }
    if (newValue === 2) {
      navigate(`/hr/employees/${id}?tab=tasks`, { state });
    }
    if (newValue === 3) {
      navigate(`/hr/employees/${id}?tab=leaves`, { state });
    }
    if (newValue === 4) {
      navigate(`/hr/employees/${id}?tab=documents`, { state });
    }
    if (newValue === 5) {
      navigate(`/hr/employees/${id}?tab=timesheet`, { state });
    }
    if (newValue === 6) {
      navigate(`/hr/employees/${id}?tab=appreciation`, { state });
    }
    if (newValue === 7) {
      navigate(`/hr/employees/${id}?tab=shift-roaster`, { state });
    }
    if (newValue === 8) {
      navigate(`/hr/employees/${id}?tab=permissions`, { state });
    }
    if (newValue === 9) {
      navigate(`/hr/employees/${id}?tab=activity`, { state });
    }
    if (newValue === 10) {
      navigate(`/hr/employees/${id}?tab=immigration`, { state });
    }
    if (newValue === 11) {
      navigate(`/hr/employees/${id}?tab=emergency-contact`, { state });
    }

    if (newValue === 0) {
      navigate(`/hr/employees/${id}`, { state });
    }
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        maxWidth: "100%",
        background: "#fff",
        color: "black",
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <StyledTab label="Profile" {...a11yProps(0)} />
          <StyledTab label="Project" {...a11yProps(1)} />
          <StyledTab label="Tasks" {...a11yProps(2)} />
          <StyledTab label="Leaves" {...a11yProps(3)} />
          <StyledTab label="Documents" {...a11yProps(4)} />

          <StyledTab label="Timesheet" {...a11yProps(5)} />
          <StyledTab label="Appreciation" {...a11yProps(6)} />
          <StyledTab label="Shift Roaster" {...a11yProps(7)} />
          <StyledTab label="Permissions" {...a11yProps(8)} />

          <StyledTab label="Activity" {...a11yProps(9)} />
          <StyledTab label="Immigration" {...a11yProps(10)} />
          <StyledTab label="Emergency Contacts" {...a11yProps(11)} />
        </StyledTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ProfileView></ProfileView>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Project />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Tasks />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <LeaveEmployee />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <Documents />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <TimeLogSheet />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <Appreciation />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={7}>
        <ShiftRoaster />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={8}>
        <FieldSett></FieldSett>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={9}>
        <FieldSett></FieldSett>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={10}>
        <Immigration></Immigration>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={11}>
        <EmergencyContacts />
      </CustomTabPanel>
    </Box>
  );
}

export default Employeeview;
