import Allroutes from "./Routes/Allroutes.jsx";
import SnackbarProvider from "./Context/SnackbarContext.js";
import React, { useEffect } from 'react';
//  const userInfo = localStorage.getItem('user-info');

function App() {


  // Trun on the following function in case of production

  // useEffect(() => {
  //   const handleContextMenu = (e) => e.preventDefault();
  //   document.addEventListener('contextmenu', handleContextMenu);

  //   const isDevToolsOpen = () => {
  //     const devTools = /./;
  //     devTools.toString = function() {
  //       return 'devtools';
  //     };
  //     return devTools.toString().includes('devtools');
  //   };

  //   const checkDevTools = () => {
  //     if (isDevToolsOpen()) {
  //       // alert('Please do not use developer tools!');
  //     }
  //   };

  //   checkDevTools();
  //   const devToolsCheckInterval = setInterval(checkDevTools, 1000);

  //   return () => {
  //     document.removeEventListener('contextmenu', handleContextMenu);
  //     clearInterval(devToolsCheckInterval);
  //   };
  // }, []);



  return (
    <SnackbarProvider> 
      <Allroutes />
    </SnackbarProvider>
  );
}

export default App;
