import {
  Box,
  Grid,
  TextField,
  createTheme,
  ThemeProvider,
  Menu,
  Stack,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { DatePicker } from "@mui/x-date-pickers";

import StatusAlert from "../../../StatusAlertList/StatusAlert";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FooterTime() {
  const [member, setMember] = React.useState("");
  const [leaveType, setLeaveType] = React.useState("");

  const [duration, setDuration] = React.useState("");
  const [date, setDate] = useState(null); // Initialize state for the date
  const [memberError, setMemberError] = React.useState(false);
  const [reason, setReason] = useState("");
  const [fileName, setFileName] = useState("");
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const Andrew = "Andrew";
  const Bohr = "Bohr";
  const Ceaser = "Ceaser";
  const Casual = "Casual";
  const Sick = "Sick";
  const Earned = "Earned";

  // Event handler to update the date state and log the date to the console
  const handleDateChange = (newDate) => {
    setDate(newDate); // Update the date state
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    const list = { member, leaveType, status, duration, date };

    if (member === "") {
      setMemberError(true);
    } else {
      const list = { member, leaveType, status };

      setMemberError(false); // Reset the error state
      handleClose(); // Close the dialog
    }
  };

  const [update, setUpdate] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({
    keys: [],
    values: [],
    open: false,
  });

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };
  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleViewMore = () => {
    const rowData = leaveFile.filter((row) => row.id === openMenuId);

    // setOpenDialog(true);
  };
  const handleDailogClosing = () => {
    setSelectedRow({ ...selectedRow, open: false });
  };

  const [status, setStatus] = useState("");

  const [updateMember, setUpdateMember] = React.useState("");
  const [updateLeaveType, setUpdateLeaveType] = React.useState("");

  const [updateDuration, setUpdateDuration] = React.useState("");
  const [updateDate, setUpdateDate] = useState(null); // Initialize state for the date
  const [updateMemberError, setUpdateMemberError] = React.useState(false);
  const [updateReason, setUpdateReason] = useState("");
  const [updateFileName, setUpdateFileName] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [data, SetData] = useState([]);

  const [openMenuId, setOpenMenuId] = useState(null);

  const item = {
    member,
    memberError,
    reason,
    duration,
    date,
    leaveType,
    fileName,
  };

  async function SaveDate() {
    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/addBusinesslocation",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      }
    );

    result = await result.json();

    showAlert("Leave Added successfully", "success");

    getData();
    setOpen(false);
  }

  // ends Here

  // Table Starts Here

  const [leaveFile, setLeaveFile] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/listBusinessLocations"
    );

    const response = await results.json();
    setLeaveFile(response);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      name: "",
      label: "Code",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Code
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Task",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Task
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Employee",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Employee
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Start Date",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Start Date
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "End Time",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              End Time
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Total Hours ",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Total Hours
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Earnings",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Earnings
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "35px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                // Add padding for starting point
              }}
            >
              <div style={{ paddingRight: "5px" }}>
                <Button
                  id="basic-button"
                  aria-controls={openDropMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div onClick={() => handleOpenMenu(value)}>
                    <MoreHorizTwoToneIcon />
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleViewMore(tableMeta.rowData)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <RemoveRedEyeIcon /> View More
                    </div>
                  </MenuItem>

                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleEdit(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EditNoteIcon /> Edit
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleDelete(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <DeleteForeverIcon /> Delete
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </>
        ),
      },
    },
  ];

  async function handleEdit(id) {
    let result = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/businesslocationget/" + id
    );
    result = await result.json();

    SetData(result);
    handleClickUpdate();
  }

  // Update Values Start

  useEffect(() => {
    setUpdateMember(data.member);
  }, [data.member]);

  useEffect(() => {
    setUpdateDate(data.date);
  }, [data.date]);

  useEffect(() => {
    setUpdateDuration(data.duration);
  }, [data.duration]);

  useEffect(() => {
    setUpdateMemberError(data.managerError);
  }, [data.managerError]);

  useEffect(() => {
    setUpdateFileName(data.fileName);
  }, [data.fileName]);

  useEffect(() => {
    setUpdateReason(data.reason);
  }, [data.reason]);

  useEffect(() => {
    setUpdateLeaveType(data.leaveType);
  }, [data.leaveType]);

  useEffect(() => {
    setUpdateStatus(data.status);
  }, [data.status]);

  async function UpdateData() {
    const formData = new FormData();

    formData.append("member", updateMember);
    formData.append("memberError", updateMemberError);
    formData.append("fileName", updateFileName);
    formData.append("date", updateDate);
    formData.append("reason", updateReason);
    formData.append("duration", updateDuration);
    formData.append("leaveType", updateLeaveType);
    formData.append("status", updateStatus);
    try {
      let response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/updateBusinessLocation/${data.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      showAlert("Leave updated successfully", "success");

      handleUpdateClose();
      getData(); // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      showAlert("Failed to update business location", "error");
    }
  }

  // Editing Ends Here

  // Delete

  async function handleDelete(id) {
    const response = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/businesslocationdelete/" + id,
      {
        method: "DELETE",
      }
    );
    const result = await response.json(); // Assigning to a new variable

    getData();
    showAlert("Leave Deleted successfully", "warning");
  }

  // end delete

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          // maxWidth: "1000px",
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  // Table Ends Here
  const handleCancel = () => {};
  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />

      <Box>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={update}
          onClose={handleUpdateClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleUpdateClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                Update Log Time
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            sx={{ padding: "30px", margin: "20px", backgroundColor: "white" }}
          >
            <Typography
              sx={{ ml: 2, flex: 1, color: "black", fontSize: "20px" }}
              component="div"
            >
              TimeLog Details
            </Typography>
            <hr sx={{ color: "grey" }} />

            <Grid container sx={{ marginTop: "20px", padding: "20px" }}>
              <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sm={4}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Project <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      value={member}
                      autoFocus
                      onChange={(e) => setMember(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={Andrew}>Andrew</MenuItem>
                      <MenuItem value={Bohr}>Bohr</MenuItem>
                      <MenuItem value={Ceaser}>Ceaser</MenuItem>
                    </Select>
                    {memberError && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        Please select a member
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sm={4}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Task
                    </Typography>
                    <Select
                      value={leaveType}
                      autoFocus
                      onChange={(e) => setLeaveType(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={Casual}>Casual</MenuItem>
                      <MenuItem value={Sick}>Sick</MenuItem>
                      <MenuItem value={Earned}>Earned</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sm={4}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Employee
                    </Typography>
                    <Select
                      value={leaveType}
                      autoFocus
                      onChange={(e) => setLeaveType(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={Casual}>Casual</MenuItem>
                      <MenuItem value={Sick}>Sick</MenuItem>
                      <MenuItem value={Earned}>Earned</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>

            <Grid container sx={{ padding: "20px" }}>
              <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sm={3}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Start Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={date}
                          onChange={handleDateChange}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                        >
                          {/* TextField component now becomes a child of DatePicker */}
                          {(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              // Add any additional TextField props if needed
                            />
                          )}
                        </DatePicker>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sm={3}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Start Time
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["TimePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DemoItem>
                          <TimePicker
                            value={date}
                            onChange={handleDateChange}
                            sx={{
                              border: "1px solid black",
                              borderRadius: "5px",
                              "& .MuiOutlinedInput-root": {
                                borderColor: "black !important",
                                color: "black !important",
                                "&:hover fieldset": {
                                  borderColor: "black !important",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black !important",
                                },
                              },
                              "& .MuiSvgIcon-root": {
                                color: "black",
                              },
                            }}
                          >
                            {(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                // Add any additional TextField props if needed
                              />
                            )}
                          </TimePicker>
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sm={3}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      End Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={date}
                          onChange={handleDateChange}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                        >
                          {/* TextField component now becomes a child of DatePicker */}
                          {(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              // Add any additional TextField props if needed
                            />
                          )}
                        </DatePicker>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sm={3}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      End Time
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["TimePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DemoItem>
                          <TimePicker
                            value={date}
                            onChange={handleDateChange}
                            sx={{
                              border: "1px solid black",
                              borderRadius: "5px",
                              "& .MuiOutlinedInput-root": {
                                borderColor: "black !important",
                                color: "black !important",
                                "&:hover fieldset": {
                                  borderColor: "black !important",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black !important",
                                },
                              },
                              "& .MuiSvgIcon-root": {
                                color: "black",
                              },
                            }}
                          >
                            {(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                // Add any additional TextField props if needed
                              />
                            )}
                          </TimePicker>
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>

            <Grid container sx={{ marginTop: "20px", padding: "20px" }}>
              <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Memo
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="---"
                        type="email"
                        onChange={(e) => setMember(e.target.value)}
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Total Hours
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="---"
                        type="text"
                        onChange={(e) => setMember(e.target.value)}
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Button
              variant="contained"
              autoFocus
              color="inherit"
              onClick={handleSave}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                margin: "25px",
              }}
            >
              Update
            </Button>
          </Box>
        </Dialog>
      </Box>

      <List
        style={{
          background: "none",
          padding: 5,
        }}
      >
        <React.Fragment>
          <Stack direction="row" spacing={2} sx={{ margin: "20px 0 5px 0" }}>
            <Box
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#1d82f5",
                color: "#fff",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                padding: "10px",
                marginLeft: "10px",
                height: "40px",
                width: "110px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: "4px",
                fontSize: "15px",
                fontFamily: "sans-serif",
                cursor: "pointer",
              }}
            >
              <AddIcon />
              Log Time
            </Box>
          </Stack>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                position: "absolute",
                background: "#141b2d",
                top: "0",
                backgroundColor: "white",
                maxHeight: "95%",
                margin: "auto",
                overflowX: "hidden",
                zIndex: 1200,
              },
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                  Log Time
                </Typography>
              </Toolbar>
            </AppBar>
            <Box sx={{ backgroundColor: "white" }}>
              <Typography
                sx={{
                  flex: 1,
                  color: "black",
                  fontSize: "20px",
                  padding: "20px",
                  borderBottom: "1px solid gray",
                }}
                component="div"
              >
                TimeLog Details
              </Typography>
              <hr sx={{ color: "grey" }} />

              <Grid
                container
                sx={{ padding: { xs: "5px", sm: "5px", md: "20px" } }}
              >
                <Grid
                  item
                  xs={11}
                  md={4}
                  sm={5.5}
                  sx={{
                    paddingLeft: { xs: 2 },
                    paddingTop: { xs: 2, sm: 1 },
                  }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Project <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      value={member}
                      autoFocus
                      onChange={(e) => setMember(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={Andrew}>Andrew</MenuItem>
                      <MenuItem value={Bohr}>Bohr</MenuItem>
                      <MenuItem value={Ceaser}>Ceaser</MenuItem>
                    </Select>
                    {memberError && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        Please select a member
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={11}
                  md={4}
                  sm={6}
                  sx={{
                    paddingLeft: { xs: 2 },
                    paddingTop: { xs: 2, sm: 1 },
                  }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Task
                    </Typography>
                    <Select
                      value={leaveType}
                      autoFocus
                      onChange={(e) => setLeaveType(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={Casual}>Casual</MenuItem>
                      <MenuItem value={Sick}>Sick</MenuItem>
                      <MenuItem value={Earned}>Earned</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={11}
                  md={4}
                  sm={5.5}
                  sx={{
                    paddingLeft: { xs: 2 },
                    paddingTop: { xs: 2, sm: 1 },
                  }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Employee
                    </Typography>
                    <Select
                      value={leaveType}
                      autoFocus
                      onChange={(e) => setLeaveType(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={Casual}>Casual</MenuItem>
                      <MenuItem value={Sick}>Sick</MenuItem>
                      <MenuItem value={Earned}>Earned</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{ padding: { xs: "5px", sm: "5px", md: "20px" } }}
              >
                <Grid
                  item
                  xs={11}
                  md={3}
                  sm={5.5}
                  sx={{
                    paddingLeft: { xs: 2 },
                    paddingTop: { xs: 2, sm: 1 },
                  }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Start Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={date}
                          onChange={handleDateChange}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                        >
                          {/* TextField component now becomes a child of DatePicker */}
                          {(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              // Add any additional TextField props if needed
                            />
                          )}
                        </DatePicker>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={11}
                  md={3}
                  sm={5.5}
                  sx={{
                    paddingLeft: { xs: 2 },
                    paddingTop: { xs: 2, sm: 1 },
                  }}
                >
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Start Time
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["TimePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DemoItem>
                          <TimePicker
                            value={date}
                            onChange={handleDateChange}
                            sx={{
                              border: "1px solid black",
                              borderRadius: "5px",
                              "& .MuiOutlinedInput-root": {
                                borderColor: "black !important",
                                color: "black !important",
                                "&:hover fieldset": {
                                  borderColor: "black !important",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black !important",
                                },
                              },
                              "& .MuiSvgIcon-root": {
                                color: "black",
                              },
                            }}
                          >
                            {(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                // Add any additional TextField props if needed
                              />
                            )}
                          </TimePicker>
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={11}
                  md={3}
                  sm={5.5}
                  sx={{
                    paddingLeft: { xs: 2 },
                    paddingTop: { xs: 2, sm: 1 },
                  }}
                >
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      End Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={date}
                          onChange={handleDateChange}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                        >
                          {/* TextField component now becomes a child of DatePicker */}
                          {(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              // Add any additional TextField props if needed
                            />
                          )}
                        </DatePicker>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={11}
                  md={3}
                  sm={5.5}
                  sx={{
                    paddingLeft: { xs: 2 },
                    paddingTop: { xs: 2, sm: 1 },
                  }}
                >
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      End Time
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["TimePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DemoItem>
                          <TimePicker
                            value={date}
                            onChange={handleDateChange}
                            sx={{
                              border: "1px solid black",
                              borderRadius: "5px",
                              "& .MuiOutlinedInput-root": {
                                borderColor: "black !important",
                                color: "black !important",
                                "&:hover fieldset": {
                                  borderColor: "black !important",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black !important",
                                },
                              },
                              "& .MuiSvgIcon-root": {
                                color: "black",
                              },
                            }}
                          >
                            {(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                // Add any additional TextField props if needed
                              />
                            )}
                          </TimePicker>
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{ padding: { xs: "5px", sm: "5px", md: "20px" } }}
              >
                <Grid
                  item
                  xs={11}
                  sm={5.5}
                  md={5.5}
                  sx={{
                    paddingLeft: { xs: 2 },
                    paddingTop: { xs: 2, sm: 1 },
                  }}
                >
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Memo
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="---"
                        type="email"
                        onChange={(e) => setMember(e.target.value)}
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={11}
                  sm={5.5}
                  md={5.5}
                  sx={{
                    paddingLeft: { xs: 2 },
                    paddingTop: { xs: 2, sm: 1 },
                  }}
                >
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Total Hours
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="---"
                        type="text"
                        onChange={(e) => setMember(e.target.value)}
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  padding: {
                    xs: "10px 10px 20px 10px",
                    md: "10px 10px 20px 30px",
                  },
                }}
              >
                <Grid item xs={11} className="messageFooter">
                  <Box className="response" onClick={handleSave}>
                    Save
                  </Box>
                  <Box className="cancel" onClick={handleCancel}>
                    Cancel
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Dialog>
        </React.Fragment>

        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <Box
              sx={{
                maxWidth: "100%", // Ensure it doesn't exceed the parent container
              }}
            >
              <MUIDataTable
                title={
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "1.5rem",
                      color: "#333",
                    }}
                  >
                    Log Time Lists
                  </span>
                }
                data={leaveFile}
                columns={columns}
                options={options}
              />
            </Box>
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default FooterTime;
