import {
  Box,
  DialogTitle,
  Grid,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { MenuItem } from "react-pro-sidebar";
import ReactQuill from "react-quill";

import { useNavigate } from "react-router-dom";
const AddNotice = () => {
  const [description, setDescription] = useState("");
  const [noticeHeading, setNoticeHeading] = useState("");

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [departments, setDepartments] = useState("");

  const navigate = useNavigate();

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  const userData = localStorage.getItem("user-info");
  let creator;
  if (userData) {
    // Parse the JSON string into an object
    const parsedData = JSON.parse(userData);

    // Access the name property from the parsed object
    creator = parsedData.value.name;
  } else {
    console.error("No user data found in localStorage.");
  }

  const item = {
    title: noticeHeading,
    description: description,

    creator: creator,
    // notice_to: selectedRadio,
    department: departments,
  };

  async function SaveData() {
    // Create a new FormData object
    const formData = new FormData();

    // Append each item to the FormData object
    for (const key in item) {
      if (item[key] !== undefined && item[key] !== null) {
        formData.append(key, item[key]);
      }
    }

    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/addNotice",
      {
        method: "POST",
        body: formData,
      }
    );

    await result.json();

    showAlert("Added successfully", "success");

    navigate("/notice");
  }

  // const handleAddNotice = () => {
  //   setAddNoticeButton(false);
  // };
  return (
    // <Box sx={{ background: "#fff" }}>
    //   {/* <Grid
    //     container
    //     spacing={2}
    //     sx={{
    //       padding: { xs: "20px", sm: "20px" },
    //       color: "#000",
    //     }}
    //   >
    //     <Grid item xs={8} sm={3.5} md={3.5}>
    //       <FormControl>
    //         <RadioGroup
    //           row
    //           aria-labelledby="demo-row-radio-buttons-group-label"
    //           name="row-radio-buttons-group"
    //           value={selectedRadio}
    //           onChange={(e) => setSelectedRadio(e.target.value)}
    //         >
    //           <FormControlLabel
    //             value="Client"
    //             control={
    //               <Radio
    //                 sx={{
    //                   color: "black",
    //                   "&.Mui-checked": { color: "gray" },
    //                 }}
    //               />
    //             }
    //             label="Client"
    //           />
    //           <FormControlLabel
    //             value="Employee"
    //             control={
    //               <Radio
    //                 sx={{
    //                   color: "black",
    //                   "&.Mui-checked": { color: "gray" },
    //                 }}
    //               />
    //             }
    //             label="Employee"
    //           />
    //           <FormControlLabel
    //             value="App User"
    //             control={
    //               <Radio
    //                 sx={{
    //                   color: "black",
    //                   "&.Mui-checked": { color: "gray" },
    //                 }}
    //               />
    //             }
    //             label="App User"
    //           />
    //         </RadioGroup>
    //       </FormControl>
    //     </Grid>
    //   </Grid> */}

    //   <Grid
    //     container
    //     spacing={2}
    //     sx={{
    //       padding: { xs: "20px", sm: "20px" },
    //     }}
    //   >
    //     <Grid item xs={12} sm={5.5} md={5.5}>
    //       <Stack direction="column" spacing={2}>
    //         <Typography sx={{ color: "#000" }}>Notice Heading</Typography>
    //         <TextField
    //           autoFocus
    //           margin="dense"
    //           placeholder="Notice Heading Name"
    //           type="text"
    //           fullWidth
    //           value={noticeHeading}
    //           onChange={(e) => setNoticeHeading(e.target.value)}
    //           inputProps={{ style: { color: "black" } }}
    //           InputLabelProps={{
    //             style: { color: "black" },
    //           }}
    //           sx={{
    //             border: "1px solid black",
    //             borderRadius: "5px",
    //             "& .MuiOutlinedInput-root": {
    //               borderColor: "black",
    //               "&:hover fieldset": {
    //                 borderColor: "black",
    //               },
    //               "&.Mui-focused fieldset": {
    //                 borderColor: "black",
    //               },
    //             },
    //             "& .MuiOutlinedInput-input": {
    //               "&::placeholder": { color: "gray" },
    //             },
    //           }}
    //         />
    //       </Stack>
    //     </Grid>

    //     <Grid item xs={12} sm={5.5} md={5.5}>
    //       <Stack direction="column" spacing={2}>
    //         <Typography sx={{ color: "#000" }}>Department</Typography>
    //         <Select
    //           value={departments}
    //           autoFocus
    //           fullWidth
    //           onChange={(e) => setDepartments(e.target.value)}
    //           displayEmpty
    //           inputProps={{
    //             "aria-label": "Without label",
    //           }}
    //           sx={{
    //             border: "1px solid black",
    //             boxSizing: "border-box",
    //             "& .MuiOutlinedInput-root": {
    //               borderColor: "black !important",
    //               "&:hover fieldset": {
    //                 borderColor: "black !important",
    //               },
    //               "&.Mui-focused fieldset": {
    //                 borderColor: "black !important",
    //               },
    //             },
    //             color: "black",
    //           }}
    //         >
    //           <MenuItem disabled value="">
    //             <em>---</em>
    //           </MenuItem>
    //           <MenuItem value={0}>Trainee</MenuItem>
    //           <MenuItem value={1}>Junior</MenuItem>
    //           <MenuItem value={2}>Senior</MenuItem>
    //           <MenuItem value={3}>Team Lead</MenuItem>
    //           <MenuItem value={4}>Project Manager</MenuItem>
    //         </Select>
    //       </Stack>
    //     </Grid>
    //   </Grid>

    //   <Grid container sx={{ padding: "20px" }}>
    //     <Stack direction="column" sx={{ width: "100%" }}>
    //       <Typography variant="p" gutterBottom style={{ color: "black" }}>
    //         Notice Details<span style={{ color: "red" }}>*</span>
    //       </Typography>

    //       <ReactQuill
    //         value={description}
    //         onChange={setDescription}
    //         modules={modules}
    //         theme="snow"
    //         style={{
    //           width: "100%",
    //           boxSizing: "border-box",
    //           color: "black",
    //           borderBlockColor: "black",
    //         }}
    //       />
    //     </Stack>
    //   </Grid>

    //   <Button
    //     variant="contained"
    //     autoFocus
    //     color="inherit"
    //     onClick={SaveData}
    //     sx={{
    //       backgroundColor: "blue",
    //       "&:hover": { backgroundColor: "orange" },
    //       textAlign: "center",

    //       marginLeft: "25px",
    //       marginTop: { xs: "70px", sm: "50px", md: "40px" },
    //       marginBottom: "30px",
    //     }}
    //   >
    //     Save
    //   </Button>
    // </Box>
    <>
      <Box sx={{ background: "#fff", color: "#000" }}>
        <DialogTitle>
          <span
            style={{
              background: "blue",
              color: "#fff",
              padding: "15px",
              borderRadius: "5px",
            }}
          >
            Add Notice
          </span>
        </DialogTitle>
      </Box>
      <Box sx={{ background: "#fff" }}>
        <Grid
          container
          spacing={2}
          sx={{
            padding: { xs: "20px", sm: "20px" },
            color: "#000",
          }}
        >
          {/* <Grid item xs={11} sm={5} md={5}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="Client"
                  control={
                    <Radio
                      sx={{
                        color: "black",
                        "&.Mui-checked": { color: "#1d82f5" },
                      }}
                    />
                  }
                  label="Client"
                />
                <FormControlLabel
                  value="Employee"
                  control={
                    <Radio
                      sx={{
                        color: "black",
                        "&.Mui-checked": { color: "#1d82f5" },
                      }}
                    />
                  }
                  label="Employee"
                />
                <FormControlLabel
                  value="App User"
                  control={
                    <Radio
                      sx={{
                        color: "black",
                        "&.Mui-checked": { color: "#1d82f5" },
                      }}
                    />
                  }
                  label="App User"
                />
              </RadioGroup>
            </FormControl>
          </Grid> */}

          {/* {radioButton && (
            <Grid item xs={11} sm={5} md={5}>
              <Typography sx={{ color: "#000" }}>User</Typography>
              <Select
                value={updateDepartments}
                autoFocus
                fullWidth
                onChange={(e) => setUpdateDepartments(e.target.value)}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                sx={{
                  border: "1px solid black",
                  boxSizing: "border-box",
                  "& .MuiOutlinedInput-root": {
                    borderColor: "black !important",
                    "&:hover fieldset": {
                      borderColor: "black !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black !important",
                    },
                  },
                  color: "black",
                }}
              >
                <MenuItem disabled value="">
                  <em>---</em>
                </MenuItem>
                <MenuItem value={0}>Driver</MenuItem>
                <MenuItem value={1}>Owner</MenuItem>
                <MenuItem value={2}>Dhaba</MenuItem>
                <MenuItem value={3}>Crane Owner</MenuItem>
                <MenuItem value={4}>Crane Operator</MenuItem>
              </Select>
            </Grid>
          )} */}
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            padding: { xs: "20px", sm: "20px" },
          }}
        >
          <Grid item xs={12} sm={5.5} md={5.5}>
            <Stack direction="column" spacing={2}>
              <Typography sx={{ color: "#000" }}>Notice Heading</Typography>
              <TextField
                autoFocus
                margin="dense"
                placeholder="Notice Heading Name"
                type="text"
                fullWidth
                // value={updateHeading}
                // onChange={(e) => setUpdateHeading(e.target.value)}
                inputProps={{ style: { color: "black" } }}
                InputLabelProps={{
                  style: { color: "black" },
                }}
                sx={{
                  border: "1px solid black",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-root": {
                    borderColor: "black",
                    "&:hover fieldset": {
                      borderColor: "black",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    "&::placeholder": { color: "gray" },
                  },
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={5.5} md={5.5}>
            <Stack direction="column" spacing={2}>
              <Typography sx={{ color: "#000" }}>Department</Typography>
              <Select
                value={departments}
                autoFocus
                fullWidth
                onChange={(e) => setDepartments(e.target.value)}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                sx={{
                  border: "1px solid black",
                  boxSizing: "border-box",
                  "& .MuiOutlinedInput-root": {
                    borderColor: "black !important",
                    "&:hover fieldset": {
                      borderColor: "black !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black !important",
                    },
                  },
                  color: "black",
                }}
              >
                <MenuItem disabled value="">
                  <em>---</em>
                </MenuItem>
                <MenuItem value={0}>Trainee</MenuItem>
                <MenuItem value={1}>Junior</MenuItem>
                <MenuItem value={2}>Senior</MenuItem>
                <MenuItem value={3}>Team Lead</MenuItem>
                <MenuItem value={4}>Project Manager</MenuItem>
              </Select>
            </Stack>
          </Grid>
        </Grid>

        <Grid container sx={{ padding: "20px" }}>
          <Stack direction="column" sx={{ width: "100%" }}>
            <Typography variant="p" gutterBottom style={{ color: "black" }}>
              Notice Details<span style={{ color: "red" }}>*</span>
            </Typography>

            <ReactQuill
              // value={updateDescription}
              // onChange={setUpdateDescription}
              modules={modules}
              theme="snow"
              style={{
                width: "100%",
                boxSizing: "border-box",
                color: "black",
                borderBlockColor: "black",
              }}
            />
          </Stack>
        </Grid>
        <Grid
          container
          sx={{
            padding: "20px",
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            autoFocus
            color="inherit"
            // onClick={UpdateData}
            sx={{
              backgroundColor: "#1d82f5",
              "&:hover": { backgroundColor: "orange" },
              textAlign: "center",

              marginLeft: "25px",
              marginTop: { xs: "70px", sm: "50px", md: "40px" },
              marginBottom: "30px",
            }}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            autoFocus
            color="inherit"
            // onClick={cancelNotice}
            sx={{
              backgroundColor: "#333",
              "&:hover": { backgroundColor: "orange" },
              textAlign: "center",

              marginLeft: "10px",
              marginTop: { xs: "70px", sm: "50px", md: "40px" },
              marginBottom: "30px",
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
      {/* <Box sx={{ background: "#fff" }}> */}
      {/* <Grid
          container
          spacing={2}
          sx={{
            padding: { xs: "20px", sm: "20px" },
            color: "#000",
          }}
        >
          <Grid item xs={8} sm={3.5} md={3.5}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={selectedRadio}
                onChange={(e) => setSelectedRadio(e.target.value)}
              >
                <FormControlLabel
                  value="Client"
                  control={
                    <Radio
                      sx={{
                        color: "black",
                        "&.Mui-checked": { color: "gray" },
                      }}
                    />
                  }
                  label="Client"
                />
                <FormControlLabel
                  value="Employee"
                  control={
                    <Radio
                      sx={{
                        color: "black",
                        "&.Mui-checked": { color: "gray" },
                      }}
                    />
                  }
                  label="Employee"
                />
                <FormControlLabel
                  value="App User"
                  control={
                    <Radio
                      sx={{
                        color: "black",
                        "&.Mui-checked": { color: "gray" },
                      }}
                    />
                  }
                  label="App User"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid> */}

      {/* <Grid
          container
          spacing={2}
          sx={{
            padding: { xs: "20px", sm: "20px" },
          }}
        >
          <Grid item xs={12} sm={5.5} md={5.5}>
            <Stack direction="column" spacing={2}>
              <Typography sx={{ color: "#000" }}>Notice Heading</Typography>
              <TextField
                autoFocus
                margin="dense"
                placeholder="Notice Heading Name"
                type="text"
                fullWidth
                value={noticeHeading}
                onChange={(e) => setNoticeHeading(e.target.value)}
                inputProps={{ style: { color: "black" } }}
                InputLabelProps={{
                  style: { color: "black" },
                }}
                sx={{
                  border: "1px solid black",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-root": {
                    borderColor: "black",
                    "&:hover fieldset": {
                      borderColor: "black",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    "&::placeholder": { color: "gray" },
                  },
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={5.5} md={5.5}>
            <Stack direction="column" spacing={2}>
              <Typography sx={{ color: "#000" }}>Department</Typography>
              <Select
                value={departments}
                autoFocus
                fullWidth
                onChange={(e) => setDepartments(e.target.value)}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                sx={{
                  border: "1px solid black",
                  boxSizing: "border-box",
                  "& .MuiOutlinedInput-root": {
                    borderColor: "black !important",
                    "&:hover fieldset": {
                      borderColor: "black !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black !important",
                    },
                  },
                  color: "black",
                }}
              >
                <MenuItem disabled value="">
                  <em>---</em>
                </MenuItem>
                <MenuItem value={0}>Trainee</MenuItem>
                <MenuItem value={1}>Junior</MenuItem>
                <MenuItem value={2}>Senior</MenuItem>
                <MenuItem value={3}>Team Lead</MenuItem>
                <MenuItem value={4}>Project Manager</MenuItem>
              </Select>
            </Stack>
          </Grid>
        </Grid> */}

      {/* <Grid container sx={{ padding: "20px" }}>
          <Stack direction="column" sx={{ width: "100%" }}>
            <Typography variant="p" gutterBottom style={{ color: "black" }}>
              Notice Details<span style={{ color: "red" }}>*</span>
            </Typography>

            <ReactQuill
              value={description}
              onChange={setDescription}
              modules={modules}
              theme="snow"
              style={{
                width: "100%",
                boxSizing: "border-box",
                color: "black",
                borderBlockColor: "black",
              }}
            />
          </Stack>
        </Grid> */}

      {/* <Button
          variant="contained"
          autoFocus
          color="inherit"
          onClick={SaveData}
          sx={{
            backgroundColor: "blue",
            "&:hover": { backgroundColor: "orange" },
            textAlign: "center",

            marginLeft: "25px",
            marginTop: { xs: "70px", sm: "50px", md: "40px" },
            marginBottom: "30px",
          }}
        >
          Save
        </Button> */}
      {/* <Grid
          container
          sx={{
            padding: {
              xs: "80px 10px 20px 10px",
              sm: "60px 10px 20px 10px",
              md: "50px 10px 20px 10px",
            },
          }}
        >
          <Grid item sx={11} className="messageFooter">
            <Box className="response" onClick={SaveData}>
              Save
            </Box> */}
      {/* <Box className="cancel" onClick={handleCancel}>
              Cancel
            </Box> */}
      {/* </Grid>
        </Grid>
      </Box> */}
    </>
  );
};

export default AddNotice;
