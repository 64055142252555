import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import { financeSettingsApi } from "../../../Data/axios"; 

// Extract empid from localStorage
const getEmpIdFromLocalStorage = () => {
  const userInfo = JSON.parse(localStorage.getItem("user-info"));
  return userInfo?.value?.empid || "";
};

function FinancePaymentSettings() {
  const [open, setOpen] = useState(false);
  const [paymentData, setPaymentData] = useState({
    title: "",
    description: "",
    createdby: getEmpIdFromLocalStorage(),
    updatedby: "",
  });
  const [payments, setPayments] = useState([]);
  const [editingId, setEditingId] = useState(null);

  
  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    try {
      const data = await financeSettingsApi.getAllInvoicePaymenst();
      setPayments(data);
    } catch (error) {
      console.error("Error fetching payment settings:", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPaymentData({ title: "", description: "", createdby: getEmpIdFromLocalStorage(), updatedby: "" });
    setEditingId(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPaymentData({ ...paymentData, [name]: value });
  };

  const handleSubmit = async () => {
    const empid = getEmpIdFromLocalStorage();

    if (editingId) {
      
      await financeSettingsApi.updatepaymentsettings(editingId, {
        ...paymentData,
        updatedby: empid,
      });
    } else {
     
      await financeSettingsApi.addpaymentsettings({
        ...paymentData,
        createdby: empid,
      });
    }

    fetchPayments();
    handleClose();
  };

  const handleEdit = (payment) => {
    setPaymentData({
      title: payment.title,
      description: payment.description,
      createdby: payment.createdby,
      updatedby: getEmpIdFromLocalStorage(),
    });
    setEditingId(payment.id);
    handleClickOpen();
  };

  const handleDelete = async (id) => {
    await financeSettingsApi.deletepaymentsettings(id);
    fetchPayments();
  };

  return (
    <div style={{ backgroundColor: "white", color: "black", minHeight: "100vh", padding: "20px" }}>
     
      <Button
        variant="contained"
        style={{ backgroundColor: "#003366", color: "white", marginBottom: "20px" }}
        startIcon={<Add />}
        onClick={handleClickOpen}
      >
        Add Payment Setting
      </Button>

     
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ backgroundColor: "white", color: "black" }}>
          {editingId ? "Edit Payment Setting" : "Add Payment Setting"}
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "white", color: "black" }}>
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Payment Title"
            type="text"
            fullWidth
            value={paymentData.title}
            onChange={handleInputChange}
            InputProps={{
              style: {
                color: "black",
                border: "1px solid black", 
                borderRadius: "4px", 
              },
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
          <TextField
            margin="dense"
            name="description"
            label="Payment Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={paymentData.description}
            onChange={handleInputChange}
            InputProps={{
              style: {
                color: "black",
                border: "1px solid black", 
                borderRadius: "4px", 
              },
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </DialogContent>
        <DialogActions style={{ backgroundColor: "white" }}>
          <Button onClick={handleClose} style={{ color: "black" }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "#003366", color: "white" }}
            onClick={handleSubmit}
          >
            {editingId ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

     
      <TableContainer component={Paper} style={{ marginTop: 20, backgroundColor: "white", color: "black" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "black", width: "33%" }}>Title</TableCell> 
              <TableCell style={{ color: "black", width: "33%" }}>Description</TableCell> 
              <TableCell style={{ color: "black", width: "33%" }}>Actions</TableCell> 
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment) => (
              <TableRow key={payment.id} style={{ color: "black" }}>
                <TableCell style={{ color: "black" }}>{payment.title}</TableCell>
                <TableCell style={{ color: "black" }}>{payment.description}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(payment)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDelete(payment.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default FinancePaymentSettings;
