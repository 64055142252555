import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import * as XLSX from "xlsx";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Paper,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Dialog,
} from "@mui/material";
import { ImportExport, LogoDevSharp, Person } from "@mui/icons-material";
import {
  HolidayVillage,
  NoTransferOutlined,
  EnergySavingsLeaf,
} from "@mui/icons-material";
import ListIcon from "@mui/icons-material/List";

import PersonIcon from "@mui/icons-material/Person";

import EventIcon from "@mui/icons-material/Event";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";

import HeaderAttendence from "./HeaderAttendance";
import { useLocation } from "react-router-dom";
import config from "../../../Data/AuthUser";
import { clientApis } from "../../../Data/axios";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const stats = [
  { title: "Working Days", value: 31 },
  { title: "Days Present", value: 0 },
  { title: "Leave", value: 0 },
  { title: "Absent", value: 31 },
];

// Helper function to calculate days in a month
const getDaysInMonth = (month, year) => {
  return new Date(year, month + 1, 0).getDate();
};

// Helper function to get day names
const getDayName = (date, month, year) => {
  const dayIndex = new Date(year, month, date).getDay();
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return days[dayIndex];
};

const AttendanceClients = () => {
  const [open, setOpen] = useState(false);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const [monthValue, setMonthValue] = useState(currentMonth);
  const [yearValue, setYearValue] = useState(currentYear);

  const [selectedYear, setSelectedYear] = useState(2024);
  const [employeeData, setEmployeeData] = useState([]);
  const [dates, setDates] = useState([]);
  const [attendanceDates, setAttendanceDates] = useState([]);
  const [activeIcon, setActiveIcon] = useState(null);
  const [showTable, setShowTable] = useState(true);
  const [showDays, setShowDays] = useState(true);
  const [showGrid, setShowGrid] = useState(false);
  const [workingDays, setWorkingDays] = useState(31);
  const [days, setDays] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [filteredData, setFilteredData] = useState(days);

  const [openAttendance, setOpenAttendance] = React.useState(false);

  const handleClickOpenAttendance = () => {
    setOpenAttendance(true);
  };

  const handleCloseAttendance = () => {
    setOpenAttendance(false);
  };

  const finalTime = `${yearValue}-${monthValue + 1}`;
  const handleEmployeeChange = (event) => {
    setSelectedEmployee(event.target.value);
  };
  const [detailValue, setDetailValue] = useState("");
  const location = useLocation();
  const openMenuId = location.state;
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const rowData = await clientApis.clientViewMoreId(openMenuId);
    setDetailValue(rowData);
    getEmployeeData();
  };

  const { client } = detailValue || {};

  const { user_id } = client || {};

  useEffect(() => {
    getEmployeeData();
  }, [monthValue, yearValue, user_id]);

  const getEmployeeData = async () => {
    if (user_id) {
      const result = await clientApis.clientDriverAttendance(
        user_id,
        finalTime
      );

      setEmployeeData(result);
    }
  };

  useEffect(() => {
    if (selectedEmployee) {
      const newData = days.filter((day) => day.employee === selectedEmployee); // Update with actual filter logic
      setFilteredData(newData);
    } else {
      // Reset to default data if no employee is selected
      setFilteredData(days);
    }
  }, [selectedEmployee, days]);

  // Function to calculate the number of days in the selected month and year

  const getDayOfWeek = (day, month, year) => {
    const date = new Date(year, month, day);
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysOfWeek[date.getDay()]; // Get the weekday index and return the name
  };

  useEffect(() => {
    calculateDaysInMonth(monthValue, yearValue);
  }, [monthValue, yearValue]);
  const calculateDaysInMonth = (month, year) => {
    const numDays = new Date(year, month + 1, 0).getDate(); // Get the last day of the month
    const daysArray = Array.from({ length: numDays }, (_, i) => i + 1); // Create an array of day numbers
    setDays(daysArray);
    setWorkingDays(numDays); // Update the working days
  };
  const [selectedDesignation, setSelectedDesignation] = useState("");

  const [selectedDepartment, setSelectedDepartment] = useState("");

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  // Handle designation selection
  const handleDesignationChange = (event) => {
    setSelectedDesignation(event.target.value);
  };

  const UTCToISTConverterDate = (date) => {
    const utcDate = new Date(date);

    // Convert UTC to IST (UTC + 5:30 hours)
    const istDate = new Date(utcDate.getTime() + 5.5 * 60 * 60 * 1000);

    // Format IST date as dd-mm-yyyy hh:mm AM/PM
    const formattedDate = istDate.toLocaleString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return formattedDate;
  };
  const handleListIconClick = () => {
    setActiveIcon("list");
    setShowDays(true);
    setShowGrid(false);
    setShowTable(true);
  };

  const handlePersonIconClick = () => {
    setActiveIcon("person");
    setShowGrid(true);
    setShowTable(false);
  };

  const handleWatchLaterIconClick = () => {
    setActiveIcon("watchLater");
    setShowDays(false);
    setShowGrid(false);
    setShowTable(true);
  };

  const buttonStyle = {
    backgroundColor: "#1d82f5",
    "&:hover": { backgroundColor: "orange" },
    textAlign: "center",
    padding: "10px",
    marginLeft: "10px",
    height: "40px",
    width: "170px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    borderRadius: "4px",
    fontSize: "15px",
    fontFamily: "sans-serif",
    cursor: "pointer",
  };

  const iconButtonStyle = (isActive) => ({
    backgroundColor: isActive ? "orange" : "#333", // Dark blue when active
    color: "#fff",
    // transition: "background-color 0.3s ease, transform 0.3s ease",
    borderRadius: "4px",
    maraginRight: "10px",
    "&:hover": {
      backgroundColor: "orange",
      color: "#000", // Hover effect with blue
      // transform: "scale(1.1)",
    },
  });

  useEffect(() => {
    const daysInMonth = getDaysInMonth(monthValue, yearValue);
    setDates(Array.from({ length: daysInMonth }, (_, i) => i + 1));
  }, [monthValue, yearValue]);

  const handleMonthChange = (event) => {
    setMonthValue(event.target.value);
  };

  const handleYearChange = (event) => {
    setYearValue(event.target.value);
  };

  const [selectedWork, setSelectedWork] = useState("");
  const handleWorkChange = (event) => {
    setSelectedWork(event.target.value);
  };

  const [selectedLocation, setSelectedLocation] = useState("");
  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const [importClicked, setImportClicked] = useState(false);
  const [file, setFile] = useState(null);
  const [hasHeaders, setHasHeaders] = useState(false);

  // Handle file upload
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  useEffect(() => {
    handleListIconClick();
  }, []);
  const handleToggleChange = () => {
    setHasHeaders(!hasHeaders);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleImportClick = () => {
    setImportClicked(true); // Open the dialog when the import button is clicked
  };

  const onClose = () => {
    setImportClicked(false); // Close the dialog when the back button or outside area is clicked
  };
  const driverImage = config.apiImageUrl;
  const handleStatusClick = (attendance, index) => {
    setAttendanceDates(attendance);
    setOpenAttendance(true);
  };
  const handleExportClick = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert the data to a worksheet
    // const ws = XLSX.utils.json_to_sheet(detailValue);

    // Append the worksheet to the workbook
    // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Export the workbook as an Excel file
    XLSX.writeFile(wb, `${"AttendanceSheetDrivers"}.xlsx`);
  };

  return (
    <>
      <HeaderAttendence
        setYearValue={setYearValue}
        setMonthValue={setMonthValue}
      />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ marginTop: "30px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box
                onClick={handleExportClick}
                sx={{
                  backgroundColor: "#fff",
                  textAlign: "center",
                  padding: "7px",
                  marginLeft: " 10px",
                  height: "40px",
                  width: " 82px",
                  display: "flex",
                  color: "#333",
                  justifyContent: "space-around",
                  alignItems: " center",
                  borderRadius: " 4px",
                  fontSize: "15px",
                  fontFamily: "sans-serif",
                  cursor: "pointer",
                  border: "1px solid gray",
                  "&:hover": { backgroundColor: "orange" },
                }}
              >
                <ImportExport /> Export
              </Box>
            </Box>
            {/* Icons to the right of the Export button */}
            <Box
              mr={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Tooltip title="Summary" arrow>
                <IconButton
                  onClick={handleListIconClick}
                  sx={{ ...iconButtonStyle(activeIcon === "list") }}
                >
                  <ListIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Attendance by Member" arrow>
                <IconButton
                  onClick={handlePersonIconClick}
                  sx={{ ...iconButtonStyle(activeIcon === "person") }}
                >
                  <Person />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        {/* </div> */}

        {/* Conditionally render Attendance Table */}
        <Box
          mt={1}
          sx={{
            "&::-webkit-scrollbar": {
              width: "8px", // Set the minimum width of the scrollbar
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "gray", // Scrollbar thumb color
              borderRadius: "10px", // Rounded corners
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1", // Scrollbar track color
            },
            "scrollbar-color": "gray #f1f1f1", // For Firefox
            "scrollbar-width": "thin", // For Firefox, thin scrollbar
          }}
        >
          {showTable && (
            <TableContainer
              component={Paper}
              sx={{ bgcolor: "#fff", color: "#000", width: "100%" }}
            >
              <Table sx={{ borderCollapse: "collapse", width: "100%" }}>
                <TableHead>
                  {/* Icons and Descriptions Row */}
                  <TableRow>
                    <TableCell colSpan={36}>
                      <Box display="flex" alignItems="center" gap={2}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          sx={{
                            fontSize: "1rem",
                            textTransform: "uppercase",
                            letterSpacing: "0.5px",
                            display: "flex",
                            alignItems: "center",
                            color: "#000",
                          }}
                        >
                          Note:
                        </Typography>
                        {/* Legend of symbols */}
                        <Box display="flex" gap={4.2}>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "1rem",
                              display: "flex",
                              alignItems: "center",
                              color: "#000",
                              transition: "color 0.3s",
                              "&:hover": { color: "#4caf50" },
                            }}
                          >
                            <CheckCircleIcon
                              sx={{
                                fontSize: "1.5rem",
                                color: "#4caf50",
                                marginRight: 1,
                              }}
                            />{" "}
                            - Present
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "1rem",
                              display: "flex",
                              color: "#000",
                              alignItems: "center",
                              transition: "color 0.3s",
                              "&:hover": { color: "#f44336" },
                            }}
                          >
                            <CloseIcon
                              sx={{
                                fontSize: "1.5rem",
                                color: "#f44336",
                                marginRight: 1,
                              }}
                            />{" "}
                            - Absent
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "1rem",
                              display: "flex",
                              alignItems: "center",
                              color: "#000",
                              transition: "color 0.3s",
                              "&:hover": { color: "#9c27b0" },
                            }}
                          >
                            <FlightTakeoffIcon
                              sx={{
                                fontSize: "1.5rem",
                                color: "#9c27b0",
                                marginRight: 1,
                              }}
                            />{" "}
                            - On Leave
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "1rem",
                              display: "flex",
                              alignItems: "center",
                              color: "#000",
                              transition: "color 0.3s",
                              "&:hover": { color: "yellow" },
                            }}
                          >
                            <HolidayVillage
                              sx={{
                                fontSize: "1.5rem",
                                color: "yellow",
                                marginRight: 1,
                              }}
                            />{" "}
                            - Relieved
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "1rem",
                              display: "flex",
                              alignItems: "center",
                              color: "#000",
                              transition: "color 0.3s",
                              "&:hover": { color: "#333" },
                            }}
                          >
                            <EnergySavingsLeaf
                              sx={{
                                fontSize: "1.5rem",
                                color: "#333",
                                marginRight: 1,
                              }}
                            />{" "}
                            - Leave Request
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "1rem",
                              display: "flex",
                              alignItems: "center",
                              color: "#000",
                              transition: "color 0.3s",
                              "&:hover": { color: "orange" },
                            }}
                          >
                            <NoTransferOutlined
                              sx={{
                                fontSize: "1.5rem",
                                color: "orange",
                                marginRight: 1,
                              }}
                            />{" "}
                            - Leave Rejected
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>

                  {/* Dates Row */}
                  <TableRow sx={{ backgroundColor: "#f0f5ff" }}>
                    <TableCell>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "0.75rem",
                          fontWeight: "bold",
                          color: "#000",
                        }}
                      >
                        Employee
                      </Typography>
                    </TableCell>
                    {dates.map((date) => (
                      <TableCell
                        key={date}
                        align="center"
                        sx={{
                          fontSize: "0.75rem",
                          padding: "0px",
                          fontWeight: "bold",
                          color: "#000",
                        }}
                      >
                        {date}
                      </TableCell>
                    ))}
                    <TableCell>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "0.75rem",
                          fontWeight: "bold",
                          color: "#000",
                        }}
                      >
                        Total
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Day Names Row */}
                  {showDays && (
                    <TableRow sx={{ backgroundColor: "#fff" }}>
                      <TableCell />
                      {dates.map((date) => (
                        <TableCell
                          key={date}
                          align="center"
                          sx={{
                            fontSize: "0.75rem",
                            padding: "0px",
                            color: "gray",
                            fontWeight: "bold",
                          }}
                        >
                          {getDayName(date, monthValue, yearValue)}{" "}
                        </TableCell>
                      ))}
                      <TableCell />
                    </TableRow>
                  )}
                </TableHead>

                <TableBody>
                  {employeeData.map((employee) => {
                    const imageUrl = `${config.apiImageUrl}${employee?.image}`;

                    const sortedAttendance = employee?.attendance?.sort(
                      (a, b) => new Date(a.date) - new Date(b.date)
                    );

                    return (
                      <TableRow
                        key={employee.driver_id}
                        sx={{ "&:hover": { backgroundColor: "#e0f7fa" } }}
                      >
                        {/* Employee Information */}
                        <TableCell sx={{ fontSize: "0.75rem", padding: "7px" }}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="flex-start"
                          >
                            <Box display="flex" justifyContent="flex-start">
                              <img
                                src={imageUrl}
                                alt={`${employee.username}`}
                                style={{
                                  borderRadius: "50%",
                                  marginRight: "4px",
                                  width: "30px",
                                  height: "30px",
                                  border: "2px solid #1976d2",
                                }}
                              />
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "0.75rem",
                                  fontWeight: 600,
                                  color: "#000",
                                  alignSelf: "center",
                                }}
                              >
                                {employee.username}
                              </Typography>
                            </Box>
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "0.65rem", color: "gray" }}
                            >
                              {employee.driver_id}
                            </Typography>
                          </Box>
                        </TableCell>

                        {/* Attendance Data for each date */}
                        {sortedAttendance.map((attendance) => {
                          return (
                            <TableCell
                              key={attendance.date}
                              align="center"
                              sx={{ fontSize: "0.75rem", padding: "4px" }}
                            >
                              <>
                                {attendance.status === "1" && (
                                  <CheckCircleIcon
                                    sx={{
                                      fontSize: "1rem",
                                      color: "#4caf50",
                                      marginRight: 1,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleStatusClick(attendance);
                                    }}
                                  />
                                )}
                                {attendance.status === "1603" && (
                                  <CloseIcon
                                    sx={{
                                      fontSize: "1rem",
                                      color: "#f44336",
                                      marginRight: 1,
                                    }}
                                  />
                                )}
                                {attendance.status === "1610" && (
                                  <EnergySavingsLeaf
                                    sx={{
                                      fontSize: "1rem",
                                      color: "#333",
                                      marginRight: 1,
                                    }}
                                  />
                                )}
                                {attendance.status === "1612" && (
                                  <FlightTakeoffIcon
                                    sx={{
                                      fontSize: "1rem",
                                      color: "#9c27b0",
                                      marginRight: 1,
                                    }}
                                  />
                                )}
                              </>
                            </TableCell>
                          );
                        })}

                        {/* Total Attendance */}
                        <TableCell
                          align="center"
                          sx={{
                            fontSize: "0.75rem",
                            padding: "4px",
                            color: "gray",
                          }}
                        >
                          <Typography variant="body2">
                            0/{employee?.attendance.length}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        <Dialog
          open={openAttendance}
          onClose={handleCloseAttendance}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{ bgcolor: "#fff", color: "#000" }}
          >
            <strong>{"Driver Attendance Profile"}</strong>
          </DialogTitle>
          <DialogContent sx={{ bgcolor: "#fff", color: "#000" }}>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ bgcolor: "#fff", color: "#000" }}
            >
              {[attendanceDates].map((data, index) => {
                return (
                  <div key={index}>
                    {/* Use a unique key for each item */}
                    <div>
                      <strong>Location:</strong> {data.address}
                    </div>
                    <div>
                      <strong>Time:</strong> {UTCToISTConverterDate(data.date)}
                    </div>
                    <div>
                      <strong>Picture:</strong>{" "}
                      <img
                        key={data.date}
                        src={`${driverImage}${data.pic}`}
                        alt="driver"
                      />
                    </div>
                    <div>
                      <strong>Latitute:</strong> {data.lat}
                    </div>
                    <div>
                      <strong>Logitute:</strong> {data.lng}
                    </div>
                  </div>
                );
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ bgcolor: "#fff", color: "#000" }}>
            <Button onClick={handleCloseAttendance}>Cancel</Button>
            <Button onClick={handleCloseAttendance} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          mt={1}
          sx={{
            overflowY: "auto", // Add scrollbar for vertical overflow
            scrollbarWidth: "thin", // For Firefox (makes the scrollbar thin)
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "gray",
              borderRadius: "10px",
              border: "2px solid #f1f1f1",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "darkgray",
            },
          }}
        >
          {showGrid && (
            <Box sx={{ marginTop: 0 }}>
              <Grid container spacing={2.5}>
                {stats.map((stat) => (
                  <Grid item xs={6} sm={4} md={2.5} key={stat.title}>
                    <Box
                      sx={{
                        textAlign: "center",
                        margin: "5px 0 0 5px",

                        backgroundColor: "#fff",
                        padding: "20px",
                        borderRadius: "4px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        border: "1px solid #e0e0e0",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{
                          fontWeight: "bold",
                          color: "#333",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        {stat.title === "Working Days"
                          ? "Working Days"
                          : stat.title === "Absent"
                          ? "Absent"
                          : stat.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          color: "gray",
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        <CalendarTodayIcon />
                      </Typography>
                      <Typography
                        variant="h6"
                        color="primary"
                        sx={{
                          fontWeight: 600,
                          color: "#1d82f5",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        {stat.title === "Working Days" ||
                        stat.title === "Absent"
                          ? workingDays
                          : stat.value}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              {/* Attendance Table */}
              <Box
                sx={{
                  marginTop: 3,
                  backgroundColor: "white",
                  padding: 3,
                  borderRadius: 2,
                  boxShadow: 1,
                }}
              >
                {/* Filter Section */}
                <Grid container spacing={2} ml={3} mb={2}>
                  {/* Employee Filter Dropdown */}
                  <Grid item xs={12} sm={4}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="#000"
                        sx={{
                          fontSize: "1rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <PersonIcon
                          sx={{ marginRight: 0.5, fontSize: "20px" }}
                        />{" "}
                        Driver:
                      </Typography>
                      <Select
                        variant="outlined"
                        size="small"
                        // value={selectedEmployees}
                        // onChange={handleEmployeesChange}
                        displayEmpty
                        sx={{
                          marginLeft: 0.5,
                          minWidth: "60px",
                          backgroundColor: "#fff",
                          color: "#000",
                        }}
                      >
                        {/* {employees.map((employee) => (
                          <MenuItem key={employee.name} value={employee.name}>
                            {employee.name}
                          </MenuItem>
                        ))} */}
                      </Select>
                    </Box>
                  </Grid>
                </Grid>

                {/* Attendance Table */}
                <table
                  style={{
                    width: "100%",
                    textAlign: "center",
                    borderCollapse: "collapse",
                    fontFamily: "'Roboto', sans-serif",
                  }}
                >
                  <thead>
                    <tr>
                      {[
                        "Date",
                        "Status",
                        "Clock In",
                        "Address",
                        "View",
                        "",
                      ].map((header) => (
                        <th
                          key={header}
                          style={{
                            padding: "12px",
                            borderBottom: "2px solid #1976d2",
                            backgroundColor: "#f5f5f5",
                            color: "#1976d2",
                            fontWeight: "bold",
                          }}
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((day) => (
                      <tr key={day.date}>
                        <td
                          style={{
                            padding: "12px",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "15px",
                              color: "#333",
                            }}
                          >
                            {String(day).padStart(2, "0")}-
                            {String(monthValue + 1).padStart(2, "0")}-
                            {yearValue}
                          </div>
                          <div
                            style={{
                              fontSize: "15px",
                              color: "#888",
                            }}
                          >
                            {getDayOfWeek(day, monthValue, yearValue)}
                          </div>
                        </td>

                        <td
                          style={{
                            padding: "12px",
                            borderBottom: "1px solid #ddd",
                            color: "red",
                            // fontWeight: "bold",
                          }}
                        >
                          Absent
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                          <Button variant="outlined">View</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AttendanceClients;
