import PurchaseSettings from "./PurchaseSettings/PurchaseSettings";
import SettingPageLayout from "./SettingPageLayout";

function MainPurchaseSettings() {
  return (
    <SettingPageLayout>
      <PurchaseSettings />
    </SettingPageLayout>
  );
}

export default MainPurchaseSettings;
