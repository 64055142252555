import React from "react";

import { Box, Typography } from "@mui/material";
import styles from "./ActivityTicket.module.css";
import ActivityCom from "./ActivityCom";
const ActivityTicket = () => {
  const activities = [
    {
      title: "Woodrow Gerhold",
      text: "Ticket created",

      date: "28-05-2024 11:22 am",
      color: "orange",
      iconText: "",
    },
  ];
  return (
    <Box className={styles.right}>
      <Typography variant="h6" sx={{ color: "#000", fontWeight: "700" }}>
        Ticket Activity
      </Typography>
      {activities.map((activity, i) => (
        <ActivityCom
          key={activity.title + i}
          activity={activity}
          color={activity.color}
        />
      ))}
    </Box>
  );
};

export default ActivityTicket;
