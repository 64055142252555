import React from "react";
import FooterTime from "./FooterTime";
import HeaderTime from "./HeaderTime";

const TimeSheetView = () => {
  return (
    <>
      <HeaderTime />
      <FooterTime />
    </>
  );
};

export default TimeSheetView;
