import * as React from 'react';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Button, TextField, Grid, createTheme, ThemeProvider } from '@mui/material';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddJitsi() {
    const [open, setOpen] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <React.Fragment>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', marginLeft: '10px' }}><AddIcon />Add Meeting</Button>
                    {/* <Button variant="contained" disabled>
        Disabled
      </Button>
      <Button variant="contained" href="#contained-buttons">
        Link
      </Button> */}
                </Stack>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    PaperProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                >

                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                Add New Employee
                            </Typography>

                        </Toolbar>

                    </AppBar>
                    <Box>


                        <Grid container spacing={2} style={{ color: 'black', margin: "10px" }}>
                        <Grid item xs={4} sm={1}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Employee ID
                                </Typography>
                               
                                <Grid container spacing={2} alignItems="center">
                               
                                    <Grid item xs={12}>
                                    <br></br>
                                        <TextField
                                            label="Enter ID"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="e.g.ID-1"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>

                            <Grid item xs={4} sm={3}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Name
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="Enter name"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="e.g.Name"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Personal Email
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="enter email"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="eg@example.com"
                                            type="email"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid container spacing={2} style={{ color: 'black', margin: "10px" }}>
                        <Grid item xs={8} sm={2}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Password
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="Enter password"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="e.g.+91 12345689"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={8} sm={2}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Department
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="Enter Department"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="e.g. HR"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'gray',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={8} sm={2}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Designation
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="Enter Designation"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="e.g. HR"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'gray',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'gray',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>

                        </Grid>


                        <Grid container spacing={2} style={{ color: 'black', margin: "10px" }}>
                        <Grid item xs={8} sm={3}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Mobile No
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="enter Mobile No"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="e.g.+91 12345689"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={8} sm={2.5}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Country
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="enter Mobile No"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="e.g.India"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={8} sm={2.5}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Language
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="enter Mobile No"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="e.g.+91 12345689"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={8} sm={3}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    User Role
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="enter Mobile No"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="e.g.+91 12345689"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>

                            <Grid item xs={10} sm={10}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    Address
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="Enter Address"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="e.g.No:2468,Street, Nagar, City"
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <Typography variant="p" gutterBottom style={{ color: 'black' }}>
                                    About
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <br></br>
                                        <TextField
                                            label="About"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Type here..."
                                            inputProps={{ style: { color: 'black' } }}
                                            InputLabelProps={{ style: { color: 'black' } }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'black',
                                                    '&:hover fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'black',
                                                    },
                                                },
                                                '& .MuiOutlinedInput-input': {
                                                    '&::placeholder': { color: 'gray' }
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>


                        </Grid>
                        
                       

                        <Button variant="contained" autoFocus color="inherit" onClick={handleClose} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', margin: '25px' }}>
                            Save
                        </Button>

                    </Box>
                </Dialog>

            </React.Fragment>

        </div>


    );
}

