import * as React from "react";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box, Button, Grid } from "@mui/material";
//import { Refresh, ReplayOutlined } from '@mui/icons-material';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import InfoIcon from "@mui/icons-material/Info";
import TextField from "@mui/material/TextField";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AssignBulkShifts() {
  const [open, setOpen] = React.useState(false);
  const [departmnet, setDepartment] = React.useState("");
  const [employees, setEmployees] = React.useState("");
  const [employeeShift, setEmployeeShift] = React.useState("");
  const [shiftby, setShiftby] = React.useState("");
  const [date, setDate] = React.useState(null);
  const [employeesError, setEmployeesError] = React.useState(false);
  const [remark, setRemark] = useState("");
  const [fileName, setFileName] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name); // Update file name
    console.log(file.name); // Print file name to console
  };

  // Event handler to update the date state and log the date to the console
  const handleDateChange = (newDate) => {
    setDate(newDate); // Update the date state
    console.log(newDate); // Log the new date to the console
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const list = {
      departmnet,
      employees,
      employeeShift,
      date,
      shiftby,
      remark,
      fileName,
    };
    console.log(list);
    if (employees === "") {
      setEmployeesError(true);
    } else {
      const list = {
        departmnet,
        employees,
        employeeShift,
        date,
        shiftby,
        remark,
        fileName,
      };
      console.log(list);
      setEmployeesError(false); // Reset the error state
      handleClose(); // Close the dialog
    }
  };
  return (
    <div>
      <React.Fragment>
        <Stack direction="row" spacing={2} sx={{ margin: "20px 0 5px 2px" }}>
          <Box
            onClick={handleClickOpen}
            sx={{
              backgroundColor: "#1d82f5",
              "&:hover": { backgroundColor: "orange" },
              textAlign: "center",
              padding: "10px",
              marginLeft: "10px",
              height: "40px",
              width: "170px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              borderRadius: "4px",
              fontSize: "15px",
              fontFamily: "sans-serif",
              cursor: "pointer",
            }}
          >
            <AddIcon />
            Assign Bulk Shifts
          </Box>
        </Stack>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                Assign Bulk Shifts
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            sx={{ padding: "30px", margin: "20px", backgroundColor: "white" }}
          >
            <Typography
              sx={{ ml: 2, flex: 1, color: "black", fontSize: "20px" }}
              component="div"
            >
              Add Shift Roaster
            </Typography>
            <hr sx={{ color: "gray" }} />
            <Grid
              container
              sx={{ padding: "20px", backgroundColor: "#7BC9FF" }}
            >
              <Typography variant="p" gutterBottom style={{ color: "black" }}>
                <InfoIcon></InfoIcon> This existing shift will be overridden
              </Typography>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ marginTop: "20px", padding: "20px" }}
            >
              <Stack direction="row" sx={{ width: "100%" }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sm={6}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Department
                    </Typography>
                    <Select
                      value={departmnet}
                      onChange={(e) => setDepartment(e.target.value)}
                      autoFocus
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={"Company"}>Company</MenuItem>
                      <MenuItem value={"BusinessDevelopment"}>
                        Business Development
                      </MenuItem>
                      <MenuItem value={"HR"}>HR</MenuItem>
                      <MenuItem value={"Operations"}>Operations</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sm={6}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Employee<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      autoFocus
                      value={employees}
                      displayEmpty
                      onChange={(e) => setEmployees(e.target.value)}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={"Andrew"}>Andrew</MenuItem>
                      <MenuItem value={"Bohr"}>Bohr</MenuItem>
                      <MenuItem value={"Ceaser"}>Ceaser</MenuItem>
                      <MenuItem value={"David"}>David</MenuItem>
                      <MenuItem value={"Einsten"}>Einsten</MenuItem>
                      <MenuItem value={"Fahren"}>Fahren</MenuItem>
                    </Select>
                    {employeesError && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        Please select a Employee
                      </Typography>
                    )}
                  </Stack>
                </Grid>
              </Stack>
            </Grid>
            <Grid container spacing={2} sx={{ padding: "20px" }}>
              <Stack direction="row" sx={{ width: "100%" }}>
                <Grid item xs={12} sm={3} md={3}>
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Employee Shift
                    </Typography>
                    <Select
                      value={employeeShift}
                      autoFocus
                      displayEmpty
                      onChange={(e) => setEmployeeShift(e.target.value)}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={"Day Off"}>Day Off</MenuItem>
                      <MenuItem value={"General Shift [11:30:00-20:30:00]"}>
                        General Shift [11:30:00-20:30:00]
                      </MenuItem>
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4} md={4} sx={{ marginLeft: "20px" }}>
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Assign Shift By
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        row
                        onChange={(e) => setShiftby(e.target.value)}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="date"
                          control={
                            <Radio
                              sx={{
                                color: "grey",
                                "&.Mui-checked": { color: "#1d82f5" },
                              }}
                            />
                          }
                          label="Date"
                          style={{ color: "black" }}
                        />
                        <FormControlLabel
                          value="multiple"
                          control={
                            <Radio
                              sx={{
                                color: "grey",
                                "&.Mui-checked": { color: "#1d82f5" },
                              }}
                            />
                          }
                          label="Multiple"
                          style={{ color: "black" }}
                        />
                        <FormControlLabel
                          value="month"
                          control={
                            <Radio
                              sx={{
                                color: "grey",
                                "&.Mui-checked": { color: "#1d82f5" },
                              }}
                            />
                          }
                          label="Month"
                          style={{ color: "black" }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={3.5} md={3.5}>
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      You can select Mulitple Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={date}
                          onChange={handleDateChange}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                        >
                          {/* TextField component now becomes a child of DatePicker */}
                          {(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              // Add any additional TextField props if needed
                            />
                          )}
                        </DatePicker>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>
            <Grid container sx={{ padding: "20px" }}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Send Email"
                  style={{ color: "black" }}
                />
              </FormGroup>
            </Grid>
            <Grid container sx={{ padding: "20px" }}>
              <Stack direction="column" sx={{ width: "100%" }}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Remark
                </Typography>
                <textarea
                  id="remark"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  style={{
                    border: "1px solid black",
                    borderRadius: "4px",
                    padding: "10px",
                    width: "100%",
                    boxSizing: "border-box",
                    minHeight: "100px", // Set your desired minimum height
                  }}
                />
              </Stack>
            </Grid>
            ;
            <Grid container sx={{ padding: "20px" }}>
              <Stack direction="column" sx={{ width: "100%" }}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Add file
                </Typography>
                <label
                  htmlFor="file-upload"
                  style={{
                    cursor: "pointer",
                    color: "black",
                    border: "1px solid black",
                    borderRadius: "4px",
                    padding: "10px",
                    width: "100%",
                    boxSizing: "border-box",
                    minHeight: "100px", // Set your desired minimum height
                    backgroundColor: "lightgray", // Change background color to light gray
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Upload File
                </label>
                <input
                  onChange={handleFileChange} // Call handleFileChange when file changes
                  type="file"
                  id="file-upload"
                  style={{ display: "none" }}
                />
                {fileName && <div style={{ color: "black" }}>{fileName}</div>}
              </Stack>
            </Grid>
            <Button
              variant="contained"
              autoFocus
              color="inherit"
              onClick={handleSave}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                margin: "25px",
              }}
            >
              Save
            </Button>
          </Box>
        </Dialog>
      </React.Fragment>
    </div>
  );
}
