import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import StatusAlert from "../../../StatusAlertList/StatusAlert";
import { companySettingsApi } from '../../../Data/axios'; 

const PaymentSettingTab = () => {
  const [paymentDetails, setPaymentDetails] = useState({
    razorpay_key: "",
    razorpay_secret: "",
  });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleChangeMap = (e) =>
    setPaymentDetails({ ...paymentDetails, [e.target.name]: e.target.value });

  useEffect(() => {
    getPaymentData();
  }, []);

  const getPaymentData = async () => {
    try {
      const data = await companySettingsApi.getAll();
      // Assuming the first item is the one you need
      const settings = data[0];
      setPaymentDetails({
        razorpay_key: settings.razorpay_key || "",
        razorpay_secret: settings.razorpay_secret || "",
      });
    } catch (error) {
      console.error("Data fetch failed", error);
      showAlert("Failed to fetch payment settings!", "error");
    }
  };

  const updatePaymentData = async (e) => {
    e.preventDefault();
    const formData = {
      razorpay_key: paymentDetails.razorpay_key,
      razorpay_secret: paymentDetails.razorpay_secret,
    };

    try {
      const response = await companySettingsApi.PaymentSettingsUpdate(formData);
      if (response) {
        showAlert("Payment Data Updated Successfully!", "success");
      }
    } catch (error) {
      showAlert("Failed to update the Payment Data!", "error");
    }
  };

  return (
    <Container>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />
      <Box
        component="form"
        onSubmit={updatePaymentData}
        sx={{
          mt: 3,
          p: 2,
          borderRadius: 1,
        }}
      >
        <Typography variant="h5" gutterBottom style={{ color: "black" }}>
          Razorpay Keys
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Razorpay key"
              name="razorpay_key"
              onChange={handleChangeMap}
              value={paymentDetails.razorpay_key}
              sx={{
                backgroundColor: "white",
                "& .MuiInputBase-input": {
                  color: "black",
                },
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              InputProps={{
                style: {
                  color: "black",
                  border: "1px solid black",
                  borderRadius: "5px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Razorpay secret key"
              name="razorpay_secret"
              onChange={handleChangeMap}
              value={paymentDetails.razorpay_secret}
              sx={{
                backgroundColor: "white",
                "& .MuiInputBase-input": {
                  color: "black",
                },
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              InputProps={{
                style: {
                  color: "black",
                  border: "1px solid black",
                  borderRadius: "5px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PaymentSettingTab;
