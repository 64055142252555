import * as React from 'react';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Button, TextField, Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Padding } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { pink } from '@mui/material/colors';
import List from '@mui/material/List';




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddFieldTasks() {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState(false);
    const [email, setEmail] = React.useState(false);
    const [designation, setDesignation] = React.useState(false);
    const [department, setDepartment] = React.useState(false);



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSave = () => {
        const list = { name, email, department, designation }
        console.log(list)
    };

    const [Text, setText] = React.useState('');

    const handleChange = (event) => {
        setText(event.target.value);
    };

    return (
        <div>
            <React.Fragment>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', paddingLeft:"20px" }}><AddIcon />Edit Template</Button>
                </Stack>
                <Dialog
                    // fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    PaperProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                >

                    <AppBar sx={{ position: 'relative', backgroundColor: "#ff9800" }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} component="div">
                                Edit Template
                            </Typography>

                        </Toolbar>

                    </AppBar>
                    <div style={{ marginBottom: "20px" }}></div>
                    <Box display="flex" flexDirection="column" style={{ Padding: "20px" }}>
                        <Box sx={{ flexGrow: 1 }}>
                            {/* first column */}
                            <Grid item xs={12} md={3}>
                                <List>

                                <Grid container spacing={1} style={{marginBottom:"10px",marginLeft:"10px"}}>

                                    <Grid item xs={3} style={{ color: "black" }}>
                                        <TextField id="outlined-basic" label="Name" variant="outlined" focused />
                                    </Grid>
                                    <Grid item xs={3} style={{ color: "black" }}>
                                        <FormControl fullWidth focused>
                                            <InputLabel id="demo-simple-select-label">Text</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={Text}
                                                label="Text"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} style={{ color: "black" }}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Mandatory Field" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={3} style={{ color: "black" }}>
                                        <Tooltip title="Delete">
                                            <IconButton>
                                                <DeleteIcon sx={{ color: pink[500] }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                </List>
                                <List>
                                <Grid container spacing={1} style={{marginBottom:"10px",marginLeft:"10px"}}>

                                    <Grid item xs={3} style={{ color: "black" }}>
                                        <TextField id="outlined-basic" label="Name" variant="outlined" focused />
                                    </Grid>
                                    <Grid item xs={3} style={{ color: "black" }}>
                                        <FormControl fullWidth focused>
                                            <InputLabel id="demo-simple-select-label">Text</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={Text}
                                                label="Text"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} style={{ color: "black" }}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Mandatory Field" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={3} style={{ color: "black" }}>
                                        <Tooltip title="Delete">
                                            <IconButton>
                                                <DeleteIcon sx={{ color: pink[500] }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                </List>
                                <List>
                                <Grid container spacing={1} style={{marginBottom:"10px",marginLeft:"10px"}}>

                                    <Grid item xs={3} style={{ color: "black" }}>
                                        <TextField id="outlined-basic" label="Name" variant="outlined" focused />
                                    </Grid>
                                    <Grid item xs={3} style={{ color: "black" }}>
                                        <FormControl fullWidth focused>
                                            <InputLabel id="demo-simple-select-label">Text</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={Text}
                                                label="Text"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} style={{ color: "black" }}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Mandatory Field" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={3} style={{ color: "black" }}>
                                        <Tooltip title="Delete">
                                            <IconButton>
                                                <DeleteIcon sx={{ color: pink[500] }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                </List>
                                <List>
                                <Grid container spacing={1} style={{marginBottom:"10px",marginLeft:"10px"}}>

                                    <Grid item xs={3} style={{ color: "black" }}>
                                        <TextField id="outlined-basic" label="Name" variant="outlined" focused />
                                    </Grid>
                                    <Grid item xs={3} style={{ color: "black" }}>
                                        <FormControl fullWidth focused>
                                            <InputLabel id="demo-simple-select-label">Text</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={Text}
                                                label="Text"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} style={{ color: "black" }}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Mandatory Field" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={3} style={{ color: "black" }}>
                                        <Tooltip title="Delete">
                                            <IconButton>
                                                <DeleteIcon sx={{ color: pink[500] }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                </List>
                            </Grid>
                            <Button variant="contained" autoFocus color="inherit" onClick={handleSave} sx={{ backgroundColor: 'blue', '&:hover': { backgroundColor: 'orange' }, textAlign: 'center', margin: '25px' }}>
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Dialog>

            </React.Fragment>

        </div>


    );
}

