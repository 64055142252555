import TabsTicketSettings from "./TabsTicketSettings";

const TicketSettings = () => {
  return (
    <div>
      <TabsTicketSettings />
    </div>
  );
};

export default TicketSettings;
