import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  { day: "Tue", value: 1002 },
  { day: "Wed", value: 1202 },
  { day: "Thru", value: 902 },
  { day: "Fri", value: 1102 },
  { day: "Sat", value: 502 },
  { day: "Sun", value: 1100 },
];

const chartSetting = {
  width: 200,
  height: 150,
};

export default function BasicArea() {
  return (
    <LineChart
      width={chartSetting.width}
      height={chartSetting.height}
      data={data}
      margin={{ top: 5, right: 10, left: 0, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="day" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="value"
        stroke="rgba(0, 123, 255, 1)"
        fill="rgba(0, 123, 255, 0.3)"
        dot={{ fill: "rgba(0, 123, 255, 1)" }}
        activeDot={{ r: 8 }}
      />
    </LineChart>
  );
}
