import {
  Box,
  Grid,
  TextField,
  createTheme,
  ThemeProvider,
  Stack,
  Select,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import Typography from "@mui/material/Typography";

import Slide from "@mui/material/Slide";

import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";

import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import StatusAlert from "../../../StatusAlertList/StatusAlert";
import { DesignationApis } from "../../../Data/axios";
import HeaderDesignation from "./HeaderDesignation";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function Designation() {
  const [open, setOpen] = React.useState(false);
  const [designationList, setDesignationList] = useState([]);
  const [name, setName] = React.useState("");

  const [parent, setParent] = React.useState("");

  const [updateName, setUpdateName] = React.useState("");
  const [updateParentDesignation, setUpdateParentDesignation] =
    React.useState("");

  const [data, setData] = useState([]);
  const [designationData, setDesignationData] = useState([]);

  const [update, setUpdate] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
    setUpdateName("");
    setUpdateParentDesignation("");
  };
  const Trainee = "Trainee";
  const Junior = "Junior";
  const Senior = "Senior";
  const TeamLead = "Team Lead";
  const ProjectManager = "Project Manager";
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName("");
    setParent("");
  };

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const results = await DesignationApis.getAll();
    setDesignationData(results);
  }

  const columns = [
    {
      name: "designation",
      label: "Designation",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Designation
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "parent",
      label: "Parent Designation",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Parent Designation
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "70px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Actions
            </th>
          );
        },
        customBodyRender: (value) => (
          <div
            key={value}
            style={{
              display: "flex",
              justifyContent: "flex-end", // Aligns content to the left
              alignItems: "center",
              paddingLeft: "20px", // Add padding for starting point
            }}
          >
            <Stack
              spacing={2}
              direction="row"
              sx={{
                justifyContent: "flex-end",
                "& .MuiButton-root": {
                  margin: "0 4px",
                },
              }}
            >
              <Button variant="outlined" onClick={() => handleEdit(value)}>
                <EditNoteIcon /> Update
              </Button>
              <Button variant="outlined" onClick={() => handleDelete(value)}>
                <DeleteForeverIcon /> Delete
              </Button>
            </Stack>
          </div>
        ),
      },
    },
  ];
  const fetchDataDesignation = async () => {
    try {
      const response = await fetch(
        "https://rptpl.roadpilot.co.in/public/api/getDesignation"
      );
      const data = await response.json();

      setDesignationList(data);
    } catch (err) {
      throw new Error("Failed to fetch data. Please try again later.");
    }
  };
  useEffect(() => {
    fetchDataDesignation();
  }, []);

  async function handleEdit(id) {
    const result = designationData.filter((item) => item.id === id);

    setData(result[0]);

    handleClickUpdate();
  }
  useEffect(() => {
    setUpdateName(data.designation);
  }, [data.designation]);

  useEffect(() => {
    setUpdateParentDesignation(data.parent);
  }, [data.parent]);

  async function updateDesignation() {
    const creator_name = localStorage.getItem("empid");
    const formData = new FormData();

    formData.append("designation", updateName);
    formData.append("parent", updateParentDesignation);
    formData.append("creator", creator_name);
    try {
      await DesignationApis.update(data.id, formData);

      handleUpdateClose();

      getData();
      setName("");
      setParent("");
      showAlert("  Updated successfully", "success");
    } catch (error) {
      console.error(error);
      showAlert("Failed to update ", "error");
    }
  }

  async function handleDelete(id) {
    await DesignationApis.deletebyId(id);

    getData();
    showAlert(" Deleted Successfully!", "warning");
  }
  const userInfoString = localStorage.getItem("user-info");

  // Parse the string into an object
  const userInfo = JSON.parse(userInfoString);

  // Access the empid
  const empid = userInfo?.value?.empid;
  async function AddDepartment() {
    const formData = new FormData();

    formData.append("designation", name);
    formData.append("parent", parent);

    formData.append("creator", empid);

    await DesignationApis.add(formData);

    showAlert(" Added successfully", "success");

    getData();
    handleClose();
  }

  const options = {
    selectableRows: "none",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <HeaderDesignation />
      <React.Fragment>
        <Dialog
          fullWidth
          maxWidth="md"
          open={update}
          onClose={handleUpdateClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <Box sx={{ background: "#fff", color: "#000" }}>
            <DialogTitle>
              <span
                style={{
                  background: "blue",
                  color: "#fff",
                  padding: "15px",
                  borderRadius: "5px",
                }}
              >
                Update Designation
              </span>
            </DialogTitle>
            <Grid
              container
              spacing={2}
              sx={{
                padding: { xs: "10px", sm: "20px" },
              }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <Stack direction="column" spacing={2}>
                  <Typography>Name</Typography>
                  <TextField
                    autoFocus
                    margin="dense"
                    placeholder="Designation Name"
                    type="text"
                    fullWidth
                    value={updateName}
                    onChange={(e) => setUpdateName(e.target.value)}
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Stack direction="column" spacing={2}>
                  <Typography>Parent</Typography>
                  <Select
                    value={updateParentDesignation}
                    autoFocus
                    fullWidth
                    onChange={(e) => setUpdateParentDesignation(e.target.value)}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    sx={{
                      border: "1px solid black",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>---</em>
                    </MenuItem>
                    <MenuItem value={Trainee}>Trainee</MenuItem>
                    <MenuItem value={Junior}>Junior</MenuItem>
                    <MenuItem value={Senior}>Senior</MenuItem>
                    <MenuItem value={TeamLead}>Team Lead</MenuItem>
                    <MenuItem value={ProjectManager}>Project Manager</MenuItem>
                  </Select>
                </Stack>
              </Grid>
              <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                <DialogActions>
                  <Button onClick={handleUpdateClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={updateDesignation}
                    color="primary"
                    sx={{
                      background: "blue",
                      color: "#fff",
                      "&:hover": {
                        background: "#000",
                      },
                    }}
                  >
                    Add
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      </React.Fragment>
      <List style={{ background: "none" }}>
        <StatusAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          severity={alertSeverity}
        />
        <ListItemText style={{ background: "none" }}>
          <React.Fragment>
            <Stack
              direction="row"
              spacing={2}
              sx={{ marginTop: "10px", marginLeft: "2px" }}
            >
              <Box
                onClick={handleClickOpen}
                sx={{
                  backgroundColor: "#1d82f5",
                  "&:hover": { backgroundColor: "orange" },
                  textAlign: "center",
                  padding: "10px",
                  marginLeft: "10px",
                  height: "40px",
                  width: "155px",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  borderRadius: "4px",
                  fontSize: "15px",
                  fontFamily: "sans-serif",
                  cursor: "pointer",
                }}
              >
                <AddIcon />
                Add Designation
              </Box>
            </Stack>
            <Dialog
              fullWidth
              maxWidth="md"
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
              PaperProps={{
                style: {
                  position: "absolute",
                  background: "#141b2d",
                  top: "0",
                  backgroundColor: "white",
                  maxHeight: "95%",
                  margin: "auto",
                  overflowX: "hidden",
                  zIndex: 1200,
                },
              }}
            >
              <Box sx={{ background: "#fff", color: "#000" }}>
                <DialogTitle>
                  <span
                    style={{
                      background: "blue",
                      color: "#fff",
                      padding: "15px",
                      borderRadius: "5px",
                    }}
                  >
                    Add New Designation
                  </span>
                </DialogTitle>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    padding: { xs: "10px", sm: "20px" },
                  }}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <Stack direction="column" spacing={2}>
                      <Typography>Name</Typography>
                      <TextField
                        autoFocus
                        margin="dense"
                        placeholder="Designation Name"
                        type="text"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{
                          style: { color: "black" },
                        }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Stack direction="column" spacing={2}>
                      <Typography>Parent</Typography>
                      <Select
                        value={parent}
                        autoFocus
                        fullWidth
                        onChange={(e) => setParent(e.target.value)}
                        displayEmpty
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        sx={{
                          border: "1px solid black",
                          boxSizing: "border-box",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black !important",
                            "&:hover fieldset": {
                              borderColor: "black !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black !important",
                            },
                          },
                          color: "black",
                        }}
                      >
                        <MenuItem disabled value="">
                          <em>---</em>
                        </MenuItem>
                        <MenuItem value={Trainee}>Trainee</MenuItem>
                        <MenuItem value={Junior}>Junior</MenuItem>
                        <MenuItem value={Senior}>Senior</MenuItem>
                        <MenuItem value={TeamLead}>Team Lead</MenuItem>
                        <MenuItem value={ProjectManager}>
                          Project Manager
                        </MenuItem>
                      </Select>
                    </Stack>
                  </Grid>
                  <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Cancel
                      </Button>
                      <Button
                        onClick={AddDepartment}
                        color="primary"
                        sx={{
                          background: "blue",
                          color: "#fff",
                          "&:hover": {
                            background: "#000",
                          },
                        }}
                      >
                        Add
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </Box>
            </Dialog>
          </React.Fragment>
        </ListItemText>
        <Divider />
        <ListItemText style={{ background: "white", margin: 0, width: "100%" }}>
          <ThemeProvider theme={getTheme()}>
            <MUIDataTable
              title={
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    color: "#333",
                  }}
                >
                  Designations
                </span>
              }
              data={designationData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default Designation;
