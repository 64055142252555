import React from "react";
import TabsAttendanceSettings from "./TabsAttendanceSettings";

function AttendanceSettings({ isDown }) {
  return (
    <div>
      <TabsAttendanceSettings />
    </div>
  );
}

export default AttendanceSettings;
