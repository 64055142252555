import React, { useEffect, useState } from "react";
import StatusAlert from "../../../StatusAlertList/StatusAlert";
import { companySettingsApi } from "../../../Data/axios"; 

function AppSettings() {
  const [appDetails, setAppDetails] = useState({
    site_twitter: "",
    site_instagram: "",
    site_linkedin: "",
    site_youtube: "",
    site_pinterest: "",
    site_facebook: "",
    site_whatsapp: "",
    helpline: "",
    row_limit: "",
    employee_export: "0",
  });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleChange = (e) => setAppDetails({ ...appDetails, [e.target.name]: e.target.value });

  useEffect(() => {
    getCompanyData();
  }, []);

  const getCompanyData = async () => {
    try {
      const response = await companySettingsApi.getAll();
      const data = response[0]; 

      setAppDetails({
        site_facebook: data.site_facebook || "",
        site_twitter: data.site_twitter || "",
        site_instagram: data.site_instagram || "",
        site_linkedin: data.site_linkedin || "",
        site_youtube: data.site_youtube || "",
        site_pinterest: data.site_pinterest || "",
        site_whatsapp: data.site_whatsapp || "",
        helpline: data.helpline || "",
        row_limit: data.row_limit || "",
        employee_export: data.employee_export === "1" ? "1" : "0",
      });
    } catch (error) {
      console.error("Failed to fetch company data", error);
    }
  };

  const updateCompanyData = async (e) => {
    e.preventDefault();
    console.log("Updating...", appDetails);

    try {
      await companySettingsApi.AppSettingsUpdate(appDetails);
      showAlert("Company Data Updated Successfully!", "success");
    } catch (error) {
      showAlert("Failed to update the Company Data!", "error");
    }
  };

  return (
    <div className="companysettings_container">
      <StatusAlert open={alertOpen} setOpen={setAlertOpen} message={alertMessage} severity={alertSeverity} />
      <form onSubmit={updateCompanyData} style={{ width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <label htmlFor="site_facebook" style={{ color: "black" }}>Facebook Id</label>
            <input
              type="text"
              id="site_facebook"
              name="site_facebook"
              placeholder="Facebook link"
              value={appDetails.site_facebook}
              onChange={handleChange}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
                color: 'black', 
                '::placeholder': { color: 'gray' }, 
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="site_instagram" style={{ color: "black" }}>Instagram Id</label>
            <input
              type="text"
              id="site_instagram"
              name="site_instagram"
              placeholder="Instagram link"
              value={appDetails.site_instagram}
              onChange={handleChange}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
                color: 'black', 
                '::placeholder': { color: 'gray' }, 
              }}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <label htmlFor="site_youtube" style={{ color: "black" }}>YouTube Id</label>
            <input
              type="text"
              id="site_youtube"
              name="site_youtube"
              placeholder="YouTube link"
              value={appDetails.site_youtube}
              onChange={handleChange}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
                color: 'black', 
                '::placeholder': { color: 'gray' }, 
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="site_whatsapp" style={{ color: "black" }}>WhatsApp Id</label>
            <input
              type="text"
              id="site_whatsapp"
              name="site_whatsapp"
              placeholder="WhatsApp link"
              value={appDetails.site_whatsapp}
              onChange={handleChange}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
                color: 'black', 
                '::placeholder': { color: 'gray' }, 
              }}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <label htmlFor="site_twitter" style={{ color: "black" }}>Twitter Id</label>
            <input
              type="text"
              id="site_twitter"
              name="site_twitter"
              placeholder="Twitter link"
              value={appDetails.site_twitter}
              onChange={handleChange}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
                color: 'black', 
                '::placeholder': { color: 'gray' }, 
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="site_linkedin" style={{ color: "black" }}>LinkedIn Id</label>
            <input
              type="text"
              id="site_linkedin"
              name="site_linkedin"
              placeholder="LinkedIn link"
              value={appDetails.site_linkedin}
              onChange={handleChange}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
                color: 'black', 
                '::placeholder': { color: 'gray' }, 
              }}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <label htmlFor="site_pinterest" style={{ color: "black" }}>Pinterest Id</label>
            <input
              type="text"
              id="site_pinterest"
              name="site_pinterest"
              placeholder="Pinterest link"
              value={appDetails.site_pinterest}
              onChange={handleChange}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
                color: 'black', 
                '::placeholder': { color: 'gray' }, 
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="helpline" style={{ color: "black" }}>Helpline No.</label>
            <input
              type="text"
              id="helpline"
              name="helpline"
              placeholder="Helpline number"
              value={appDetails.helpline}
              onChange={handleChange}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
                color: 'black', 
                '::placeholder': { color: 'gray' }, 
              }}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <label htmlFor="row_limit" style={{ color: "black" }}>Row Limit</label>
            <input
              type="text"
              id="row_limit"
              name="row_limit"
              placeholder="Row limit"
              value={appDetails.row_limit}
              onChange={handleChange}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
                color: 'black', 
                '::placeholder': { color: 'gray' }, 
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="employee_export" style={{ color: "black" }}>Employee Export</label>
            <select
              id="employee_export"
              name="employee_export"
              value={appDetails.employee_export}
              onChange={handleChange}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxSizing: "border-box",
                color: 'black', 
                '::placeholder': { color: 'gray' }, 
              }}
            >
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </div>
        </div>
        <button
          type="submit"
          className="companyButton"
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "#3f51b5", // Button color
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default AppSettings;
