// import * as React from "react";
// import Box from "@mui/joy/Box";
// import CircularProgress from "@mui/joy/CircularProgress";
import { Typography } from "@mui/material";

// export default function NotFound() {
//   return (
//     <Box
//       sx={{
//         marginTop: "5rem",
//         display: "flex",
//         gap: 2,
//         alignItems: "center",
//         flexWrap: "wrap",
//         justifyContent: "center",
//       }}
//     >
//       <Typography
//         sx={{
//           fontSize: "2rem",
//           color: "hsl(48  98%  49%)",
//           textAlign: "center",
//         }}
//       >
//         Road Pilot...
//       </Typography>
//       <br />
//       <br />
//       <br />
//       <div>
//         <CircularProgress size="md" />
//       </div>
//     </Box>
//   );
// }
import * as React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      {/* <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      /> */}
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: "50%",
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

export default function NotFound() {
  return (
    <Stack spacing={2} sx={{ flexGrow: 1, marginTop: "5rem" }}>
      <Typography
        sx={{
          fontSize: "2rem",
          color: "hsl(48  98%  49%)",
          textAlign: "center",
        }}
      >
        Page Not Found!!
      </Typography>
      <br></br>
      <br></br>

      <FacebookCircularProgress />
    </Stack>
  );
}
