import React from "react";

import { Box, Typography } from "@mui/material";
import styles from "./ActivityTicket.module.css";
import ContactCom from "./ContactCom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const ContactActivity = () => {
  const activities = [
    {
      title: "Amet velit in dicta.",
      text: "Closed",
      number: "11",
      icon: <FiberManualRecordIcon sx={{ color: "red" }} />,
      date: "30-05-2024 12:00 am",
      color: "orange",
      iconText: "",
    },
    {
      title: "Amet velit in dicta.",
      text: "Closed",
      number: "11",
      icon: <FiberManualRecordIcon sx={{ color: "red" }} />,
      date: "30-05-2024 12:00 am",
      color: "orange",
      iconText: "",
    },
    {
      title: "Amet velit in dicta.",
      text: "Closed",
      number: "11",
      icon: <FiberManualRecordIcon sx={{ color: "red" }} />,
      date: "30-05-2024 12:00 am",
      color: "orange",
      iconText: "",
    },
    {
      title: "Amet velit in dicta.",
      text: "Closed",
      number: "11",
      icon: <FiberManualRecordIcon sx={{ color: "red" }} />,
      date: "30-05-2024 12:00 am",
      color: "orange",
      iconText: "",
    },
    {
      title: "Amet velit in dicta.",
      text: "Closed",
      number: "11",
      icon: <FiberManualRecordIcon sx={{ color: "red" }} />,
      date: "30-05-2024 12:00 am",
      color: "orange",
      iconText: "",
    },
    {
      title: "Amet velit in dicta.",
      text: "Closed",
      number: "11",
      icon: <FiberManualRecordIcon sx={{ color: "red" }} />,
      date: "30-05-2024 12:00 am",
      color: "orange",
      iconText: "",
    },
  ];
  return (
    <Box className={styles.right}>
      <Typography
        variant="h6"
        sx={{ color: "#000", fontWeight: "700", paddingTop: "20px" }}
      >
        Recent Tickets
      </Typography>
      {activities.map((activity, i) => (
        <ContactCom
          key={activity.title + i}
          activity={activity}
          color={activity.color}
        />
      ))}
    </Box>
  );
};

export default ContactActivity;
