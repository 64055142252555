import React, { useEffect, useState } from "react";
import { Typography, TextField, Grid, Link, Box } from "@mui/material";
import Button from "@mui/material/Button";
import StatusAlert from "../../../StatusAlertList/StatusAlert";
function GoogleSettingsTab() {
  /*Google map Api  */
  const [mapDetails, setMapDetails] = useState({
    site_map: "",
  });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const handleChangeMap = (e) =>
    setMapDetails({ ...mapDetails, [e.target.name]: e.target.value });

  useEffect(() => {
    getMapData();
  }, []);

  const getMapData = () => {
    const url = "";
    const options = {
      method: "POST",
    };

    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        setMapDetails({ ...mapDetails, ...data });
      })
      .catch((error) => {
        console.error("data fetched failed", error);
      });
  };
  const updateMapData = (e) => {
    e.preventDefault();

    const url = "";

    const data = mapDetails;

    const options = {
      method: "POST", // HTTP method
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        showAlert("Google Data Updated Successfully!", "success");
      })
      .catch((error) => {
        showAlert("Failed to update the Google Data!", "error");
      }); // Handle errors
  };
  /*Google calender Api  */
  const [calenderDetails, setCalenderDetails] = useState({
    site_companyid: "",

    site_companysecret: "",
  });

  const handleChangeCalender = (e) =>
    setCalenderDetails({
      ...calenderDetails,
      [e.target.name]: e.target.value,
    });

  useEffect(() => {
    getCalenderData();
  }, []);

  const getCalenderData = () => {
    const url = "";
    const options = {
      method: "POST",
    };

    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        setCalenderDetails({ ...calenderDetails, ...data });
      })
      .catch((error) => {
        console.error("data fetched failed", error);
      });
  };
  const updateCalenderData = (e) => {
    e.preventDefault();

    const url = "";

    const data = calenderDetails;

    const options = {
      method: "POST", // HTTP method
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        showAlert("Google Data Updated Successfully!", "success");
      })
      .catch((error) => {
        showAlert("Failed to update the Google Data!", "error");
      }); // Handle errors
  };
  return (
    <Box
      sx={{
        overflowY: "auto", // Enable vertical scrolling
      }}
    >
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />

      <Box
        component="form"
        onSubmit={updateMapData}
        sx={{
          mt: 3,

          p: 2,
          borderRadius: 1,

          // width: "780px",
          mb: 3, // Adding margin bottom for spacing between forms
        }}
      >
        <Typography variant="h5" gutterBottom style={{ color: "black" }}>
          Google map api
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Map api"
              name="site_map"
              onChange={handleChangeMap}
              value={mapDetails.site_map}
              sx={{
                backgroundColor: "white",
                "& .MuiInputBase-input": {
                  color: "black",
                },
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              InputProps={{
                style: {
                  color: "black",
                  border: "1px solid black",
                  borderRadius: "5px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ color: "black" }}>
              Visit{" "}
              <Link
                href="https://console.cloud.google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Cloud Console
              </Link>{" "}
              to get the keys
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Save Map Api
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box
        component="form"
        onSubmit={updateCalenderData}
        sx={{
          mt: 3,

          p: 2,
          borderRadius: 1,

          // width: "780px",
          mb: 3, // Adding margin bottom for spacing between forms
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          style={{ color: "black", marginTop: "20px" }}
        >
          Google Calander api
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              name="site_companyid"
              placeholder="Company ID"
              onChange={handleChangeCalender}
              value={calenderDetails.site_companyid}
              sx={{
                backgroundColor: "white",
                "& .MuiInputBase-input": {
                  color: "black",
                },
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              InputProps={{
                style: {
                  color: "black",
                  border: "1px solid black",
                  borderRadius: "5px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              name="site_companysecret"
              placeholder="Company Secret"
              onChange={handleChangeCalender}
              value={calenderDetails.site_companysecret}
              sx={{
                backgroundColor: "white",
                "& .MuiInputBase-input": {
                  color: "black",
                },
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              InputProps={{
                style: {
                  color: "black",
                  border: "1px solid black",
                  borderRadius: "5px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Save Calander Api
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default GoogleSettingsTab;
