import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import HelpIcon from '@mui/icons-material/Help';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GroupsIcon from '@mui/icons-material/Groups';
import { Placeholder } from 'react-bootstrap';


function AdmUsers() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
  return (
    <Box display="flex" flexDirection="column">
      <Box sx={{ flexGrow: 1 }}>
        {/* First row */}
        <Grid container spacing={2} direction="row" style={{ marginBottom: '10px' }}>
          <Grid item xs={12} sm={4}>
          <Item elevation={3} style={{  backgroundColor: '#fff' }}>
            <Typography gutterBottom variant="subtitle1" style={{ textAlign: 'left', fontSize: '20px', color: '#4d4f5c' }}>
              Total Drivers
            </Typography>
            <Typography variant="body2" gutterBottom style={{ textAlign: 'right', color: '#4d4f5c' }}>
              <GroupIcon />
            </Typography>
            <Typography variant="body2" style={{ textAlign: 'left', fontSize: '16px' }} color="blue">
              275
            </Typography>
          </Item>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Item elevation={3} style={{ backgroundColor: '#fff' }}>
            <Typography gutterBottom variant="subtitle1" style={{ textAlign: 'left', fontSize: '20px', color: '#4d4f5c' }}>
              Total Fleet owners
            </Typography>
            <Typography variant="body2" gutterBottom style={{ textAlign: 'right', color: '#4d4f5c' }}>
              <GroupIcon />
            </Typography>
            <Typography variant="body2" style={{ textAlign: 'left', fontSize: '16px' }} color="blue">
              42
            </Typography>
          </Item>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Item elevation={3} style={{ backgroundColor: '#fff' }}>
            <Typography gutterBottom variant="subtitle1" style={{ textAlign: 'left', fontSize: '20px', color: '#4d4f5c' }}>
              Total Dhaba
            </Typography>
            <Typography variant="body2" gutterBottom style={{ textAlign: 'right', color: '#4d4f5c' }}>
              <LocalActivityIcon />
            </Typography>
            <Typography variant="body2" style={{ textAlign: 'left', fontSize: '16px' }} color="blue">
              140
            </Typography>
          </Item>
          </Grid>
        </Grid>
        {/* <div style={{ marginBottom: '10px' }}></div> */}

        {/* Second row */}
        <Grid container spacing={2} direction="row" style={{ marginBottom: '10px' }}>
          <Grid item xs={12} sm={4}>
          <Item elevation={3} style={{ backgroundColor: '#fff' }}>
            <Typography gutterBottom variant="subtitle1" style={{ textAlign: 'left', fontSize: '20px', color: '#4d4f5c' }}>
              Total Mechanic
            </Typography>
            <Typography variant="body2" gutterBottom style={{ textAlign: 'right', color: '#4d4f5c' }}>
              <LocalActivityIcon />
            </Typography>
            <Typography variant="body2" style={{ textAlign: 'left', fontSize: '16px' }} color="blue">
              140
            </Typography>
          </Item>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Item elevation={3} style={{ backgroundColor: '#fff' }}>
            <Typography gutterBottom variant="subtitle1" style={{ textAlign: 'left', fontSize: '20px', color: '#4d4f5c' }}>
              Total Crane owners
            </Typography>
            <Typography variant="body2" gutterBottom style={{ textAlign: 'right', color: '#4d4f5c' }}>
              <LocalActivityIcon />
            </Typography>
            <Typography variant="body2" style={{ textAlign: 'left', fontSize: '16px' }} color="blue">
              9
            </Typography>
          </Item>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Item elevation={3} style={{ backgroundColor: '#fff' }}>
            <Typography gutterBottom variant="subtitle1" style={{ textAlign: 'left', fontSize: '20px', color: '#4d4f5c' }}>
              Total Crane Operator
            </Typography>
            <Typography variant="body2" gutterBottom style={{ textAlign: 'right', color: '#4d4f5c' }}>
              <LocalActivityIcon />
            </Typography>
            <Typography variant="body2" style={{ textAlign: 'left', fontSize: '16px' }} color="blue">
              275
            </Typography>
          </Item>
          </Grid>
        </Grid>
        {/* <div style={{ marginBottom: '10px' }}></div> */}
        

        <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
          <Grid item xs={12} sm={6}>
          <Item elevation={2} style={{ height: '320px', backgroundColor: '#fff' }}>
            <Typography gutterBottom variant="subtitle1" style={{ textAlign: 'left', fontSize: '20px', color: '#161e29' }}>
              User Wise Earnings
              <HelpIcon />
            </Typography>
            <br />
            <Typography variant="body2" gutterBottom style={{ textAlign: 'center', color: '#4d4f5c' }}>
              <SignalCellularAltIcon />
            </Typography>
            <Typography variant="body2" style={{ textAlign: 'center', fontSize: '16px', color: '#4d4f5c' }}>
              <Placeholder>-Not Enough Data-</Placeholder>
            </Typography>
          </Item>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Item elevation={2} style={{ height: '320px', backgroundColor: '#fff' }}>
            <Typography gutterBottom variant="subtitle1" style={{ textAlign: 'left', fontSize: '20px', color: '#161e29' }}>
              User Wise Timelog
            </Typography>
            <br />
            <Typography variant="body2" gutterBottom style={{ textAlign: 'center', color: '#4d4f5c' }}>
              <SignalCellularAltIcon />
            </Typography>
            <Typography variant="body2" style={{ textAlign: 'center', fontSize: '16px', color: '#4d4f5c' }}>
              <Placeholder>-Not Enough Data-</Placeholder>
            </Typography>
          </Item>
          </Grid>
        </Grid>


        <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
          <Grid item xs={12} sm={6}>
          <Item elevation={2} style={{ height: '320px', backgroundColor: '#fff' }}>
            <Typography gutterBottom variant="subtitle1" style={{ textAlign: 'left', fontSize: '20px', color: '#161e29' }}>
              Sale Count By Stages and Pipeline
              <HelpIcon />
            </Typography>
            <br />
            <Typography variant="body2" gutterBottom style={{ textAlign: 'center', color: '#4d4f5c' }}>
              <CalendarTodayIcon />
            </Typography>
            <Typography variant="body2" style={{ textAlign: 'center', fontSize: '16px', color: '#4d4f5c' }}>
              <Placeholder>-Not Enough Data-</Placeholder>
            </Typography>
          </Item>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Item elevation={2} style={{ height: '320px', backgroundColor: '#fff' }}>
            <Typography gutterBottom variant="subtitle1" style={{ textAlign: 'left', fontSize: '20px', color: '#161e29' }}>
              Leads Count By Source
            </Typography>
            <br />
            <Typography variant="body2" gutterBottom style={{ textAlign: 'center', color: '#4d4f5c' }}>
              <LocalActivityIcon />
            </Typography>
            <Typography variant="body2" style={{ textAlign: 'center', fontSize: '16px', color: '#4d4f5c' }}>
              <Placeholder>-Not Enough Data-</Placeholder>
            </Typography>
          </Item>
          </Grid>
        </Grid>




        <Grid container spacing={1} direction="row">
          <Grid item xs={12} sm={6}>
          <Item elevation={2} style={{ height: '320px', backgroundColor: '#fff' }}>
            <Typography gutterBottom variant="subtitle1" style={{ textAlign: 'left', fontSize: '20px', color: '#161e29' }}>
              Latest User
            </Typography>
            <br />
            <Typography variant="body2" gutterBottom style={{ textAlign: 'center', color: '#4d4f5c' }}>
              <ChecklistIcon />
            </Typography>
            <Typography variant="body2" style={{ textAlign: 'center', fontSize: '16px', color: '#4d4f5c' }}>
              <Placeholder>-Not Enough Data-</Placeholder>
            </Typography>
          </Item>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Item elevation={2} style={{ height: '320px', backgroundColor: '#fff' }}>
            <Typography gutterBottom variant="subtitle1" style={{ textAlign: 'left', fontSize: '20px', color: '#161e29' }}>
              Recent Login Activities
            </Typography>
            <br />
            <Typography variant="body2" gutterBottom style={{ textAlign: 'center', color: '#4d4f5c' }}>
              <GroupsIcon />
            </Typography>
            <Typography variant="body2" style={{ textAlign: 'center', fontSize: '16px', color: '#4d4f5c' }}>
              <Placeholder>-Not Enough Data-</Placeholder>
            </Typography>
          </Item>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default AdmUsers;
