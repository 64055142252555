import AppSettings from "./AppSettings/AppSettings";
import SettingPageLayout from "./SettingPageLayout";

function MainSettings({ isDown }) {
  return (
    <SettingPageLayout>
      <AppSettings isDown={isDown} />
    </SettingPageLayout>
  );
}

export default MainSettings;
