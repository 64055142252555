import {
  Box,
  Grid,
  TextField,
  createTheme,
  ThemeProvider,
  Menu,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import BusinessDialog from "./BusinessDialog";
import { getKeysAndValues } from "../../../utils/getKeysAndValues";
import StatusAlert from "../../../StatusAlertList/StatusAlert";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { BusinessSettingsApi } from "../../../Data/axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BusinessSettings() {
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [update, setUpdate] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({
    keys: [],
    values: [],
    open: false,
  });

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };
  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleViewMore = () => {
    const rowData = businesslocation.filter((row) => row.id === openMenuId);

    const { keys, values } = getKeysAndValues(rowData[0]);

    setSelectedRow({ keys, values, open: true });
    // setOpenDialog(true);
  };
  const handleDailogClosing = () => {
    setSelectedRow({ ...selectedRow, open: false });
  };

  const [type, setType] = useState("");

  const [status, setStatus] = useState("");

  const handleGenderChange = (event) => {
    setType(event.target.value);
  };

  const OfficeType = ["Rent", "Contact", "Own", "Others"];

  // Function Starts Here

  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [manager, setManager] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [officecode, setOfficecode] = useState("");
  const [details, setDetails] = useState("");

  const [updatetype, setUpdateType] = useState("");
  const [updatelocation, setUpdateLocation] = useState("");
  const [updateaddress, setUpdateAddress] = useState("");
  const [updatecountry, setUpdateCountry] = useState("");
  const [updatemanager, setUpdateManager] = useState("");
  const [updatephone, setUpdatePhone] = useState("");
  const [updateemail, setUpdateEmail] = useState("");
  const [updateofficecode, setUpdateOfficecode] = useState("");
  const [updatedetails, setUpdateDetails] = useState("");

  const [data, SetData] = useState([]);

  const StatusOptions = ["Active", "Inactive"];
  const [openMenuId, setOpenMenuId] = useState(null);

  const item = {
    location,
    address,
    country,
    manager,
    phone,
    email,
    officecode,
    details,
    type,
  };

  async function SaveDate() {
    const formData = new FormData();

    // Append each item to the FormData object
    for (const key in item) {
      if (item[key] !== undefined && item[key] !== null) {
        formData.append(key, item[key]);
      }
    }
    const response = await BusinessSettingsApi.add(formData);
    if (response === "1602") {
      showAlert("Business location Added successfully", "success");
      getData();
      setOpen(false);
    }
 
  }

 

  const [businesslocation, setBusinesslocation] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
 
    const response = BusinessSettingsApi.getAll();
   
    setBusinesslocation(response);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      name: "officecode",
      label: "Office Code",
      options: {
        customBodyRender: (value) =>
          (value || " ").charAt(0).toUpperCase() + (value || " ").slice(1),
      },
    },
    {
      name: "location",
      label: "Location",
      options: {
        customBodyRender: (value) =>
          (value || " ").charAt(0).toUpperCase() + (value || " ").slice(1),
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        customBodyRender: (value) =>
          (value || " ").charAt(0).toUpperCase() + (value || " ").slice(1),
      },
    },
    {
      name: "manager",
      label: "Manager",
      options: {
        customBodyRender: (value) =>
          (value || " ").charAt(0).toUpperCase() + (value || " ").slice(1),
      },
    },
    {
      name: "phone",
      label: "Phone",
      options: {
        customBodyRender: (value) =>
          (value || " ").charAt(0).toUpperCase() + (value || " ").slice(1),
      },
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => (
          <>
            <div>
              <Button
                id="basic-button"
                aria-controls={openDropMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDropMenu ? "true" : undefined}
                onClick={handleClick}
              >
                <div onClick={() => handleOpenMenu(value)}>
                  <MoreHorizTwoToneIcon />
                </div>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDropMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleCloseMenu}>
                  <div onClick={() => handleViewMore(tableMeta.rowData)}>
                    <RemoveRedEyeIcon /> View More
                  </div>
                </MenuItem>
                <MenuItem onClick={handleCloseMenu}>
                  <div onClick={() => handleEdit(value)}>
                    <EditNoteIcon /> Update
                  </div>
                </MenuItem>
                <MenuItem onClick={handleCloseMenu}>
                  <div onClick={() => handleDelete(value)}>
                    <DeleteForeverIcon /> Delete
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </>
        ),
      },
    },
  ];

  async function handleEdit(id) {
    const result = await BusinessSettingsApi.edit(id);
    SetData(result);
    handleClickUpdate();
  }



  useEffect(() => {
    setUpdateOfficecode(data.officecode);
  }, [data.officecode]);

  useEffect(() => {
    setUpdateCountry(data.country);
  }, [data.country]);

  useEffect(() => {
    setUpdateLocation(data.location);
  }, [data.location]);

  useEffect(() => {
    setUpdateManager(data.manager);
  }, [data.manager]);

  useEffect(() => {
    setUpdatePhone(data.phone);
  }, [data.phone]);

  useEffect(() => {
    setUpdateEmail(data.email);
  }, [data.email]);

  useEffect(() => {
    setUpdateAddress(data.address);
  }, [data.address]);

  useEffect(() => {
    setUpdateType(data.type);
  }, [data.type]);

  useEffect(() => {
    setUpdateDetails(data.details);
  }, [data.details]);

  useEffect(() => {
    setStatus(data.status);
  }, [data.status]);

  // Update Values Ends
  // Editing Starts here

  async function UpdateData() {
    const formData = new FormData();

    formData.append("officecode", updateofficecode);
    formData.append("country", updatecountry);
    formData.append("location", updatelocation);
    formData.append("address", updateaddress);
    formData.append("manager", updatemanager);
    formData.append("phone", updatephone);
    formData.append("email", updateemail);
    formData.append("type", updatetype);
    formData.append("details", updatedetails);
    formData.append("status", status);

    try {
      const response = await BusinessSettingsApi.update(data.id, formData);
      // let response = await fetch(
      //   `https://rptpl.roadpilot.co.in/public/api/updateBusinessLocation/${data.id}`,
      //   {
      //     method: "POST",
      //     body: formData,
      //   }
      // );

      // if (!response.ok) {
      //   throw new Error("Failed to update data");
      // }
      if (response === "1602") {
        showAlert("Business location updated successfully", "success");

        handleUpdateClose();
        getData();
      }
      // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      showAlert("Failed to update business location", "error");
    }
  }

  // Editing Ends Here

  // Delete

  async function handleDelete(id) {
    const response = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/businesslocationdelete/" + id,
      {
        method: "DELETE",
      }
    );
    const result = await response.json(); // Assigning to a new variable

    getData();
    showAlert("Business location Deleted successfully", "warning");
  }

  // end delete

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          // maxWidth: "1000px",
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  // Table Ends Here
  const theme = useTheme();
  const isBelow500px = useMediaQuery(theme.breakpoints.down("500"));
  return (
    <>
      <BusinessDialog
        open={selectedRow.open}
        handleClose={handleDailogClosing}
        rowData={selectedRow}
      />
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />

      <Box>
        <Dialog
          fullWidth
          maxWidth="md"
          open={update}
          onClose={handleUpdateClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleUpdateClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                Update Business Location
              </Typography>
            </Toolbar>
          </AppBar>

          <Box>
            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Office Code
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="RPTPL001"
                      value={updateofficecode}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdateOfficecode(newValue);
                      }}
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Country
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="India"
                        value={updatecountry}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setUpdateCountry(newValue);
                        }}
                        inputProps={{
                          style: {
                            color: "black",
                            border: "1px solid black",
                            borderRadius: "5px",
                          },
                        }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8} sm={4}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Location
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="Lucknow"
                      type="email"
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      value={updatelocation}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdateLocation(newValue);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Manager Name
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="Name"
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      value={updatemanager}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdateManager(newValue);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Manager Number
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="+91 123456789"
                      type="email"
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      value={updatephone}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdatePhone(newValue);
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Manager email
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="manager@gmail.com"
                      type="email"
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      value={updateemail}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdateEmail(newValue);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={8}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Address
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="e.g.Address"
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      value={updateaddress}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdateAddress(newValue);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={3} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Status
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      select
                      value={status === 1 ? "Active" : "Inactive"} // Set the value based on status
                      fullWidth
                      style={{ border: "1px solid black", borderRadius: "5px" }}
                      placeholder="Status"
                      onChange={(e) => {
                        const value = e.target.value;
                        const statusValue = value === "Active" ? 1 : 0;
                        setStatus(statusValue);
                      }}
                      inputProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        "& .MuiSelect-root": {
                          color: "black",
                        },
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                          color: "black",
                        },
                      }}
                    >
                      {StatusOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={4}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Office type
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      select
                      onChange={handleGenderChange}
                      fullWidth
                      style={{ border: "1px solid black", borderRadius: "5px" }}
                      placeholder="Own"
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{ style: { color: "black" } }}
                      value={updatetype}
                      sx={{
                        "& .MuiSelect-root": {
                          color: "black", // Set text color to black for the selected option
                        },
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black", // Set border color to black
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" }, // Set placeholder color to gray
                          color: "black", // Set text color to black
                        },
                      }}
                    >
                      {OfficeType.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Details
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="Rent,Contact date"
                      type="email"
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      value={updatedetails}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdateDetails(newValue);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              autoFocus
              color="inherit"
              onClick={UpdateData}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                margin: "25px",
              }}
            >
              Update
            </Button>
          </Box>
        </Dialog>
      </Box>

      <List
        style={{
          background: "none",
          padding: 5,
        }}
      >
        <ListItemText
          style={{ background: "none", margin: "5px 0", zIndex: 13000 }}
        >
          <React.Fragment>
            <StatusAlert
              open={alertOpen}
              setOpen={setAlertOpen}
              message={alertMessage}
              severity={alertSeverity}
            />
            <Button
              variant="contained"
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "blue",

                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
              }}
            >
              <AddCircleIcon /> Add Business Locations
            </Button>
            <Dialog
              fullWidth
              maxWidth="md"
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
              PaperProps={{
                style: {
                  position: "absolute",
                  background: "#141b2d",
                  top: "0",
                  backgroundColor: "white",
                  maxHeight: "95%",
                  margin: "auto",
                  overflowX: "hidden",
                  zIndex: 1200,
                },
              }}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} component="div">
                    Add Business Location
                  </Typography>
                </Toolbar>
              </AppBar>
              <Box>
                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", margin: "10px", zIndex: 13000 }}
                >
                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Office Code
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          placeholder="RPTPL001"
                          onChange={(e) => setOfficecode(e.target.value)}
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Country
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="India"
                          onChange={(e) => setCountry(e.target.value)}
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={8} sm={4}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Location
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={8}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="Lucknow"
                          type="email"
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          onChange={(e) => setLocation(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", margin: "10px" }}
                >
                  <Grid item xs={8} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Manager Name
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={8}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="Name"
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          onChange={(e) => setManager(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={4} sm={3}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Manager Number
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={8}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="+91 123456789"
                          type="email"
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          onChange={(e) => setPhone(e.target.value)}
                          InputLabelProps={{ style: { color: "black" } }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Manager email
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="manager@gmail.com"
                          type="email"
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          onChange={(e) => setEmail(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", margin: "10px" }}
                >
                  <Grid item xs={10} sm={10}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Address
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="e.g.Address"
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          onChange={(e) => setAddress(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", margin: "10px" }}
                >
                  <Grid item xs={8} sm={4}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Office type
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          required
                          select
                          value={type}
                          onChange={handleGenderChange}
                          fullWidth
                          style={{
                            color: "black",
                            border: "1px solid black",
                            borderRadius: "5px",
                          }}
                          placeholder="Own"
                          inputProps={{
                            style: {
                              color: "black",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          sx={{
                            "& .MuiSelect-root": {
                              color: "black", // Set text color to black for the selected option
                            },
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black", // Set border color to black
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" }, // Set placeholder color to gray
                              color: "black", // Set text color to black
                            },
                          }}
                        >
                          {OfficeType.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Details
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          placeholder="Rent,Contact date"
                          type="email"
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          onChange={(e) => setDetails(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Button
                  variant="contained"
                  autoFocus
                  color="inherit"
                  onClick={SaveDate}
                  sx={{
                    backgroundColor: "blue",
                    "&:hover": { backgroundColor: "orange" },
                    textAlign: "center",
                    margin: "25px",
                  }}
                >
                  Save
                </Button>
              </Box>
            </Dialog>
          </React.Fragment>
        </ListItemText>
        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <Box
              sx={{
                overflowX: isBelow500px ? "auto" : "unset",
                maxWidth: "100%", // Ensure it doesn't exceed the parent container
              }}
            >
              <MUIDataTable
                title={"Business Location"}
                // data={businesslocation}
                columns={columns}
                options={options}
              />
            </Box>
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default BusinessSettings;
