import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Slide,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DetailsTicket = () => {
  const [openTicket, setOpenTicket] = useState(false);
  const [openAgent, setOpenAgent] = useState(false);
  const [openType, setOpenType] = useState(false);
  const [openChannel, setOpenChannel] = useState(false);
  const [groupName, setGroupName] = useState([]);
  const [agentName, setAgentName] = useState([]);
  const [ticketType, setTicketType] = useState([]);
  const [assignGroup, setAssignGroup] = useState([]);
  const [assignAgent, setAssignAgent] = useState([]);
  const [priorityType, setPriorityType] = useState([]);
  const [channelName, setChannelName] = useState([]);
  const [channelType, setChannelType] = useState([]);
  const [typeName, setTypeName] = useState([]);
  const priorityTypeItem = ["Low", "Medium", "High", "Urgent"];
  const handleDialogCloseTicket = () => {
    setOpenTicket(false);
  };
  const handleDialogCloseAgent = () => {
    setOpenAgent(false);
  };
  const handleDialogCloseType = () => {
    setOpenType(false);
  };
  const handleDialogCloseChannel = () => {
    setOpenChannel(false);
  };
  const AddTicket = () => {};
  const AddAgent = () => {};
  const AddType = () => {};
  const AddChannel = () => {};
  const handleAddAgent = () => {
    setOpenAgent(true);
  };
  const handleAddTicket = () => {
    setOpenTicket(true);
  };
  const handleAddType = () => {
    setOpenType(true);
  };
  const handleAddChannel = () => {
    setOpenChannel(true);
  };
  return (
    <Box sx={{ background: "#fff" }}>
      <Grid container spacing={1} sx={{ color: "black" }}>
        <Grid item xs={11} sm={11} md={11}>
          <Typography variant="p" gutterBottom style={{ color: "black" }}>
            Assign Group
          </Typography>

          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                <Select
                  labelId="designation-select-label"
                  id="designation-select"
                  value={() => {}}
                  onChange={
                    (e) => {}
                    // setSelectedDesignation(e.target.value)
                  }
                  displayEmpty
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleAddTicket}
                        edge="end"
                        sx={{
                          color: "#000",
                          backgroundColor: "#fff",
                          "&:hover": {
                            backgroundColor: "#fff",
                            color: "#000",
                          },
                        }}
                      >
                        Add
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  {/* {designations.map((item) => (
                            <MenuItem key={item.id} value={item.designation}>
                              {item.designation}
                            </MenuItem>
                          ))} */}
                </Select>

                {/* Dialog for adding new department */}
                <Dialog
                  fullWidth
                  maxWidth="md"
                  open={openTicket}
                  onClose={handleDialogCloseTicket}
                  TransitionComponent={Transition}
                  PaperProps={{
                    style: {
                      position: "absolute",
                      background: "#141b2d",
                      top: "0",
                      backgroundColor: "white",
                      maxHeight: "95%",
                      margin: "auto",
                      overflowX: "hidden",
                      zIndex: 1200,
                    },
                  }}
                >
                  <Box sx={{ background: "#fff", color: "#000" }}>
                    <DialogTitle>
                      <span
                        style={{
                          background: "blue",
                          color: "#fff",
                          padding: "15px",
                          borderRadius: "5px",
                        }}
                      >
                        Add New Designation
                      </span>
                    </DialogTitle>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        marginTop: "10px",
                        padding: { xs: "10px", sm: "20px" },
                      }}
                    >
                      <Grid item xs={12} sm={6} md={5.5}>
                        <Stack direction="column" spacing={2}>
                          <Typography>Group Name </Typography>
                          <TextField
                            autoFocus
                            margin="dense"
                            placeholder="Designation Name"
                            type="text"
                            fullWidth
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            inputProps={{ style: { color: "black" } }}
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            sx={{
                              border: "1px solid black",
                              borderRadius: "5px",
                              "& .MuiOutlinedInput-root": {
                                borderColor: "black",
                                "&:hover fieldset": {
                                  borderColor: "black",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black",
                                },
                              },
                              "& .MuiOutlinedInput-input": {
                                "&::placeholder": { color: "gray" },
                              },
                            }}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                        <DialogActions>
                          <Button
                            onClick={handleDialogCloseTicket}
                            color="primary"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={AddTicket}
                            color="primary"
                            sx={{
                              background: "blue",
                              color: "#fff",
                              "&:hover": {
                                background: "#000",
                              },
                            }}
                          >
                            Add
                          </Button>
                        </DialogActions>
                      </Grid>
                    </Grid>
                  </Box>
                </Dialog>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11} sm={11} md={11}>
          <Typography variant="p" gutterBottom style={{ color: "black" }}>
            Agent
          </Typography>

          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                <Select
                  labelId="department-select-label"
                  id="department-select"
                  value={agentName}
                  onChange={(e) => setAgentName(e.target.value)}
                  displayEmpty
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleAddAgent}
                        edge="end"
                        sx={{
                          color: "#000",
                          backgroundColor: "#fff",
                          "&:hover": {
                            backgroundColor: "#fff",
                            color: "#000",
                          },
                        }}
                      >
                        Add
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  {/* {departments.map((department) => (
                  <MenuItem key={department.id} value={department.department}>
                    {department.department}
                  </MenuItem>
                ))} */}
                </Select>

                {/* Dialog for adding new department */}
                <Dialog
                  fullWidth
                  maxWidth="md"
                  open={openAgent}
                  onClose={handleDialogCloseAgent}
                  TransitionComponent={Transition}
                  PaperProps={{
                    style: {
                      position: "absolute",
                      background: "#141b2d",
                      top: "0",
                      backgroundColor: "white",
                      maxHeight: "95%",
                      margin: "auto",
                      overflowX: "hidden",
                      zIndex: 1200,
                    },
                  }}
                >
                  <Box sx={{ background: "#fff", color: "#000" }}>
                    <DialogTitle>
                      <span
                        style={{
                          background: "blue",
                          color: "#fff",
                          padding: "15px",
                          borderRadius: "5px",
                        }}
                      >
                        Add New Ticket Agent
                      </span>
                    </DialogTitle>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        padding: { xs: "10px", sm: "20px" },
                      }}
                    >
                      <Grid item xs={12} sm={6} md={6}>
                        <Stack direction="column" spacing={2}>
                          <Typography>Assign Group </Typography>
                          <Select
                            value={assignGroup}
                            autoFocus
                            fullWidth
                            onChange={(e) => setAssignGroup(e.target.value)}
                            displayEmpty
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            sx={{
                              border: "1px solid black",
                              boxSizing: "border-box",
                              "& .MuiOutlinedInput-root": {
                                borderColor: "black !important",
                                "&:hover fieldset": {
                                  borderColor: "black !important",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black !important",
                                },
                              },
                              color: "black",
                            }}
                          >
                            <MenuItem disabled value="">
                              <em>---</em>
                            </MenuItem>
                            {/* {departments.map((department) => (
                            <MenuItem
                              key={department.id}
                              value={department.department}
                            >
                              {department.department}
                            </MenuItem>
                          ))} */}
                          </Select>
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <Stack direction="column" spacing={2}>
                          <Typography>Agent </Typography>
                          <Select
                            value={assignAgent}
                            autoFocus
                            fullWidth
                            onChange={(e) => setAssignAgent(e.target.value)}
                            displayEmpty
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            sx={{
                              border: "1px solid black",
                              boxSizing: "border-box",
                              "& .MuiOutlinedInput-root": {
                                borderColor: "black !important",
                                "&:hover fieldset": {
                                  borderColor: "black !important",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black !important",
                                },
                              },
                              color: "black",
                            }}
                          >
                            <MenuItem disabled value="">
                              <em>---</em>
                            </MenuItem>
                            {/* {departments.map((department) => (
                            <MenuItem
                              key={department.id}
                              value={department.department}
                            >
                              {department.department}
                            </MenuItem>
                          ))} */}
                          </Select>
                        </Stack>
                      </Grid>

                      <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                        <DialogActions>
                          <Button
                            onClick={handleDialogCloseAgent}
                            color="primary"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={AddAgent}
                            color="primary"
                            sx={{
                              background: "blue",
                              color: "#fff",
                              "&:hover": {
                                background: "#000",
                              },
                            }}
                          >
                            Add
                          </Button>
                        </DialogActions>
                      </Grid>
                    </Grid>
                  </Box>
                </Dialog>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11} sm={11} md={11}>
          <Typography variant="p" gutterBottom style={{ color: "black" }}>
            Priority
          </Typography>

          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                <Select
                  labelId="department-select-label"
                  id="department-select"
                  value={priorityType}
                  onChange={(e) => setPriorityType(e.target.value)}
                  displayEmpty
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  {priorityTypeItem.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11} sm={11} md={11}>
          <Typography variant="p" gutterBottom style={{ color: "black" }}>
            Type
          </Typography>

          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                <Select
                  labelId="designation-select-label"
                  id="designation-select"
                  value={ticketType}
                  onChange={(e) => setTicketType(e.target.value)}
                  displayEmpty
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleAddType}
                        edge="end"
                        sx={{
                          color: "#000",
                          backgroundColor: "#fff",
                          "&:hover": {
                            backgroundColor: "#fff",
                            color: "#000",
                          },
                        }}
                      >
                        Add
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  {/* {designations.map((item) => (
                            <MenuItem key={item.id} value={item.designation}>
                              {item.designation}
                            </MenuItem>
                          ))} */}
                </Select>

                {/* Dialog for adding new department */}
                <Dialog
                  fullWidth
                  maxWidth="md"
                  open={openType}
                  onClose={handleDialogCloseType}
                  TransitionComponent={Transition}
                  PaperProps={{
                    style: {
                      position: "absolute",
                      background: "#141b2d",
                      top: "0",
                      backgroundColor: "white",
                      maxHeight: "95%",
                      margin: "auto",
                      overflowX: "hidden",
                      zIndex: 1200,
                    },
                  }}
                >
                  <Box sx={{ background: "#fff", color: "#000" }}>
                    <DialogTitle>
                      <span
                        style={{
                          background: "blue",
                          color: "#fff",
                          padding: "15px",
                          borderRadius: "5px",
                        }}
                      >
                        Add New Ticket Type
                      </span>
                    </DialogTitle>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        marginTop: "10px",
                        padding: { xs: "10px", sm: "20px" },
                      }}
                    >
                      <Grid item xs={11} sm={11} md={11}>
                        <Stack direction="column" spacing={2}>
                          <Typography>Ticket Type </Typography>
                          <TextField
                            autoFocus
                            margin="dense"
                            placeholder="Designation Name"
                            type="text"
                            fullWidth
                            value={typeName}
                            onChange={(e) => setTypeName(e.target.value)}
                            inputProps={{ style: { color: "black" } }}
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            sx={{
                              border: "1px solid black",
                              borderRadius: "5px",
                              "& .MuiOutlinedInput-root": {
                                borderColor: "black",
                                "&:hover fieldset": {
                                  borderColor: "black",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black",
                                },
                              },
                              "& .MuiOutlinedInput-input": {
                                "&::placeholder": { color: "gray" },
                              },
                            }}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                        <DialogActions>
                          <Button
                            onClick={handleDialogCloseType}
                            color="primary"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={AddType}
                            color="primary"
                            sx={{
                              background: "blue",
                              color: "#fff",
                              "&:hover": {
                                background: "#000",
                              },
                            }}
                          >
                            Add
                          </Button>
                        </DialogActions>
                      </Grid>
                    </Grid>
                  </Box>
                </Dialog>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11} sm={11} md={11}>
          <Typography variant="p" gutterBottom style={{ color: "black" }}>
            Channel Name
          </Typography>

          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                <Select
                  labelId="department-select-label"
                  id="department-select"
                  value={channelType}
                  onChange={(e) => setChannelType(e.target.value)}
                  displayEmpty
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleAddChannel}
                        edge="end"
                        sx={{
                          color: "#000",
                          backgroundColor: "#fff",
                          "&:hover": {
                            backgroundColor: "#fff",
                            color: "#000",
                          },
                        }}
                      >
                        Add
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  {/* {departments.map((department) => (
                            <MenuItem
                              key={department.id}
                              value={department.department}
                            >
                              {department.department}
                            </MenuItem>
                          ))}  */}
                </Select>

                <Dialog
                  fullWidth
                  maxWidth="md"
                  open={openChannel}
                  onClose={handleDialogCloseChannel}
                  TransitionComponent={Transition}
                  PaperProps={{
                    style: {
                      position: "absolute",
                      background: "#141b2d",
                      top: "0",
                      backgroundColor: "white",
                      maxHeight: "95%",
                      margin: "auto",
                      overflowX: "hidden",
                      zIndex: 1200,
                    },
                  }}
                >
                  <Box sx={{ background: "#fff", color: "#000" }}>
                    <DialogTitle>
                      <span
                        style={{
                          background: "blue",
                          color: "#fff",
                          padding: "15px",
                          borderRadius: "5px",
                        }}
                      >
                        Add Ticket Channel
                      </span>
                    </DialogTitle>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        padding: { xs: "10px", sm: "20px" },
                      }}
                    >
                      <Grid item xs={11} sm={11} md={11}>
                        <Stack direction="column" spacing={2}>
                          <Typography>Ticket Channel </Typography>
                          <Select
                            value={channelName}
                            autoFocus
                            fullWidth
                            onChange={(e) => setChannelName(e.target.value)}
                            displayEmpty
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            sx={{
                              border: "1px solid black",
                              boxSizing: "border-box",
                              "& .MuiOutlinedInput-root": {
                                borderColor: "black !important",
                                "&:hover fieldset": {
                                  borderColor: "black !important",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black !important",
                                },
                              },
                              color: "black",
                            }}
                          >
                            <MenuItem disabled value="">
                              <em>---</em>
                            </MenuItem>
                            {/* {departments.map((department) => (
                                      <MenuItem
                                        key={department.id}
                                        value={department.department}
                                      >
                                        {department.department}
                                      </MenuItem>
                                    ))} */}
                          </Select>
                        </Stack>
                      </Grid>
                      <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                        <DialogActions>
                          <Button
                            onClick={handleDialogCloseChannel}
                            color="primary"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={AddChannel}
                            color="primary"
                            sx={{
                              background: "blue",
                              color: "#fff",
                              "&:hover": {
                                background: "#000",
                              },
                            }}
                          >
                            Add
                          </Button>
                        </DialogActions>
                      </Grid>
                    </Grid>
                  </Box>
                </Dialog>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: "20px", marginLeft: "10px" }}>
          <Button variant="contained">Update</Button>
        </Box>
      </Grid>
    </Box>
  );
};

export default DetailsTicket;
