import * as React from "react";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box, Button, TextField, Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import "quill-emoji/dist/quill-emoji.css"; // Import Quill emoji CSS

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddContract({ update, setUpdate }) {
  const [open, setOpen] = React.useState(false);
  const [contractNo, setContractNo] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [project, setProject] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [contractType, setContractType] = React.useState("");
  const [contractValue, setContractValue] = React.useState("");
  const [currency, setCurrency] = React.useState("");
  const [clients, setClients] = React.useState("");
  const [cell, setCell] = React.useState("");
  const [offcPhone, setOffcPhone] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [notes, setNotes] = React.useState("");

  const [contractNoError, setContractNoError] = React.useState("");

  const handleStartDateChange = (newStartDate) => {
    setStartDate(newStartDate); // Update the date state
    // Log the new date to the console
  };
  const handleUpdateClose = () => {
    setUpdate(false);
  };

  const handleEndDateChange = (newEndDate) => {
    setEndDate(newEndDate); // Update the date state
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const list = {
      contractNo,
      subject,
      project,
      description,
      startDate,
      endDate,
      contractType,
      contractValue,
      currency,
      clients,
      cell,
      offcPhone,
      city,
      state,
      country,
      postalCode,
    };

    if (contractNo === "") {
      setContractNoError(true);
    } else {
      const list = {
        contractNo,
        subject,
        project,
        description,
        startDate,
        endDate,
        contractType,
        contractValue,
        currency,
        clients,
        cell,
        offcPhone,
        city,
        state,
        country,
        postalCode,
      };

      setContractNoError(false); // Reset the error state
      handleClose(); // Close the dialog
    }
  };

  return (
    <div>
      <Box>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={update}
          onClose={handleUpdateClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleUpdateClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                Update Contact
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            sx={{
              padding: { xs: "15px", sm: "30px" },
              margin: { xs: "10px", sm: "20px" },
              backgroundColor: "white",
            }}
          >
            <Typography
              sx={{
                ml: 2,
                flex: 1,
                color: "black",
                fontSize: { xs: "16px", sm: "20px" },
              }}
              component="div"
            >
              Contract Details
            </Typography>
            <hr sx={{ color: "gray" }} />

            <Grid
              container
              spacing={2}
              sx={{ marginTop: "20px", padding: { xs: "10px", sm: "20px" } }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Contract Number<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={contractNo}
                    onChange={(e) => setContractNo(e.target.value)}
                    placeholder="eg. Human Resource"
                    inputProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                      width: "100%",
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Subject<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="eg. Human Resource"
                    inputProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                      width: "100%",
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} md={4} sm={6}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Project
                  </Typography>
                  <Select
                    value={project}
                    onChange={(e) => setProject(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                      width: "100%",
                    }}
                  >
                    <MenuItem value={""}>---</MenuItem>
                    <MenuItem value={"Android Application"}>
                      Android Application
                    </MenuItem>
                    <MenuItem value={"React Application"}>
                      React Application
                    </MenuItem>
                  </Select>
                </Stack>
              </Grid>
            </Grid>

            <br />
            <br />
            <Grid
              item
              xs={12}
              md={10}
              sm={10}
              sx={{ paddingRight: { xs: 0, sm: 2 } }}
            >
              <Stack direction="column" sx={{ width: "100%" }}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    color: "black",
                    fontSize: { xs: "0.875rem", sm: "1rem" },
                  }} // Responsive font size
                >
                  Description
                </Typography>

                <ReactQuill
                  value={description}
                  onChange={(value) => setDescription(value)} // Updated onChange to match ReactQuill's API
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    color: "black",
                    height: "100px", // Set your desired height
                    minHeight: "100px", // Ensure a minimum height
                  }}
                />
              </Stack>
            </Grid>

            <br />
            <br />
            <Grid
              container
              spacing={2}
              sx={{ marginTop: "20px", padding: { xs: "10px", sm: "20px" } }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ width: "100%" }}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Start Date<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={startDate}
                          onChange={(date) => handleStartDateChange(date)}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      End Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={endDate}
                          onChange={(date) => handleEndDateChange(date)}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{ padding: { xs: "10px", sm: "20px" } }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ width: "100%" }}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Contract Type
                    </Typography>
                    <Select
                      value={contractType}
                      onChange={(e) => setContractType(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem value="">---</MenuItem>
                      <MenuItem value="Monthly">Monthly</MenuItem>
                      <MenuItem value="Yearly">Yearly</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Contract Value
                    </Typography>
                    <TextField
                      variant="outlined"
                      onChange={(e) => setContractValue(e.target.value)}
                      placeholder="eg. Human Resource"
                      inputProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Currency
                    </Typography>
                    <Select
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem value="">---</MenuItem>
                      <MenuItem value="INR (Rs.)">INR (Rs.)</MenuItem>
                      <MenuItem value="US($)">US($)</MenuItem>
                      <MenuItem value="EURO">EURO</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>

            <Typography
              sx={{
                ml: 2,
                flex: 1,
                color: "black",
                fontSize: { xs: "18px", sm: "20px" },
                mt: 3,
              }}
              component="div"
            >
              Client Details
            </Typography>
            <hr sx={{ color: "gray" }} />

            <Grid
              container
              spacing={2}
              sx={{ marginTop: "20px", padding: { xs: "10px", sm: "20px" } }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ width: "100%" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Clients<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      value={clients}
                      onChange={(e) => setClients(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={"David"}>David</MenuItem>
                      <MenuItem value={"Chrissy"}>Chrissy</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Cell
                    </Typography>
                    <TextField
                      variant="outlined"
                      onChange={(e) => setCell(e.target.value)}
                      placeholder="eg. Human Resource"
                      inputProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Office Phone Number
                    </Typography>
                    <TextField
                      variant="outlined"
                      onChange={(e) => setOffcPhone(e.target.value)}
                      placeholder="eg. +123456789"
                      inputProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    />
                  </Stack>
                </Grid>
              </Stack>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{ marginTop: "20px", padding: { xs: "10px", sm: "20px" } }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ width: "100%" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      City
                    </Typography>
                    <TextField
                      variant="outlined"
                      onChange={(e) => setCity(e.target.value)}
                      placeholder="eg. Human Resource"
                      inputProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      State
                    </Typography>
                    <TextField
                      variant="outlined"
                      onChange={(e) => setState(e.target.value)}
                      placeholder="eg. New York"
                      inputProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Country
                    </Typography>
                    <TextField
                      variant="outlined"
                      onChange={(e) => setCountry(e.target.value)}
                      placeholder="eg. Dubai"
                      inputProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Postal Code
                    </Typography>
                    <TextField
                      variant="outlined"
                      onChange={(e) => setPostalCode(e.target.value)}
                      placeholder="eg. 12345"
                      inputProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    />
                  </Stack>
                </Grid>
              </Stack>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{ marginTop: "20px", padding: { xs: "10px", sm: "20px" } }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ width: "100%" }}
              >
                <Grid item xs={12} sm={6} md={6}>
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Alternate Address
                    </Typography>
                    <textarea
                      id="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      style={{
                        border: "1px solid black",
                        borderRadius: "4px",
                        padding: "10px",
                        width: "100%",
                        boxSizing: "border-box",
                        minHeight: "100px",
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Notes
                    </Typography>
                    <textarea
                      id="notes"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      style={{
                        border: "1px solid black",
                        borderRadius: "4px",
                        padding: "10px",
                        width: "100%",
                        boxSizing: "border-box",
                        minHeight: "100px",
                      }}
                    />
                  </Stack>
                </Grid>
              </Stack>
            </Grid>

            <Button
              variant="contained"
              autoFocus
              onClick={handleSave}
              color="inherit"
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                margin: "25px",
              }}
            >
              Update
            </Button>
          </Box>
        </Dialog>
      </Box>
      <React.Fragment>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            onClick={handleClickOpen}
            sx={{
              backgroundColor: "blue",
              "&:hover": { backgroundColor: "orange" },
              textAlign: "center",
              marginLeft: "10px",
            }}
          >
            <AddIcon />
            Add Contract
          </Button>
          {/* <Button variant="contained" disabled>
        Disabled
      </Button>
      <Button variant="contained" href="#contained-buttons">
        Link
      </Button> */}
        </Stack>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                New Contact
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            sx={{
              padding: { xs: "15px", sm: "30px" },
              margin: { xs: "10px", sm: "20px" },
              backgroundColor: "white",
            }}
          >
            <Typography
              sx={{
                ml: 2,
                flex: 1,
                color: "black",
                fontSize: { xs: "16px", sm: "20px" },
              }}
              component="div"
            >
              Contract Details
            </Typography>
            <hr sx={{ color: "gray" }} />

            <Grid
              container
              spacing={2}
              sx={{ marginTop: "20px", padding: { xs: "10px", sm: "20px" } }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Contract Number<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={contractNo}
                    onChange={(e) => setContractNo(e.target.value)}
                    placeholder="eg. Human Resource"
                    inputProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                      width: "100%",
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Subject<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="eg. Human Resource"
                    inputProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                      width: "100%",
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} md={4} sm={6}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Project
                  </Typography>
                  <Select
                    value={project}
                    onChange={(e) => setProject(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                      width: "100%",
                    }}
                  >
                    <MenuItem value={""}>---</MenuItem>
                    <MenuItem value={"Android Application"}>
                      Android Application
                    </MenuItem>
                    <MenuItem value={"React Application"}>
                      React Application
                    </MenuItem>
                  </Select>
                </Stack>
              </Grid>
            </Grid>

            <br />
            <br />
            <Grid
              item
              xs={12}
              md={10}
              sm={10}
              sx={{ paddingRight: { xs: 0, sm: 2 } }}
            >
              <Stack direction="column" sx={{ width: "100%" }}>
                <Typography
                  variant="body1" // Updated from "p" to "body1" for correct Typography variant
                  gutterBottom
                  sx={{
                    color: "black",
                    fontSize: { xs: "0.875rem", sm: "1rem" },
                  }} // Responsive font size
                >
                  Description
                </Typography>

                <ReactQuill
                  value={description}
                  onChange={(value) => setDescription(value)} // Updated onChange to match ReactQuill's API
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    color: "black",
                    height: "100px", // Set your desired height
                    minHeight: "100px", // Ensure a minimum height
                  }}
                />
              </Stack>
            </Grid>

            <br />
            <br />
            <Grid
              container
              spacing={2}
              sx={{ marginTop: "20px", padding: { xs: "10px", sm: "20px" } }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ width: "100%" }}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Start Date<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={startDate}
                          onChange={(date) => handleStartDateChange(date)}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      End Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={endDate}
                          onChange={(date) => handleEndDateChange(date)}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{ padding: { xs: "10px", sm: "20px" } }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ width: "100%" }}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Contract Type
                    </Typography>
                    <Select
                      value={contractType}
                      onChange={(e) => setContractType(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem value="">---</MenuItem>
                      <MenuItem value="Monthly">Monthly</MenuItem>
                      <MenuItem value="Yearly">Yearly</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Contract Value
                    </Typography>
                    <TextField
                      variant="outlined"
                      onChange={(e) => setContractValue(e.target.value)}
                      placeholder="eg. Human Resource"
                      inputProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Currency
                    </Typography>
                    <Select
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem value="">---</MenuItem>
                      <MenuItem value="INR (Rs.)">INR (Rs.)</MenuItem>
                      <MenuItem value="US($)">US($)</MenuItem>
                      <MenuItem value="EURO">EURO</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>

            <Typography
              sx={{
                ml: 2,
                flex: 1,
                color: "black",
                fontSize: { xs: "18px", sm: "20px" },
                mt: 3,
              }}
              component="div"
            >
              Client Details
            </Typography>
            <hr sx={{ color: "gray" }} />

            <Grid
              container
              spacing={2}
              sx={{ marginTop: "20px", padding: { xs: "10px", sm: "20px" } }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ width: "100%" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Clients<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      value={clients}
                      onChange={(e) => setClients(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={"David"}>David</MenuItem>
                      <MenuItem value={"Chrissy"}>Chrissy</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Cell
                    </Typography>
                    <TextField
                      variant="outlined"
                      onChange={(e) => setCell(e.target.value)}
                      placeholder="eg. Human Resource"
                      inputProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Office Phone Number
                    </Typography>
                    <TextField
                      variant="outlined"
                      onChange={(e) => setOffcPhone(e.target.value)}
                      placeholder="eg. +123456789"
                      inputProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    />
                  </Stack>
                </Grid>
              </Stack>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{ marginTop: "20px", padding: { xs: "10px", sm: "20px" } }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ width: "100%" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      City
                    </Typography>
                    <TextField
                      variant="outlined"
                      onChange={(e) => setCity(e.target.value)}
                      placeholder="eg. Human Resource"
                      inputProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      State
                    </Typography>
                    <TextField
                      variant="outlined"
                      onChange={(e) => setState(e.target.value)}
                      placeholder="eg. New York"
                      inputProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Country
                    </Typography>
                    <TextField
                      variant="outlined"
                      onChange={(e) => setCountry(e.target.value)}
                      placeholder="eg. Dubai"
                      inputProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Postal Code
                    </Typography>
                    <TextField
                      variant="outlined"
                      onChange={(e) => setPostalCode(e.target.value)}
                      placeholder="eg. 12345"
                      inputProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    />
                  </Stack>
                </Grid>
              </Stack>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{ marginTop: "20px", padding: { xs: "10px", sm: "20px" } }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ width: "100%" }}
              >
                <Grid item xs={12} sm={6} md={6}>
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Alternate Address
                    </Typography>
                    <textarea
                      id="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      style={{
                        border: "1px solid black",
                        borderRadius: "4px",
                        padding: "10px",
                        width: "100%",
                        boxSizing: "border-box",
                        minHeight: "100px",
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "black" }}
                    >
                      Notes
                    </Typography>
                    <textarea
                      id="notes"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      style={{
                        border: "1px solid black",
                        borderRadius: "4px",
                        padding: "10px",
                        width: "100%",
                        boxSizing: "border-box",
                        minHeight: "100px",
                      }}
                    />
                  </Stack>
                </Grid>
              </Stack>
            </Grid>

            <Button
              variant="contained"
              autoFocus
              onClick={handleSave}
              color="inherit"
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                margin: "25px",
              }}
            >
              Update
            </Button>
          </Box>
        </Dialog>
      </React.Fragment>
    </div>
  );
}
