import React from "react";

import LeadsTabs from "./LeadsSettings/LeadsTabs";

import SettingPageLayout from "./SettingPageLayout";

function MainLeadsSettings() {
  return (
    <SettingPageLayout>
      <LeadsTabs />
    </SettingPageLayout>
  );
}

export default MainLeadsSettings;
