import React from "react";

import BusinessSettings from "./BusinessSettings/BusinessSettings";
import SettingPageLayout from "./SettingPageLayout";

function MainBusiness() {
  return (
    <SettingPageLayout>
      <BusinessSettings />
    </SettingPageLayout>
  );
}

export default MainBusiness;
