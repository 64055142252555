import React from "react";
import TabsAppSettings from "./TabsAppSettings";

function AppSettings({ isDown }) {
  return (
    <div>
      <TabsAppSettings isDown={isDown} />
    </div>
  );
}

export default AppSettings;
