import {
  Box,
  Grid,
  Snackbar,
  TextField,
  Alert,
  ThemeProvide,
  createTheme,
  ThemeProvider,
  Menu,
  Stack,
  makeStyles,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";
import Radio from "@mui/material/Radio";
import ReactQuill from "react-quill";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import { getKeysAndValues } from "../../../utils/getKeysAndValues";
import StatusAlert from "../../../StatusAlertList/StatusAlert";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Notes({ isDown }) {
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [update, setUpdate] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({
    keys: [],
    values: [],
    open: false,
  });

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };
  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleViewMore = () => {
    const rowData = businesslocation.filter((row) => row.id === openMenuId);

    const { keys, values } = getKeysAndValues(rowData[0]);

    setSelectedRow({ keys, values, open: true });
    // setOpenDialog(true);
  };
  const handleDailogClosing = () => {
    setSelectedRow({ ...selectedRow, open: false });
  };

  // function TransitionLeft(props) {
  //   return <Slide {...props} direction="left" />;
  // }

  // const vertical = 'top';
  // const horizontal = 'right';

  const [type, setType] = useState("");

  const [status, setStatus] = useState("");

  const handleGenderChange = (event) => {
    setType(event.target.value);
  };

  const OfficeType = ["Rent", "Contact", "Own", "Others"];

  // Function Starts Here

  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [manager, setManager] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [officecode, setOfficecode] = useState("");
  const [details, setDetails] = useState("");

  const [updatetype, setUpdateType] = useState("");
  const [updatelocation, setUpdateLocation] = useState("");
  const [updateaddress, setUpdateAddress] = useState("");
  const [updatecountry, setUpdateCountry] = useState("");
  const [updatemanager, setUpdateManager] = useState("");
  const [updatephone, setUpdatePhone] = useState("");
  const [updateemail, setUpdateEmail] = useState("");
  const [updateofficecode, setUpdateOfficecode] = useState("");
  const [updatedetails, setUpdateDetails] = useState("");

  const [data, SetData] = useState([]);

  const StatusOptions = ["Active", "Inactive"];
  const [openMenuId, setOpenMenuId] = useState(null);

  const item = {
    location,
    address,
    country,
    manager,
    phone,
    email,
    officecode,
    details,
    type,
  };

  async function SaveDate() {
    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/addBusinesslocation",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      }
    );

    result = await result.json();

    showAlert("Business location Added successfully", "success");

    getData();
    setOpen(false);
  }

  // ends Here

  // Table Starts Here

  const [businesslocation, setBusinesslocation] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/listBusinessLocations"
    );

    const response = await results.json();
    setBusinesslocation(response);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      name: "notetitle",
      label: "Note Title",
      options: {
        customBodyRender: (value) =>
          (value || " ").charAt(0).toUpperCase() + (value || " ").slice(1),
      },
    },
    {
      name: "notetype",
      label: "Note Type",
      options: {
        customBodyRender: (value) =>
          (value || " ").charAt(0).toUpperCase() + (value || " ").slice(1),
      },
    },
    {
      name: "created",
      label: "Created",
      options: {
        customBodyRender: (value) =>
          (value || " ").charAt(0).toUpperCase() + (value || " ").slice(1),
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        customBodyRender: (value) => (
          <>
            <div>
              <Button
                id="basic-button"
                aria-controls={openDropMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDropMenu ? "true" : undefined}
                onClick={handleClick}
              >
                <div onClick={() => handleOpenMenu(value)}>
                  <MoreHorizTwoToneIcon />
                </div>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDropMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => handleViewMore(value)}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <RemoveRedEyeIcon />
                    View More
                  </div>
                </MenuItem>
                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => handleEdit(value)}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <EditNoteIcon /> Update
                  </div>
                </MenuItem>
                <MenuItem onClick={handleCloseMenu}>
                  <div
                    onClick={() => handleDelete(value)}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteForeverIcon /> Delete
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </>
        ),
      },
    },
  ];

  async function handleEdit(id) {
    let result = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/businesslocationget/" + id
    );
    result = await result.json();

    SetData(result);
    handleClickUpdate();
  }

  // Update Values Start

  useEffect(() => {
    setUpdateOfficecode(data.officecode);
  }, [data.officecode]);

  useEffect(() => {
    setUpdateCountry(data.country);
  }, [data.country]);

  useEffect(() => {
    setUpdateLocation(data.location);
  }, [data.location]);

  useEffect(() => {
    setUpdateManager(data.manager);
  }, [data.manager]);

  useEffect(() => {
    setUpdatePhone(data.phone);
  }, [data.phone]);

  useEffect(() => {
    setUpdateEmail(data.email);
  }, [data.email]);

  useEffect(() => {
    setUpdateAddress(data.address);
  }, [data.address]);

  useEffect(() => {
    setUpdateType(data.type);
  }, [data.type]);

  useEffect(() => {
    setUpdateDetails(data.details);
  }, [data.details]);

  useEffect(() => {
    setStatus(data.status);
  }, [data.status]);

  // Update Values Ends
  // Editing Starts here

  async function UpdateData() {
    const formData = new FormData();

    formData.append("officecode", updateofficecode);
    formData.append("country", updatecountry);
    formData.append("location", updatelocation);
    formData.append("address", updateaddress);
    formData.append("manager", updatemanager);
    formData.append("phone", updatephone);
    formData.append("email", updateemail);
    formData.append("type", updatetype);
    formData.append("details", updatedetails);
    formData.append("status", status);

    try {
      let response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/updateBusinessLocation/${data.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      showAlert("Business location updated successfully", "success");

      handleUpdateClose();
      getData(); // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      showAlert("Failed to update business location", "error");
    }
  }

  async function handleDelete(id) {
    const response = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/businesslocationdelete/" + id,
      {
        method: "DELETE",
      }
    );
    const result = await response.json(); // Assigning to a new variable

    getData();
    showAlert("Business location Deleted successfully", "warning");
  }

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />

      <Box>
        <Dialog
          fullWidth
          maxWidth="md"
          open={update}
          onClose={handleUpdateClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleUpdateClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                Application Note Details
              </Typography>
            </Toolbar>
          </AppBar>

          <Box>
            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px", zIndex: "4000" }}
            >
              <Grid item xs={10} sm={8} md={6}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Note Title
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={10} sm={8} md={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      placeholder="Title "
                      onChange={(e) => setOfficecode(e.target.value)}
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={10} sm={8} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    sx={{
                      color: "black",

                      padding: "4px",
                      borderRadius: "4px",
                    }}
                  >
                    Note Type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Public"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                            "&.Mui-checked": { color: "gray" },
                          }}
                        />
                      }
                      label="Public"
                    />
                    <FormControlLabel
                      value="Private"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                            "&.Mui-checked": { color: "gray" },
                          }}
                        />
                      }
                      label="Private"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container sx={{ padding: "20px" }}>
              <Stack direction="column" sx={{ width: "100%" }}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Note Detail<span style={{ color: "red" }}>*</span>
                </Typography>

                <ReactQuill
                  value={address}
                  onChange={() => {}}
                  // modules={modules}
                  // theme="snow"
                  style={{
                    // border: '1px solid black',
                    // borderRadius: '4px',
                    //padding: '10px',
                    width: "100%",
                    boxSizing: "border-box",
                    color: "black",
                    borderBlockColor: "black",
                    // minHeight: '100px' // Set your desired minimum height
                  }}
                />
              </Stack>
            </Grid>
            <br />
            <br />
            <Button
              variant="contained"
              autoFocus
              color="inherit"
              onClick={UpdateData}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                margin: "25px",
              }}
            >
              Update
            </Button>
          </Box>
        </Dialog>
      </Box>

      <List
        style={{
          background: "none",
          padding: 5,
        }}
      >
        <ListItemText style={{ background: "none", margin: 5, zIndex: "4000" }}>
          <React.Fragment>
            <StatusAlert
              open={alertOpen}
              setOpen={setAlertOpen}
              message={alertMessage}
              severity={alertSeverity}
            />
            <Button
              variant="contained"
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
              }}
            >
              <AddCircleIcon /> Add Note
            </Button>
            <Dialog
              fullWidth
              maxWidth="md"
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
              PaperProps={{
                style: {
                  position: "absolute",
                  background: "#141b2d",
                  top: "0",
                  backgroundColor: "white",
                  maxHeight: "95%",
                  margin: "auto",
                  overflowX: "hidden",
                  zIndex: 1200,
                },
              }}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} component="div">
                    Add Application Note
                  </Typography>
                </Toolbar>
              </AppBar>
              <Box>
                <Grid
                  container
                  spacing={2}
                  style={{ color: "black", margin: "10px", zIndex: "4000" }}
                >
                  <Grid item xs={10} sm={8} md={6}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Note Title
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={10} sm={8} md={6}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          placeholder="Title "
                          onChange={(e) => setOfficecode(e.target.value)}
                          inputProps={{
                            style: {
                              color: "black",
                              border: "1px solid black",
                              borderRadius: "5px",
                            },
                          }}
                          InputLabelProps={{ style: { color: "black" } }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={10} sm={8} md={6}>
                    <FormControl>
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        sx={{
                          color: "black",

                          padding: "4px",
                          borderRadius: "4px",
                        }}
                      >
                        Note Type
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Public"
                          control={
                            <Radio
                              sx={{
                                color: "black",
                                "&.Mui-checked": { color: "gray" },
                              }}
                            />
                          }
                          label="Public"
                        />
                        <FormControlLabel
                          value="Private"
                          control={
                            <Radio
                              sx={{
                                color: "black",
                                "&.Mui-checked": { color: "gray" },
                              }}
                            />
                          }
                          label="Private"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container sx={{ padding: "20px" }}>
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Note Detail<span style={{ color: "red" }}>*</span>
                    </Typography>
                    {/* <CustomToolbar style={{ color: 'black' }} /> Render custom emoji toolbar */}
                    <ReactQuill
                      value={address}
                      onChange={() => {}}
                      // modules={modules}
                      // theme="snow"
                      style={{
                        // border: '1px solid black',
                        // borderRadius: '4px',
                        //padding: '10px',
                        width: "100%",
                        boxSizing: "border-box",
                        color: "black",
                        borderBlockColor: "black",
                        // minHeight: '100px' // Set your desired minimum height
                      }}
                    />
                  </Stack>
                </Grid>
                <br />
                <br></br>

                <Button
                  variant="contained"
                  autoFocus
                  color="inherit"
                  onClick={SaveDate}
                  sx={{
                    backgroundColor: "blue",
                    "&:hover": { backgroundColor: "orange" },
                    textAlign: "center",
                    margin: "25px",
                  }}
                >
                  Save
                </Button>
              </Box>
            </Dialog>
          </React.Fragment>
        </ListItemText>
        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <MUIDataTable
              fullScreen={true}
              title={"Applications Notes"}
              data={businesslocation}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default Notes;
