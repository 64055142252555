import { Box, createTheme, ThemeProvider, Menu, Stack } from "@mui/material";

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";

import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import Slide from "@mui/material/Slide";

import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";

import AddIcon from "@mui/icons-material/Add";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { useNavigate } from "react-router-dom";

import StatusAlert from "../../StatusAlertList/StatusAlert";

import NoticeBoardHeader from "./NoticeBoardHeader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function NoticeBoard() {
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [noticeHeading, setNoticeHeading] = useState("");

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [departments, setDepartments] = useState(null);
  const [selectedRadio, setSelectedRadio] = useState("Client");

  const navigate = useNavigate();
  const [radioButton, setRadioButton] = useState(false);
  const [noticeData, setNoticeData] = useState([]);
  const [update, setUpdate] = React.useState(false);

  const [openMenuId, setOpenMenuId] = useState(null);
  const [userType, setUserType] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };

  const handleSearchButton = async () => {
    const result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/Noticefilter?text=${searchValue}`,
      {
        method: "POST",
      }
    );
    const response = await result.json();
    const updatedResponse = response.map((notice) => ({
      ...notice,
      notice_to: convertToUserType(notice.notice_to),
      user_type: convertToMainUserType(notice.user_type), // convert the value
    }));
    setNoticeData(updatedResponse);
  };
  useEffect(() => {
    handleSearchButton();
  }, [searchValue]);

  const handleDateButton = async () => {
    const result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/Noticefilter?start_date=${startDate}&end_date=${endDate}`,
      {
        method: "POST",
      }
    );
    const response = await result.json();
    const updatedResponse = response.map((notice) => ({
      ...notice,
      notice_to: convertToUserType(notice.notice_to),
      user_type: convertToMainUserType(notice.user_type), // convert the value
    }));
    setNoticeData(updatedResponse);
  };
  useEffect(() => {
    handleDateButton();
  }, [startDate, endDate]);
  const handleCancel = () => {
    navigate("/notice");
  };
  const handleViewMore = async () => {
    const result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/getNoticeById/${openMenuId}`,
      {
        method: "POST",
      }
    );
    const rowData = await result.json();

    navigate(`/notice/${openMenuId}`, { state: rowData });
  };

  // Function Starts Here

  const userData = localStorage.getItem("user-info");
  let creator;
  if (userData) {
    // Parse the JSON string into an object
    const parsedData = JSON.parse(userData);

    // Access the name property from the parsed object
    creator = parsedData.value.name;
  } else {
    console.error("No user data found in localStorage.");
  }
  let valueToSend;

  if (selectedRadio === "Client") {
    valueToSend = 1;
  } else if (selectedRadio === "Employee") {
    valueToSend = 2;
  } else if (selectedRadio === "App User") {
    valueToSend = 3;
  }

  const item = {
    title: noticeHeading,
    description: description,

    creator: creator,
    notice_to: valueToSend,
    user_type: userType,
    department: departments,
  };

  async function SaveData() {
    // Create a new FormData object
    const formData = new FormData();

    // Append each item to the FormData object
    for (const key in item) {
      if (item[key] !== undefined && item[key] !== null) {
        formData.append(key, item[key]);
      }
    }

    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/addNotice",
      {
        method: "POST",
        body: formData,
      }
    );

    await result.json();

    showAlert("Added successfully", "success");

    getData();
    setOpen(false);
  }

  // ends Here

  // Table Starts Here

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getNotices"
    );
    const response = await results.json();

    // Map through response to convert notice_to values
    const updatedResponse = response.map((notice) => ({
      ...notice,
      notice_to: convertToUserType(notice.notice_to),
      user_type: convertToMainUserType(notice.user_type), // convert the value
    }));

    setNoticeData(updatedResponse); // Store the updated response
  }

  const convertToUserType = (value) => {
    if (value === "1") return "Client";
    if (value === "2") return "Employee";
    if (value === "3") return "App User";
    return "Unknown"; // Fallback for invalid values
  };
  const convertToMainUserType = (value) => {
    if (value === "1") return "Driver";
    if (value === "2") return "Owner";
    if (value === "3") return "Dhaba";
    if (value === "4") return "Mechanic";
    if (value === "5") return "Crane Owner";
    if (value === "6") return "Crane Operator";

    return "Unknown"; // Fallback for invalid values
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  const columns = [
    {
      name: "title",
      label: "Notice",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Notice
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Date",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Date
            </th>
          );
        },
        customBodyRender: (value) => {
          const date = new Date(value);

          // Extract the day, month, and year from the Date object
          const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if necessary
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
          const year = date.getFullYear();

          // Format the date as "DD-MM-YYYY"
          const formattedDate = `${day}-${month}-${year}`;
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {formattedDate}
            </div>
          );
        },
      },
    },
    {
      name: "notice_to",
      label: "To",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              To
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "70px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                paddingRight: "20px", // Add padding for starting point
              }}
            >
              <div style={{ paddingRight: "20px" }}>
                <Button
                  id="basic-button"
                  aria-controls={openDropMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div onClick={() => handleOpenMenu(value)}>
                    <MoreHorizTwoToneIcon />
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleViewMore(tableMeta.rowData)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <RemoveRedEyeIcon /> View More
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleEdit(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EditNoteIcon /> Edit
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleDelete(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <DeleteForeverIcon /> Delete
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </>
        ),
      },
    },
  ];

  async function handleEdit(id) {
    const result = noticeData.filter((item) => item.id === openMenuId);
    navigate(`/notice/${openMenuId}/edit`, { state: result[0] });

    handleClickUpdate();
  }

  const handleUpdateClose = () => {
    setOpen(false);
  };

  async function handleDelete() {
    const response = await fetch(
      ` https://rptpl.roadpilot.co.in/public/api/deleteNotice/${openMenuId}`,
      {
        method: "DELETE",
      }
    );
    const result = await response.json(); // Assigning to a new variable

    getData();
    showAlert(" Deleted successfully", "warning");
  }

  // end delete

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });
  useEffect(() => {
    if (selectedRadio === "App User") {
      setRadioButton(true);
    } else {
      setRadioButton(false);
    }
  }, [selectedRadio]);

  const handleAddButton = () => {
    navigate("/notice/create");
  };
  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />

      <NoticeBoardHeader
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      <List
        style={{
          background: "none",
          padding: 5,
          marginTop: "10px",
        }}
      >
        <Stack direction="row" spacing={2} sx={{ marginBottom: "5px" }}>
          <Box
            onClick={handleAddButton}
            sx={{
              backgroundColor: "#1d82f5",
              "&:hover": { backgroundColor: "orange" },
              textAlign: "center",
              padding: "10px",
              marginLeft: "10px",
              height: "40px",
              width: "115px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              borderRadius: "4px",
              fontSize: "15px",
              fontFamily: "sans-serif",
              cursor: "pointer",
            }}
          >
            <AddIcon />
            Add Notice
          </Box>
        </Stack>
        {/* <React.Fragment>
          <Stack
            direction="row"
            spacing={2}
            sx={{ marginBottom: "5px", marginTop: "10px" }}
          >
            <Box
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#1d82f5",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                padding: "10px",
                marginLeft: "10px",
                height: "40px",
                width: "115px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: "4px",
                fontSize: "15px",
                fontFamily: "sans-serif",
                cursor: "pointer",
              }}
            >
              <AddIcon />
              Add Notice
            </Box>
          </Stack>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                position: "absolute",
                background: "#141b2d",
                top: "0",
                backgroundColor: "white",
                maxHeight: "95%",
                margin: "auto",
                overflowX: "hidden",
                zIndex: 1200,
              },
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                  Add New Notice
                </Typography>
              </Toolbar>
            </AppBar>
            <Box sx={{ background: "#fff" }}>
              <Grid
                container
                spacing={2}
                sx={{
                  padding: { xs: "20px", sm: "20px" },
                  color: "#000",
                }}
              >
                <Grid item xs={11} sm={6} md={6}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={selectedRadio}
                      onChange={(e) => setSelectedRadio(e.target.value)}
                    >
                      <FormControlLabel
                        value="Client"
                        control={
                          <Radio
                            sx={{
                              color: "black",
                              "&.Mui-checked": { color: "#1d82f5" },
                            }}
                          />
                        }
                        label="Client"
                      />
                      <FormControlLabel
                        value="Employee"
                        control={
                          <Radio
                            sx={{
                              color: "black",
                              "&.Mui-checked": { color: "#1d82f5" },
                            }}
                          />
                        }
                        label="Employee"
                      />
                      <FormControlLabel
                        value="App User"
                        control={
                          <Radio
                            sx={{
                              color: "black",
                              "&.Mui-checked": { color: "#1d82f5" },
                            }}
                          />
                        }
                        label="App User"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {radioButton && (
                  <Grid item xs={11} sm={5} md={5}>
                    <Typography sx={{ color: "#000", paddingBottom: "7px" }}>
                      UserType
                    </Typography>
                    <Select
                      value={userType}
                      autoFocus
                      fullWidth
                      onChange={(e) => setUserType(e.target.value)}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={1}>Driver</MenuItem>
                      <MenuItem value={2}>Owner</MenuItem>
                      <MenuItem value={3}>Dhaba</MenuItem>
                      <MenuItem value={4}>Mechanic</MenuItem>
                      <MenuItem value={5}>Crane Owner</MenuItem>
                      <MenuItem value={6}>Crane Operator</MenuItem>
                    </Select>
                  </Grid>
                )}
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  padding: { xs: "20px", sm: "20px" },
                }}
              >
                <Grid item xs={12} sm={5.5} md={5.5}>
                  <Stack direction="column" spacing={2}>
                    <Typography sx={{ color: "#000" }}>
                      Notice Heading
                    </Typography>
                    <TextField
                      autoFocus
                      margin="dense"
                      placeholder="Notice Heading Name"
                      type="text"
                      fullWidth
                      value={noticeHeading}
                      onChange={(e) => setNoticeHeading(e.target.value)}
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={5.5} md={5.5}>
                  <Stack direction="column" spacing={2}>
                    <Typography sx={{ color: "#000" }}>Department</Typography>
                    <Select
                      value={departments}
                      autoFocus
                      fullWidth
                      onChange={(e) => setDepartments(e.target.value)}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={Trainee}>Trainee</MenuItem>
                      <MenuItem value={Junior}>Junior</MenuItem>
                      <MenuItem value={Senior}>Senior</MenuItem>
                      <MenuItem value={TeamLead}>Team Lead</MenuItem>
                      <MenuItem value={ProjectManager}>
                        Project Manager
                      </MenuItem>
                    </Select>
                  </Stack>
                </Grid>
              </Grid>

              <Grid container sx={{ padding: "20px" }}>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Notice Details<span style={{ color: "red" }}>*</span>
                  </Typography>

                  <ReactQuill
                    value={description}
                    onChange={setDescription}
                    modules={modules}
                    theme="snow"
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      color: "black",
                      borderBlockColor: "black",
                    }}
                  />
                </Stack>
              </Grid>

              <Grid
                container
                sx={{
                  padding: {
                    xs: "80px 10px 20px 10px",
                    sm: "60px 10px 20px 10px",
                    md: "50px 10px 20px 10px",
                  },
                }}
              >
                <Grid item sx={11} className="messageFooter">
                  <Box className="response" onClick={SaveData}>
                    Save
                  </Box>
                  <Box className="cancel" onClick={handleCancel}>
                    Cancel
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Dialog>
        </React.Fragment> */}

        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <Box
              sx={{
                maxWidth: "100%",
              }}
            >
              <MUIDataTable
                title={
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "1.5rem",
                      color: "#333",
                    }}
                  >
                    Notice Lists
                  </span>
                }
                data={noticeData}
                columns={columns}
                options={options}
              />
            </Box>
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default NoticeBoard;
