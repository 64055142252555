import {  Grid, Paper, styled, Typography } from "@mui/material";
import { Pie, PieChart } from "recharts";

const ContactTicket = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Item
          style={{
            height: "280px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            backgroundColor: "#fff",
            color: "#000",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: 800 }}>
            Tickets
          </Typography>
          <PieChart width={400} height={240}>
            <Pie
              data={[
                { name: "Closed", value: 30 },
                { name: "Resolved", value: 15 },
                { name: "Open", value: 40 },
                { name: "Pending", value: 15 },
              ]}
              dataKey="value"
              label={({ name, percent }) =>
                `${name} (${(percent * 100).toFixed(0)}%)`
              }
              labelStyle={{
                fill: "#000", // Set label color to black
                fontSize: "14px", // Adjust font size
              }}
              outerRadius={80}
              fill="#8884d8"
            />
          </PieChart>
        </Item>
      </Grid>
    </Grid>
  );
};

export default ContactTicket;
