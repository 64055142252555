import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormLabel,
  Alert,
  Switch,
  Stack,
} from "@mui/material";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Paper,
  Grid,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  ImportExport,
  EventNote,
  Person,
  CloudUpload,
} from "@mui/icons-material";
import ListIcon from "@mui/icons-material/List";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

import PersonIcon from "@mui/icons-material/Person";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BusinessIcon from "@mui/icons-material/Business";
import BadgeIcon from "@mui/icons-material/Badge";
import EventIcon from "@mui/icons-material/Event";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import StarIcon from "@mui/icons-material/Star";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import WarningIcon from "@mui/icons-material/Warning";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import AttendenceHeader from "./AttendenceHeader";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const employees = [
  {
    name: "Sadie Sauer",
    role: "Senior",
    depart: "Public Relations",
    img: "https://i.pravatar.cc/30?u=admin@example.com",
  },
  {
    name: "Tracy Kunze",
    role: "Team Lead",
    depart: "Public Relations",
    img: "https://i.pravatar.cc/30?u=employee@example.com",
  },
  {
    name: "Hermann Lynch",
    role: "Trainee",
    depart: "Sales",
    img: "https://i.pravatar.cc/30?u=simonis.nina@example.net9",
  },
  {
    name: "Norene Schaefer",
    role: "Senior",
    depart: "Finance",
    img: "https://i.pravatar.cc/30?u=fcrooks@example.com4",
  },
  {
    name: "Jarrell Moen Jr.",
    role: "Senior",
    depart: "Sales",
    img: "https://i.pravatar.cc/30?u=jraynor@example.org0",
  },
  {
    name: "Mr. Davonte Spencer",
    role: "Team Lead",
    depart: "Sales",
    img: "https://i.pravatar.cc/30?u=pcrist@example.com9",
  },
  {
    name: "Demarco O'Kon",
    role: "Project Manager",
    depart: "Human Resources",
    img: "https://i.pravatar.cc/30?u=lola01@example.org9",
  },
  {
    name: "Narciso Crona",
    role: "Team Lead",
    depart: "Public Relations",
    img: "https://i.pravatar.cc/30?u=alford.schultz@example.org4",
  },
  {
    name: "Mr. Vern Parisian",
    role: "Senior",
    depart: "Sales",
    img: "https://i.pravatar.cc/30?u=elta.mertz@example.org4",
  },
  {
    name: "Woodrow Gerhold",
    role: "Junior",
    depart: "Public Relations",
    img: "https://i.pravatar.cc/30?u=keeling.halle@example.net5",
  },
  {
    name: "Dr. Milo Veum",
    role: "Trainee",
    depart: "Public Relations",
    img: "https://i.pravatar.cc/30?u=josianne.graham@example.net7",
  },
  {
    name: "Reyna Bayer",
    role: "Trainee",
    depart: "Finance",
    img: "https://i.pravatar.cc/30?u=nicolas.emily@example.net1",
  },
  {
    name: "Eunice Carroll V",
    role: "Senior",
    depart: "Marketing",
    img: "https://i.pravatar.cc/30?u=kiel.zemlak@example.org4",
  },
];

const stats = [
  { title: "Working Days", value: 31 },
  { title: "Days Present", value: 0 },
  { title: "Late", value: 0 },
  { title: "Half Day", value: 0 },
  { title: "Absent", value: 31 },
  { title: "Holidays", value: 0 },
];

// Helper function to calculate days in a month
const getDaysInMonth = (month, year) => {
  return new Date(year, month + 1, 0).getDate();
};

// Helper function to get day names
const getDayName = (date, month, year) => {
  const dayIndex = new Date(year, month, date).getDay();
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return days[dayIndex];
};

const Attendance = () => {
  const [open, setOpen] = useState(false);
  const [showDays, setShowDays] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(7);
  const [selectedYear, setSelectedYear] = useState(2024);
  const [dates, setDates] = useState([]);
  const [activeIcon, setActiveIcon] = useState(null);
  const [showTable, setShowTable] = useState(true);
  const [showGrid, setShowGrid] = useState(false);
  const [workingDays, setWorkingDays] = useState(31);
  const [days, setDays] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [filteredData, setFilteredData] = useState(days);
  const [selectedEmployees, setSelectedEmployees] = useState(
    employees[0]?.name || ""
  );

  const handleEmployeesChange = (event) => {
    setSelectedEmployees(event.target.value);
  };

  // Handle employee selection
  const handleEmployeeChange = (event) => {
    setSelectedEmployee(event.target.value);
  };

  useEffect(() => {
    if (selectedEmployee) {
      const newData = days.filter((day) => day.employee === selectedEmployee); // Update with actual filter logic
      setFilteredData(newData);
    } else {
      // Reset to default data if no employee is selected
      setFilteredData(days);
    }
  }, [selectedEmployee, days]);

  // Function to calculate the number of days in the selected month and year
  const calculateDaysInMonth = (month, year) => {
    const numDays = new Date(year, month + 1, 0).getDate(); // Get the last day of the month
    const daysArray = Array.from({ length: numDays }, (_, i) => i + 1); // Create an array of day numbers
    setDays(daysArray);
    setWorkingDays(numDays); // Update the working days
  };

  const getDayOfWeek = (day, month, year) => {
    const date = new Date(year, month, day);
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysOfWeek[date.getDay()]; // Get the weekday index and return the name
  };

  useEffect(() => {
    calculateDaysInMonth(selectedMonth, selectedYear);
  }, [selectedMonth, selectedYear]);

  const [selectedDesignation, setSelectedDesignation] = useState("");

  const [selectedDepartment, setSelectedDepartment] = useState("");

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };
  const handleDateChange = (newDate) => {
    // setDate(newDate); // Update the date state
  };
  // Handle designation selection
  const handleDesignationChange = (event) => {
    setSelectedDesignation(event.target.value);
  };

  // Filter employees based on selected employee, designation, and department
  const filteredEmployees = employees.filter((employee) => {
    const matchesEmployee =
      selectedEmployee === "" || employee.name === selectedEmployee;
    const matchesDesignation =
      selectedDesignation === "" || employee.role === selectedDesignation;
    const matchesDepartment =
      selectedDepartment === "" || employee.depart === selectedDepartment;

    return matchesEmployee && matchesDesignation && matchesDepartment;
  });
  useEffect(() => {
    handleListIconClick();
  }, []);
  const handleListIconClick = () => {
    setActiveIcon("list");
    setShowDays(true);
    setShowGrid(false);
    setShowTable(true);
  };

  const handlePersonIconClick = () => {
    setActiveIcon("person");
    setShowGrid(true);
    setShowTable(false);
  };

  const handleWatchLaterIconClick = () => {
    setActiveIcon("watchLater");
    setShowDays(false);
    setShowGrid(false);
    setShowTable(true);
  };

  const buttonStyle = {
    backgroundColor: "#1d82f5",
    "&:hover": { backgroundColor: "orange" },
    textAlign: "center",
    padding: "10px",
    marginLeft: "10px",
    height: "40px",
    width: "170px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    borderRadius: "4px",
    fontSize: "15px",
    fontFamily: "sans-serif",
    cursor: "pointer",
  };

  const iconButtonStyle = (isActive) => ({
    backgroundColor: isActive ? "orange" : "#333", // Dark blue when active
    color: "#fff",
    // transition: "background-color 0.3s ease, transform 0.3s ease",
    borderRadius: "4px",
    maraginRight: "10px",
    "&:hover": {
      backgroundColor: "orange",
      color: "#000", // Hover effect with blue
      // transform: "scale(1.1)",
    },
  });

  useEffect(() => {
    // Calculate the number of days in the selected month and year
    const daysInMonth = getDaysInMonth(selectedMonth, selectedYear);
    setDates(Array.from({ length: daysInMonth }, (_, i) => i + 1));
  }, [selectedMonth, selectedYear]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const [selectedWork, setSelectedWork] = useState("");
  const handleWorkChange = (event) => {
    setSelectedWork(event.target.value);
  };

  const [selectedLocation, setSelectedLocation] = useState("");
  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const [importClicked, setImportClicked] = useState(false);
  const [file, setFile] = useState(null);
  const [hasHeaders, setHasHeaders] = useState(false);

  // Handle file upload
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleToggleChange = () => {
    setHasHeaders(!hasHeaders);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleImportClick = () => {
    setImportClicked(true); // Open the dialog when the import button is clicked
  };

  const onClose = () => {
    setImportClicked(false); // Close the dialog when the back button or outside area is clicked
  };

  const handleExportClick = () => {
    const link = document.createElement("a");
    link.href = "/mnt/data/Attendance_From_01-09-2024_To_25-09-2024.xlsx";
    link.download = "Attendance_From_01-09-2024_To_25-09-2024.xlsx";
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <AttendenceHeader />
      <Box sx={{ marginTop: "30px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#1d82f5",
                "&:hover": { backgroundColor: "orange", color: "#000" },
                textAlign: "center",
                padding: "10px",
                marginLeft: "10px",
                height: "40px",
                width: "160px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: "4px",
                fontSize: "15px",
                fontFamily: "sans-serif",
                cursor: "pointer",
              }}
            >
              <EventNote /> Mark Attendance
            </Box>
            {/* <Box
              onClick={handleImportClick}
              sx={{
                backgroundColor: "#fff",
                textAlign: "center",
                padding: "7px",
                marginLeft: " 10px",
                height: "40px",
                width: " 82px",
                display: "flex",
                color: "#333",
                justifyContent: "space-around",
                alignItems: " center",
                borderRadius: " 4px",
                fontSize: "15px",
                fontFamily: "sans-serif",
                cursor: "pointer",
                border: "1px solid gray",
                "&:hover": { backgroundColor: "orange" },
              }}
            >
              <ImportExport /> Import
            </Box> */}

            <Box
              onClick={handleExportClick}
              sx={{
                backgroundColor: "#fff",
                textAlign: "center",
                padding: "7px",
                marginLeft: " 10px",
                height: "40px",
                width: " 82px",
                display: "flex",
                color: "#333",
                justifyContent: "space-around",
                alignItems: " center",
                borderRadius: " 4px",
                fontSize: "15px",
                fontFamily: "sans-serif",
                cursor: "pointer",
                border: "1px solid gray",
                "&:hover": { backgroundColor: "orange" },
              }}
            >
              <ImportExport /> Export
            </Box>
          </Box>
          {/* Icons to the right of the Export button */}
          <Box
            mr={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <Tooltip title="Summary" arrow>
              <IconButton
                onClick={handleListIconClick}
                sx={{ ...iconButtonStyle(activeIcon === "list") }}
              >
                <ListIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Attendance by Member" arrow>
              <IconButton
                onClick={handlePersonIconClick}
                sx={{ ...iconButtonStyle(activeIcon === "person") }}
              >
                <Person />
              </IconButton>
            </Tooltip>

            <Tooltip title="Attendance by Hour" arrow>
              <IconButton
                onClick={handleWatchLaterIconClick}
                sx={{ ...iconButtonStyle(activeIcon === "watchLater") }}
              >
                <WatchLaterIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        {/* Attendance Details Modal */}
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle
            fontWeight={700}
            sx={{
              bgcolor: "#1d82f5",
              color: "#fff",
            }}
          >
            Attendance Details
          </DialogTitle>
          <DialogContent
            sx={{ bgcolor: "#fff", color: "#000", paddingTop: "10px" }}
          >
            <Grid container spacing={2}>
              {/* Department and Employees Selection */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <h3
                    style={{
                      fontSize: "1rem",
                      fontWeight: "550",
                      paddingTop: "10px",
                    }}
                  >
                    Department
                  </h3>
                  <Select
                    value={selectedDepartment || ""}
                    onChange={handleDepartmentChange}
                    displayEmpty
                    sx={{
                      border: "1px solid black",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Nothing Selected
                    </MenuItem>
                    <MenuItem value="Marketing">Marketing</MenuItem>
                    <MenuItem value="Sales">Sales</MenuItem>
                    <MenuItem value="Human Resources">Human Resources</MenuItem>
                    <MenuItem value="Public relations">
                      Public relations
                    </MenuItem>
                    <MenuItem value="Research">Research</MenuItem>
                    <MenuItem value="Finance">Finance</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <h6
                    style={{
                      fontSize: "1rem",
                      fontWeight: "550",
                      paddingTop: "10px",
                    }}
                  >
                    Employees
                  </h6>
                  <Select
                    value={selectedEmployees || ""}
                    onChange={handleEmployeesChange}
                    displayEmpty
                    sx={{
                      border: "1px solid black",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Nothing Selected
                    </MenuItem>
                    <MenuItem value="Sadie Sauer">Sadie Sauer</MenuItem>
                    <MenuItem value="Tracy Kunze">Tracy Kunze</MenuItem>
                    <MenuItem value="Hermann Lynch">Hermann Lynch</MenuItem>
                    <MenuItem value="Norene Schaefer">Norene Schaefer</MenuItem>
                    <MenuItem value="Jarrell Moen Jr.">
                      Jarrell Moen Jr.
                    </MenuItem>
                    <MenuItem value="Mr. Davonte Spencer">
                      Mr. Davonte Spencer
                    </MenuItem>
                    <MenuItem value="Demarco O'Kon">Demarco O'Kon</MenuItem>
                    <MenuItem value="Narciso Crona">Narciso Crona</MenuItem>
                    <MenuItem value="Mr. Vern Parisian">
                      Mr. Vern Parisian
                    </MenuItem>
                    <MenuItem value="Woodrow Gerhold">Woodrow Gerhold</MenuItem>
                    <MenuItem value="Dr. Milo Veum">Dr. Milo Veum</MenuItem>
                    <MenuItem value="Reyna Bayer">Reyna Bayer</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Location */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <h3
                    style={{
                      fontSize: "1rem",
                      fontWeight: "550",
                      paddingTop: "10px",
                    }}
                  >
                    Location
                  </h3>
                  <Select
                    value={selectedLocation || "WorkSuite"}
                    onChange={handleLocationChange}
                    displayEmpty
                    sx={{
                      border: "1px solid black",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                    }}
                  >
                    <MenuItem value="WorkSuite">WorkSuite</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Mark Attendance By */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <h3
                    style={{
                      fontSize: "1rem",
                      fontWeight: "550",
                      paddingTop: "10px",
                    }}
                  >
                    Working From*
                  </h3>
                  <Select
                    value={selectedWork || "Office"}
                    onChange={handleWorkChange}
                    displayEmpty
                    sx={{
                      border: "1px solid black",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                    }}
                  >
                    <MenuItem value="Office">Office</MenuItem>
                    <MenuItem value="Home">Home</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Clock In and Clock Out */}
              <Grid item xs={12} sm={6} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{
                      fontSize: "1rem",
                      fontWeight: "550",
                      paddingTop: "10px",
                    }}
                  >
                    Check In
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["TimePicker"]}
                      // onChange={(e) => handleDateChange(e.target.value)}
                      sx={{ marginTop: "-10px" }}
                    >
                      <DemoItem>
                        <TimePicker
                          //value={date}
                          //onChange={handleDateChange}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                        >
                          {(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              // Add any additional TextField props if needed
                            />
                          )}
                        </TimePicker>
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{
                      fontSize: "1rem",
                      fontWeight: "550",
                      paddingTop: "10px",
                    }}
                  >
                    Check Out
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["TimePicker"]}
                      // onChange={(e) => handleDateChange(e.target.value)}
                      sx={{ marginTop: "-10px" }}
                    >
                      <DemoItem>
                        <TimePicker
                          //value={date}
                          //onChange={handleDateChange}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                        >
                          {(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              // Add any additional TextField props if needed
                            />
                          )}
                        </TimePicker>
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </Stack>
              </Grid>

              {/* Year and Month */}
              <Grid item xs={12} sm={6}>
                <Stack direction="column">
                  <h3
                    style={{
                      fontSize: "1rem",
                      fontWeight: "550",
                      paddingTop: "10px",
                    }}
                  >
                    Year
                  </h3>
                  <TextField
                    // label="Year"
                    type="number"
                    defaultValue="2024"
                    fullWidth
                    sx={{
                      border: "1px solid black",
                      borderRadius: "4px",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        color: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                          color: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                          color: "black !important",
                        },
                      },
                      color: "black",
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <h3
                    style={{
                      fontSize: "1rem",
                      fontWeight: "550",
                      paddingTop: "10px",
                    }}
                  >
                    Month*
                  </h3>
                  <Select
                    defaultValue="September"
                    sx={{
                      border: "1px solid black",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                    }}
                  >
                    <MenuItem value="January">January</MenuItem>
                    <MenuItem value="February">February</MenuItem>
                    <MenuItem value="March">March</MenuItem>
                    <MenuItem value="April">April</MenuItem>
                    <MenuItem value="May">May</MenuItem>
                    <MenuItem value="June">June</MenuItem>
                    <MenuItem value="July">July</MenuItem>
                    <MenuItem value="August">August</MenuItem>
                    <MenuItem value="September">September</MenuItem>
                    <MenuItem value="October">October</MenuItem>
                    <MenuItem value="November">November</MenuItem>
                    <MenuItem value="December">December</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Additional Options */}
              <Grid item xs={12}>
                <FormGroup row>
                  {/* Half Day */}
                  <FormControl component="fieldset" sx={{ mr: 10 }}>
                    <FormLabel
                      component="legend"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "550",
                        paddingTop: "10px",
                        color: "#000",
                      }}
                    >
                      Half Day
                    </FormLabel>
                    <RadioGroup row defaultValue="No">
                      <FormControlLabel
                        value="Yes"
                        control={
                          <Radio
                            sx={{
                              color: "gray", // Default color
                              "&.Mui-checked": {
                                color: "#1d82f5", // Color when checked
                              },
                            }}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={
                          <Radio
                            sx={{
                              color: "gray",
                              "&.Mui-checked": {
                                color: "#1d82f5",
                              },
                            }}
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>

                  {/* Late */}
                  <FormControl component="fieldset" sx={{ mr: 10 }}>
                    <FormLabel
                      component="legend"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "550",
                        paddingTop: "10px",
                        color: "#000",
                      }}
                    >
                      Late
                    </FormLabel>
                    <RadioGroup row defaultValue="No">
                      <FormControlLabel
                        value="Yes"
                        control={
                          <Radio
                            sx={{
                              color: "gray",
                              "&.Mui-checked": {
                                color: "#1d82f5",
                              },
                            }}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={
                          <Radio
                            sx={{
                              color: "gray",
                              "&.Mui-checked": {
                                color: "#1d82f5",
                              },
                            }}
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>

                  {/* Attendance Overwrite */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: "gray",
                          "&.Mui-checked": {
                            color: "#1d82f5",
                          },
                        }}
                      />
                    }
                    label="Attendance Overwrite"
                    // sx={{ mt: 4 }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{ bgcolor: "#fff", color: "#000", padding: "0 20px 20px 0" }}
          >
            <Box className="response" onClick={handleClose}>
              Save
            </Box>
            <Box className="cancel" onClick={handleClose}>
              Cancel
            </Box>
          </DialogActions>
        </Dialog>

        {/* Import Attendance Dialog */}
        <Dialog
          open={importClicked}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Import Attendance</DialogTitle>
          <DialogContent>
            {/* Warning Alert about Date Format */}
            <Alert severity="warning" style={{ marginBottom: "20px" }}>
              Datetime format should be in <strong>Y-m-d H:i:s</strong> (e.g.
              2022-04-21 12:00:00) format. Make sure the date format is correct
              in the excel file.
            </Alert>

            {/* File Upload Field */}
            <div className="file-upload">
              <TextField
                type="file"
                inputProps={{ accept: ".xls, .xlsx, .csv" }} // File types allowed
                onChange={handleFileChange}
                label={file ? file.name : "Choose a file"}
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <CloudUpload style={{ color: "#ccc", fontSize: "30px" }} />
                  ),
                }}
              />
            </div>

            {/* Toggle Switch for Headers */}
            <FormControlLabel
              control={
                <Switch checked={hasHeaders} onChange={handleToggleChange} />
              }
              label="File Contains Headings Row"
            />
          </DialogContent>

          <DialogActions>
            {/* Action Buttons */}
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: "10px" }}
            >
              Upload
            </Button>
            <Button variant="outlined" color="default" onClick={onClose}>
              Back
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {/* </div> */}

      {/* Conditionally render Attendance Table */}
      <Box mt={1}></Box>
      {showTable && (
        <TableContainer
          component={Paper}
          sx={{
            bgcolor: "#fff",
            color: "#000",
            width: "100%",
            "&::-webkit-scrollbar": {
              width: "8px", // Set the minimum width of the scrollbar
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "gray", // Scrollbar thumb color
              borderRadius: "10px", // Rounded corners
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1", // Scrollbar track color
            },
            "scrollbar-color": "gray #f1f1f1", // For Firefox
            "scrollbar-width": "thin", // For Firefox, thin scrollbar
          }}
        >
          <Table sx={{ borderCollapse: "collapse", width: "100%" }}>
            <TableHead>
              {/* Icons and Descriptions Row */}
              <TableRow>
                <TableCell colSpan={36}>
                  <Box display="flex" alignItems="center" gap={2}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        fontSize: "1rem",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                        display: "flex",
                        alignItems: "center",
                        color: "#000",
                      }}
                    >
                      Note:
                    </Typography>
                    {/* Legend of symbols */}
                    <Box display="flex" gap={4.2}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "1rem",
                          display: "flex",
                          alignItems: "center",
                          color: "#000",

                          transition: "color 0.3s",
                          "&:hover": { color: "#ffeb3b" },
                        }}
                      >
                        <StarIcon
                          sx={{
                            fontSize: "1.5rem",
                            color: "#ffeb3b",
                            marginRight: 1,
                          }}
                        />{" "}
                        - Holiday
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "1rem",
                          display: "flex",
                          alignItems: "center",
                          color: "#000",
                          transition: "color 0.3s",
                          "&:hover": { color: "#2196f3" },
                        }}
                      >
                        <EventIcon
                          sx={{
                            fontSize: "1.5rem",
                            color: "#2196f3",
                            marginRight: 1,
                          }}
                        />{" "}
                        - Day Off
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "1rem",
                          display: "flex",
                          alignItems: "center",
                          color: "#000",
                          transition: "color 0.3s",
                          "&:hover": { color: "#4caf50" },
                        }}
                      >
                        <CheckCircleIcon
                          sx={{
                            fontSize: "1.5rem",
                            color: "#4caf50",
                            marginRight: 1,
                          }}
                        />{" "}
                        - Present
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "1rem",
                          display: "flex",
                          color: "#000",
                          alignItems: "center",
                          transition: "color 0.3s",
                          "&:hover": { color: "#ffc107" },
                        }}
                      >
                        <StarHalfIcon
                          sx={{
                            fontSize: "1.5rem",
                            color: "#ffc107",
                            marginRight: 1,
                          }}
                        />{" "}
                        - Half Day
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "1rem",
                          display: "flex",
                          alignItems: "center",
                          color: "#000",
                          transition: "color 0.3s",
                          "&:hover": { color: "#ff9800" },
                        }}
                      >
                        <WarningIcon
                          sx={{
                            fontSize: "1.5rem",
                            color: "#ff9800",
                            marginRight: 1,
                          }}
                        />{" "}
                        - Late
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "1rem",
                          display: "flex",
                          color: "#000",
                          alignItems: "center",
                          transition: "color 0.3s",
                          "&:hover": { color: "#f44336" },
                        }}
                      >
                        <CloseIcon
                          sx={{
                            fontSize: "1.5rem",
                            color: "#f44336",
                            marginRight: 1,
                          }}
                        />{" "}
                        - Absent
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "1rem",
                          display: "flex",
                          alignItems: "center",
                          color: "#000",
                          transition: "color 0.3s",
                          "&:hover": { color: "#9c27b0" },
                        }}
                      >
                        <FlightTakeoffIcon
                          sx={{
                            fontSize: "1.5rem",
                            color: "#9c27b0",
                            marginRight: 1,
                          }}
                        />{" "}
                        - On Leave
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>

              {/* Dates Row */}
              <TableRow sx={{ backgroundColor: "#f0f5ff" }}>
                <TableCell>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "0.75rem",
                      fontWeight: "bold",
                      color: "#000",
                    }}
                  >
                    Employee
                  </Typography>
                </TableCell>
                {dates.map((date) => (
                  <TableCell
                    key={date}
                    align="center"
                    sx={{
                      fontSize: "0.75rem",
                      padding: "0px",
                      fontWeight: "bold",
                      color: "#000",
                    }}
                  >
                    {date}
                  </TableCell>
                ))}
                <TableCell>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "0.75rem",
                      fontWeight: "bold",
                      color: "#000",
                    }}
                  >
                    Total
                  </Typography>
                </TableCell>
              </TableRow>

              {/* Day Names Row */}
              {showDays && (
                <TableRow sx={{ backgroundColor: "#fff" }}>
                  <TableCell />
                  {dates.map((date) => (
                    <TableCell
                      key={date}
                      align="center"
                      sx={{
                        fontSize: "0.75rem",
                        padding: "0px",
                        color: "gray",
                        fontWeight: "bold",
                      }}
                    >
                      {getDayName(date, selectedMonth, selectedYear)}{" "}
                      {/* Correct day for each date */}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              )}
            </TableHead>

            <TableBody>
              {filteredEmployees.map((employee) => (
                <TableRow
                  key={employee.name}
                  sx={{ "&:hover": { backgroundColor: "#e0f7fa" } }}
                >
                  <TableCell sx={{ fontSize: "0.75rem", padding: "7px" }}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <img
                        src={employee.img}
                        alt={`${employee.name} avatar`}
                        style={{
                          borderRadius: "50%",
                          marginRight: "4px",
                          width: "30px",
                          height: "30px",
                          border: "2px solid #1976d2",
                        }}
                      />
                      <Box display="flex" flexDirection="column">
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "0.75rem",
                            fontWeight: 600,
                            color: "#000",
                          }}
                        >
                          {employee.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "0.65rem", color: "gray" }}
                        >
                          {employee.role}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  {dates.map((date) => (
                    <TableCell
                      key={date}
                      align="center"
                      sx={{ fontSize: "0.75rem", padding: "4px" }}
                    >
                      <CloseIcon
                        sx={{
                          fontSize: "1rem",
                          color: "#f44336",
                          marginRight: 1,
                        }}
                      />{" "}
                    </TableCell>
                  ))}
                  <TableCell
                    align="center"
                    sx={{
                      fontSize: "0.75rem",
                      padding: "4px",
                      color: "gray",
                    }}
                  >
                    <Typography variant="body2">0/{dates.length}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box
        mt={1}
        sx={{
          overflowY: "auto", // Add scrollbar for vertical overflow
          scrollbarWidth: "thin", // For Firefox (makes the scrollbar thin)
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "gray",
            borderRadius: "10px",
            border: "2px solid #f1f1f1",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "darkgray",
          },
        }}
      >
        {showGrid && (
          <Box sx={{ marginTop: 0 }}>
            <Grid container spacing={2}>
              {stats.map((stat) => (
                <Grid item xs={6} sm={4} md={1.9} key={stat.title}>
                  <Box
                    sx={{
                      textAlign: "center",
                      margin: "5px 0 0 5px",

                      backgroundColor: "#fff",
                      padding: "20px",
                      borderRadius: "4px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{
                        fontWeight: "bold",
                        color: "#333",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      {stat.title === "Working Days"
                        ? "Working Days"
                        : stat.title === "Absent"
                        ? "Absent"
                        : stat.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        color: "gray",
                        display: "flex",
                        justifyContent: "right",
                      }}
                    >
                      <CalendarTodayIcon />
                    </Typography>
                    <Typography
                      variant="h6"
                      color="primary"
                      sx={{
                        fontWeight: 600,
                        color: "#1d82f5",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      {stat.title === "Working Days" || stat.title === "Absent"
                        ? workingDays
                        : stat.value}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            {/* Attendance Table */}
            <Box
              sx={{
                marginTop: 3,
                backgroundColor: "white",
                padding: 3,
                borderRadius: 2,
                boxShadow: 1,
              }}
            >
              {/* Filter Section */}
              <Grid container spacing={2} ml={3} mb={2}>
                {/* Employee Filter Dropdown */}
                <Grid item xs={12} sm={4}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="#000"
                      sx={{
                        fontSize: "1rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PersonIcon sx={{ marginRight: 0.5, fontSize: "20px" }} />{" "}
                      Employee:
                    </Typography>
                    <Select
                      variant="outlined"
                      size="small"
                      value={selectedEmployees}
                      onChange={handleEmployeesChange}
                      displayEmpty
                      sx={{
                        marginLeft: 0.5,
                        minWidth: "60px",
                        backgroundColor: "#fff",
                        color: "#000",
                      }}
                    >
                      {employees.map((employee) => (
                        <MenuItem key={employee.name} value={employee.name}>
                          {employee.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="#000"
                      sx={{
                        fontSize: "1rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <EventIcon sx={{ marginRight: 0.5, fontSize: "20px" }} />{" "}
                      Month:
                    </Typography>
                    <Select
                      variant="outlined"
                      size="small"
                      value={selectedMonth}
                      onChange={handleMonthChange}
                      sx={{
                        marginLeft: 0.5,
                        minWidth: "80px",
                        backgroundColor: "#fff",
                        color: "#000",
                      }}
                    >
                      {[
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                      ].map((month, index) => (
                        <MenuItem key={index} value={index}>
                          {month}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>

                {/* Year Selector */}
                <Grid item xs={12} sm={4}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="#000"
                      sx={{
                        fontSize: "1rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CalendarTodayIcon
                        sx={{ marginRight: 0.5, fontSize: "20px" }}
                      />{" "}
                      Year:
                    </Typography>
                    <Select
                      variant="outlined"
                      size="small"
                      value={selectedYear}
                      onChange={handleYearChange}
                      sx={{
                        marginLeft: 0.5,
                        minWidth: "60px",
                        backgroundColor: "#fff",
                        color: "#000",
                      }}
                    >
                      {[2024, 2023, 2022, 2021, 2020].map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>
              </Grid>

              {/* Attendance Table */}
              <table
                style={{
                  width: "100%",
                  textAlign: "center",
                  borderCollapse: "collapse",
                  fontFamily: "'Roboto', sans-serif",
                }}
              >
                <thead>
                  <tr>
                    {[
                      "Date",
                      "Status",
                      "Clock In",
                      "Clock Out",
                      "Total",
                      "Others",
                    ].map((header) => (
                      <th
                        key={header}
                        style={{
                          padding: "12px",
                          borderBottom: "2px solid #1976d2",
                          backgroundColor: "#f5f5f5",
                          color: "#1976d2",
                          fontWeight: "bold",
                        }}
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((day) => (
                    <tr key={day.date}>
                      <td
                        style={{
                          padding: "12px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "15px",
                            color: "#333",
                          }}
                        >
                          {String(day).padStart(2, "0")}-
                          {String(selectedMonth + 1).padStart(2, "0")}-
                          {selectedYear}
                        </div>
                        <div
                          style={{
                            fontSize: "15px",
                            color: "#888",
                          }}
                        >
                          {getDayOfWeek(day, selectedMonth, selectedYear)}
                        </div>
                      </td>

                      <td
                        style={{
                          padding: "12px",
                          borderBottom: "1px solid #ddd",
                          color: "red",
                          // fontWeight: "bold",
                        }}
                      >
                        Absent
                      </td>
                      <td
                        style={{
                          padding: "12px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        -
                      </td>
                      <td
                        style={{
                          padding: "12px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        -
                      </td>
                      <td
                        style={{
                          padding: "12px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        -
                      </td>
                      <td
                        style={{
                          padding: "12px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        -
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Attendance;
