import { React } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GroupIcon from '@mui/icons-material/Group';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import './AdminHr.css';
import HelpIcon from '@mui/icons-material/Help';
import { Placeholder } from 'react-bootstrap';
import ChecklistIcon from '@mui/icons-material/Checklist';
import GroupsIcon from '@mui/icons-material/Groups';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { LineChart } from '@mui/x-charts/LineChart';


function AdminHr() {


    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const dataset = [
        {
            london: 59,
            paris: 57,
            newYork: 86,
            seoul: 21,
            month: 'Jan',
        },
        {
            london: 50,
            paris: 52,
            newYork: 78,
            seoul: 28,
            month: 'Fev',
        },
        {
            london: 47,
            paris: 53,
            newYork: 106,
            seoul: 41,
            month: 'Mar',
        },
        {
            london: 54,
            paris: 56,
            newYork: 92,
            seoul: 73,
            month: 'Apr',
        },
        {
            london: 57,
            paris: 69,
            newYork: 92,
            seoul: 99,
            month: 'May',
        },
        {
            london: 60,
            paris: 63,
            newYork: 103,
            seoul: 144,
            month: 'June',
        },
        {
            london: 59,
            paris: 60,
            newYork: 105,
            seoul: 319,
            month: 'July',
        },
        {
            london: 65,
            paris: 60,
            newYork: 106,
            seoul: 249,
            month: 'Aug',
        },
        {
            london: 51,
            paris: 51,
            newYork: 95,
            seoul: 131,
            month: 'Sept',
        },
        {
            london: 60,
            paris: 65,
            newYork: 97,
            seoul: 55,
            month: 'Oct',
        },
        {
            london: 67,
            paris: 64,
            newYork: 76,
            seoul: 48,
            month: 'Nov',
        },
        {
            london: 61,
            paris: 70,
            newYork: 103,
            seoul: 25,
            month: 'Dec',
        },
    ];
    const valueFormatter = (value) => `${value}mm`;

    const chartSetting = {
        yAxis: [
            {
                label: '',
            },
        ],
        series: [{ dataKey: 'seoul', label: 'Seoul rainfall', valueFormatter }],
        // width: 1000,
        // height: 250,
        sx: {
            [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
                transform: 'translateX(-10px)',
            },
        },
    };

    return (
        <Box display="flex" flexDirection="column">
            <Box sx={{ flexGrow: 1 }}>

                {/* first row  */}
                <Grid container spacing={2} direction="row" style={{ marginBottom: '10px' }}>

                    {/* first box */}
                    <Grid item xs={12} sm={4}>
                        <Item elevation={3} style={{ height: '120px', backgroundColor: "#fff" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                Leaves Approved<HelpIcon />
                            </Typography>
                            <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                <GroupIcon></GroupIcon>
                            </Typography>
                            <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                4
                            </Typography>
                        </Item>
                    </Grid>

                    {/* secpnd box */}
                    <Grid item xs={12} sm={4}>
                        <Item elevation={3} style={{ height: '120px', backgroundColor: "#fff" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                Employees
                            </Typography>
                            <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                <GroupIcon></GroupIcon>
                            </Typography>
                            <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                42
                            </Typography>
                        </Item>
                    </Grid>
                    {/* third box */}
                    <Grid item xs={12} sm={4}>
                        <Item elevation={3} style={{ height: '120px', backgroundColor: "#fff" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                Employee Exits<HelpIcon />
                            </Typography>
                            <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                <LocalActivityIcon></LocalActivityIcon>
                            </Typography>
                            <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                275
                            </Typography>
                        </Item>
                    </Grid>


                </Grid>

                {/* second row  */}
                <Grid container spacing={2} direction="row" style={{ marginBottom: '10px' }}>

                    {/* first box */}
                    <Grid item xs={12} sm={4}>
                        <Item elevation={3} style={{ height: '120px', backgroundColor: "#fff" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                Today Attendance
                            </Typography>
                            <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                <LocalActivityIcon></LocalActivityIcon>
                            </Typography>
                            <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                275
                            </Typography>
                        </Item>
                    </Grid>

                    {/* secpnd box */}
                    <Grid item xs={12} sm={4}>
                        <Item elevation={3} style={{ height: '120px', backgroundColor: "#fff" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                Average Attendance
                            </Typography>
                            <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                <LocalActivityIcon></LocalActivityIcon>
                            </Typography>
                            <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                140
                            </Typography>
                        </Item>
                    </Grid>


                </Grid>


                <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>

                    <Grid item xs={12} sm={6}>

                        <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                Department Wise Employee <HelpIcon></HelpIcon>
                            </Typography>
                            <PieChart
                                series={[
                                    {
                                        data: [
                                            { id: 0, value: 10, label: 'series A' },
                                            { id: 1, value: 15, label: 'series B' },
                                            { id: 2, value: 20, label: 'series C' },
                                        ],
                                    },
                                ]}
                                maxwidth={500}
                                height={200}
                                sx={{
                                    fill: 'black',
                                    fontWeight: 'bold',
                                    '& text': {
                                        fill: 'black !important',
                                        fontWeight: 'bold',
                                    },
                                    '& text > label': {
                                        fill: 'black !important',
                                        fontWeight: 'bold',
                                    },
                                }}


                            />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff", width: '100%' }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                Designation Wise Employee<HelpIcon></HelpIcon>
                            </Typography>
                            <br></br>
                            <PieChart
                                series={[
                                    {

                                        data: [
                                            { id: 0, value: 10, label: 'CEO', color: 'orange' },
                                            { id: 1, value: 15, label: 'BD Intern', color: 'aqua' },
                                            { id: 2, value: 0, label: 'BD Associate' },
                                            { id: 3, value: 0, label: 'BD Executive' },
                                            { id: 4, value: 10, label: 'BD AIl', color: 'green' },
                                            { id: 5, value: 0, label: 'BD Team leads' },
                                            { id: 6, value: 10, label: 'BD Assistance Manager' },
                                            { id: 7, value: 0, label: 'BD Manager' },
                                            { id: 8, value: 10, label: 'BD Senior Manager' },
                                            { id: 9, value: 10, label: 'Hr Intern' },
                                            { id: 10, value: 0, label: 'Operations Listed' }
                                        ],
                                    },
                                ]}
                                maxwidth={'500'}
                                //width={800}
                                height={200}
                                slotProps={{
                                    legend: { hidden: false },
                                  }}
                                sx={{
                                    fill: 'black',
                                    fontWeight: 'bold',
                                    fontSize:'auto',
                                    '& text': {
                                        fill: 'black !important',
                                        fontWeight: 'bold',
                                    },
                                    // '& text > label': {
                                    //     fill: 'black !important',
                                    //     fontWeight: 'bold',
                                    // },
                                }}


                            />
                        </Item>
                    </Grid>
                </Grid>

                <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                    <Grid item xs={12} sm={6}>
                        <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff", width: '100%' }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                Gender Wise Employee<HelpIcon></HelpIcon>
                            </Typography>
                            <br></br>
                            <PieChart
                                series={[
                                    {

                                        data: [
                                            { id: 0, value: 10, label: 'Male', color: 'blue' },
                                            { id: 1, value: 35, label: 'Female', color: 'pink' }
                                        ],
                                    },
                                ]}
                                maxwidth={500}
                                height={200}
                                sx={{
                                    fill: 'black',
                                    fontWeight: 'bold',
                                    '& text': {
                                        fill: 'black !important',
                                        fontWeight: 'bold',
                                    },
                                    '& text > label': {
                                        fill: 'black !important',
                                        fontWeight: 'bold',
                                    },
                                }}


                            />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff", width: '100%' }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                Role Wise Employee<HelpIcon></HelpIcon>
                            </Typography>
                            <br></br>
                            <PieChart
                                series={[
                                    {

                                        data: [
                                            { id: 0, value: 10, label: 'Admin', color: '#D20062' },
                                            { id: 1, value: 15, label: 'Employee', color: '#D6589F' },
                                            { id: 2, value: 0, label: 'Hr', color: '#D895DA' },
                                            { id: 3, value: 0, label: 'BDA', color: '#C4E4FF' },
                                            { id: 7, value: 0, label: 'Manager', color: '#FB9AD1' },
                                            { id: 8, value: 10, label: 'Support', color: '#FFCDEA' }
                                        ],
                                    },
                                ]}
                                maxwidth={500}
                                height={200}
                                sx={{
                                    fill: 'black',
                                    fontWeight: 'bold',
                                    '& text': {
                                        fill: 'black !important',
                                        fontWeight: 'bold',
                                    },
                                    '& text > label': {
                                        fill: 'black !important',
                                        fontWeight: 'bold',
                                    },
                                }}


                            />
                        </Item>
                    </Grid>



                </Grid>

                <Grid style={{ marginBottom: '10px' }}>
                <Grid >
                <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff", width: '100%' }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                             Headcout<HelpIcon></HelpIcon>
                            </Typography>
                            <br></br>
                            <BarChart
                                height={200}
                                maxwidth={900}
                                sx={{
                                    fill: 'black !important',
                                    fontWeight: 'bold',
                                    '& text': {
                                        fill: 'black !important',
                                        fontWeight: 'bold',
                                    },
                                    // '& text > label': {
                                    //     fill: 'black !important',
                                    //     fontWeight: 'bold',
                                    // },
                                }}
                                dataset={dataset}
                                xAxis={[
                                    { scaleType: 'band', dataKey: 'month' },
                                ]}
                                {...chartSetting}

                            />
                </Item>
                /</Grid>
                </Grid>

                <Grid style={{ marginBottom: '10px' }}>
                    <Grid >
                        <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff", width: '100%' }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                Joining vs Attrition<HelpIcon></HelpIcon>
                            </Typography>
                            <br></br>
                            <LineChart
                                // width={1000}
                                height={200}
                                series={[
                                    { curve: "linear", data: [0, 5, 2, 6, 3, 9.3] }

                                ]}
                            />
                        </Item>
                    </Grid>

                </Grid>

                <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
                    <Grid item xs={12} sm={6}>
                        <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                Leaves Taken<HelpIcon></HelpIcon>
                            </Typography>
                            <br></br>
                            <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                                <ChecklistIcon></ChecklistIcon>
                            </Typography>
                            <Typography variant="body2" style={{ fontSize: "16px", color: "#4d4f5c" }}>
                                <Placeholder>-Not Enough Data-</Placeholder>
                            </Typography>
                        </Item>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                Birthdays<HelpIcon></HelpIcon>
                            </Typography>
                            <br></br>
                            <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                                <GroupsIcon></GroupsIcon>
                            </Typography>
                            <Typography variant="body2" style={{ fontSize: "16px", color: "#4d4f5c" }}>
                                <Placeholder>-Not Enough Data-</Placeholder>
                            </Typography>
                        </Item>
                    </Grid>

                </Grid>

            </Box>
        </Box>
    )
}

export default AdminHr;