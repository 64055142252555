import React from 'react';
import SettingPageLayout from './SettingPageLayout';
import FinanceSettings from './FinanceSettings/financesettings'; // Correcting the import statement

function MainFinanceSettings() {
  return (
    <SettingPageLayout>
      <FinanceSettings /> 
    </SettingPageLayout>
  );
}

export default MainFinanceSettings;

