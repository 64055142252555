import React from "react";
import ProfileTabs from "./ProfileTabs";

function ProfileSettings({ isDown }) {
  return (
    <div>
      <ProfileTabs isDown={isDown} />
    </div>
  );
}

export default ProfileSettings;
