import React, { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import BusinessIcon from "@mui/icons-material/Business";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PaymentsIcon from '@mui/icons-material/Payments';
import GavelIcon from "@mui/icons-material/Gavel";
import AddCardIcon from "@mui/icons-material/AddCard";
import BugReportIcon from "@mui/icons-material/BugReport";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import InventoryIcon from "@mui/icons-material/Inventory";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import WorkIcon from "@mui/icons-material/Work";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";

function SettingsSidebar({ showSettingSidebar, isDown, onClose }) {
  const [selected, setSelected] = useState("");

  const handleMenuItemClick = (itemName) => {
    setSelected(itemName);
    // if (isCollapsed) {
    //   setIsCollapsed(false); // Close the sidebar if it's collapsed
    // }
    onClose && onClose();
  };

  return (
    <Box>
      <Sidebar
        // collapsed={isCollapsed}
        // onMouseEnter={() => !isDown && setIsCollapsed(false)}
        // onMouseLeave={() => !isDown && setIsCollapsed(true)}
        style={{
          zIndex: 1000,

          height: "100vh",
        }}
      >
        <Menu>
          <MenuItem>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: "700",
                  color: "#141b2d",
                }}
              >
                Admin Settings
              </Typography>
            </Box>
          </MenuItem>

          <Box>
            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "Company_settings" ? "white" : "black",
                backgroundColor:
                  selected === "Company_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/settings" />}
              onClick={() => handleMenuItemClick("Company_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <ApartmentIcon style={{ marginRight: "15px" }} />
                Company Settings
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "App_settings" ? "white" : "black",
                backgroundColor:
                  selected === "App_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/App_settings" />}
              onClick={() => handleMenuItemClick("App_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <AppSettingsAltIcon style={{ marginRight: "1rem" }} />
                App Settings
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "Business_settings" ? "white" : "black",
                backgroundColor:
                  selected === "Business_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/Business_settings" />}
              onClick={() => handleMenuItemClick("Business_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <BusinessIcon style={{ marginRight: "15px" }} />
                Business Settings
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "Profile_settings" ? "white" : "black",
                backgroundColor:
                  selected === "Profile_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/Profile_settings" />}
              onClick={() => handleMenuItemClick("Profile_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <AssignmentIndIcon style={{ marginRight: "15px" }} />
                Profile Settings
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "Leads_settings" ? "white" : "black",
                backgroundColor:
                  selected === "Leads_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/Leads_settings" />}
              onClick={() => handleMenuItemClick("Leads_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <GavelIcon style={{ marginRight: "15px" }} />
                Leads Settings
              </div>
            </MenuItem>

            <hr></hr>

                  <MenuItem
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: selected === "finance_settings" ? "white" : "black",
                      backgroundColor:
                        selected === "finance_settings" ? "#C0B476" : "transparent",
                    }}
                    component={<Link to="/finance_settings" />}
                    onClick={() => handleMenuItemClick("finance_settings")}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <PaymentsIcon style={{ marginRight: "15px" }} />
                      Finance Settings
                    </div>
                  </MenuItem>


            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "Tax_settings" ? "white" : "black",
                backgroundColor:
                  selected === "Tax_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/Tax_settings" />}
              onClick={() => handleMenuItemClick("Tax_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <AddCardIcon style={{ marginRight: "15px" }} />
                Tax Settings
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "Ticket_settings" ? "white" : "black",
                backgroundColor:
                  selected === "Ticket_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/Ticket_settings" />}
              onClick={() => handleMenuItemClick("Ticket_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <BugReportIcon style={{ marginRight: "15px" }} />
                Ticket Settings
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "Project_settings" ? "white" : "black",
                backgroundColor:
                  selected === "Project_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/Project_settings" />}
              onClick={() => handleMenuItemClick("Project_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <AccountTreeIcon style={{ marginRight: "15px" }} />
                Project Settings
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "rp_settings" ? "white" : "black",
                backgroundColor:
                  selected === "rp_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/rp_settings" />}
              onClick={() => handleMenuItemClick("rp_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <PermIdentityIcon style={{ marginRight: "15px" }} />
                Roles and Permissions
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "Attendance_settings" ? "white" : "black",
                backgroundColor:
                  selected === "Attendance_settings"
                    ? "#C0B476"
                    : "transparent",
              }}
              component={<Link to="/Attendance_settings" />}
              onClick={() => handleMenuItemClick("Attendance_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <EmojiPeopleIcon style={{ marginRight: "15px" }} />
                Attendance Settings
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "Payroll_settings" ? "white" : "black",
                backgroundColor:
                  selected === "Payroll_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/Payroll_settings" />}
              onClick={() => handleMenuItemClick("Payroll_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <PointOfSaleIcon style={{ marginRight: "15px" }} />
                Payroll Settings
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selected === "Leave_settings" ? "white" : "black",
                backgroundColor:
                  selected === "Leave_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/Leave_settings" />}
              onClick={() => handleMenuItemClick("Leave_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <ExitToAppIcon style={{ marginRight: "15px" }} />
                Leave Settings
              </div>
            </MenuItem>

            <hr></hr>
            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "Notification_settings" ? "white" : "black",
                backgroundColor:
                  selected === "Notification_settings"
                    ? "#C0B476"
                    : "transparent",
              }}
              component={<Link to="/Notification_settings" />}
              onClick={() => handleMenuItemClick("Notification_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <NotificationsNoneIcon style={{ marginRight: "15px" }} />
                Notification Settings
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "time_log_settings" ? "white" : "black",
                backgroundColor:
                  selected === "time_log_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/time_log_settings" />}
              onClick={() => handleMenuItemClick("time_log_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <AccessTimeIcon style={{ marginRight: "15px" }} />
                Time Log Settings
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "Task_settings" ? "white" : "black",
                backgroundColor:
                  selected === "Task_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/Task_settings" />}
              onClick={() => handleMenuItemClick("Task_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <TaskAltIcon style={{ marginRight: "15px" }} />
                Task Settings
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "security_settings" ? "white" : "black",
                backgroundColor:
                  selected === "security_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/security_settings" />}
              onClick={() => handleMenuItemClick("security_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <AddModeratorIcon style={{ marginRight: "15px" }} />
                Security Settings
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "purchase_settings" ? "white" : "black",
                backgroundColor:
                  selected === "purchase_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/purchase_settings" />}
              onClick={() => handleMenuItemClick("purchase_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <InventoryIcon style={{ marginRight: "15px" }} />
                Purchase Settings
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "assets_settings" ? "white" : "black",
                backgroundColor:
                  selected === "assets_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/assets_settings" />}
              onClick={() => handleMenuItemClick("assets_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <WebAssetIcon style={{ marginRight: "15px" }} />
                Assets Settings
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "recruit_settings" ? "white" : "black",
                backgroundColor:
                  selected === "recruit_settings" ? "#C0B476" : "transparent",
              }}
              component={<Link to="/recruit_settings" />}
              onClick={() => handleMenuItemClick("recruit_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <WorkIcon style={{ marginRight: "15px" }} />
                Recruit Settings
              </div>
            </MenuItem>

            <hr></hr>

            <MenuItem
              style={{
                display: "flex",
                alignItems: "center",
                color: selected === "subscriptoin_settings" ? "white" : "black",
                backgroundColor:
                  selected === "subscriptoin_settings"
                    ? "#C0B476"
                    : "transparent",
              }}
              component={<Link to="/subscriptoin_settings" />}
              onClick={() => handleMenuItemClick("subscriptoin_settings")}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <MeetingRoomIcon style={{ marginRight: "15px" }} />
                Subscriptoin Settings
              </div>
            </MenuItem>

            <hr style={{ marginBottom: "50px" }}></hr>
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
}

export default SettingsSidebar;
