import React, { useState } from "react";
import "./leaves.css";

const ViewMoreLeaves = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleEllipsisHover = () => {
    setShowDropdown(true);
  };

  const handleEllipsisLeave = () => {
    setShowDropdown(false);
  };

  return (
    <div className="leave-details">
      <div className="leave-header">
        <div className="header-left">
          <h2>Leave Details</h2>
          <p className="apply-date">Apply Date - 28-05-2024 11:22 AM</p>
        </div>
        <div
          className="ellipsis-container"
          onMouseEnter={handleEllipsisHover}
          onMouseLeave={handleEllipsisLeave}
        >
          <span className="ellipsis">...</span>
          {showDropdown && (
            <div className="dropdown-menu">
              <button className="dropdown-item">Approve</button>
              <button className="dropdown-item">Reject</button>
              <button className="dropdown-item">Edit</button>
              <button className="dropdown-item">Delete</button>
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="leave-details-section">
        <div className="leave-row">
          <span className="leave-label">Applicant Name</span>
          <span className="leave-value">
            <img
              src="src\scenes\HR\Leaves\person.jpeg"
              className="file-icon"
              alt="Profile"
            />
            Demarco O'Kon
            <br />
            Project Manager
          </span>
        </div>
        <div className="leave-row">
          <span className="leave-label">Leave Date</span>
          <span className="leave-value">11-04-2024</span>
        </div>
        <div className="leave-row">
          <span className="leave-label">Leave Type</span>
          <span className="leave-value earned">Earned</span>
        </div>
        <div className="leave-row">
          <span className="leave-label">Paid</span>
          <span className="leave-value">Yes</span>
        </div>
        <div className="leave-row">
          <span className="leave-label">Reason For Absence</span>
          <span className="leave-value">
            But she did not quite like the look of it in a languid, sleepy
            voice...
          </span>
        </div>
        <div className="leave-row">
          <span className="leave-label">Status</span>
          <span className="leave-value pending">
            <span className="status-dot"></span> Pending
          </span>
        </div>
        <div className="leave-row">
          <span className="leave-label">Files</span>
          <span className="leave-value">
            <img
              src="https://via.placeholder.com/50"
              alt="No file uploaded"
              className="file-icon"
            />
            - No file uploaded. -
          </span>
        </div>
      </div>
    </div>
  );
};

export default ViewMoreLeaves;
