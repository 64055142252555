import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
const ResponsiveLabel = styled("label")({
  fontSize: "16px",
  "@media (max-width: 600px)": {
    fontSize: "14px",
  },
});

const FormContainer = styled("div")({
  padding: "20px",
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  marginTop: "10px",
});

const FlexContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "15px",
  "@media (max-width: 600px)": {
    flexDirection: "column",
  },
});

const FlexItem = styled("div")({
  flex: 1,
  marginRight: "10px",
  "&:last-child": {
    marginRight: "0",
  },
  "@media (max-width: 600px)": {
    marginRight: "0",
    marginBottom: "10px",
  },
});

const NoUnderlineTextField = styled(TextField)(() => ({
  "& .MuiInputBase-root": {
    border: "none",
    boxShadow: "none",
    height: "70px",
    padding: "5px",
    color: "#000",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& label": {
    color: "#000",
  },
  "& input": {
    padding: "5px",
    height: "40px",
    boxSizing: "border-box",
  },
}));

const NoUnderlineSelect = styled(Select)(() => ({
  "& .MuiInputBase-root": {
    border: "none",
    boxShadow: "none",
    height: "50px",
    padding: "10px",
    backgroundColor: "white",
    color: "#000",
  },
  "& .MuiSelect-select": {
    padding: "10px",
  },
}));

function AddNewLeaveType({ onSubmit, initialData, onCancel }) {
  const [leaveType, setLeaveType] = useState(initialData?.type || "");
  const [allotmentType, setAllotmentType] = useState(
    initialData?.allotment || ""
  );
  const [noOfLeaves, setNoOfLeaves] = useState(initialData?.leaves || "");
  const [monthlyLimit, setMonthlyLimit] = useState(initialData?.limit || "");
  const [paidStatus, setPaidStatus] = useState(initialData?.paidStatus || "");
  const [colorCode, setColorCode] = useState(
    initialData?.colorCode || "#00FF00"
  );
  const [userRoles, setUserRoles] = useState([]);
  const [designations, setDesignations] = useState([]);

  // Empty handleSave to make Save button unfunctional
  const handleSave = () => {
    // Save button does nothing
  };

  return (
    <FormContainer>
      <h4>{initialData ? "Edit Leave Type" : "Add New Leave Type"}</h4>
      <hr />
      <FlexContainer>
        <FlexItem>
          <label>Leave Type</label>
          <NoUnderlineTextField
            fullWidth
            value={leaveType}
            onChange={(e) => setLeaveType(e.target.value)}
          />
        </FlexItem>
        <FlexItem>
          <label>Leave Allotment Type</label>
          <NoUnderlineSelect
            fullWidth
            value={allotmentType}
            onChange={(e) => setAllotmentType(e.target.value)}
            style={{ marginTop: "10px" }}
          >
            <MenuItem value="Yearly">Yearly</MenuItem>
            <MenuItem value="Monthly">Monthly</MenuItem>
          </NoUnderlineSelect>
        </FlexItem>
      </FlexContainer>
      <FlexContainer>
        <FlexItem>
          <label>No. of Leaves</label>
          <NoUnderlineTextField
            fullWidth
            value={noOfLeaves}
            onChange={(e) => setNoOfLeaves(e.target.value)}
          />
        </FlexItem>
        <FlexItem>
          <label>Monthly Limit</label>
          <NoUnderlineTextField
            fullWidth
            value={monthlyLimit}
            onChange={(e) => setMonthlyLimit(e.target.value)}
          />
        </FlexItem>
      </FlexContainer>
      <FlexContainer style={{ display: "flex", gap: "10px" }}>
        <FlexItem>
          <label>Paid Status</label>
          <NoUnderlineSelect
            fullWidth
            value={paidStatus}
            onChange={(e) => setPaidStatus(e.target.value)}
          >
            <MenuItem value="Paid">Paid</MenuItem>
            <MenuItem value="Unpaid">Unpaid</MenuItem>
          </NoUnderlineSelect>
        </FlexItem>
        <FlexItem>
          <label>Color Code:</label>
          <input
            style={{
              height: "40px",
              width: "100%",
              border: "none",
              padding: "0",
            }}
            type="color"
            value={colorCode}
            onChange={(e) => setColorCode(e.target.value)}
          />
        </FlexItem>
      </FlexContainer>

      <FlexContainer style={{ display: "flex", gap: "10px" }}>
        <FlexItem>
          <label>Department</label>
          <NoUnderlineSelect
            fullWidth
            multiple
            value={userRoles}
            onChange={(e) => setUserRoles(e.target.value)}
            renderValue={(selected) => (
              <div>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </div>
            )}
          >
            <MenuItem value="Marketing">Marketing</MenuItem>
            <MenuItem value="Sales">Sales</MenuItem>
            <MenuItem value="Human">Human Resources</MenuItem>
            <MenuItem value="HR">HR</MenuItem>
            <MenuItem value="Research">Research</MenuItem>
            <MenuItem value="Finance">Finance</MenuItem>
          </NoUnderlineSelect>
        </FlexItem>

        <FlexItem>
          <label>Designations</label>
          <NoUnderlineSelect
            fullWidth
            multiple
            value={designations}
            onChange={(e) => setDesignations(e.target.value)}
            renderValue={(selected) => (
              <div>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </div>
            )}
          >
            <MenuItem value="Senior">Senior</MenuItem>
            <MenuItem value="Junior">Junior</MenuItem>
            <MenuItem value="Project Manager">Project Manager</MenuItem>
            <MenuItem value="Senior">Team Lead</MenuItem>
            <MenuItem value="Senior">Trainee</MenuItem>
          </NoUnderlineSelect>
        </FlexItem>
      </FlexContainer>

      <div style={{ display: "flex", gap: "10px", marginTop: "15px" }}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
        <Button variant="contained" color="primary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </FormContainer>
  );
}

export default AddNewLeaveType;
