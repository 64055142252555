import {
  Box,
  Button,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import React, { useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  HighlightOff,
  EventNote,
  Reply,
  DeleteOutline,
  FiberManualRecord,
  MoreHoriz,
  KeyboardBackspace,
} from "@mui/icons-material";
import styles from "./ViewMoreTickets.module.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DetailsTicket from "./DetailsTicket";
import ActivityTicket from "./ActivityTicket";
import ContactTicket from "./ContactTicket";
import ContactActivity from "./ContactActivity";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";

const data = [
  {
    img: "https://i.pravatar.cc/300?u=nicolas.emily@example.net1",
    name: "Reyna Bayer",
    date: "28-05-2024 11:22 am",
    text: "Some of the what?' said the queen never said.",
  },
  {
    img: "https://i.pravatar.cc/300?u=keeling.halle@example.net5",
    name: "Woodrow Gerhold",
    date: "28-05-2024 11:22 am",
    text: "Alice as he came, 'Oh! the Duchess, it had some.",
  },
  {
    img: "https://i.pravatar.cc/300?u=admin@example.com",
    name: "Sadie Sauer",
    date: "28-05-2024 11:22 am",
    text: "Alice, timidly; 'some of the Nile On every.",
  },
  {
    img: "https://i.pravatar.cc/300?u=nicolas.emily@example.net1",
    name: "Reyna Bayer",
    date: "28-05-2024 11:22 am",
    text: "Some of the what?' said the queen never said.",
  },
  {
    img: "https://i.pravatar.cc/300?u=nicolas.emily@example.net1",
    name: "Reyna Bayer",
    date: "28-05-2024 11:22 am",
    text: "Some of the what?' said the queen never said.",
  },
  {
    img: "https://i.pravatar.cc/300?u=keeling.halle@example.net5",
    name: "Woodrow Gerhold",
    date: "28-05-2024 11:22 am",
    text: "Alice as he came, 'Oh! the Duchess, it had some.",
  },
  {
    img: "https://i.pravatar.cc/300?u=admin@example.com",
    name: "Sadie Sauer",
    date: "28-05-2024 11:22 am",
    text: "Alice, timidly; 'some of the Nile On every.",
  },
  {
    img: "https://i.pravatar.cc/300?u=nicolas.emily@example.net1",
    name: "Reyna Bayer",
    date: "28-05-2024 11:22 am",
    text: "Some of the what?' said the queen never said.",
  },
];
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "#C0B476",
    "&.Mui-selected": {
      color: "#000000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "red",
    },
  })
);
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ViewMoreTickets() {
  const [age, setAge] = React.useState("");

  const handleChangeNew = (event) => {
    setAge(event.target.value);
  };
  const [value, setValue] = React.useState(0);
  const [deleteIcon, setDeleteIcon] = useState(null);
  const [viewReply, setViewReply] = useState(false);
  const [viewNote, setViewNote] = useState(false);
  const [renderFile, setRenderFile] = useState(false);
  const [renderFileNote, setRenderFileNote] = useState(false);
  const [name, setName] = useState(null);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl1, setAnchorEl1] = useState(null);

  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  const users = ["salman", "lokesh", "ashu", "neelam"];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleReply = () => {
    setViewReply(!viewReply);
  };

  const handleNote = () => {
    setViewNote(!viewNote);
  };
  const handleRenderFile = () => {
    setRenderFile(!renderFile);
  };
  const handleRenderFileNote = () => {
    setRenderFileNote(!renderFileNote);
  };
  const handleCancel = () => {
    setViewReply(false);
  };
  const handleCancelNote = () => {
    setViewNote(false);
  };
  const handleBack = () => {
    navigate("/tickets");
  };
  return (
    <Box className={styles.grid}>
      <Box className={styles.top}>
        <Box className={styles.box} onClick={handleReply}>
          {" "}
          <Reply />
          &nbsp; Reply
        </Box>
        <Box className={styles.box} onClick={handleNote}>
          {" "}
          <EventNote />
          &nbsp; Add Note
        </Box>
        <Box className={styles.box}>
          <HighlightOff />
          &nbsp; Close
        </Box>
        <Box className={styles.box}>
          <DeleteOutline />
          &nbsp; Delete
        </Box>
      </Box>
      <Box className={styles.mainarea}>
        <Box className={styles.content}>
          <Box className={styles.topinfo}>
            <Box sx={{ color: "#000" }}>
              <h5>Lorem, ipsum dolor.</h5>
              <p>Requested on 24-01-2024 10:57 am</p>
            </Box>

            <p
              style={{
                color: "#000",
                display: "flex",
                alignItems: "center",
                fontSize: "18px",
              }}
            >
              <FiberManualRecord fontSize="small" sx={{ color: "red" }} />{" "}
              &nbsp; Open
            </p>
          </Box>
          <Box className={styles.main}>
            {data.map((item, index) => (
              <Box className={styles.frame}>
                <Box className={styles.row}>
                  <figure>
                    <img src={item.img} alt="customerImage" />
                  </figure>
                  <Box>
                    <p>
                      <strong>{item.name}</strong> {item.date}
                    </p>
                    <p>{item.text}</p>
                  </Box>
                </Box>
                <Box className={styles.threeDot}>
                  <Button
                    id="basic-button"
                    aria-controls={deleteIcon ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={deleteIcon ? "true" : undefined}
                    sx={{ marginRight: "40px" }}
                    onClick={() =>
                      setDeleteIcon((prevIndex) =>
                        prevIndex === index ? null : index
                      )
                    }
                  >
                    <MoreHoriz />
                  </Button>

                  {deleteIcon === index && (
                    <Button
                      variant="outlined"
                      sx={{
                        background: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "30px",
                        width: "100px",
                      }}
                    >
                      <DeleteOutline />
                      Delete
                    </Button>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
          <Box className={styles.footer}>
            {viewReply && (
              <Box className={styles.replyButton}>
                <p className={styles.titleBottom}>To:{"Woodrow Gerhold"}</p>

                <Grid container sx={{ paddingLeft: "20px" }}>
                  <Stack direction="column" sx={{ width: "95%" }}>
                    <ReactQuill
                      // value={description}
                      // onChange={setDescription}
                      modules={modules}
                      theme="snow"
                      style={{
                        width: "100%",

                        boxSizing: "border-box",
                        color: "black",
                        borderBlockColor: "black",
                      }}
                    />
                  </Stack>
                </Grid>
                {renderFile ? (
                  <Grid
                    container
                    sx={{ paddingLeft: "20px", marginTop: "10px" }}
                  >
                    <Stack direction="column" sx={{ width: "95%" }}>
                      <br />
                      <br />
                      <br />
                      <br />

                      <label
                        htmlFor="file-upload"
                        style={{
                          cursor: "pointer",
                          color: "black",
                          border: "1px solid #d8d6d6",
                          borderRadius: "4px",
                          padding: "10px",
                          width: "100%",
                          boxSizing: "border-box",
                          minHeight: "100px", // Set your desired minimum height
                          backgroundColor: "#fff", // Change background color to light gray
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Choose a File
                      </label>
                      <input
                        // onChange={handleFileChange}
                        type="file"
                        id="file-upload"
                        style={{ display: "none" }}
                      />
                      {/* {uploadFile && (
                    <div style={{ color: "black" }}>{uploadFile.name}</div>
                  )} */}
                    </Stack>
                  </Grid>
                ) : (
                  <Box
                    onClick={handleRenderFile}
                    sx={{
                      cursor: "pointer",
                      color: "#1d82f5",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 550,
                      paddingLeft: "20px",
                      marginTop: "80px",
                    }}
                  >
                    <AttachFileIcon fontSize="small" />
                    Upload File
                  </Box>
                )}

                <Box className={styles.footReply}>
                  <Box className={styles.responseReply}>
                    <div>
                      <Box
                        id="basic-button"
                        aria-controls={open1 ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open1 ? "true" : undefined}
                        onClick={handleClick1}
                      >
                        Submit
                      </Box>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl1}
                        open={open1}
                        onClose={handleClose1}
                        sx={{ zIndex: 3000 }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          value="open"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <FiberManualRecordIcon
                            fontSize="small"
                            sx={{ color: "red" }}
                          />{" "}
                          &nbsp; Submit as Open
                        </MenuItem>
                        <MenuItem
                          value="pending"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <FiberManualRecordIcon
                            fontSize="small"
                            sx={{ color: "yellow" }}
                          />{" "}
                          &nbsp; Submit as Pending
                        </MenuItem>
                        <MenuItem
                          value="resolved"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <FiberManualRecordIcon
                            color="success"
                            fontSize="small"
                          />{" "}
                          &nbsp; Submit as Resolved
                        </MenuItem>
                        <MenuItem
                          value="closed"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FiberManualRecordIcon
                            fontSize="small"
                            color="primary"
                          />{" "}
                          &nbsp; Submit as Closed
                        </MenuItem>
                      </Menu>
                    </div>
                  </Box>
                  <Box className={styles.cancelReply} onClick={handleCancel}>
                    Cancel
                  </Box>
                </Box>
              </Box>
            )}
            {viewNote && (
              <Box className={styles.replyButton}>
                <p className={styles.titleBottom}>
                  <Grid
                    item
                    xs={8}
                    sm={3.5}
                    md={3.5}
                    sx={{ zIndex: 5000, display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      To:
                    </Typography>

                    <Grid
                      container
                      spacing={2}
                      sx={{ zIndex: 5000, marginLeft: "15px" }}
                    >
                      <Grid item xs={10} sm={10} md={10} sx={{ zIndex: 5000 }}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          sx={{ mt: 2, zIndex: 5000 }}
                        >
                          <Select
                            labelId="department-select-label"
                            id="department-select"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            displayEmpty
                            sx={{
                              zIndex: 5000,
                              height: "42px",
                              border: "1px solid black",
                              boxSizing: "border-box",
                              "& .MuiOutlinedInput-root": {
                                borderColor: "black !important",
                                "&:hover fieldset": {
                                  borderColor: "black !important",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black !important",
                                },
                              },
                              color: "black",
                            }}
                          >
                            <MenuItem disabled value="">
                              <em>---</em>
                            </MenuItem>
                            <MenuItem value="driver">driver</MenuItem>
                            {users.map((user) => (
                              <MenuItem key={user} value={user}>
                                {user}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </p>

                <Grid container sx={{ paddingLeft: "20px" }}>
                  <Stack direction="column" sx={{ width: "95%" }}>
                    <ReactQuill
                      // value={description}
                      // onChange={setDescription}
                      modules={modules}
                      theme="snow"
                      style={{
                        width: "100%",

                        boxSizing: "border-box",
                        color: "black",
                        borderBlockColor: "black",
                      }}
                    />
                  </Stack>
                </Grid>

                {renderFileNote ? (
                  <Grid
                    container
                    sx={{ paddingLeft: "20px", marginTop: "10px" }}
                  >
                    <Stack direction="column" sx={{ width: "95%" }}>
                      <br />
                      <br />
                      <br />
                      <br />

                      <label
                        htmlFor="file-upload"
                        style={{
                          cursor: "pointer",
                          color: "black",
                          border: "1px solid #d8d6d6",
                          borderRadius: "4px",
                          padding: "10px",
                          width: "100%",
                          boxSizing: "border-box",
                          minHeight: "100px", // Set your desired minimum height
                          backgroundColor: "#fff", // Change background color to light gray
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Choose a File
                      </label>
                      <input
                        // onChange={handleFileChange}
                        type="file"
                        id="file-upload"
                        style={{ display: "none" }}
                      />
                      {/* {uploadFile && (
                    <div style={{ color: "black" }}>{uploadFile.name}</div>
                  )} */}
                    </Stack>
                  </Grid>
                ) : (
                  <Box
                    onClick={handleRenderFileNote}
                    sx={{
                      cursor: "pointer",
                      color: "#1d82f5",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 550,
                      paddingLeft: "20px",
                      marginTop: "80px",
                    }}
                  >
                    <AttachFileIcon fontSize="small" />
                    Upload File
                  </Box>
                )}

                <Box className={styles.footReply}>
                  <Box className={styles.responseAddNote}>
                    <div>
                      <Box
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        Add Note
                      </Box>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        sx={{ zIndex: 3000 }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          value="open"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <FiberManualRecordIcon
                            fontSize="small"
                            sx={{ color: "red" }}
                          />{" "}
                          &nbsp; Add Note and Submit as Open
                        </MenuItem>
                        <MenuItem
                          value="pending"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <FiberManualRecordIcon
                            fontSize="small"
                            sx={{ color: "yellow" }}
                          />{" "}
                          &nbsp; Add Note and Submit as Pending
                        </MenuItem>
                        <MenuItem
                          value="resolved"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <FiberManualRecordIcon
                            color="success"
                            fontSize="small"
                          />{" "}
                          &nbsp;Add Note and Submit as Resolved
                        </MenuItem>
                        <MenuItem
                          value="closed"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FiberManualRecordIcon
                            fontSize="small"
                            color="primary"
                          />{" "}
                          &nbsp; Add Note and Submit as Closed
                        </MenuItem>
                      </Menu>
                    </div>
                  </Box>
                  <Box
                    className={styles.cancelReply}
                    onClick={handleCancelNote}
                  >
                    Cancel
                  </Box>
                </Box>
              </Box>
            )}
            <Box className={styles.foot}>
              <Box className={styles.response} onClick={handleReply}>
                <Reply />
                &nbsp; Reply
              </Box>
              <Box className={styles.responseNote} onClick={handleNote}>
                <EventNote />
                &nbsp; Add Note
              </Box>
              <Box className={styles.responseBack} onClick={handleBack}>
                <KeyboardBackspace />
                &nbsp; Back
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.side}>
          <Box className={styles.title}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              // className={styles.header}
            >
              <StyledTab label="Details" {...a11yProps(0)} />
              <StyledTab label="Contact" {...a11yProps(1)} />
              <StyledTab label="Other" {...a11yProps(2)} />
              <StyledTab label="Activity" {...a11yProps(3)} />
            </StyledTabs>
          </Box>

          <Box className={styles.sidebar}>
            <CustomTabPanel value={value} index={0}>
              <DetailsTicket />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ContactTicket />
              <ContactActivity />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}></CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <ActivityTicket />
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ViewMoreTickets;
