// import React, { useState } from "react";
// import {
//   Box,
//   Button,
//   Tab,
//   Tabs,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   IconButton,
//   Grid,
// } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import "./LeaveSettings.css"; // Ensure this CSS has media queries for smaller screens
// import TableChartIcon from "@mui/icons-material/TableChart";
// import AddNewLeaveType from "./AddNewLeaveType";
// import { styled } from "@mui/material/styles";
// import {
//   FormControlLabel,
//   FormControl,
//   Radio,
//   RadioGroup,
//   Select,
//   MenuItem,
//   Typography,
//   Paper,
// } from "@mui/material";

// // Helper function for TabPanel
// const TabPanel = ({ children, value, index }) => {
//   return value === index && <Box>{children}</Box>;
// };

// // Responsive table container
// const ResponsiveTableContainer = styled(Box)({
//   width: "100%",
//   overflowX: "auto",
// });

// // Helper styles for action buttons
// const IconButtonWrapper = styled(IconButton)({
//   marginLeft: "8px",
// });

// const LeaveSettings = () => {
//   const [tabValue, setTabValue] = useState(0);
//   const [leaveCount, setLeaveCount] = useState("joining");
//   const [approvalType, setApprovalType] = useState("Pre-Approve");
//   const [selectedOption, setSelectedOption] = useState("dateOfJoining");
//   const [showForm, setShowForm] = useState(false); // To toggle form visibility
//   const [editingLeaveType, setEditingLeaveType] = useState(null); // Store data for editing
//   const [isFormOpen, setIsFormOpen] = useState(false);

//   const handleRadioChange = (event) => {
//     setSelectedOption(event.target.value);
//   };
//   const handleLeaveCountChange = (event) => {
//     setLeaveCount(event.target.value);
//   };

//   const handleApprovalChange = (event) => {
//     setApprovalType(event.target.value);
//   };

//   const handleChange = (event, newValue) => {
//     setTabValue(newValue);
//   };
//   const handleSubmit = (newLeaveType) => {
//     if (editingLeaveType) {
//       // Update existing leave type
//       setLeaveTypes(
//         leaveTypes.map((leave) =>
//           leave.id === editingLeaveType.id
//             ? { ...leave, ...newLeaveType }
//             : leave
//         )
//       );
//     } else {
//       // Add new leave type
//       setLeaveTypes([
//         ...leaveTypes,
//         { id: leaveTypes.length + 1, ...newLeaveType },
//       ]);
//     }
//     setShowForm(false); // Hide form after submission
//     setEditingLeaveType(null); // Clear editing data
//   };

//   // Handle edit button click
//   const handleEdit = (leaveType) => {
//     setEditingLeaveType(leaveType);
//     setShowForm(true); // Show form with data for editing
//   };

//   // Handle cancel button
//   const handleCancel = () => {
//     setShowForm(false);
//     setEditingLeaveType(null); // Reset form
//   };
//   // Add this function inside your LeaveSettings component
//   const handleDelete = (leaveId) => {
//     // Confirm before deleting
//     const confirmDelete = window.confirm(
//       "Are you sure you want to delete this leave type?"
//     );

//     if (confirmDelete) {
//       // Update the leaveTypes state by filtering out the leave with the given id
//       const updatedLeaveTypes = leaveTypes.filter(
//         (leave) => leave.id !== leaveId
//       );

//       // Update the state
//       setLeaveTypes(updatedLeaveTypes);
//     }
//   };

//   const [leaveTypes, setLeaveTypes] = useState([
//     {
//       id: 1, // Unique ID
//       type: "Casual",
//       allotment: "Yearly",
//       leaves: 5,
//       limit: "--",
//       paidStatus: "Paid",
//       department: [
//         "Marketing",
//         "Sales",
//         "HR",
//         "Public Relations",
//         "Research",
//         "Finance",
//       ],
//       designation: [
//         "Trainee",
//         "Senior",
//         "Junior",
//         "Team Lead",
//         "Project Manager",
//       ],
//       color: "green",
//     },
//     {
//       id: 2, // Unique ID
//       type: "Sick",
//       allotment: "Yearly",
//       leaves: 5,
//       limit: "--",
//       paidStatus: "Paid",
//       department: [
//         "Marketing",
//         "Sales",
//         "HR",
//         "Public Relations",
//         "Research",
//         "Finance",
//       ],
//       designation: [
//         "Trainee",
//         "Senior",
//         "Junior",
//         "Team Lead",
//         "Project Manager",
//       ],
//       color: "red",
//     },
//     {
//       id: 3, // Unique ID
//       type: "Earned",
//       allotment: "Yearly",
//       leaves: 5,
//       limit: "--",
//       paidStatus: "Paid",
//       department: [
//         "Marketing",
//         "Sales",
//         "HR",
//         "Public Relations",
//         "Research",
//         "Finance",
//       ],
//       designation: [
//         "Trainee",
//         "Senior",
//         "Junior",
//         "Team Lead",
//         "Project Manager",
//       ],
//       color: "purple",
//     },
//   ]);

//   return (
//     <Box className="leave-settings-container">
//       {/* Tabs Section */}
//       <div className="tabs-wrapper">
//         <Tabs value={tabValue} onChange={handleChange}>
//           <Tab label="Leaves Type Settings" />
//           <Tab label="Leaves General Settings" />
//           <Tab label="Archived Leaves Type" />
//         </Tabs>
//       </div>

//       {/* Tab content */}
//       <TabPanel value={tabValue} index={0}>
//         {/* Add New Leave Type Button */}
//         {!showForm && (
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={() => setShowForm(true)}
//             style={{ marginTop: "10px" }}
//           >
//             + Add New Leave Type
//           </Button>
//         )}

//         {/* Display the form when showForm is true */}
//         {showForm && (
//           <AddNewLeaveType
//             onSubmit={handleSubmit}
//             initialData={editingLeaveType} // Pass data if editing
//             onCancel={handleCancel}
//           />
//         )}

//         {/* Display the table when showForm is false */}
//         {!showForm && (
//           <ResponsiveTableContainer>
//             <Table className="leave-table">
//               {/* Table Head */}
//               <TableHead className="leave-table-head">
//                 <TableRow>
//                   <TableCell>Leave Type</TableCell>
//                   <TableCell>Leave Allotment Type</TableCell>
//                   <TableCell>No. of Leaves</TableCell>
//                   <TableCell>Monthly Limit</TableCell>
//                   <TableCell>Leave Paid Status</TableCell>
//                   <TableCell>Department</TableCell>
//                   <TableCell>Designation</TableCell>
//                   <TableCell>Action</TableCell>
//                 </TableRow>
//               </TableHead>

//               {/* Table Body */}
//               <TableBody>
//                 {leaveTypes.map((leave, index) => (
//                   <TableRow key={index}>
//                     <TableCell>
//                       <Box className="leave-type-badge">
//                         {/* Add colored dot based on leave type */}
//                         <Box
//                           className="color-indicator"
//                           style={{
//                             backgroundColor: leave.color,
//                             width: "12px",
//                             height: "12px",
//                             borderRadius: "50%",
//                             display: "inline-block",
//                             marginRight: "8px",
//                           }}
//                         />
//                         {leave.type}
//                       </Box>
//                     </TableCell>
//                     <TableCell>{leave.allotment}</TableCell>
//                     <TableCell>{leave.leaves}</TableCell>
//                     <TableCell>{leave.limit}</TableCell>
//                     <TableCell>{leave.paidStatus}</TableCell>
//                     <TableCell>
//                       <div className="department-list">
//                         {leave.department.map((dept, index) => (
//                           <div key={index}>{dept}</div>
//                         ))}
//                       </div>
//                     </TableCell>
//                     <TableCell>
//                       <div className="designation-list">
//                         {leave.designation.map((desig, index) => (
//                           <div key={index}>{desig}</div>
//                         ))}
//                       </div>
//                     </TableCell>
//                     <TableCell>
//                       <IconButtonWrapper
//                         color="primary"
//                         onClick={() => handleEdit(leave)}
//                       >
//                         <EditIcon />
//                       </IconButtonWrapper>
//                       <IconButtonWrapper
//                         color="secondary"
//                         onClick={() => handleDelete(leave.id)}
//                       >
//                         <DeleteIcon />
//                       </IconButtonWrapper>
//                     </TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </ResponsiveTableContainer>
//         )}
//       </TabPanel>

//       {/* Other tab content */}
//       <TabPanel value={tabValue} index={1}>
//         <Box style={{ marginLeft: "10px" }}>
//           <FormControl component="fieldset" style={{ marginTop: "10px" }}>
//             <RadioGroup
//               aria-label="leave-setting"
//               name="leave-setting"
//               value={selectedOption}
//               onChange={handleRadioChange}
//               row // Align radio buttons in a row
//             >
//               <FormControlLabel
//                 value="dateOfJoining"
//                 control={<Radio />}
//                 label="Count leaves from the date of joining"
//               />
//               <FormControlLabel
//                 value="year"
//                 control={<Radio />}
//                 label="Count leaves from the start of the year"
//               />
//             </RadioGroup>
//           </FormControl>

//           {/* Conditionally display "Year Starts from" and dropdown */}
//           {selectedOption === "year" && (
//             <Box mt={2}>
//               <Typography>Year Starts from</Typography>
//               <Select defaultValue="January">
//                 <MenuItem value="January">January</MenuItem>
//                 <MenuItem value="February">February</MenuItem>
//                 <MenuItem value="March">March</MenuItem>
//                 <MenuItem value="April">April</MenuItem>
//                 <MenuItem value="January">May</MenuItem>
//                 <MenuItem value="February">June</MenuItem>
//                 <MenuItem value="March">July</MenuItem>
//                 <MenuItem value="April">August</MenuItem>
//                 <MenuItem value="January">september</MenuItem>
//                 <MenuItem value="February">october</MenuItem>
//                 <MenuItem value="March">November</MenuItem>
//                 <MenuItem value="April">December</MenuItem>
//               </Select>
//             </Box>
//           )}

//           {/* Note Section (Always visible) */}
//           <Box
//             mt={2}
//             p={2}
//             style={{ backgroundColor: "#E0F7FA", borderRadius: "4px" }}
//           >
//             <Typography variant="body2" color="textSecondary">
//               Note: Approve means direct approval, Pre-Approval means another
//               approval by admin/hr is required.
//             </Typography>
//           </Box>

//           {/* Reporting Manager Section (Always visible) */}
//           <Box mt={2}>
//             <Grid container spacing={2}>
//               <Grid
//                 item
//                 xs={12}
//                 style={{ display: "flex", alignItems: "center" }}
//               >
//                 <Typography style={{ marginRight: "8px" }}>
//                   Reporting Manager can
//                 </Typography>
//                 <Select
//                   defaultValue="Pre-Approve"
//                   style={{ marginLeft: "8px", marginRight: "8px" }}
//                 >
//                   <MenuItem value="Pre-Approve">Pre-Approve</MenuItem>
//                   <MenuItem value="Approve">Approve</MenuItem>
//                   <MenuItem value=" Not Approve">Not Approve</MenuItem>
//                 </Select>
//                 <Typography>the Leave</Typography>
//               </Grid>
//             </Grid>
//           </Box>
//         </Box>
//       </TabPanel>
//       <TabPanel value={tabValue} index={2}>
//         <ResponsiveTableContainer>
//           <Table className="leave-table">
//             <TableHead className="leave-table-head">
//               <TableRow>
//                 <TableCell sx={{ border: "none" }}>Leave Type</TableCell>
//                 <TableCell sx={{ border: "none" }}>
//                   Leave Allotment Type
//                 </TableCell>
//                 <TableCell sx={{ border: "none" }}>No of Leaves</TableCell>
//                 <TableCell sx={{ border: "none" }}>Monthly Limit</TableCell>
//                 <TableCell sx={{ border: "none" }}>Leave Paid Status</TableCell>
//                 <TableCell sx={{ border: "none" }}>Department</TableCell>
//                 <TableCell sx={{ border: "none" }}>Designation</TableCell>
//                 <TableCell sx={{ border: "none" }}>Action</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {/* Empty state when there is no archived leave type */}
//               <TableRow>
//                 <TableCell colSpan={8} align="center">
//                   <Box className="empty-archived-leaves">
//                     <TableChartIcon
//                       style={{ fontSize: 30, color: "#B0B0B0" }}
//                     />
//                     <p className="no-data-message">- No leave type added. -</p>
//                   </Box>
//                 </TableCell>
//               </TableRow>
//             </TableBody>
//           </Table>
//         </ResponsiveTableContainer>
//       </TabPanel>
//     </Box>
//   );
// };

// export default LeaveSettings;

import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Card, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import LeaveType from "./LeaveType";
import LeaveGeneral from "./LeaveGeneral";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "#C0B476",
    "&.Mui-selected": {
      color: "#000000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "red",
    },
  })
);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function LeaveSettings() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card sx={{ backgroundColor: "white" }}>
      <CardContent>
        <Box sx={{ maxWidth: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              background: "white",
              padding: "0",
            }}
          >
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <StyledTab label="Leaves Type Settings" {...a11yProps(0)} />
              <StyledTab label="Leaves General Settings" {...a11yProps(1)} />
            </StyledTabs>
          </Box>
          <hr></hr>
          <CustomTabPanel value={value} index={0}>
            <LeaveType />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <LeaveGeneral />
          </CustomTabPanel>
        </Box>
      </CardContent>
    </Card>
  );
}
