import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import Slide from "@mui/material/Slide";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AgentsDailog({ setOfficecode, setCountry }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [showData, setShowData] = React.useState([]);
  const [showGroup, setShowGroup] = React.useState([]);
  const [agentData, setAgentData] = React.useState("");
  const [agentGroup, setAgentGroup] = React.useState("");

  React.useEffect(() => {
    getData();
  }, [showData]);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/serveUsers/2"
    );
    const response = await results.json();

    setShowData(response);
  }
  React.useEffect(() => {
    getGroup();
  }, [showData]);

  async function getGroup() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getTicketGroupSettings"
    );
    const response = await results.json();

    setShowGroup(response);
  }
  const handleSave = () => {};
  return (
    <React.Fragment>
      <Box
        onClick={handleClickOpen}
        sx={{
          backgroundColor: "#1d82f5",
          "&:hover": { backgroundColor: "orange" },
          textAlign: "center",
          padding: "10px",
          marginLeft: "10px",
          height: "40px",
          width: "155px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          borderRadius: "4px",
          fontSize: "15px",
          fontFamily: "sans-serif",
          cursor: "pointer",
        }}
      >
        <AddIcon />
        Add New Agents
      </Box>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        PaperProps={{
          style: {
            position: "absolute",
            background: "#141b2d",
            top: "0",
            backgroundColor: "white",
            maxHeight: "95%",
            margin: "auto",
            overflowX: "hidden",
            zIndex: 1200,
          },
        }}
      >
        <AppBar sx={{ position: "relative", width: "100%" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} component="div">
              Add New Ticket Agent
            </Typography>
          </Toolbar>
        </AppBar>

        <Box sx={{ padding: "20px" }}>
          <Grid
            container
            spacing={2}
            style={{
              color: "black",

              width: "100%",
            }}
          >
            <Grid item xs={12} sm={11} md={11}>
              <Stack direction="column" spacing={2}>
                <Typography sx={{ color: "#000" }}>Agent Choose</Typography>
                <Select
                  value={agentData}
                  autoFocus
                  fullWidth
                  onChange={(e) => setAgentData(e.target.value)}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  {showData.map((data) => (
                    <MenuItem value={data.id} key={data.id}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={11} md={11}>
              <Stack direction="column" spacing={2}>
                <Typography sx={{ color: "#000" }}>Agent Group</Typography>
                <Select
                  value={agentGroup}
                  autoFocus
                  fullWidth
                  onChange={(e) => setAgentGroup(e.target.value)}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  {showGroup.map((data) => (
                    <MenuItem value={data.id} key={data.id}>
                      {data.ticket_group}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
          </Grid>

          <DialogActions sx={{ paddingTop: "20px" }}>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              // variant="outlined"

              sx={{
                backgroundColor: "#1d82f5",
                color: "#fff",
                "&:hover": { backgroundColor: "orange" },
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
