import React, { useEffect, useState } from "react";
import "tailwindcss/tailwind.css";
import "./Companies.css";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import AddCompany from "./AddCompanies";
import PageLayout from "../global/PageLayout";

function Companies() {
  const [users, setUsers] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleViewClick = () => {
    // Implement logic to handle view action
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const columns = [
    {
      name: "id",
      label: "ID", // to rename column
    },
    //   {
    //       name:"firstName",
    //   },
    {
      name: "name",
      label: "Company Name",
    },
    {
      name: "address",
      label: "Address",
    },
    {
      name: "details",
      label: "Company Details",
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Stack
            value={value}
            direction="row"
            spacing={1}
            alignItems="center"
            onChange={(event) => handleStatusChange(event, tableMeta.rowIndex)}
          >
            <AntSwitch
              defaultChecked
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography value="on">Active</Typography>
          </Stack>
        ),
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <FormControl>
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleViewClick}>View</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Edit</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Delete</MenuItem>
              {/* <MenuItem onClick={handleCloseMenu}>Change to Client</MenuItem> */}
              <MenuItem onClick={handleCloseMenu}>Add Follow up</MenuItem>
            </Menu>
          </FormControl>
        ),
      },
    },
  ];

  const handleStatusChange = (event, rowIndex) => {
    const newUsers = [...users];
    newUsers[rowIndex].status = event.target.value;
    setUsers(newUsers);
  };

  // const handleStatusChange = (event, rowIndex) => {
  //     const { value } = event.target;
  //     setUsers(prevUsers => {
  //         const updatedUsers = [...prevUsers];
  //         updatedUsers[rowIndex].status = value;
  //         return updatedUsers;
  //     });
  // };

  useEffect(() => {
    fetch("https://dummyjson.com/users")
      .then((res) => res.json())
      .then((data) => {
        let local = data?.users?.map((user) => ({
          ...user,
          name: user?.firstName + " " + user?.lastName,
          // status: "inactive"
        }));

        setUsers(local);
      });
  });

  const options = {
    //selectableRows: false,
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5px",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <PageLayout>
      <div className="py-10 min-h-screen grid place-items-center">
        <AddCompany />
        <br />
        <div className="w-10/12 max-w-4xl">
          <ThemeProvider theme={getTheme()}>
            <MUIDataTable
              title="Company list"
              data={employee}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </div>
      </div>
    </PageLayout>
  );
}

export default Companies;
