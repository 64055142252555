import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  { services: "Job", months: 50 },
  { services: "Daba", months: 30 },
];

const chartSetting = {
  width: 200,
  height: 230,
};

const valueFormatter = (value) => `${value}m`;

export default function BarChartView() {
  return (
    <BarChart
      width={chartSetting.width}
      height={chartSetting.height}
      data={data}
      layout="horizontal"
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="services"
        tick={{ fill: "black" }}
        label={{ fill: "black" }}
      />
      <YAxis
        tick={{ fill: "black" }}
        label={{
          value: "months",
          angle: -90,
          position: "insideLeft",
          fill: "black",
        }}
      />
      <Tooltip />
      <Legend />
      <Bar
        dataKey="months"
        fill="blue"
        label={{ position: "top", fill: "black", formatter: valueFormatter }}
      />
    </BarChart>
  );
}
