import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { styled } from "@mui/material/styles";

import DetailsClients from "./DetailsClients";
import DriverClients from "./DriverClients";
import ContactClients from "./ContactClients";
import PaymentsClients from "./PaymentsClients";
import InvoiceClients from "./InvoiceClients";
import EstimateClients from "./EstimateClients";
import DocumentClients from "./DocumentClients";
import NotesClients from "./NotesClients";
import TicketClients from "./TicketClients";
import CreditNoteClients from "./CreditNoteClients";
import OrderClients from "./OrderClients";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import AttendanceClients from "./AttendanceClients";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    // marginRight: theme.spacing(1),
    color: "#C0B476",
    "&.Mui-selected": {
      color: "#000000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "red",
    },
  })
);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ViewMoreClients() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [value, setValue] = React.useState(0);
  const { id } = useParams();
  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      navigate(`/clients/${id}?tab=drivers`, { state });
    }
    if (newValue === 2) {
      navigate(`/clients/${id}?tab=attendance`, { state });
    }
    if (newValue === 3) {
      navigate(`/clients/${id}?tab=contacts`, { state });
    }
    if (newValue === 4) {
      navigate(`/clients/${id}?tab=payments`, { state });
    }
    if (newValue === 5) {
      navigate(`/clients/${id}?tab=invoices`, { state });
    }
    if (newValue === 6) {
      navigate(`/clients/${id}?tab=estimates`, { state });
    }
    if (newValue === 7) {
      navigate(`/clients/${id}?tab=creditnotes`, { state });
    }
    if (newValue === 8) {
      navigate(`/clients/${id}?tab=documents`, { state });
    }
    if (newValue === 9) {
      navigate(`/clients/${id}?tab=notes`, { state });
    }
    if (newValue === 10) {
      navigate(`/clients/${id}?tab=tickets`, { state });
    }
    if (newValue === 11) {
      navigate(`/clients/${id}?tab=orders`, { state });
    }
    if (newValue === 0) {
      navigate(`/clients/${id}`, { state });
    }
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          borderColor: "divider",
          borderBottom: "1px solid gray",
          paddingBottom: "10px",
          bgcolor: "#fff",
        }}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab label="Profile" {...a11yProps(0)} />
          <StyledTab label="Driver" {...a11yProps(1)} />
          <StyledTab label="Attedance" {...a11yProps(2)} />
          <StyledTab label="Contact" {...a11yProps(3)} />
          <StyledTab label="Payments" {...a11yProps(4)} />
          <StyledTab label="Invoices" {...a11yProps(5)} />
          <StyledTab label="Estimates" {...a11yProps(6)} />
          <StyledTab label="Credit Note" {...a11yProps(7)} />
          <StyledTab label="Documents" {...a11yProps(8)} />
          <StyledTab label="Notes" {...a11yProps(9)} />
          <StyledTab label="Tickets" {...a11yProps(10)} />
          <StyledTab label="Orders" {...a11yProps(11)} />
        </StyledTabs>
      </Box>

      <CustomTabPanel value={value} index={0} sx={{ bgcolor: "#eff1f1" }}>
        <DetailsClients />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} sx={{ bgcolor: "#eff1f1" }}>
        <DriverClients id={id} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2} sx={{ bgcolor: "#eff1f1" }}>
        <AttendanceClients />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3} sx={{ bgcolor: "#eff1f1" }}>
        <ContactClients />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4} sx={{ bgcolor: "#eff1f1" }}>
        <PaymentsClients />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5} sx={{ bgcolor: "#eff1f1" }}>
        <InvoiceClients />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6} sx={{ bgcolor: "#eff1f1" }}>
        <EstimateClients />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={7} sx={{ bgcolor: "#eff1f1" }}>
        <CreditNoteClients />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={8} sx={{ bgcolor: "#eff1f1" }}>
        <DocumentClients />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={9} sx={{ bgcolor: "#eff1f1" }}>
        <NotesClients />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={10} sx={{ bgcolor: "#eff1f1" }}>
        <TicketClients />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={11} sx={{ bgcolor: "#eff1f1" }}>
        <OrderClients />
      </CustomTabPanel>
    </Box>
  );
}

export default ViewMoreClients;
