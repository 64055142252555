import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import GroupIcon from '@mui/icons-material/Group';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import './admProject.css';
import HelpIcon from '@mui/icons-material/Help';
import { Placeholder } from 'react-bootstrap';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MUIDataTable from "mui-datatables";
import { makeStyles } from '@mui/styles';
import { Box , Grid, createTheme, ThemeProvider} from '@mui/material';

function AdmProject() {
    const [users, setUsers] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [setShowPopup] = useState(false);

    const Item = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(1),
        textAlign: 'center',
    }));

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: '#ffffff', 
        },
    }));

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleViewClick = () => {
        setShowPopup(true); // Open the pop-up window when "View" is clicked
        handleCloseMenu(); // Close the menu
    };

    const handleStatusChange = (event, rowIndex) => {
        const newUsers = [...users];
        newUsers[rowIndex].status = event.target.value;
        setUsers(newUsers);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };



    const columns = [
        {
            name: "id",
            label: "ID"  // to rename column
        },
        {
            name: "name",
            label: "Name"
        },
        {
            name: "email",
            label: "Email"
        },
        {
            name: "age",
            label: "Age"
        },
        {
            name: "userRole",
            label: "User Role",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <FormControl size="small">
                        <Select
                            value={value}
                            onChange={(event) => handleStatusChange(event, tableMeta.rowIndex)}
                            labelId="status-label"
                            id="status-select"
                            label="User Role"
                        >
                            <MenuItem value="App Administrator">App Administrator</MenuItem>
                            <MenuItem value="Employee">Employee</MenuItem>
                            <MenuItem value="HR">HR</MenuItem>
                            <MenuItem value="BDA">BDA</MenuItem>
                            <MenuItem value="Manager">Manager</MenuItem>
                            <MenuItem value="Support">Support</MenuItem>
                        </Select>
                    </FormControl>
                )
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <FormControl>
                        <IconButton onClick={handleMenuClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                        >
                            <MenuItem onClick={handleViewClick}>View</MenuItem>
                            <MenuItem onClick={handleCloseMenu}>Edit</MenuItem>
                            <MenuItem onClick={handleCloseMenu}>Delete</MenuItem>
                            {/* <MenuItem onClick={handleCloseMenu}>Change to Client</MenuItem> */}
                            <MenuItem onClick={handleCloseMenu}>Add Follow up</MenuItem>
                        </Menu>
                    </FormControl>
                )
            }
        }
    ];


    const options = {
        //selectableRows: false,
        elevation: 0,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 25, 100],
        tableBody: {
            style: {
                maxHeight: '500px',
                overflowY: 'scroll',
                marginLeft:'5PX'
            }
        },
        tableHead: {
            style: {
                backgroundColor: '#f2f2f2',
                color: 'black'
            }
        },
        rows: {
            style: {
                marginLeft: '5px',
                textAlign: 'center'
            },
            selectableRowsOnClick: true
        }
    };
    
       const getTheme = () =>
       createTheme({
         palette: {
           mode: 'light',
           primary: {
             main: '#2196f3',
           },
           secondary: {
             main: '#03a9f4',
           },
         },
         typography: {
           fontFamily: [
             'Roboto',
             'Helvetica',
             'Arial',
             'Sans-serif',
           ].join(','),
         },
         components: {
           MuiButton: {
             styleOverrides: {
               root: {
                 textTransform: 'none',
               },
             },
           },
         },
         tableBody: {
          style: {
              maxHeight: '500px',
              overflowY: 'scroll'
          }
      },
      tableHead: {
          style: {
              backgroundColor: '#f2f2f2',
              color: 'black'
          }
      },
      rows: {
          style: {
              '&:hover': {
                  backgroundColor: '#f2f2f2'
              }
          }
      }
       });
    

    //const classes = useStyles();
    return (
        <Box display="flex" flexDirection="column" >
        <Box sx={{ flexGrow: 1 }}>
          

                {/* first col  */}


                <Grid item xs={12} md={6}>


                <Grid container spacing={1}>

                {/* first column */}
                <Grid item xs={12} md={6}>

                    {/* first row */}
                    <Grid container spacing={1} >


                        <Grid item xs={12} sm={6}>
                        <Item style={{  backgroundColor: "#fff" }}>
                                <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left",fontSize: "20px",color: "#4d4f5c" }}>
                                    Total Tasks
                                </Typography>
                                <Typography variant="body2" gutterBottom style={{ textAlign: "right" ,color: "#4d4f5c"}}>
                                <GroupIcon></GroupIcon>
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: "left",fontSize: "16px" }} color="blue">
                                    275
                                </Typography>
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <Item style={{  backgroundColor: "#fff" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left",fontSize: "20px",color: "#4d4f5c" }}>
                                    Uresolved Tasks
                                </Typography>
                                <Typography variant="body2" gutterBottom style={{ textAlign: "right",color: "#4d4f5c" }}>
                                <GroupIcon></GroupIcon>
                                </Typography>
                                <Typography variant="body2" style={{textAlign: "left", fontSize: "16px" }} color="blue">
                                    42
                                </Typography>
                            </Item>
                        </Grid>
                    </Grid>
                    <div style={{ marginBottom: '10px' }}></div>

                    {/* second row */}
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                        <Item style={{ backgroundColor: "#fff" }}>
                                <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left",fontSize: "20px",color: "#161e29" }}>
                                    Task Report <HelpIcon></HelpIcon>
                                </Typography>
                                <div style={{marginBottom:"20px"}}></div>
                                <Typography variant="body2" gutterBottom style={{ textAlign: "center",color: "#4d4f5c" }}>
                                <SignalCellularAltIcon></SignalCellularAltIcon>
                                </Typography>
                                <Typography variant="body2" style={{textAlign: "centre", fontSize: "16px" ,color: "#4d4f5c"}}>
                                    <Placeholder>-Not Enough Data-</Placeholder>
                                </Typography>
                            </Item>
                        </Grid>
                    </Grid>

                </Grid>


                {/* second col  */}
                
                <Grid item xs={12} md={6}>

                {/* first row */}
                <Grid container spacing={1} >   


                    <Grid item xs={12} sm={6}>
                    <Item style={{  backgroundColor: "#fff" }}>
                            <Typography gutterBottom variant="subtitle1" style={{textAlign: "left", fontSize: "20px",color: "#4d4f5c" }}>
                                    Total Projects
                                </Typography>
                                <Typography variant="body2" gutterBottom style={{ textAlign: "right",color: "#4d4f5c" }}>
                                <LocalActivityIcon></LocalActivityIcon>
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: "left",fontSize: "16px" }} color="blue">
                                    140
                                </Typography>
                            </Item>
                    </Grid>
                </Grid>

                <div style={{ marginBottom: '10px' }}></div>

                {/* fourth row */}
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Item style={{  backgroundColor: "#fff" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                                Timesheet <HelpIcon />
                            </Typography>
                                    <ThemeProvider theme={getTheme()}>
                                    <MUIDataTable
                                        fullScreen={true}
                                        columns={columns}
                                        options={options}/>
                                    </ThemeProvider>
                                   
                        </Item>
                    </Grid>
                </Grid>

                </Grid>

            </Grid>
       </Grid>
      
        </Box>
    </Box>
    )
}

export default AdmProject;