import {
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  Menu,
  Stack,
  Select,
  FormControl,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import AddIcon from "@mui/icons-material/Add";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import StatusAlert from "../../../StatusAlertList/StatusAlert";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { useNavigate } from "react-router-dom";
import EmployeeHeader from "./EmployeeHeader";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Employee() {
  const [empid, setEmpid] = React.useState(false);
  const [name, setName] = React.useState(false);
  const [email, setEmail] = React.useState(false);
  const [password, setPassword] = React.useState(false);
  const [designation, setDesignation] = React.useState(false);
  // const [department, setDepartment] = React.useState(false);
  const [mobile, setMobile] = React.useState(false);
  const [country, setCountry] = React.useState(false);

  const [reportingTo, setReportingTo] = React.useState(false);

  const [language, setLanguage] = React.useState(false);
  const [userRole, setUserRole] = React.useState(false);
  const [address, setAddress] = React.useState(false);
  const [about, setAbout] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [designations, setDesignations] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // setFileName(file.name); // Update file name
    // Print file name to console
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Event handler to update the date state and log the date to the console
  const handleDateChange = (newDate) => {
    // Log the new date to the console
  };

  const item = {
    empid,
    name,
    email,
    password,
    designation,
    reportingTo,
    mobile,
    country,
    language,
    userRole,
    address,
    about,
  };

  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);
  const [update, setUpdate] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({
    keys: [],
    values: [],
    open: false,
  });

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };
  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleViewMore = () => {
    const rowData = employeeData.filter((row) => row.id === openMenuId);

    navigate(`/hr/employees/${openMenuId}`, { state: rowData[0] });
    // setOpenDialog(true);
  };

  // Function Starts Here

  const [updateMember, setUpdateMember] = React.useState("");
  const [updateLeaveType, setUpdateLeaveType] = React.useState("");

  const [updateDuration, setUpdateDuration] = React.useState("");
  const [updateDate, setUpdateDate] = useState(null); // Initialize state for the date
  const [updateMemberError, setUpdateMemberError] = React.useState(false);
  const [updateReason, setUpdateReason] = useState("");
  const [updateFileName, setUpdateFileName] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [data, SetData] = useState([]);

  const [openMenuId, setOpenMenuId] = useState(null);

  async function SaveDate() {
    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/register",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      }
    );

    result = await result.json();

    showAlert(" Added successfully", "success");

    getData();
    setOpen(false);
  }

  // ends Here

  // Table Starts Here

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getAllEmployees"
    );

    const response = await results.json();
    setEmployeeData(response);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      name: "empid",
      label: "Employee ID",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Employee ID
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Name
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Email
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "role",
      label: "User Role",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              User Role
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "report_to",
      label: "Reporting To",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Reporting To
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "35px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                // Add padding for starting point
              }}
            >
              <div style={{ paddingRight: "5px" }}>
                <Button
                  id="basic-button"
                  aria-controls={openDropMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div onClick={() => handleOpenMenu(value)}>
                    <MoreHorizTwoToneIcon />
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleViewMore(tableMeta.rowData)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <RemoveRedEyeIcon /> View More
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleEdit(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EditNoteIcon /> Edit
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleDelete(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <DeleteForeverIcon /> Delete
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </>
        ),
      },
    },
  ];

  async function handleEdit(id) {
    let result = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/businesslocationget/" + id
    );
    result = await result.json();

    SetData(result);
    handleClickUpdate();
  }

  // Update Values Start

  useEffect(() => {
    setUpdateMember(data.member);
  }, [data.member]);

  useEffect(() => {
    setUpdateDate(data.date);
  }, [data.date]);

  useEffect(() => {
    setUpdateDuration(data.duration);
  }, [data.duration]);

  useEffect(() => {
    setUpdateMemberError(data.managerError);
  }, [data.managerError]);

  useEffect(() => {
    setUpdateFileName(data.fileName);
  }, [data.fileName]);

  useEffect(() => {
    setUpdateReason(data.reason);
  }, [data.reason]);

  useEffect(() => {
    setUpdateLeaveType(data.leaveType);
  }, [data.leaveType]);

  useEffect(() => {
    setUpdateStatus(data.status);
  }, [data.status]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDesig, setOpenDialogDesig] = useState(false);
  const [newDepartment, setNewDepartment] = useState("");
  const [newParentDepartment, setNewParentDepartment] = useState("");
  const [newParentDesignation, setNewParentDesignation] = useState("");

  const [newDesignation, setNewDesignation] = useState("");
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://rptpl.roadpilot.co.in/public/api/getDepartments"
      );
      const data = await response.json();

      setDepartments(data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchDataDesignation = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://rptpl.roadpilot.co.in/public/api/getDesignation"
      );
      const data = await response.json();

      setDesignations(data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDataDesignation();
  }, []);

  const handleAddClick = () => {
    setOpenDialog(true); // Open dialog when Add button is clicked
  };
  const handleAddClickDesig = () => {
    setOpenDialogDesig(true); // Open dialog when Add button is clicked
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setNewDepartment("");
    setNewParentDepartment(""); // Reset input field
  };
  const handleDialogCloseDesig = () => {
    setOpenDialogDesig(false);
    setNewDesignation("");
    setNewParentDesignation("");
  };
  async function AddDepartment() {
    const creator_name = localStorage.getItem("empid");
    let result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/addDepartment?department=${newDepartment}&parent=${newParentDesignation}&creator_id=${creator_name}`,
      {
        method: "POST",
      }
    );

    result = await result.json();

    showAlert(" Added successfully", "success");

    fetchData();
    handleDialogClose();
  }
  async function AddDesignation() {
    const creator_name = localStorage.getItem("empid");
    let result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/addDesignation?designation=${newDesignation}&parent=${newParentDesignation}&creator=${creator_name}`,
      {
        method: "POST",
      }
    );

    result = await result.json();

    showAlert(" Added successfully", "success");

    fetchDataDesignation();
    handleDialogCloseDesig();
  }

  const Marketing = "Marketing";
  const Sales = "Sales";
  const HumanResources = "Human Resource";
  const Research = "Research";
  const Finance = "Finance";
  const PublicRelations = "Public Relations";

  async function UpdateData() {
    const formData = new FormData();

    formData.append("member", updateMember);
    formData.append("memberError", updateMemberError);
    formData.append("fileName", updateFileName);
    formData.append("date", updateDate);
    formData.append("reason", updateReason);
    formData.append("duration", updateDuration);
    formData.append("leaveType", updateLeaveType);
    formData.append("status", updateStatus);
    try {
      let response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/updateBusinessLocation/${data.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      showAlert("Leave updated successfully", "success");

      handleUpdateClose();
      getData(); // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      showAlert("Failed to update business location", "error");
    }
  }

  // Editing Ends Here

  // Delete

  async function handleDelete(id) {
    const response = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/businesslocationdelete/" + id,
      {
        method: "DELETE",
      }
    );
    const result = await response.json(); // Assigning to a new variable

    getData();
    showAlert("Leave Deleted successfully", "warning");
  }

  // end delete
  const Trainee = "Trainee";
  const Junior = "Junior";
  const Senior = "Senior";
  const TeamLead = "Team Lead";
  const ProjectManager = "Project Manager";
  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          // maxWidth: "1000px",
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  // Table Ends Here

  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />
      <EmployeeHeader />
      <Box>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={update}
          onClose={handleUpdateClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleUpdateClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                Update Employee
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ background: "#fff" }}>
            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={6} sm={2}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Employee ID
                </Typography>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      placeholder="e.g.ID-1"
                      onChange={(e) => setEmpid(e.target.value)}
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Name
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      onChange={(e) => setName(e.target.value)}
                      placeholder="e.g.Name"
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Personal Email
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <br></br>
                    <TextField
                      onChange={(e) => setEmail(e.target.value)}
                      fullWidth
                      placeholder="eg@example.com"
                      type="email"
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Reporting To
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <br></br>
                    <TextField
                      onChange={(e) => setEmail(e.target.value)}
                      fullWidth
                      placeholder="abc"
                      type="email"
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={4} md={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Password
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="e.g.+91 12345689"
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8} sm={4} md={4}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Designation
                </Typography>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                      <Select
                        labelId="designation-select-label"
                        id="designation-select"
                        value={selectedDesignation}
                        onChange={(e) => setSelectedDesignation(e.target.value)}
                        displayEmpty
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleAddClickDesig}
                              edge="end"
                              sx={{
                                color: "#000",
                                backgroundColor: "#fff",
                                "&:hover": {
                                  backgroundColor: "#fff",
                                  color: "#000",
                                },
                              }}
                            >
                              Add
                            </IconButton>
                          </InputAdornment>
                        }
                        sx={{
                          border: "1px solid black",
                          boxSizing: "border-box",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black !important",
                            "&:hover fieldset": {
                              borderColor: "black !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black !important",
                            },
                          },
                          color: "black",
                        }}
                      >
                        <MenuItem disabled value="">
                          <em>---</em>
                        </MenuItem>
                        {designations.map((item) => (
                          <MenuItem key={item.id} value={item.designation}>
                            {item.designation}
                          </MenuItem>
                        ))}
                      </Select>

                      {/* Dialog for adding new department */}
                      <Dialog
                        fullWidth
                        maxWidth="md"
                        open={openDialogDesig}
                        onClose={handleDialogCloseDesig}
                        TransitionComponent={Transition}
                        PaperProps={{
                          style: {
                            position: "absolute",
                            background: "#141b2d",
                            top: "0",
                            backgroundColor: "white",
                            maxHeight: "95%",
                            margin: "auto",
                            overflowX: "hidden",
                            zIndex: 1200,
                          },
                        }}
                      >
                        <Box sx={{ background: "#fff", color: "#000" }}>
                          <DialogTitle>
                            <span
                              style={{
                                background: "blue",
                                color: "#fff",
                                padding: "15px",
                                borderRadius: "5px",
                              }}
                            >
                              Add New Designation
                            </span>
                          </DialogTitle>
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              marginTop: "10px",
                              padding: { xs: "10px", sm: "20px" },
                            }}
                          >
                            <Grid item xs={12} sm={6} md={5.5}>
                              <Stack direction="column" spacing={2}>
                                <Typography>Name</Typography>
                                <TextField
                                  autoFocus
                                  margin="dense"
                                  placeholder="Designation Name"
                                  type="text"
                                  fullWidth
                                  value={newDesignation}
                                  onChange={(e) =>
                                    setNewDesignation(e.target.value)
                                  }
                                  inputProps={{ style: { color: "black" } }}
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  sx={{
                                    border: "1px solid black",
                                    borderRadius: "5px",
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "black",
                                      "&:hover fieldset": {
                                        borderColor: "black",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "black",
                                      },
                                    },
                                    "& .MuiOutlinedInput-input": {
                                      "&::placeholder": { color: "gray" },
                                    },
                                  }}
                                />
                              </Stack>
                            </Grid>

                            <Grid item xs={12} sm={6} md={5.5}>
                              <Stack direction="column" spacing={2}>
                                <Typography>Parent</Typography>
                                <Select
                                  value={newParentDesignation}
                                  autoFocus
                                  fullWidth
                                  onChange={(e) =>
                                    setNewParentDesignation(e.target.value)
                                  }
                                  displayEmpty
                                  inputProps={{
                                    "aria-label": "Without label",
                                  }}
                                  sx={{
                                    border: "1px solid black",
                                    boxSizing: "border-box",
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "black !important",
                                      "&:hover fieldset": {
                                        borderColor: "black !important",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "black !important",
                                      },
                                    },
                                    color: "black",
                                  }}
                                >
                                  <MenuItem disabled value="">
                                    <em>---</em>
                                  </MenuItem>
                                  <MenuItem value={Trainee}>Trainee</MenuItem>
                                  <MenuItem value={Junior}>Junior</MenuItem>
                                  <MenuItem value={Senior}>Senior</MenuItem>
                                  <MenuItem value={TeamLead}>
                                    Team Lead
                                  </MenuItem>
                                  <MenuItem value={ProjectManager}>
                                    Project Manager
                                  </MenuItem>
                                </Select>
                              </Stack>
                            </Grid>
                            <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                              <DialogActions>
                                <Button
                                  onClick={handleDialogCloseDesig}
                                  color="primary"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={AddDesignation}
                                  color="primary"
                                  sx={{
                                    background: "blue",
                                    color: "#fff",
                                    "&:hover": {
                                      background: "#000",
                                    },
                                  }}
                                >
                                  Add
                                </Button>
                              </DialogActions>
                            </Grid>
                          </Grid>
                        </Box>
                      </Dialog>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8} sm={4} md={4}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Department
                </Typography>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                      <Select
                        labelId="department-select-label"
                        id="department-select"
                        value={selectedDepartment}
                        onChange={(e) => setSelectedDepartment(e.target.value)}
                        displayEmpty
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleAddClick}
                              edge="end"
                              sx={{
                                color: "#000",
                                backgroundColor: "#fff",
                                "&:hover": {
                                  backgroundColor: "#fff",
                                  color: "#000",
                                },
                              }}
                            >
                              Add
                            </IconButton>
                          </InputAdornment>
                        }
                        sx={{
                          border: "1px solid black",
                          boxSizing: "border-box",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black !important",
                            "&:hover fieldset": {
                              borderColor: "black !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black !important",
                            },
                          },
                          color: "black",
                        }}
                      >
                        <MenuItem disabled value="">
                          <em>---</em>
                        </MenuItem>
                        {departments.map((department) => (
                          <MenuItem
                            key={department.id}
                            value={department.department}
                          >
                            {department.department}
                          </MenuItem>
                        ))}
                      </Select>

                      {/* Dialog for adding new department */}
                      <Dialog
                        fullWidth
                        maxWidth="md"
                        open={openDialog}
                        onClose={handleDialogClose}
                        TransitionComponent={Transition}
                        PaperProps={{
                          style: {
                            position: "absolute",
                            background: "#141b2d",
                            top: "0",
                            backgroundColor: "white",
                            maxHeight: "95%",
                            margin: "auto",
                            overflowX: "hidden",
                            zIndex: 1200,
                          },
                        }}
                      >
                        <Box sx={{ background: "#fff", color: "#000" }}>
                          <DialogTitle>
                            <span
                              style={{
                                background: "blue",
                                color: "#fff",
                                padding: "15px",
                                borderRadius: "5px",
                              }}
                            >
                              Add New Department
                            </span>
                          </DialogTitle>
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              padding: { xs: "10px", sm: "20px" },
                            }}
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <Stack direction="column" spacing={2}>
                                <Typography>Name</Typography>
                                <TextField
                                  autoFocus
                                  margin="dense"
                                  placeholder="Department Name"
                                  type="text"
                                  fullWidth
                                  value={newDepartment}
                                  onChange={(e) =>
                                    setNewDepartment(e.target.value)
                                  }
                                  inputProps={{ style: { color: "black" } }}
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  sx={{
                                    border: "1px solid black",
                                    borderRadius: "5px",
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "black",
                                      "&:hover fieldset": {
                                        borderColor: "black",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "black",
                                      },
                                    },
                                    "& .MuiOutlinedInput-input": {
                                      "&::placeholder": { color: "gray" },
                                    },
                                  }}
                                />
                              </Stack>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                              <Stack direction="column" spacing={2}>
                                <Typography>Parent</Typography>
                                <Select
                                  value={newParentDepartment}
                                  autoFocus
                                  fullWidth
                                  onChange={(e) =>
                                    setNewParentDepartment(e.target.value)
                                  }
                                  displayEmpty
                                  inputProps={{
                                    "aria-label": "Without label",
                                  }}
                                  sx={{
                                    border: "1px solid black",
                                    boxSizing: "border-box",
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "black !important",
                                      "&:hover fieldset": {
                                        borderColor: "black !important",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "black !important",
                                      },
                                    },
                                    color: "black",
                                  }}
                                >
                                  <MenuItem disabled value="">
                                    <em>---</em>
                                  </MenuItem>
                                  <MenuItem value={Marketing}>
                                    Marketing
                                  </MenuItem>
                                  <MenuItem value={Sales}>Sales</MenuItem>
                                  <MenuItem value={HumanResources}>
                                    Human Resources
                                  </MenuItem>
                                  <MenuItem value={PublicRelations}>
                                    Public Relations
                                  </MenuItem>
                                  <MenuItem value={Research}>Research</MenuItem>
                                  <MenuItem value={Finance}>Finance</MenuItem>
                                </Select>
                              </Stack>
                            </Grid>

                            <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                              <DialogActions>
                                <Button
                                  onClick={handleDialogClose}
                                  color="primary"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={AddDepartment}
                                  color="primary"
                                  sx={{
                                    background: "blue",
                                    color: "#fff",
                                    "&:hover": {
                                      background: "#000",
                                    },
                                  }}
                                >
                                  Add
                                </Button>
                              </DialogActions>
                            </Grid>
                          </Grid>
                        </Box>
                      </Dialog>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Mobile No
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      onChange={(e) => setMobile(e.target.value)}
                      placeholder="e.g.+91 12345689"
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8} sm={2.5}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Country
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      onChange={(e) => setCountry(e.target.value)}
                      placeholder="e.g.India"
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8} sm={2.5}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Language
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      onChange={(e) => setLanguage(e.target.value)}
                      placeholder="e.g.hindi, english"
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  User Role
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      onChange={(e) => setUserRole(e.target.value)}
                      placeholder="e.g.abc"
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={10} sm={10}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Address
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      onChange={(e) => setAddress(e.target.value)}
                      placeholder="e.g.No:2468,Street, Nagar, City"
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={10} sm={10}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  About
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      onChange={(e) => setAbout(e.target.value)}
                      placeholder="Type here..."
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              autoFocus
              color="inherit"
              onClick={UpdateData}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                margin: "25px",
              }}
            >
              Update
            </Button>
          </Box>
        </Dialog>
      </Box>

      <List
        style={{
          background: "none",
          padding: 5,
        }}
      >
        <React.Fragment>
          <Stack
            direction="row"
            spacing={2}
            sx={{ marginBottom: "5px", marginTop: "10px" }}
          >
            <Box
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#1d82f5",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                padding: "10px",
                marginLeft: "10px",
                height: "40px",
                width: "140px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: "4px",
                fontSize: "15px",
                fontFamily: "sans-serif",
                cursor: "pointer",
                marginTop: "20px",
              }}
            >
              <AddIcon />
              Add Employee
            </Box>
          </Stack>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                position: "absolute",
                background: "#141b2d",
                top: "0",
                backgroundColor: "white",
                maxHeight: "95%",
                margin: "auto",
                overflowX: "hidden",
                zIndex: 1200,
              },
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                  Add New Employee
                </Typography>
              </Toolbar>
            </AppBar>
            <Box sx={{ background: "#fff" }}>
              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px" }}
              >
                <Grid item xs={6} sm={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Employee ID
                  </Typography>

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <br></br>
                      <TextField
                        fullWidth
                        placeholder="e.g.ID-1"
                        onChange={(e) => setEmpid(e.target.value)}
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={4} sm={3}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Name
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <br></br>
                      <TextField
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                        placeholder="e.g.Name"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Personal Email
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <br></br>
                      <TextField
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        placeholder="eg@example.com"
                        type="email"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Reporting To
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <br></br>
                      <TextField
                        onChange={(e) => setReportingTo(e.target.value)}
                        fullWidth
                        placeholder="abc"
                        type="text"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px" }}
              >
                <Grid item xs={8} sm={4} md={3}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Password
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <br></br>
                      <TextField
                        fullWidth
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="e.g.+91 12345689"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={8} sm={4} md={4}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Designation
                  </Typography>

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                        <Select
                          labelId="designation-select-label"
                          id="designation-select"
                          value={selectedDesignation}
                          onChange={(e) =>
                            setSelectedDesignation(e.target.value)
                          }
                          displayEmpty
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleAddClickDesig}
                                edge="end"
                                sx={{
                                  color: "#000",
                                  backgroundColor: "#fff",
                                  "&:hover": {
                                    backgroundColor: "#fff",
                                    color: "#000",
                                  },
                                }}
                              >
                                Add
                              </IconButton>
                            </InputAdornment>
                          }
                          sx={{
                            border: "1px solid black",
                            boxSizing: "border-box",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            color: "black",
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>---</em>
                          </MenuItem>
                          {designations.map((item) => (
                            <MenuItem key={item.id} value={item.designation}>
                              {item.designation}
                            </MenuItem>
                          ))}
                        </Select>

                        {/* Dialog for adding new department */}
                        <Dialog
                          fullWidth
                          maxWidth="md"
                          open={openDialogDesig}
                          onClose={handleDialogCloseDesig}
                          TransitionComponent={Transition}
                          PaperProps={{
                            style: {
                              position: "absolute",
                              background: "#141b2d",
                              top: "0",
                              backgroundColor: "white",
                              maxHeight: "95%",
                              margin: "auto",
                              overflowX: "hidden",
                              zIndex: 1200,
                            },
                          }}
                        >
                          <Box sx={{ background: "#fff", color: "#000" }}>
                            <DialogTitle>
                              <span
                                style={{
                                  background: "blue",
                                  color: "#fff",
                                  padding: "15px",
                                  borderRadius: "5px",
                                }}
                              >
                                Add New Designation
                              </span>
                            </DialogTitle>
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                marginTop: "10px",
                                padding: { xs: "10px", sm: "20px" },
                              }}
                            >
                              <Grid item xs={12} sm={6} md={5.5}>
                                <Stack direction="column" spacing={2}>
                                  <Typography>Name</Typography>
                                  <TextField
                                    autoFocus
                                    margin="dense"
                                    placeholder="Designation Name"
                                    type="text"
                                    fullWidth
                                    value={newDesignation}
                                    onChange={(e) =>
                                      setNewDesignation(e.target.value)
                                    }
                                    inputProps={{ style: { color: "black" } }}
                                    InputLabelProps={{
                                      style: { color: "black" },
                                    }}
                                    sx={{
                                      border: "1px solid black",
                                      borderRadius: "5px",
                                      "& .MuiOutlinedInput-root": {
                                        borderColor: "black",
                                        "&:hover fieldset": {
                                          borderColor: "black",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "black",
                                        },
                                      },
                                      "& .MuiOutlinedInput-input": {
                                        "&::placeholder": { color: "gray" },
                                      },
                                    }}
                                  />
                                </Stack>
                              </Grid>

                              <Grid item xs={12} sm={6} md={5.5}>
                                <Stack direction="column" spacing={2}>
                                  <Typography>Parent</Typography>
                                  <Select
                                    value={newParentDesignation}
                                    autoFocus
                                    fullWidth
                                    onChange={(e) =>
                                      setNewParentDesignation(e.target.value)
                                    }
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    sx={{
                                      border: "1px solid black",
                                      boxSizing: "border-box",
                                      "& .MuiOutlinedInput-root": {
                                        borderColor: "black !important",
                                        "&:hover fieldset": {
                                          borderColor: "black !important",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "black !important",
                                        },
                                      },
                                      color: "black",
                                    }}
                                  >
                                    <MenuItem disabled value="">
                                      <em>---</em>
                                    </MenuItem>
                                    <MenuItem value={Trainee}>Trainee</MenuItem>
                                    <MenuItem value={Junior}>Junior</MenuItem>
                                    <MenuItem value={Senior}>Senior</MenuItem>
                                    <MenuItem value={TeamLead}>
                                      Team Lead
                                    </MenuItem>
                                    <MenuItem value={ProjectManager}>
                                      Project Manager
                                    </MenuItem>
                                  </Select>
                                </Stack>
                              </Grid>
                              <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                                <DialogActions>
                                  <Button
                                    onClick={handleDialogCloseDesig}
                                    color="primary"
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={AddDesignation}
                                    color="primary"
                                    sx={{
                                      background: "blue",
                                      color: "#fff",
                                      "&:hover": {
                                        background: "#000",
                                      },
                                    }}
                                  >
                                    Add
                                  </Button>
                                </DialogActions>
                              </Grid>
                            </Grid>
                          </Box>
                        </Dialog>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8} sm={4} md={4}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Department
                  </Typography>

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                        <Select
                          labelId="department-select-label"
                          id="department-select"
                          value={selectedDepartment}
                          onChange={(e) =>
                            setSelectedDepartment(e.target.value)
                          }
                          displayEmpty
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleAddClick}
                                edge="end"
                                sx={{
                                  color: "#000",
                                  backgroundColor: "#fff",
                                  "&:hover": {
                                    backgroundColor: "#fff",
                                    color: "#000",
                                  },
                                }}
                              >
                                Add
                              </IconButton>
                            </InputAdornment>
                          }
                          sx={{
                            border: "1px solid black",
                            boxSizing: "border-box",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            color: "black",
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>---</em>
                          </MenuItem>
                          {departments.map((department) => (
                            <MenuItem
                              key={department.id}
                              value={department.department}
                            >
                              {department.department}
                            </MenuItem>
                          ))}
                        </Select>

                        {/* Dialog for adding new department */}
                        <Dialog
                          fullWidth
                          maxWidth="md"
                          open={openDialog}
                          onClose={handleDialogClose}
                          TransitionComponent={Transition}
                          PaperProps={{
                            style: {
                              position: "absolute",
                              background: "#141b2d",
                              top: "0",
                              backgroundColor: "white",
                              maxHeight: "95%",
                              margin: "auto",
                              overflowX: "hidden",
                              zIndex: 1200,
                            },
                          }}
                        >
                          <Box sx={{ background: "#fff", color: "#000" }}>
                            <DialogTitle>
                              <span
                                style={{
                                  background: "blue",
                                  color: "#fff",
                                  padding: "15px",
                                  borderRadius: "5px",
                                }}
                              >
                                Add New Department
                              </span>
                            </DialogTitle>
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                padding: { xs: "10px", sm: "20px" },
                              }}
                            >
                              <Grid item xs={12} sm={6} md={6}>
                                <Stack direction="column" spacing={2}>
                                  <Typography>Name</Typography>
                                  <TextField
                                    autoFocus
                                    margin="dense"
                                    placeholder="Department Name"
                                    type="text"
                                    fullWidth
                                    value={newDepartment}
                                    onChange={(e) =>
                                      setNewDepartment(e.target.value)
                                    }
                                    inputProps={{ style: { color: "black" } }}
                                    InputLabelProps={{
                                      style: { color: "black" },
                                    }}
                                    sx={{
                                      border: "1px solid black",
                                      borderRadius: "5px",
                                      "& .MuiOutlinedInput-root": {
                                        borderColor: "black",
                                        "&:hover fieldset": {
                                          borderColor: "black",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "black",
                                        },
                                      },
                                      "& .MuiOutlinedInput-input": {
                                        "&::placeholder": { color: "gray" },
                                      },
                                    }}
                                  />
                                </Stack>
                              </Grid>

                              <Grid item xs={12} sm={6} md={6}>
                                <Stack direction="column" spacing={2}>
                                  <Typography>Parent</Typography>
                                  <Select
                                    value={newParentDepartment}
                                    autoFocus
                                    fullWidth
                                    onChange={(e) =>
                                      setNewParentDepartment(e.target.value)
                                    }
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    sx={{
                                      border: "1px solid black",
                                      boxSizing: "border-box",
                                      "& .MuiOutlinedInput-root": {
                                        borderColor: "black !important",
                                        "&:hover fieldset": {
                                          borderColor: "black !important",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "black !important",
                                        },
                                      },
                                      color: "black",
                                    }}
                                  >
                                    <MenuItem disabled value="">
                                      <em>---</em>
                                    </MenuItem>
                                    <MenuItem value={Marketing}>
                                      Marketing
                                    </MenuItem>
                                    <MenuItem value={Sales}>Sales</MenuItem>
                                    <MenuItem value={HumanResources}>
                                      Human Resources
                                    </MenuItem>
                                    <MenuItem value={PublicRelations}>
                                      Public Relations
                                    </MenuItem>
                                    <MenuItem value={Research}>
                                      Research
                                    </MenuItem>
                                    <MenuItem value={Finance}>Finance</MenuItem>
                                  </Select>
                                </Stack>
                              </Grid>

                              <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                                <DialogActions>
                                  <Button
                                    onClick={handleDialogClose}
                                    color="primary"
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={AddDepartment}
                                    color="primary"
                                    sx={{
                                      background: "blue",
                                      color: "#fff",
                                      "&:hover": {
                                        background: "#000",
                                      },
                                    }}
                                  >
                                    Add
                                  </Button>
                                </DialogActions>
                              </Grid>
                            </Grid>
                          </Box>
                        </Dialog>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px" }}
              >
                <Grid item xs={8} sm={3}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Mobile No
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <br></br>
                      <TextField
                        fullWidth
                        onChange={(e) => setMobile(e.target.value)}
                        placeholder="e.g.+91 12345689"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8} sm={2.5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Country
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <br></br>
                      <TextField
                        fullWidth
                        onChange={(e) => setCountry(e.target.value)}
                        placeholder="e.g.India"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8} sm={2.5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Language
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <br></br>
                      <TextField
                        fullWidth
                        onChange={(e) => setLanguage(e.target.value)}
                        placeholder="e.g.hindi, english"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8} sm={3}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    User Role
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <br></br>
                      <TextField
                        fullWidth
                        onChange={(e) => setUserRole(e.target.value)}
                        placeholder="e.g.abc"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={10} sm={10}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Address
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <br></br>
                      <TextField
                        fullWidth
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="e.g.No:2468,Street, Nagar, City"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={10} sm={10}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    About
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <br></br>
                      <TextField
                        fullWidth
                        onChange={(e) => setAbout(e.target.value)}
                        placeholder="Type here..."
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Button
                variant="contained"
                autoFocus
                color="inherit"
                onClick={SaveDate}
                sx={{
                  backgroundColor: "blue",
                  "&:hover": { backgroundColor: "orange" },
                  textAlign: "center",
                  margin: "25px",
                }}
              >
                Save
              </Button>
            </Box>
          </Dialog>
        </React.Fragment>

        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <Box
              sx={{
                maxWidth: "100%",
              }}
            >
              <MUIDataTable
                title={
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "1.5rem",
                      color: "#333",
                    }}
                  >
                    Employee Lists
                  </span>
                }
                data={employeeData}
                columns={columns}
                options={options}
              />
            </Box>
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default Employee;
