import React from "react";
import "tailwindcss/tailwind.css";
import "./Reports.css";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import AttributionIcon from "@mui/icons-material/Attribution";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Placeholder } from "react-bootstrap";
function Reports() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const getMuiTheme = () =>
    createTheme({
      typography: {
        fontFamily: "Poppins",
      },
      palette: {
        background: {
          paper: "#1e293b",
          default: "0f172a",
        },
        mode: "dark",
      },
      components: {
        MuiTableCell: {
          styleOverrides: {
            head: {
              padding: "10px 4px",
            },
            body: {
              padding: "7px 15px",
              color: "#e2e8f0",
            },
          },
        },
      },
    });

  return (
    <div className="py-10 min-h-screen grid place-items-center">
      <br></br>
      <div className="w-10/12 max-w-4xl">
        <ThemeProvider theme={getMuiTheme()}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3} sm={4}>
              <Item
                style={{
                  height: "120px",
                  backgroundColor: "#fff",
                  color: "#000",
                }}
              >
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  style={{ fontSize: "20px" }}
                >
                  Job Application
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ textAlign: "right" }}
                >
                  <DriveFileRenameOutlineIcon></DriveFileRenameOutlineIcon>
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontSize: "16px" }}
                  color="blue"
                >
                  275
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={6} md={3} sm={4}>
              <Item
                style={{
                  height: "120px",
                  backgroundColor: "#fff",
                  color: "#000",
                }}
              >
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  style={{ fontSize: "20px" }}
                >
                  Job Posted
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ textAlign: "right" }}
                >
                  <AttributionIcon></AttributionIcon>
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontSize: "16px" }}
                  color="blue"
                >
                  42
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={6} md={3} sm={4}>
              <Item
                style={{
                  height: "120px",
                  backgroundColor: "#fff",
                  color: "#000",
                }}
              >
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  style={{ fontSize: "20px" }}
                >
                  Candidate Hired
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ textAlign: "right" }}
                >
                  <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontSize: "16px" }}
                  color="blue"
                >
                  140
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={6} md={3} sm={4}>
              <Item
                style={{
                  height: "120px",
                  backgroundColor: "#fff",
                  color: "#000",
                }}
              >
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  style={{ fontSize: "20px" }}
                >
                  Interview Scheduled
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ textAlign: "right" }}
                >
                  <ScheduleIcon></ScheduleIcon>
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontSize: "16px" }}
                  color="blue"
                >
                  12
                </Typography>
              </Item>
            </Grid>
          </Grid>
          <br></br>
          <Box
            component="section"
            sx={{
              height: "320px",
              p: 2,
              backgroundColor: "#fff",
              color: "#000",
            }}
          >
            <Typography
              variant="body2"
              gutterBottom
              style={{ textAlign: "center" }}
            >
              <ScheduleIcon></ScheduleIcon>
            </Typography>
            <Typography
              gutterBottom
              variant="subtitle1"
              style={{ textAlign: "center", fontSize: "20px" }}
            >
              <Placeholder>- Not Enough Data -</Placeholder>
            </Typography>
          </Box>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default Reports;
