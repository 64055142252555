import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

const ContractFiles = () => {
  const [updatePhoto, setUpdatePhoto] = useState();
  const [photo, setPhoto] = useState();
  return (
    <Box
      sx={{
        padding: { xs: "10px", sm: "20px", md: "30px" }, // Responsive padding
        margin: { xs: "10px", sm: "20px" }, // Responsive margin
        backgroundColor: "white",
        borderRadius: "8px", // Rounded corners for better appearance
        boxShadow: 2, // Subtle shadow for depth
      }}
    >
      <Typography
        component="div"
        sx={{
          ml: 2,
          flex: 1,
          color: "black",
          fontSize: { xs: "16px", sm: "18px", md: "20px" }, // Responsive font size
          fontWeight: 500, // Added font weight for emphasis
          textAlign: { xs: "center", sm: "left" }, // Center title on small screens
        }}
      >
        Files
      </Typography>

      <hr
        style={{
          color: "gray",
          marginTop: "10px",
          marginBottom: "20px",
          border: "none",
          borderTop: "1px solid #eee", // Improved HR appearance
        }}
      />

      <Grid container sx={{ padding: "20px" }}>
        <Stack direction="column" sx={{ width: "100%" }}>
          <label
            htmlFor="file-upload"
            style={{
              cursor: "pointer",
              color: "black",
              border: "1px solid black",
              borderRadius: "4px",
              padding: "10px",
              width: "100%",
              boxSizing: "border-box",
              minHeight: "100px", // Set your desired minimum height
              backgroundColor: "lightgray", // Change background color to light gray
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Choose a File
          </label>
          <input
            // onChange={handlePhoto} // Call handleFileChange when file changes
            type="file"
            id="file-upload"
            style={{ display: "none" }}
          />
          {updatePhoto && <div style={{ color: "black" }}>{photo}</div>}
        </Stack>
      </Grid>
      <Button
        variant="contained"
        autoFocus
        color="inherit"
        // onClick={UpdateData}
        sx={{
          backgroundColor: "blue",
          "&:hover": { backgroundColor: "orange" },
          textAlign: "center",
          margin: "25px",
        }}
      >
        Save
      </Button>
    </Box>
  );
};

export default ContractFiles;
