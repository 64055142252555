import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React from "react";

const ContractRenewal = () => {
  return (
    <Box
      sx={{
        padding: { xs: "10px", sm: "20px", md: "30px" }, // Responsive padding
        margin: { xs: "10px", sm: "20px" }, // Responsive margin
        backgroundColor: "white",
        borderRadius: "8px", // Rounded corners for better appearance
        boxShadow: 2, // Subtle shadow for depth
      }}
    >
      <Typography
        component="div"
        sx={{
          ml: 2,
          flex: 1,
          color: "black",
          fontSize: { xs: "16px", sm: "18px", md: "20px" }, // Responsive font size
          fontWeight: 500, // Added font weight for emphasis
          textAlign: { xs: "center", sm: "left" }, // Center title on small screens
        }}
      >
        Contract Renewal History
      </Typography>

      <hr
        style={{
          color: "gray",
          marginTop: "10px",
          marginBottom: "20px",
          border: "none",
          borderTop: "1px solid #eee", // Improved HR appearance
        }}
      />

      <Grid
        container
        spacing={2}
        sx={{
          marginTop: { xs: "10px", sm: "20px" }, // Responsive margin
          padding: { xs: "10px", sm: "20px" }, // Responsive padding
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Center items on smaller screens
        }}
      >
        <Grid
          item
          xs={12}
          md={4}
          sm={4}
          sx={{ paddingRight: { xs: 0, sm: 2 } }}
        >
          <Stack direction="column">
            <Typography variant="p" gutterBottom style={{ color: "black" }}>
              Start Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker"]}
                // onChange={(e) => handleDateChange(e.target.value)}
                sx={{ marginTop: "-10px" }}
              >
                <DatePicker
                  //value={date}
                  //onChange={handleDateChange}
                  sx={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      color: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    "& .MuiSvgIcon-root": {
                      color: "black",
                    },
                  }}
                >
                  {/* TextField component now becomes a child of DatePicker */}
                  {(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      // Add any additional TextField props if needed
                    />
                  )}
                </DatePicker>
              </DemoContainer>
            </LocalizationProvider>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sm={4}
          sx={{ paddingRight: { xs: 0, sm: 2 } }}
        >
          <Stack direction="column">
            <Typography variant="p" gutterBottom style={{ color: "black" }}>
              End Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker"]}
                //onChange={(e) => handleDateChange(e.target.value)}
                sx={{ marginTop: "-10px" }}
              >
                <DatePicker
                  // value={date}
                  // onChange={handleDateChange}
                  sx={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      color: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    "& .MuiSvgIcon-root": {
                      color: "black",
                    },
                  }}
                >
                  {/* TextField component now becomes a child of DatePicker */}
                  {(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      // Add any additional TextField props if needed
                    />
                  )}
                </DatePicker>
              </DemoContainer>
            </LocalizationProvider>
          </Stack>
        </Grid>
        {/* New USD Input Field */}
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          sx={{
            paddingRight: { xs: "0", sm: "2" }, // Adjust padding on small
          }}
        >
          <FormControl variant="outlined">
            <Typography variant="p" gutterBottom style={{ color: "black" }}>
              Contract Value
            </Typography>
            <OutlinedInput
              id="outlined-adornment-weight"
              sx={{ border: "1px solid black", color: "#000" }}
              endAdornment={
                <InputAdornment position="end">
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    USD
                  </Typography>
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
                border: "1px solid black",
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "flex-start" }, // Center button on small screens
          marginTop: "20px",
        }}
      >
        <Button
          variant="contained"
          color="inherit"
          // onClick={UpdateData}
          sx={{
            backgroundColor: "blue",
            color: "white", // White text for better contrast
            "&:hover": { backgroundColor: "orange" },
            textAlign: "center",
            padding: "10px 20px", // Padding for button
            fontSize: "16px", // Responsive button font size
            transition: "background-color 0.3s", // Smooth transition for hover
          }}
        >
          Renew
        </Button>
      </Box>
    </Box>
  );
};

export default ContractRenewal;
