import { createTheme, ThemeProvider, Select } from "@mui/material";
import React, { useEffect, useState } from "react";

import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import Slide from "@mui/material/Slide";

import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";

import StatusAlert from "../../../StatusAlertList/StatusAlert";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function DriverJoined() {
  const [joinedData, setJoinedData] = useState([]);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleChangeSelected = async (event, ticketId) => {
    const selectedValue = event.target.value;

    // Perform any state update or action based on selectedValue and ticketId
    const itemStatus = {
      status: selectedValue,
    };

    // Create a new FormData object
    const formData = new FormData();

    for (const key in itemStatus) {
      if (itemStatus[key] !== undefined && itemStatus[key] !== null) {
        formData.append(key, itemStatus[key]);
      }
    }

    let result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/changestatus/${ticketId}`,
      {
        method: "POST",
        body: formData,
      }
    );

    await result.json();

    showAlert("updated successfully", "success");

    getData();
  };
  const location = useLocation();
  const rowData = location.state;
  const { id } = rowData || {};

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/getConvoyJoiningsByID/${id}`,
      {
        method: "POST",
      }
    );

    results = await results.json();

    setJoinedData(results);
  }

  const columns = [
    {
      name: "userData",
      label: "Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Name
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value.username || " ").charAt(0).toUpperCase() +
                (value.username || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "userData",
      label: "Applied On",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Applied On
            </th>
          );
        },
        customBodyRender: (value) => {
          const date = new Date(value.created_at);
          const formattedDate = format(date, "dd/MM/yyyy, HH:mm:ss");
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {formattedDate || " N/A"}
            </div>
          );
        },
      },
    },
    {
      name: "userData",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0].user_id;

          return (
            <div
              key={value.Status}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Select
                value={value.Status}
                onChange={(event) => handleChangeSelected(event, ticketId)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === "1") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Open
                      </MenuItem>
                    ); //Placeholder text
                  }

                  if (selected === "0") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Closed
                      </MenuItem>
                    ); // Placeholder text
                  }
                  return selected; //Show selected value
                }}
                sx={{
                  width: "110px",

                  border: "none",
                  color: "#000 ",
                  bgcolor: "#fff",
                  "& .MuiSelect-select": {
                    padding: 0,
                  },
                }}
              >
                <MenuItem
                  value={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Open
                </MenuItem>
                <MenuItem
                  value={0}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Closed
                </MenuItem>
              </Select>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: false,
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        // marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
        // display: "flex",
        // justifyContent: "space-around",
        // alignItems: "center",
      },
    },
    // rows: {
    //   style: {
    //     marginLeft: "5px",
    //     textAlign: "center",
    //   },
    //   selectableRowsOnClick: true,
    // },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <React.Fragment></React.Fragment>
      <List style={{ background: "none" }}>
        <StatusAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          severity={alertSeverity}
        />
        <ListItemText style={{ background: "none" }}></ListItemText>
        <Divider />
        <ListItemText style={{ background: "white", margin: 0, width: "100%" }}>
          <ThemeProvider theme={getTheme()}>
            <MUIDataTable
              title={"Joining Details"}
              data={joinedData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default DriverJoined;
