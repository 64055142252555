import * as React from "react";

import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { Box } from "@mui/material";

export default function AmountView() {
  const [selection, setSelection] = React.useState("");
  const pageControl = () => {
    if (selection === "") {
      return <div>{/* Weekly */}</div>;
    } else if (selection === 1) {
      return <div>{/* Monthly */}</div>;
    } else if (selection === 2) {
      return <div>{/* Yearly */}</div>;
    }
  };
  const handleChange = (event) => {
    setSelection(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ width: "90px" }}>
        <div>
          <Select
            value={selection}
            onChange={handleChange}
            displayEmpty
            disableUnderline
            sx={{
              padding: "5px",
              border: "1px solid gray",
              color: "#fff",
              bgcolor: "gray",
              "& .MuiSelect-select": {
                padding: 0,
              },
            }}
          >
            <MenuItem value="" selected>
              Weekly
            </MenuItem>
            <MenuItem value={1}>Monthly</MenuItem>
            <MenuItem value={2}>Yearly</MenuItem>
          </Select>
        </div>
        <br />
        {/* <Box sx={{ width: "100%" }}>{pageControl()}</Box> */}
      </FormControl>
    </div>
  );
}
