import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  const headerHeight = "50px";
  const leftSidebarWidth = "200px";
  const settingSidebarWidth = "180px";

  return {
    container: {
      display: "flex",
      height: "100vh",
      // Ensure full height for the main container
    },
    left: {
      // width: leftSidebarWidth,
      position: "sticky",
      top: 0,
      height: "100vh", // Full height to ensure it's sticky within the viewport
      overflowY: "auto",
      backgroundColor: "white",
      zIndex: 1000,
    },
    right: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    top: {
      height: headerHeight,
      position: "sticky",
      top: 0,
      zIndex: 1000,
      backgroundColor: "white", // Ensure the top bar has a background color
    },
    main: {
      display: "flex",
      // paddingInline: "0.8rem",
      // gap: "0.8rem",
      backgroundColor: "#eff1f1 ",
      flex: 1,
      overflowY: "auto",
      // Ensure the main content is scrollable
    },
    sidebar: {
      // width: settingSidebarWidth, // Ensure the width is specified
      zIndex: 1000,
      height: `calc(100vh - ${headerHeight})`,
      position: "sticky",
      top: 0,
      backgroundColor: "white", // Ensure the sidebar has a background color
    },
    mainContent: {
      flex: 1,
      // padding: "1rem .5rem",
      backgroundColor: "#eff1f1 ",
    },
  };
});

export default useStyles;
