import React, { useState } from "react";
import {
  Box,
  CssBaseline,
  IconButton,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";

import { useLocation } from "react-router-dom";
import DrawerMain from "./DrawerMain.jsx";
import MainSidebar from "./MainSidebar.jsx";
import SettingsSidebar from "../Settings/SettingsSidebar.jsx";
import Topbar from "./Topbar.jsx";
import { ColorModeContext, useMode } from "../../theme.js";
import useStyles from "./LayoutStyle.jsx";
import LocationPath from "./LocationPath.jsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const PageLayout = ({ children }) => {
  const [theme, colorMode] = useMode(); // Ensure correct destructuring
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [settingSidebar, setSettingSidebar] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const isDown = useMediaQuery(theme.breakpoints.down("md"));

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleCloseSidebar = () => {
    setShowSidebar(false);
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* main container */}
        <Box className={classes.container}>
          <DrawerMain
            anchor="right"
            open={settingSidebar}
            onClose={() => setSettingSidebar(false)}
          >
            <SettingsSidebar
              isDown={isDown}
              onClose={() => setSettingSidebar(false)}
            />
          </DrawerMain>
          {isDown ? (
            <DrawerMain
              onClose={handleCloseSidebar}
              open={showSidebar}
              anchor="left"
            >
              <MainSidebar
                showSidebar={showSidebar}
                onClose={handleCloseSidebar}
              />
            </DrawerMain>
          ) : (
            <Box className={classes.left}>
              <MainSidebar collapsed={isCollapsed} />
            </Box>
          )}

          <Box className={classes.right}>
            <Box className={classes.top}>
              <Topbar toggleSidebar={toggleSidebar} />
            </Box>
            {isDown && (
              <Box display="flex" justifyContent="space-between" p={2}>
                <LocationPath location={location} />
                {location.pathname.includes("settings") && (
                  <IconButton
                    onClick={() => setSettingSidebar(!settingSidebar)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
              </Box>
            )}
            <Box className={classes.main}>
              {!isDown && location.pathname.includes("settings") && (
                <Box className={classes.sidebar}>
                  <SettingsSidebar />
                </Box>
              )}
              <Box className={classes.mainContent}>{children}</Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default PageLayout;
