import React, { useEffect, useState } from "react";
import "tailwindcss/tailwind.css";
import "./CraneOwner.css";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FormControl from "@mui/material/FormControl";
import CraneOwnView from "./CraneOwnView";

function CraneOwner() {
  const [craneOwner, setCraneOwner] = useState([]);
  const [users, setUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleViewClick = () => {
    // Implement logic to handle view action
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      name: "username",
      label: "Name",
    },
    {
      name: "phone",
      label: "Phone",
    },
    {
      name: "address",
      label: "Address", // to rename column
    },
    {
      name: "role",
      label: "Role",
    },
    {
      name: "Status",
      label: "Status",
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <FormControl>
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem>
                <CraneOwnView></CraneOwnView>
              </MenuItem>
              <MenuItem onClick={handleCloseMenu}>Edit</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Change to Client</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Add Follow up</MenuItem>
            </Menu>
          </FormControl>
        ),
      },
    },
  ];

  const handleStatusChange = (event, rowIndex) => {
    const newUsers = [...users];
    newUsers[rowIndex].status = event.target.value;
    setUsers(newUsers);
  };

  useEffect(() => {
    getData();
  }, []);
  const convertToUserType = (value) => {
    if (value === "1") return "Enabled";
    if (value === "2") return "Disabled";
    return "Unknown"; // Fallback for invalid values
  };
  async function getData() {
    // const response = await craneOwner.getAll();
    // setCraneOwner(response);
  }

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    elevation: 0,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 25, 100],
    onChangeRowsPerPage: (newRowsPerPage) => {
      setRowsPerPage(newRowsPerPage);
    },
    tableBody: {
      style: {
        maxHeight: `${rowsPerPage * 50}px`,
        overflow: "auto",
        marginLeft: "5px",
        transform: "translateZ(0)",
        backfaceVisibility: "hidden",
        boxShadow: "none",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <div className="py-10 min-h-screen grid place-items-center">
      <br />
      <div className="w-10/12 max-w-4xl">
        <ThemeProvider theme={getTheme()}>
          <MUIDataTable
            title="Crane Owner list"
            data={craneOwner}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </div>
    </div>
  );
}

export default CraneOwner;
