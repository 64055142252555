import ProjectSettings from "./ProjectSettings/ProjectSettings";
import SettingPageLayout from "./SettingPageLayout";

function MainProjectSettings({ isDown }) {
  return (
    <SettingPageLayout>
      <ProjectSettings isDown={isDown} />
    </SettingPageLayout>
  );
}

export default MainProjectSettings;
