import { MenuItem, Select } from "@mui/material";
import * as React from "react";

export default function SelectMenu({ menu }) {
  const [selectedValue, setSelectedValue] = React.useState("None");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <Select
      defaultValue={menu[0]}
      onChange={handleChange}
      value={selectedValue}
    >
      {menu.map((item) => (
        <MenuItem value={item}>{item}</MenuItem>
      ))}
    </Select>
  );
}
