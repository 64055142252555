import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GroupIcon from '@mui/icons-material/Group';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import './AdminTicket.css';
import HelpIcon from '@mui/icons-material/Help';
import { Placeholder } from 'react-bootstrap';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Stack from '@mui/material/Stack';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

function AdmTicket() {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (
        <Box display="flex" flexDirection="column">
        <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
        <Grid item xs={12} sm={6}>

        <Item elevation={2} style={{  backgroundColor: "#fff" }}>
                                <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left",fontSize: "20px",color: "#4d4f5c" }}>
                                    Tickets
                                </Typography>
                                <Typography variant="body2" gutterBottom style={{ textAlign: "right" ,color: "#4d4f5c"}}>
                                {/* <GroupIcon></GroupIcon> */}
                                </Typography>
                                <Stack direction="row" width="100%" textAlign="center" spacing={2}>
                                <Typography component="div" variant="body2" style={{ textAlign: "left",fontSize: "16px" }} color="red">
                                    25<br></br><p style={{color:"#4d4f5c"}}>Unresolved Tickets<HelpIcon></HelpIcon></p>
                                    
                                </Typography>&nbsp;&nbsp;&nbsp;&nbsp;
                                <Typography component="div" variant="body2" style={{ textAlign: "left",fontSize: "16px" }} color="blue">
                                    5<br></br><p style={{color:"#4d4f5c"}}>Resolved Tickets<HelpIcon></HelpIcon></p>
                                    
                                </Typography>
                                </Stack>
                            </Item>
            </Grid>

            <Grid item xs={12} sm={6}>
            <Item elevation={2} style={{  backgroundColor: "#fff" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left",fontSize: "20px",color: "#4d4f5c" }}>
                                    Total Unassigned Tickets
                                </Typography>
                                <Typography variant="body2" gutterBottom style={{ textAlign: "right",color: "#4d4f5c" }}>
                                <GroupIcon></GroupIcon>
                                </Typography>
                                <Typography variant="body2" style={{textAlign: "left", fontSize: "16px" }} color="blue">
                                    42
                                </Typography>
                            </Item>
            </Grid>

            </Grid>

            <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
        <Grid item xs={12} sm={6}>
        <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff" }}>
                                <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left",fontSize: "20px",color: "#161e29" }}>
                                   Type Wise Ticket
                                </Typography>
                                <Typography variant="body2" gutterBottom style={{ textAlign: "center",color: "#4d4f5c" }}>
                                <SignalCellularAltIcon></SignalCellularAltIcon>
                                </Typography>
                                <Typography variant="body2" style={{textAlign: "centre", fontSize: "16px" ,color: "#4d4f5c"}}>
                                    <Placeholder>-Not Enough Data-</Placeholder>
                                </Typography>
                            </Item>
            </Grid>
            <Grid item xs={12} sm={6}>
            <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left",fontSize: "20px",color: "#161e29" }}>
                                    Status Wise Ticket
                                </Typography>
                                <br></br>
                                <Typography variant="body2" gutterBottom style={{ textAlign: "center",color: "#4d4f5c" }}>
                                <SignalCellularAltIcon></SignalCellularAltIcon>
                                </Typography>
                                <Typography variant="body2" style={{ fontSize: "16px" ,color: "#4d4f5c"}}>
                                <Placeholder>-Not Enough Data-</Placeholder>
                                </Typography>
                            </Item>
            </Grid>
            </Grid>


            <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
        <Grid item xs={12} sm={6}>
        <Item elevation={2}style={{ height: '320px', backgroundColor: "#fff" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left",fontSize: "20px",color: "#161e29" }}>
                                    Channel Wise Ticket
                                </Typography>
                                <br></br>
                                <Typography variant="body2" gutterBottom style={{ textAlign: "center",color: "#4d4f5c" }}>
                                <CalendarTodayIcon></CalendarTodayIcon>
                                </Typography>
                                <Typography variant="body2" style={{ fontSize: "16px" ,color: "#4d4f5c"}}>
                                <Placeholder>-Not Enough Data-</Placeholder>
                                </Typography>
                            </Item>
            </Grid>
            <Grid item xs={12} sm={6}>
            <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff" }}>
                            <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left",fontSize: "20px",color: "#161e29" }}>
                                    Open Tickets<HelpIcon></HelpIcon>
                                </Typography>
                                <br></br>
                                <Typography variant="body2" gutterBottom style={{ textAlign: "center",color: "#4d4f5c" }}>
                                <LocalActivityIcon></LocalActivityIcon>
                                </Typography>
                                <Typography variant="body2" style={{ fontSize: "16px" ,color: "#4d4f5c"}}>
                                <Placeholder>-Not Enough Data-</Placeholder>
                                </Typography>
                            </Item>
            </Grid>
            </Grid>
            </Box>
            </Box>
    )
}

export default AdmTicket;