import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GroupIcon from '@mui/icons-material/Group';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import './AdminFinance.css';
import HelpIcon from '@mui/icons-material/Help';
import { Placeholder } from 'react-bootstrap';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GroupsIcon from '@mui/icons-material/Groups';
import { LineChart } from '@mui/x-charts/LineChart';

function AdmFinance() {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (
        <Box display="flex" flexDirection="column">
      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} direction="row" style={{ marginBottom: '10px' }}>
          <Grid item xs={12} sm={4}>
          <Item elevation={3} style={{ backgroundColor: "#fff" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                            Subscription
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                            <GroupIcon></GroupIcon>
                        </Typography>
                        <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                            275
                        </Typography>
                    </Item>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Item elevation={3} style={{ backgroundColor: "#fff" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                            Transactions
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                            <GroupIcon></GroupIcon>
                        </Typography>
                        <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                            42
                        </Typography>
                    </Item>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Item elevation={3} style={{ backgroundColor: "#fff" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                            Settlement
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                            <LocalActivityIcon></LocalActivityIcon>
                        </Typography>
                        <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                            140
                        </Typography>
                    </Item>
          </Grid>
        </Grid>


        <Grid container spacing={2} direction="row" style={{ marginBottom: '10px' }}>
          <Grid item xs={12} sm={4}>
          <Item elevation={3} style={{ backgroundColor: "#fff" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                            Total Earnings
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                            <LocalActivityIcon></LocalActivityIcon>
                        </Typography>
                        <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                            140
                        </Typography>
                    </Item>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Item elevation={3} style={{ backgroundColor: "#fff" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                            Monthly Earnings
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                            <LocalActivityIcon></LocalActivityIcon>
                        </Typography>
                        <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                            9
                        </Typography>
                    </Item>
          </Grid>
          <Grid item xs={12} sm={4}>
          <Item elevation={3} style={{ backgroundColor: "#fff" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                            Total Expenses
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                            <LocalActivityIcon></LocalActivityIcon>
                        </Typography>
                        <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                            275
                        </Typography>
                    </Item>
          </Grid>
        </Grid>


        <Grid  style={{ marginBottom: '10px' }}>
          <Grid item >
          <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                            Finance overview<HelpIcon />
                        </Typography>
                        <br></br>
                        <LineChart
                        width={1000}
                        height={200}
                            series={[
                                { curve: "linear", data: [0, 5, 2, 6, 3, 9.3],label:"Expenses" },
                                { curve: "linear", data: [6, 3, 7, 9.5, 4, 2],label:"Settlement" },
                            ]}
                        />
                    </Item>
            </Grid>

           
          </Grid>

          <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
          <Grid item xs={12} sm={6}>
            <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                            Subscription Overview
                        </Typography>
                        <br></br>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                            <SignalCellularAltIcon></SignalCellularAltIcon>
                        </Typography>
                        <Typography variant="body2" style={{ fontSize: "16px", color: "#4d4f5c" }}>
                            <Placeholder>-Not Enough Data-</Placeholder>
                        </Typography>
                    </Item>
            </Grid>
            <Grid item xs={12} sm={6}>
          <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                            Expenses Overview<HelpIcon></HelpIcon>
                        </Typography>
                        <br></br>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                            <CalendarTodayIcon></CalendarTodayIcon>
                        </Typography>
                        <Typography variant="body2" style={{ fontSize: "16px", color: "#4d4f5c" }}>
                            <Placeholder>-Not Enough Data-</Placeholder>
                        </Typography>
                    </Item>
            </Grid>


          </Grid>
      

          <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
          
            <Grid item xs={12} sm={6}>
            <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                            Month Wise Earnings
                        </Typography>
                        <br></br>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                            <LocalActivityIcon></LocalActivityIcon>
                        </Typography>
                        <Typography variant="body2" style={{ fontSize: "16px", color: "#4d4f5c" }}>
                            <Placeholder>-Not Enough Data-</Placeholder>
                        </Typography>
                    </Item>
            </Grid>
            <Grid item xs={12} sm={6}>
          <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                            User Wise Earnings
                        </Typography>
                        <br></br>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                            <ChecklistIcon></ChecklistIcon>
                        </Typography>
                        <Typography variant="body2" style={{ fontSize: "16px", color: "#4d4f5c" }}>
                            <Placeholder>-Not Enough Data-</Placeholder>
                        </Typography>
                    </Item>
            </Grid>
          </Grid>
      

          <Grid container spacing={1} direction="row" style={{ marginBottom: '10px' }}>
         

            <Grid item xs={12} sm={6}>
            <Item elevation={2} style={{ height: '320px', backgroundColor: "#fff" }}>
                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#161e29" }}>
                            Recent Login Activities
                        </Typography>
                        <br></br>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "center", color: "#4d4f5c" }}>
                            <GroupsIcon></GroupsIcon>
                        </Typography>
                        <Typography variant="body2" style={{ fontSize: "16px", color: "#4d4f5c" }}>
                            <Placeholder>-Not Enough Data-</Placeholder>
                        </Typography>
                    </Item>
            </Grid>
          </Grid>
      
        </Box>
      </Box>
    )
}

export default AdmFinance;