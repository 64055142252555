import {
  Box,
  Grid,
  Snackbar,
  TextField,
  Alert,
  ThemeProvide,
  createTheme,
  ThemeProvider,
  Menu,
  Stack,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import HelpIcon from "@mui/icons-material/Help";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import StatusAlert from "../../../StatusAlertList/StatusAlert";
import ReactQuill from "react-quill";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useNavigate } from "react-router-dom";
import HeaderProposal from "./HeaderProposal";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Proposal() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [award, setAward] = React.useState("");
  const [givenTo, setGivenTo] = React.useState("");
  const [date, setDate] = React.useState(null);
  const [awardError, setAwardError] = React.useState(false);
  const [givenToError, setGivenToError] = useState("");
  const [dateError, setDateError] = useState("");
  const [summary, setSummary] = useState("");
  const [photo, setPhoto] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [updateGivenTo, setUpdateGivenTo] = React.useState("");
  const [updateAward, setUpdateAward] = React.useState("");
  const [updateSummary, setUpdateSummary] = React.useState("");
  const [updatePhoto, setUpdatePhoto] = React.useState("");

  const [updateDate, setUpdateDate] = React.useState("");
  const [updateGivenToError, setUpdateGivenToError] = React.useState("");
  const [updateAwardError, setUpdateAwardError] = React.useState("");
  const [updateDateError, setUpdateDateError] = React.useState("");
  const [data, SetData] = useState([]);
  const [appreciationData, setAppreciationData] = useState([]);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [update, setUpdate] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const [selectedRow, setSelectedRow] = React.useState({
    keys: [],
    values: [],
    open: false,
  });

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };
  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getTaxSettings"
    );
    results = await results.json();
    setAppreciationData(results);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleViewMore = () => {
    const rowData = appreciationData.filter((row) => row.id === openMenuId);

    navigate(`/hr/employees/${openMenuId}`, { state: rowData[0] });
    // setOpenDialog(true);
  };

  const columns = [
    {
      name: "",
      label: "Proposal",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Proposal
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Deals",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Deals
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Contact Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Contact Name
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Total",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Total
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Date",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Date
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Valid Till",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Valid Till
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "70px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                paddingRight: "20px", // Add padding for starting point
              }}
            >
              <div style={{ paddingRight: "20px" }}>
                <Button
                  id="basic-button"
                  aria-controls={openDropMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div onClick={() => handleOpenMenu(value)}>
                    <MoreHorizTwoToneIcon />
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleViewMore(tableMeta.rowData)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <RemoveRedEyeIcon /> View More
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleEdit()}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EditNoteIcon /> Edit
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleDelete(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <DeleteForeverIcon /> Delete
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </>
        ),
      },
    },
  ];
  async function handleEdit(id) {
    const result = appreciationData.filter((item) => item.id === id);
    SetData(result[0]);
    console.log(result, "fetching data");
    handleClickUpdate();
  }
  useEffect(() => {
    setUpdateGivenTo(data.givenTo);
  }, [data.givenTo]);

  useEffect(() => {
    setUpdateAward(data.award);
  }, [data.award]);
  useEffect(() => {
    setUpdateAwardError(data.awardError);
  }, [data.awardError]);
  useEffect(() => {
    setUpdateDate(data.date);
  }, [data.date]);
  useEffect(() => {
    setUpdatePhoto(data.photo);
  }, [data.photo]);
  useEffect(() => {
    setUpdateSummary(data.summary);
  }, [data.summary]);
  useEffect(() => {
    setUpdateGivenToError(data.givenToError);
  }, [data.givenToError]);

  async function UpdateData(id) {
    const formData = new FormData();

    formData.append("givenTo", updateGivenTo);
    formData.append("date", updateDate);
    formData.append("dateError", updateDateError);
    formData.append("award", updateAward);
    formData.append("photo", updatePhoto);
    formData.append("summary", updateSummary);
    formData.append("givenToError", updateGivenToError);
    formData.append("awardError", updateAwardError);
    try {
      let response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/updateTaxSettings/${data.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }
      console.log(response.json(), "this is json response");
      handleUpdateClose();
      //getData();
      // const updatedData = appreciationData.map((item) => {
      //   if (item.id === data.id) {
      //     item.tax_name = formData.get("tax_name");
      //     item.rate = formData.get("rate");
      //   }
      //   return item;
      // });
      showAlert("  Updated successfully", "success");
      // setAppreciationData(updatedData);
    } catch (error) {
      console.error(error);
      showAlert("Failed to update business location", "error");
    }
  }

  async function handleDelete(id) {
    const response = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/deleteTaxSettings/" + id,
      {
        method: "DELETE",
      }
    );
    console.log(response, "response");
    //const result = await response.json(); // Assigning to a new variable
    if (response.ok) {
      getData();
      showAlert("Tax Data Deleted Successfully!", "warning");
    } else {
      showAlert("Tax Data Deleted failed!", "error");
    }
  }

  // Save Lead Sourse
  const item = {
    award,
    givenTo,
    photo,
    summary,
    date,
  };
  async function SaveData() {
    try {
      const response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/addTaxSettings`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(item),
        }
      );
      console.log(response);
      // Check if the response is okay

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      // Read response as text for debugging

      // Try parsing the response as JSON

      showAlert("Tax Data Added Successfully!", "success");
      getData();
      setOpen(false);
    } catch (error) {
      console.error("Fetch error:", error);
      showAlert("Failed to add tax data", "error");
    }
  }
  // const handleFileChange = (e) => {
  //     const file = e.target.files[0];
  //     setFileName(file.name); // Update file name
  //     console.log(file.name); // Print file name to console
  // };

  // Event handler to update the date state and log the date to the console
  const handleDateChange = (newDate) => {
    setDate(newDate); // Update the date state
    console.log(newDate); // Log the new date to the console
  };

  const handleChange = (value) => {
    setSummary(value); // Update the state with the new value
    // console.log(value); // Print the value to the console
  };

  const handlePhoto = (e) => {
    const file = e.target.files[0];
    setPhoto(file.name);
    console.log(file.name); // You can also upload the file to a server if needed
  };

  const handleSave = () => {
    const list = { award, givenTo, date, summary, photo };
    console.log(list);
    if (award === "") {
      setAwardError(true);
    } else {
      const list = { award, givenTo, date, summary, photo };
      console.log(list);
      setAwardError(false); // Reset the error state
      handleClose(); // Close the dialog
    }
  };

  const options = {
    selectableRows: false,
    elevation: 0,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 25, 100],
    onChangeRowsPerPage: (newRowsPerPage) => {
      setRowsPerPage(newRowsPerPage);
    },
    tableBody: {
      style: {
        maxHeight: `${rowsPerPage * 50}px`,
        overflow: "auto",
        marginLeft: "5px",
        transform: "translateZ(0)",
        backfaceVisibility: "hidden",
        boxShadow: "none",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />
      <HeaderProposal />

      <Dialog
        fullWidth
        maxWidth="lg"
        open={update}
        onClose={handleUpdateClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            position: "absolute",
            background: "#141b2d",
            top: "0",
            backgroundColor: "white",
            maxHeight: "95%",
            margin: "auto",
            overflowX: "hidden",
            zIndex: 1200,
          },
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleUpdateClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} component="div">
              Update Proposal
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            padding: { xs: "15px", sm: "30px" },
            margin: { xs: "10px", sm: "20px" },
            backgroundColor: "white",
          }}
        >
          <Typography
            sx={{
              ml: 2,
              flex: 1,
              color: "black",
              fontSize: { xs: "16px", sm: "20px" },
            }}
            component="div"
          >
            Proposal Details
          </Typography>
          <hr sx={{ color: "gray" }} />

          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "20px",
              padding: { xs: "10px", sm: "20px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              sx={{ paddingRight: { xs: 0, sm: 2 } }}
            >
              <Stack direction="column" spacing={2}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Lead Contacts <span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                  value={award}
                  autoFocus
                  onChange={(e) => setAward(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  <MenuItem value={"Gold"}>Gold</MenuItem>
                  <MenuItem value={"Silver"}>Silver</MenuItem>
                  <MenuItem value={"Bronze"}>Bronze</MenuItem>
                </Select>
                {awardError && (
                  <Typography variant="caption" style={{ color: "red" }}>
                    Please select an award
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sm={6}
              sx={{ paddingRight: { xs: 0, sm: 2 } }}
            >
              <Stack direction="column" spacing={2}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Deal <span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                  value={givenTo}
                  autoFocus
                  onChange={(e) => setGivenTo(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  <MenuItem value={"Andrew"}>Andrew</MenuItem>
                  <MenuItem value={"Bohr"}>Bohr</MenuItem>
                  <MenuItem value={"Ceaser"}>Ceaser</MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sm={6}
              sx={{ paddingRight: { xs: 0, sm: 2 } }}
            >
              <Stack direction="column" spacing={1}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Valid Till
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["DatePicker"]}
                    onChange={(e) => handleDateChange(e.target.value)}
                    sx={{ marginTop: "-10px" }}
                  >
                    <DatePicker
                      value={date}
                      onChange={handleDateChange}
                      sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          color: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        "& .MuiSvgIcon-root": {
                          color: "black",
                        },
                      }}
                    >
                      {/* TextField component now becomes a child of DatePicker */}
                      {(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          // Add any additional TextField props if needed
                        />
                      )}
                    </DatePicker>
                  </DemoContainer>
                </LocalizationProvider>
              </Stack>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "20px",
              padding: { xs: "10px", sm: "20px" },
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              sx={{ paddingRight: { xs: 0, sm: 2 } }}
            >
              <Stack direction="column" spacing={2}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Currency
                </Typography>
                <Select
                  value={award}
                  autoFocus
                  onChange={(e) => setAward(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem value={""}>USD($)</MenuItem>
                  <MenuItem value={"Silver"}>IND(₹)</MenuItem>
                  <MenuItem value={"Bronze"}>EUR(€)</MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sm={6}
              sx={{ paddingRight: { xs: 0, sm: 2 } }}
            >
              <Stack direction="column" spacing={2}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Calculate Tax
                </Typography>
                <Select
                  value={givenTo}
                  autoFocus
                  onChange={(e) => setGivenTo(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem value={""}>After Discount</MenuItem>
                  <MenuItem value={"Bohr"}>Before Discount</MenuItem>
                </Select>
              </Stack>
            </Grid>
          </Grid>
          <Grid container sx={{ padding: "20px" }}>
            <Stack direction="column" sx={{ width: "100%" }}>
              <Typography variant="p" gutterBottom style={{ color: "black" }}>
                Description
              </Typography>
              {/* <CustomToolbar style={{ color: 'black' }} /> Render custom emoji toolbar */}
              <ReactQuill
                value={summary}
                onChange={handleChange}
                // modules={modules}
                // theme="snow"
                style={{
                  // border: '1px solid black',
                  // borderRadius: '4px',
                  //padding: '10px',
                  width: "100%",
                  boxSizing: "border-box",
                  color: "black",
                  borderBlockColor: "black",
                  // minHeight: '100px' // Set your desired minimum height
                }}
              />
              {/* <SuppressWarnings>
                                  <ReactQuill
                                    value={summary}
                                    onChange={handleChange}
                                    style={{
                                      width: '100%',
                                      boxSizing: 'border-box',
                                      color: 'black',
                                      borderBlockColor: 'black'
                                    }}
                                  />
                                </SuppressWarnings> */}
            </Stack>
          </Grid>
          <br></br>
          <br></br>
          <Button
            variant="contained"
            autoFocus
            color="inherit"
            onClick={handleSave}
            sx={{
              backgroundColor: "blue",
              "&:hover": { backgroundColor: "orange" },
              textAlign: "center",
              margin: "25px",
            }}
          >
            Update
          </Button>
        </Box>
      </Dialog>

      <List style={{ background: "none" }}>
        <ListItemText style={{ background: "none" }}>
          <Stack direction="row" spacing={2} sx={{ marginBottom: "5px" }}>
            <Box
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#1d82f5",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                padding: "10px",
                marginLeft: "2px",
                height: "40px",
                width: "140px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: "4px",
                fontSize: "15px",
                fontFamily: "sans-serif",
                cursor: "pointer",
                marginBottom: "5px",
              }}
            >
              <AddIcon />
              Add Proposal
            </Box>
          </Stack>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                position: "absolute",
                background: "#141b2d",
                top: "0",
                backgroundColor: "white",
                maxHeight: "95%",
                margin: "auto",
                overflowX: "hidden",
                zIndex: 1200,
              },
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                  New Proposal
                </Typography>
              </Toolbar>
            </AppBar>
            <Box
              sx={{
                padding: { xs: "15px", sm: "30px" },
                margin: { xs: "10px", sm: "20px" },
                backgroundColor: "white",
              }}
            >
              <Typography
                sx={{
                  ml: 2,
                  flex: 1,
                  color: "black",
                  fontSize: { xs: "16px", sm: "20px" },
                }}
                component="div"
              >
                Proposal Details
              </Typography>
              <hr sx={{ color: "gray" }} />

              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "20px",
                  padding: { xs: "10px", sm: "20px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column" spacing={2}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Lead Contacts <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      value={award}
                      autoFocus
                      onChange={(e) => setAward(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={"Gold"}>Gold</MenuItem>
                      <MenuItem value={"Silver"}>Silver</MenuItem>
                      <MenuItem value={"Bronze"}>Bronze</MenuItem>
                    </Select>
                    {awardError && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        Please select an award
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sm={6}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column" spacing={2}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Deal <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      value={givenTo}
                      autoFocus
                      onChange={(e) => setGivenTo(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>
                      <MenuItem value={"Andrew"}>Andrew</MenuItem>
                      <MenuItem value={"Bohr"}>Bohr</MenuItem>
                      <MenuItem value={"Ceaser"}>Ceaser</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sm={6}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column" spacing={1}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Valid Till
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={date}
                          onChange={handleDateChange}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                        >
                          {/* TextField component now becomes a child of DatePicker */}
                          {(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              // Add any additional TextField props if needed
                            />
                          )}
                        </DatePicker>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "20px",
                  padding: { xs: "10px", sm: "20px" },
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column" spacing={2}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Currency
                    </Typography>
                    <Select
                      value={award}
                      autoFocus
                      onChange={(e) => setAward(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem value={""}>USD($)</MenuItem>
                      <MenuItem value={"Silver"}>IND(₹)</MenuItem>
                      <MenuItem value={"Bronze"}>EUR(€)</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sm={6}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column" spacing={2}>
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Calculate Tax
                    </Typography>
                    <Select
                      value={givenTo}
                      autoFocus
                      onChange={(e) => setGivenTo(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        border: "1px solid black",
                        boxSizing: "border-box",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem value={""}>After Discount</MenuItem>
                      <MenuItem value={"Bohr"}>Before Discount</MenuItem>
                    </Select>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container sx={{ padding: "20px" }}>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Description
                  </Typography>
                  {/* <CustomToolbar style={{ color: 'black' }} /> Render custom emoji toolbar */}
                  <ReactQuill
                    value={summary}
                    onChange={handleChange}
                    // modules={modules}
                    // theme="snow"
                    style={{
                      // border: '1px solid black',
                      // borderRadius: '4px',
                      //padding: '10px',
                      width: "100%",
                      boxSizing: "border-box",
                      color: "black",
                      borderBlockColor: "black",
                      // minHeight: '100px' // Set your desired minimum height
                    }}
                  />
                  {/* <SuppressWarnings>
                                  <ReactQuill
                                    value={summary}
                                    onChange={handleChange}
                                    style={{
                                      width: '100%',
                                      boxSizing: 'border-box',
                                      color: 'black',
                                      borderBlockColor: 'black'
                                    }}
                                  />
                                </SuppressWarnings> */}
                </Stack>
              </Grid>
              <br></br>
              <br></br>
              <Button
                variant="contained"
                autoFocus
                color="inherit"
                onClick={handleSave}
                sx={{
                  backgroundColor: "blue",
                  "&:hover": { backgroundColor: "orange" },
                  textAlign: "center",
                  margin: "25px",
                }}
              >
                Save
              </Button>
            </Box>
          </Dialog>
        </ListItemText>
        <Divider />
        <ListItemText style={{ background: "white", margin: 0, width: "100%" }}>
          <ThemeProvider theme={getTheme()}>
            <MUIDataTable
              title={
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    color: "#333",
                  }}
                >
                  Proposal Lists
                </span>
              }
              data={appreciationData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default Proposal;
