import {
  AppBar,
  Box,
  Button,
  createTheme,
  Dialog,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Slide,
  Stack,
  TextField,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import { membershipApis } from "../../../Data/axios";
import MUIDataTable from "mui-datatables";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { getUserInfo } from "../../../Data/UserData";
import StatusAlert from "../../../StatusAlertList/StatusAlert";
const ViewMoreKnowledge = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openButton, setOpenButton] = React.useState(false);
  const openDropMenu = Boolean(anchorEl);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [data, setData] = useState([]);
  const [updateDetails, setUpdateDetails] = React.useState("");
  const [updateStatus, setUpdateStatus] = React.useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const [detailValue, setDetailValue] = useState([]);
  const [switchState, setSwitchState] = useState(
    detailValue.reduce((acc, row) => {
      acc[row.id] = row.status === "1"; // Initialize state based on the data
      return acc;
    }, {})
  );
  console.log(detailValue, "detailValue got ");

  const handleSwitchChange = (userId) => (event) => {
    const newValue = event.target.checked;
    setSwitchState((prevState) => ({
      ...prevState,
      [userId]: newValue,
    }));

    // Update the original data with the new value ("1" or "0")
    setDetailValue((prevData) =>
      prevData.map((row) =>
        row.id === userId ? { ...row, status: newValue ? "1" : "0" } : row
      )
    );

    console.log(`Row ${userId} updated status to: ${newValue ? "1" : "0"}`);
    // You can also perform an API call here if needed
  };
  // const [switchValues, setSwitchValues] = useState({});

  // const handleSwitchChange = (event, rowId) => {
  //   const newStatus = event.target.checked ? 1 : 0;
  //   setSwitchValues((prevState) => ({
  //     ...prevState,
  //     [rowId]: newStatus,
  //   }));
  // };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#899",
    color: "#000",

    ...theme.typography.body2,
    padding: theme.spacing(1),
    cursor: "pointer",
    textAlign: "center",
    ...theme.applyStyles("dark", {
      backgroundColor: "#899",
      color: "#000",
    }),
  }));

  const location = useLocation();
  const openMenuIdNew = location.state;
  useEffect(() => {
    getDataPass();
  }, [openMenuIdNew]);
  const getDataPass = async () => {
    const rowData = await membershipApis.membershipViewMoreId(openMenuIdNew);
    setDetailValue(rowData);
  };

  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const handleUpdateButton = () => {
    setOpenButton(true);
  };
  const handleCloseButton = () => {
    setOpenButton(false);
  };
  async function handleEdit() {
    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/getMembershiplist/" + openMenuId
    );
    const resultNew = await result.json();

    setData(resultNew);
    handleUpdateButton();
  }
  useEffect(() => {
    setUpdateDetails(data.text);
  }, [data.text]);

  useEffect(() => {
    setUpdateStatus(data.status);
  }, [data.status]);
  async function UpdateData() {
    const formData = new FormData();
    formData.append("text", updateDetails);
    formData.append("status", updateStatus);
    formData.append("membership_id", openMenuIdNew);
    formData.append("created_by", getUserInfo());

    try {
      let response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/updateMembershiplist/${data.id}`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update data");
      }
      showAlert(" updated successfully", "success");
      handleCloseButton();
      getDataPass();
    } catch (error) {
      console.error(error);
      showAlert("Failed to update business location", "error");
    }
  }
  const columns = [
    {
      name: "text",
      label: "Details",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Details
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value || " "}
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value, tableMeta, index) => {
          const rowId = tableMeta.rowData[2];

          return (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Switch
                checked={switchState[rowId] || false}
                onChange={handleSwitchChange(rowId)}
                inputProps={{ "aria-label": `Switch for row ${rowId}` }}
              />
            </div>
          );
          // return (
          //   <div
          //     key={index}
          //     style={{
          //       display: "flex",
          //       justifyContent: "center",
          //       alignItems: "center",
          //     }}
          //   >
          //     <Select
          //       value={value}
          //       // onChange={(event) => handleChangeSelected(event, value.id)}
          //       displayEmpty
          //       renderValue={(selected) => {
          //         if (selected === "1") {
          //           return (
          //             <MenuItem sx={{ display: "flex", alignItems: "center" }}>
          //               Active
          //             </MenuItem>
          //           ); // Placeholder text
          //         }
          //         if (selected === "0") {
          //           return (
          //             <MenuItem sx={{ display: "flex", alignItems: "center" }}>
          //               Relieve
          //             </MenuItem>
          //           ); // Placeholder text
          //         }
          //         if (selected === "2") {
          //           return (
          //             <MenuItem sx={{ display: "flex", alignItems: "center" }}>
          //               Leave
          //             </MenuItem>
          //           ); // Placeholder text
          //         }
          //         return selected; // Show selected value
          //       }}
          //       sx={{
          //         width: "110px",

          //         border: "none",
          //         color: "#000 ",
          //         bgcolor: "#fff",
          //         "& .MuiSelect-select": {
          //           padding: 0,
          //         },
          //       }}
          //     >
          //       <MenuItem
          //         value={1}
          //         sx={{ display: "flex", alignItems: "center" }}
          //       >
          //         Active
          //       </MenuItem>
          //       <MenuItem
          //         value={0}
          //         sx={{ display: "flex", alignItems: "center" }}
          //       >
          //         Relieve
          //       </MenuItem>
          //       <MenuItem
          //         value={2}
          //         sx={{ display: "flex", alignItems: "center" }}
          //       >
          //         Leave
          //       </MenuItem>
          //     </Select>
          //   </div>
          // );
          //  <Switch
          //       checked={switchValues[rowId] === 1 || value === 1} // Use the state for this row or the original value
          //       onChange={(event) => handleSwitchChange(event, rowId)}
          //       color="primary"
          //       inputProps={{ "aria-label": "controlled" }}
          //     />
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "70px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                paddingRight: "20px", // Add padding for starting point
              }}
            >
              <div style={{ paddingRight: "20px" }}>
                <Button
                  id="basic-button"
                  aria-controls={openDropMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div onClick={() => handleOpenMenu(value)}>
                    <MoreHorizTwoToneIcon />
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleEdit()}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EditNoteIcon /> Edit
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </>
        ),
      },
    },
  ];
  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    elevation: 0,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 25, 100],
    onChangeRowsPerPage: (newRowsPerPage) => {
      setRowsPerPage(newRowsPerPage);
    },
    tableBody: {
      style: {
        maxHeight: `${rowsPerPage * 50}px`,
        overflow: "auto",
        marginLeft: "5px",
        transform: "translateZ(0)",
        backfaceVisibility: "hidden",
        boxShadow: "none",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {},
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });
  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />
      <Dialog
        fullWidth
        maxWidth="md"
        open={openButton}
        onClose={handleCloseButton}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            position: "absolute",
            background: "#141b2d",
            top: "0",
            backgroundColor: "white",
            maxHeight: "95%",
            margin: "auto",
            overflowX: "hidden",
            zIndex: 1200,
          },
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseButton}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} component="div">
              Update Membership Details
            </Typography>
          </Toolbar>
        </AppBar>

        <Box sx={{ background: "#fff" }}>
          <Grid
            container
            spacing={2}
            style={{ color: "black", margin: "10px" }}
          >
            <Grid item xs={11} sm={10} md={10}>
              <Stack direction="column" spacing={2}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Detail
                </Typography>
                <TextField
                  value={updateDetails}
                  onChange={(e) => setUpdateDetails(e.target.value)}
                  fullWidth
                  placeholder="eg.abc"
                  type="text"
                  inputProps={{ style: { color: "black" } }}
                  InputLabelProps={{ style: { color: "black" } }}
                  sx={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black",
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      "&::placeholder": { color: "gray" },
                    },
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container sx={{ padding: "0 0 20px 20px" }}>
            <Grid item xs={11} className="messageFooter">
              <Box className="response" onClick={UpdateData}>
                Update
              </Box>
              <Box className="cancel" onClick={handleCloseButton}>
                Cancel
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <Box
        sx={{
          color: "#333",
          padding: "20px",
          fontSize: "20px",
          fontWeight: 550,
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            paddingBottom: "5px",
          }}
        >
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Item sx={{ backgroundColor: "#fff", color: "#333" }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                  paddingLeft: "20px",
                }}
              >
                Total Purchase-
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle1"
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                  paddingLeft: "20px",
                }}
              >
                Active Membership-
              </Typography>
            </Item>
          </Grid>
        </Grid>
        <Box sx={{ color: "#333", bgcolor: "#fff", height: "75vh" }}>
          <ListItemText style={{ background: "white", margin: 0 }}>
            <ThemeProvider theme={getTheme()}>
              <MUIDataTable
                title={
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "1.5rem",
                      color: "#333",
                    }}
                  >
                    Membership Details
                  </span>
                }
                data={detailValue}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </ListItemText>
        </Box>
      </Box>
    </>
  );
};

export default ViewMoreKnowledge;
