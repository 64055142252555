import React from "react";
import { ColorModeContext, useMode } from "../../theme";
import { Box, ThemeProvider } from "@mui/material";

function SettingPageLayout({ children }) {
  const [theme = {}, colorMode] = useMode();
  // check if down to the tablet mode

  return (
    <Box>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <main>{children}</main>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Box>
  );
}

export default SettingPageLayout;
