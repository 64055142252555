import React, { useEffect, useState } from 'react';
import { Grid, TextField, Box, Button, Typography } from '@mui/material';
import { financeSettingsApi } from '../../../Data/axios'; 
import StatusAlert from "../../../StatusAlertList/StatusAlert";
function PrefixSettings() {

  const [invoicePrefix, setInvoicePrefix] = useState('');
  const [invoiceSeparator, setInvoiceSeparator] = useState('');
  const [invoiceDigits, setInvoiceDigits] = useState('');

 
  const [estimatePrefix, setEstimatePrefix] = useState('');
  const [estimateSeparator, setEstimateSeparator] = useState('');
  const [estimateDigits, setEstimateDigits] = useState('');


  const [estimateRequestPrefix, setEstimateRequestPrefix] = useState('');
  const [estimateRequestSeparator, setEstimateRequestSeparator] = useState('');
  const [estimateRequestDigits, setEstimateRequestDigits] = useState('');

 
  const [orderPrefix, setOrderPrefix] = useState('');
  const [orderSeparator, setOrderSeparator] = useState('');
  const [orderDigits, setOrderDigits] = useState('');

 
  const [proposalPrefix, setProposalPrefix] = useState('');
  const [proposalSeparator, setProposalSeparator] = useState('');
  const [proposalDigits, setProposalDigits] = useState('');

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const generateExample = (prefix, separator, digits) => {
    const digitCount = parseInt(digits, 10);
    if (isNaN(digitCount) || digitCount < 1) {
      return ''; 
    }
    const number = String('0'.repeat(digitCount - 1) + '1').slice(-digitCount);
    return `${prefix}${separator}${number}`;
  };

 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await financeSettingsApi.getAll();
        const settings = response[0]; 
        setInvoicePrefix(settings.invoice_prefix || '');
        setInvoiceSeparator(settings.invoice_start_separator || '');
        setInvoiceDigits(settings.Invoice_digits || '');
        setEstimatePrefix(settings.estimate_prefix || '');
        setEstimateSeparator(settings.estimate_start_separator || '');
        setEstimateDigits(settings.estimate_digits || '');
        setEstimateRequestPrefix(settings.estimate_request_prefix || '');
        setEstimateRequestSeparator(settings.estimate_request_start_separator || '');
        setEstimateRequestDigits(settings.estimate_request_digits || '');
        setOrderPrefix(settings.order_prefix || '');
        setOrderSeparator(settings.order_start_separator || '');
        setOrderDigits(settings.order_digits || '');
        setProposalPrefix(settings.proposal_prefix || '');
        setProposalSeparator(settings.proposal_start_separator || '');
        setProposalDigits(settings.proposal_digits || '');
      } catch (error) {
        console.error('Error fetching finance settings:', error);
      }
    };

    fetchData();
  }, []);

 
  const handleSave = async () => {
    const formData = {
      invoice_prefix: invoicePrefix,
      invoice_start_separator: invoiceSeparator,
      Invoice_digits: invoiceDigits,
      estimate_prefix: estimatePrefix,
      estimate_start_separator: estimateSeparator,
      estimate_digits: estimateDigits,
      estimate_request_prefix: estimateRequestPrefix,
      estimate_request_start_separator: estimateRequestSeparator,
      estimate_request_digits: estimateRequestDigits,
      order_prefix: orderPrefix,
      order_start_separator: orderSeparator,
      order_digits: orderDigits,
      proposal_prefix: proposalPrefix,
      proposal_start_separator: proposalSeparator,
      proposal_digits: proposalDigits,
    };

    try {
      await financeSettingsApi.updateprefix(formData);
      showAlert("Settings updated successfully!", "success");
    } catch (error) {
      console.error('Error updating finance settings:', error);
      showAlert("Failed to update settings!", "error");
    }
  };

  return (
    <Box sx={{ p: 3, maxWidth: 1200, mx: 'auto', backgroundColor: 'white' }}>
        <StatusAlert open={alertOpen} setOpen={setAlertOpen} message={alertMessage} severity={alertSeverity} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ color: 'black' }}>
            Invoice Settings
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Invoice Prefix"
              variant="outlined"
              required
              value={invoicePrefix}
              onChange={(e) => setInvoicePrefix(e.target.value)}
              sx={{ input: { color: 'black' }, fieldset: { borderColor: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }} 
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Invoice Number Separator"
              variant="outlined"
              value={invoiceSeparator}
              onChange={(e) => setInvoiceSeparator(e.target.value)}
              inputProps={{ maxLength: 1 }} 
              sx={{ input: { color: 'black' }, fieldset: { borderColor: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }} 
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Invoice Number Digits"
              variant="outlined"
              type="number"
              value={invoiceDigits}
              onChange={(e) => setInvoiceDigits(e.target.value.replace(/\D/g, ''))} 
              sx={{ input: { color: 'black' }, fieldset: { borderColor: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }} 
            />
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ border: '1px solid black', borderRadius: '4px', padding: '8px' }}>
              <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold' }}>
                Invoice Number Example
              </Typography>
              <Typography variant="body2" sx={{ color: 'black' }}>
                {generateExample(invoicePrefix, invoiceSeparator, invoiceDigits)}
              </Typography>
            </Box>
          </Grid>
        </Grid>

       
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ color: 'black' }}>
            Estimate Settings
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Estimate Prefix"
              variant="outlined"
              required
              value={estimatePrefix}
              onChange={(e) => setEstimatePrefix(e.target.value)}
              sx={{ input: { color: 'black' }, fieldset: { borderColor: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }} 
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Estimate Number Separator"
              variant="outlined"
              value={estimateSeparator}
              onChange={(e) => setEstimateSeparator(e.target.value)}
              inputProps={{ maxLength: 1 }} 
              sx={{ input: { color: 'black' }, fieldset: { borderColor: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }} 
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Estimate Number Digits"
              variant="outlined"
              type="number"
              value={estimateDigits}
              onChange={(e) => setEstimateDigits(e.target.value.replace(/\D/g, ''))} 
              sx={{ input: { color: 'black' }, fieldset: { borderColor: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }} 
            />
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ border: '1px solid black', borderRadius: '4px', padding: '8px' }}>
              <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold' }}>
                Estimate Example
              </Typography>
              <Typography variant="body2" sx={{ color: 'black' }}>
                {generateExample(estimatePrefix, estimateSeparator, estimateDigits)}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ color: 'black' }}>
            Estimate Request Settings
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Estimate Request Prefix"
              variant="outlined"
              required
              value={estimateRequestPrefix}
              onChange={(e) => setEstimateRequestPrefix(e.target.value)}
              sx={{ input: { color: 'black' }, fieldset: { borderColor: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }} 
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Estimate Request Separator"
              variant="outlined"
              value={estimateRequestSeparator}
              onChange={(e) => setEstimateRequestSeparator(e.target.value)}
              inputProps={{ maxLength: 1 }} 
              sx={{ input: { color: 'black' }, fieldset: { borderColor: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }} 
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Estimate Request Digits"
              variant="outlined"
              type="number"
              value={estimateRequestDigits}
              onChange={(e) => setEstimateRequestDigits(e.target.value.replace(/\D/g, ''))} 
              sx={{ input: { color: 'black' }, fieldset: { borderColor: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }} 
            />
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ border: '1px solid black', borderRadius: '4px', padding: '8px' }}>
              <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold' }}>
                Estimate Request Example
              </Typography>
              <Typography variant="body2" sx={{ color: 'black' }}>
                {generateExample(estimateRequestPrefix, estimateRequestSeparator, estimateRequestDigits)}
              </Typography>
            </Box>
          </Grid>
        </Grid>

       
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ color: 'black' }}>
            Order Settings
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Order Prefix"
              variant="outlined"
              required
              value={orderPrefix}
              onChange={(e) => setOrderPrefix(e.target.value)}
              sx={{ input: { color: 'black' }, fieldset: { borderColor: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }} 
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Order Number Separator"
              variant="outlined"
              value={orderSeparator}
              onChange={(e) => setOrderSeparator(e.target.value)}
              inputProps={{ maxLength: 1 }} 
              sx={{ input: { color: 'black' }, fieldset: { borderColor: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }} 
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Order Number Digits"
              variant="outlined"
              type="number"
              value={orderDigits}
              onChange={(e) => setOrderDigits(e.target.value.replace(/\D/g, ''))} 
              sx={{ input: { color: 'black' }, fieldset: { borderColor: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }} 
            />
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ border: '1px solid black', borderRadius: '4px', padding: '8px' }}>
              <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold' }}>
                Order Example
              </Typography>
              <Typography variant="body2" sx={{ color: 'black' }}>
                {generateExample(orderPrefix, orderSeparator, orderDigits)}
              </Typography>
            </Box>
          </Grid>
        </Grid>

      
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ color: 'black' }}>
            Proposal Settings
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Proposal Prefix"
              variant="outlined"
              required
              value={proposalPrefix}
              onChange={(e) => setProposalPrefix(e.target.value)}
              sx={{ input: { color: 'black' }, fieldset: { borderColor: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }} 
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Proposal Number Separator"
              variant="outlined"
              value={proposalSeparator}
              onChange={(e) => setProposalSeparator(e.target.value)}
              inputProps={{ maxLength: 1 }} 
              sx={{ input: { color: 'black' }, fieldset: { borderColor: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }} 
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Proposal Number Digits"
              variant="outlined"
              type="number"
              value={proposalDigits}
              onChange={(e) => setProposalDigits(e.target.value.replace(/\D/g, ''))} 
              sx={{ input: { color: 'black' }, fieldset: { borderColor: 'black' } }}
              InputLabelProps={{ style: { color: 'black' } }} 
            />
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ border: '1px solid black', borderRadius: '4px', padding: '8px' }}>
              <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold' }}>
                Proposal Example
              </Typography>
              <Typography variant="body2" sx={{ color: 'black' }}>
                {generateExample(proposalPrefix, proposalSeparator, proposalDigits)}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        
        <Grid item xs={12} md={12} sx={{ mt: 3 }}>
          <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PrefixSettings;
