import React, { useState } from "react";
import {
  Box,
  Button,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./LeaveSettings.css"; // Ensure this CSS has media queries for smaller screens
import TableChartIcon from "@mui/icons-material/TableChart";
import AddNewLeaveType from "./AddNewLeaveType";
import { styled } from "@mui/material/styles";
import {
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  Typography,
  Paper,
} from "@mui/material";

// Helper function for TabPanel
const TabPanel = ({ children, value, index }) => {
  return value === index && <Box>{children}</Box>;
};

// Responsive table container
const ResponsiveTableContainer = styled(Box)({
  width: "100%",
  overflowX: "auto",
});

// Helper styles for action buttons
const IconButtonWrapper = styled(IconButton)({
  marginLeft: "8px",
});

const LeaveGeneral = () => {
  const [tabValue, setTabValue] = useState(0);
  const [leaveCount, setLeaveCount] = useState("joining");
  const [approvalType, setApprovalType] = useState("Pre-Approve");
  const [selectedOption, setSelectedOption] = useState("dateOfJoining");

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <Box style={{ marginLeft: "10px" }}>
      <FormControl
        component="fieldset"
        style={{ marginTop: "10px", color: "#000" }}
      >
        <RadioGroup
          aria-label="leave-setting"
          name="leave-setting"
          value={selectedOption}
          onChange={handleRadioChange}
          row // Align radio buttons in a row
        >
          <FormControlLabel
            value="dateOfJoining"
            control={<Radio sx={{ color: "gray" }} />}
            label="Count leaves from the date of joining"
          />
          <FormControlLabel
            value="year"
            control={<Radio sx={{ color: "gray" }} />}
            label="Count leaves from the start of the year"
          />
        </RadioGroup>
      </FormControl>

      {/* Conditionally display "Year Starts from" and dropdown */}
      {selectedOption === "year" && (
        <Box mt={2} sx={{ color: "#000" }}>
          <Typography>Year Starts from</Typography>
          <Select
            defaultValue="January"
            sx={{
              color: "#000",
              bgcolor: "#f0f0f0",
              border: "1px solid gray",
              height: "45px",
            }}
          >
            <MenuItem value="January">January</MenuItem>
            <MenuItem value="February">February</MenuItem>
            <MenuItem value="March">March</MenuItem>
            <MenuItem value="April">April</MenuItem>
            <MenuItem value="January">May</MenuItem>
            <MenuItem value="February">June</MenuItem>
            <MenuItem value="March">July</MenuItem>
            <MenuItem value="April">August</MenuItem>
            <MenuItem value="January">September</MenuItem>
            <MenuItem value="February">October</MenuItem>
            <MenuItem value="March">November</MenuItem>
            <MenuItem value="April">December</MenuItem>
          </Select>
        </Box>
      )}

      {/* Note Section (Always visible) */}
      <Box
        mt={2}
        p={2}
        style={{
          backgroundColor: "#E0F7FA",
          borderRadius: "4px",
          color: "#000",
        }}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ color: "#000" }}
        >
          Note: Approve means direct approval, Pre-Approval means another
          approval by admin/hr is required.
        </Typography>
      </Box>

      {/* Reporting Manager Section (Always visible) */}
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ marginRight: "8px", color: "#000" }}>
              Reporting Manager can
            </Typography>
            <Select
              defaultValue="Pre-Approve"
              style={{
                marginLeft: "8px",
                marginRight: "8px",
                color: "#000",
                border: "1px solid gray",
                height: "45px",
                backgroundColor: "#f0f0f0",
              }}
            >
              <MenuItem value="Pre-Approve">Pre-Approve</MenuItem>
              <MenuItem value="Approve">Approve</MenuItem>
              <MenuItem value=" Not Approve">Not Approve</MenuItem>
            </Select>
            <Typography sx={{ color: "#000" }}>the Leave</Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LeaveGeneral;
