import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./HeaderLeave.module.css";
export default function HeaderLeave() {
  const [selection, setSelection] = useState(0);

  const handleChange = (event, newValue) => {
    setSelection(newValue);
  };

  return (
    <Box className={styles.top}>
      <Box
        sx={{
          width: "180px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: "10px",
        }}
        className={styles.box}
      >
        <p
          style={{
            marginRight: "10px",
            fontSize: "18px",
            color: "#333",
            fontWeight: 540,
          }}
        >
          Year
        </p>
        <Select
          value={selection}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected === 0) {
              return <MenuItem value={0}>2024</MenuItem>; // Placeholder text
            }
            return selected;
          }}
          sx={{
            width: "75px",
            margin: ".4rem",
            padding: "1.5px",
            border: "none",
            color: "#000 ",
            bgcolor: "#eff1f1",
            "& .MuiSelect-select": {
              padding: 0,
            },
          }}
          IconComponent={(props) => (
            <ExpandMoreIcon {...props} style={{ color: "black" }} />
          )}
        >
          <MenuItem value={0}>2024</MenuItem>
          <MenuItem value={1}>2023</MenuItem>
          <MenuItem value={2}>2022</MenuItem>
          <MenuItem value={3}>2021</MenuItem>
          <MenuItem value={4}>2020</MenuItem>
        </Select>
      </Box>
      <Box
        sx={{
          width: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: "10px",
        }}
        className={styles.box}
      >
        <p
          style={{
            marginRight: "10px",
            fontSize: "18px",
            color: "#333",
            fontWeight: 540,
          }}
        >
          Leave Type
        </p>
        <Select
          value={selection}
          onChange={handleChange}
          displayEmpty
          // Use renderValue to show placeholder text when value is empty
          renderValue={(selected) => {
            if (selected === 0) {
              return <MenuItem value={0}>All</MenuItem>; // Placeholder text
            }
            return selected;
          }}
          sx={{
            width: "145px",
            margin: ".4rem",
            padding: "1.5px",
            border: "none",
            color: "#000 ",
            bgcolor: "#eff1f1",
            "& .MuiSelect-select": {
              padding: 0,
            },
          }}
          IconComponent={(props) => (
            <ExpandMoreIcon {...props} style={{ color: "black" }} />
          )}
        >
          <MenuItem value={0}>All</MenuItem>
          <MenuItem value={1}>Casual</MenuItem>
          <MenuItem value={2}>Sick</MenuItem>
          <MenuItem value={3}>Earned</MenuItem>
        </Select>
      </Box>
      <Box
        sx={{
          width: "240px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: "10px",
        }}
        className={styles.box}
      >
        <p
          style={{
            marginRight: "10px",
            fontSize: "18px",
            color: "#333",
            fontWeight: 540,
          }}
        >
          Status
        </p>
        <Select
          value={selection}
          onChange={handleChange}
          displayEmpty
          // Use renderValue to show placeholder text when value is empty
          renderValue={(selected) => {
            if (selected === 0) {
              return <MenuItem value={0}>All</MenuItem>; // Placeholder text
            }
            return selected;
          }}
          sx={{
            width: "145px",
            margin: ".4rem",
            padding: "1.5px",
            border: "none",
            color: "#000 ",
            bgcolor: "#eff1f1",
            "& .MuiSelect-select": {
              padding: 0,
            },
          }}
          IconComponent={(props) => (
            <ExpandMoreIcon {...props} style={{ color: "black" }} />
          )}
        >
          <MenuItem value={0}>All</MenuItem>
          <MenuItem value={1}>Approved</MenuItem>
          <MenuItem value={2}>Pending</MenuItem>
          <MenuItem value={3}>Rejected</MenuItem>
        </Select>
      </Box>
      <Box
        sx={{
          width: "230px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className={styles.box}
      >
        <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  sx={{
                    height: "40px",
                    color: "#000",
                    padding: 0,
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#000",
                    },
                  }}
                >
                  <SearchIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
            placeholder="Start typing to search"
            sx={{
              width: "260px",
              height: "40px",
              margin: ".4rem",

              border: ".1px solid gray",
              borderRadius: "5px",
              color: "#000 ",
              bgcolor: "#eff1f1",

              boxSizing: "border-box",
            }}
          />
        </FormControl>
      </Box>
    </Box>
  );
}
