import React from 'react'
import { styled } from '@mui/material/styles';
import {Box, createTheme, ThemeProvider} from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GroupIcon from '@mui/icons-material/Group';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import './FieldDash.css';
import MUIDataTable from "mui-datatables";



function FieldDash() {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));


    const columns = [
        {
            name: "",
            label: "Name"  // to rename column
        },
        {
            name: "",
            label: "Total Distance"
        },
        {
            name: "",
            label: "Total Time"
        },
        {
            name: "",
            label: "Total Time in Motion"
        },
        {
            name: "",
            label: "Total Time at Rest"
        }
    ];


    const options = {
        //selectableRows: false,
        elevation: 0,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 25, 100],
        tableBody: {
            style: {
                maxHeight: '500px',
                overflowY: 'scroll',
                marginLeft:'5PX'
            }
        },
        tableHead: {
            style: {
                backgroundColor: '#f2f2f2',
                color: 'black'
            }
        },
        rows: {
            style: {
                marginLeft: '5px',
                textAlign: 'center'
            },
            selectableRowsOnClick: true
        }
    };
    
       const getTheme = () =>
       createTheme({
         palette: {
           mode: 'light',
           primary: {
             main: '#2196f3',
           },
           secondary: {
             main: '#03a9f4',
           },
         },
         typography: {
           fontFamily: [
             'Roboto',
             'Helvetica',
             'Arial',
             'Sans-serif',
           ].join(','),
         },
         components: {
           MuiButton: {
             styleOverrides: {
               root: {
                 textTransform: 'none',
               },
             },
           },
         },
         tableBody: {
          style: {
              maxHeight: '500px',
              overflowY: 'scroll'
          }
      },
      tableHead: {
          style: {
              backgroundColor: '#f2f2f2',
              color: 'black'
          }
      },
      rows: {
          style: {
              '&:hover': {
                  backgroundColor: '#f2f2f2'
              }
          }
      }
       });
    



    return (
        <Box display="flex" flexDirection="column" >
            <Box sx={{ flexGrow: 1 }}>
              

                    {/* first col  */}


                    <Grid item xs={12} md={6}>


                    <Grid container spacing={1}>

                    {/* first column */}
                    <Grid item xs={12} md={6}>

                        {/* first row */}
                        <Grid container spacing={1} >


                            <Grid item xs={6}>
                                <Item style={{ backgroundColor: "#fff" }}>
                                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                        Total Distance
                                    </Typography>
                                    <div style={{marginBottom:"20px"}}></div>
                                    <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                        <GroupIcon />
                                    </Typography>
                                    <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                        275
                                    </Typography>
                                  
                                </Item>
                            </Grid>
                            <Grid item xs={6}>
                                <Item style={{ backgroundColor: "#fff" }}>
                                    <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                        Total Time
                                    </Typography>
                                    <div style={{marginBottom:"20px"}}></div>
                                    <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                        <GroupIcon />
                                    </Typography>
                                    <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                        42
                                    </Typography>
                                   
                                </Item>
                            </Grid>
                        </Grid>
                        <div style={{ marginBottom: '10px' }}></div>

                    </Grid>


                    {/* second col  */}
                    
                    <Grid item xs={12} md={6}>

                    {/* first row */}
                    <Grid container spacing={1} >   


                        <Grid item xs={6}>
                        <Item style={{ backgroundColor: "#fff" }}>
                                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                        Total Time<br></br><Typography style={{fontSize:"9px"}}>Spent in motion</Typography>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                            <LocalActivityIcon></LocalActivityIcon>
                                        </Typography>
                                        <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                            275
                                        </Typography>
                                    </Item>
                        </Grid>
                        <div style={{ marginBottom: '10px' }}></div>
                        <Grid item xs={6}>
                        <Item style={{ backgroundColor: "#fff" }}>
                                        
                                        <Typography gutterBottom variant="subtitle1" style={{ textAlign: "left", fontSize: "20px", color: "#4d4f5c" }}>
                                        Total Time<br></br><Typography style={{fontSize:"9px"}}>Spent in rest</Typography>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom style={{ textAlign: "right", color: "#4d4f5c" }}>
                                            <LocalActivityIcon></LocalActivityIcon>
                                        </Typography>
                                        <Typography variant="body2" style={{ textAlign: "left", fontSize: "16px" }} color="blue">
                                            275
                                        </Typography>
                                    </Item>
                        </Grid>
                        <div style={{ marginBottom: '10px' }}></div>
                    </Grid>
                    <div style={{ marginBottom: '10px' }}></div>
                    </Grid>

                </Grid>
           </Grid>
           <Grid>
           <ThemeProvider theme={getTheme()}>
                                    <MUIDataTable
                                        fullScreen={true}
                                        columns={columns}
                                        options={options}/>
                                    </ThemeProvider>
           </Grid>
          
            </Box>
        </Box>
        
    )
}

export default FieldDash;