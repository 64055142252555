import TabsProjectSettings from "./TabsProjectSettings";

const ProjectSettings = () => {
  return (
    <div>
      <TabsProjectSettings />
    </div>
  );
};

export default ProjectSettings;
