import { Box, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import styles from "./HeaderAttendance.module.css";
export default function HeaderAttendence({ setYearValue, setMonthValue }) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const [selectionMonth, setSelectionMonth] = useState(currentMonth);
  const [selectionYear, setSelectionYear] = useState(currentYear);
  const handleChangeMonth = (event, newValue) => {
    setSelectionMonth(newValue.props.value);
    setMonthValue(newValue.props.value);
  };
  const handleChangeYear = (event, newValue) => {
    setSelectionYear(newValue.props.value);
    setYearValue(newValue.props.value);
  };
  const months = [
    "January", // 1
    "February", // 2
    "March", // 3
    "April", // 4
    "May", // 5
    "June", // 6
    "July", // 7
    "August", // 8
    "September", // 9
    "October", // 10
    "November", // 11
    "December", // 12
  ];
  const years = [2024, 2023, 2022, 2021, 2020];

  return (
    <Box className={styles.top}>
      <Box
        sx={{
          width: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: "10px",
        }}
        className={styles.box}
      >
        <p
          style={{
            marginRight: "10px",
            fontSize: "18px",
            color: "#333",
            fontWeight: 540,
          }}
        >
          Month
        </p>
        <Select
          value={selectionMonth}
          onChange={handleChangeMonth}
          displayEmpty
          renderValue={(selected) => {
            if (selected >= 0 && selected <= 11) {
              return <MenuItem value={selected}>{months[selected]}</MenuItem>;
            }
            return selected;
          }}
          sx={{
            width: "115px",
            margin: ".4rem",
            padding: "1.5px",
            border: "none",
            color: "#000 ",
            bgcolor: "#eff1f1",
            "& .MuiSelect-select": {
              padding: 0,
            },
          }}
          IconComponent={(props) => (
            <ExpandMoreIcon {...props} style={{ color: "black" }} />
          )}
        >
          {months.map((month, index) => (
            <MenuItem key={index} value={index}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        sx={{
          width: "180px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: "10px",
        }}
        className={styles.box}
      >
        <p
          style={{
            marginRight: "10px",
            fontSize: "18px",
            color: "#333",
            fontWeight: 540,
          }}
        >
          Year
        </p>
        <Select
          value={selectionYear}
          onChange={handleChangeYear}
          displayEmpty
          renderValue={(selected) => {
            return selected;
          }}
          sx={{
            width: "75px",
            margin: ".4rem",
            padding: "1.5px",
            border: "none",
            color: "#000 ",
            bgcolor: "#eff1f1",
            "& .MuiSelect-select": {
              padding: ".4rem",
            },
          }}
          IconComponent={(props) => (
            <ExpandMoreIcon {...props} style={{ color: "black" }} />
          )}
        >
          {years.map((year, index) => (
            <MenuItem key={index} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
}
