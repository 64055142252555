import React from "react";

import { Drawer } from "@mui/material";

export default function DrawerMain({ open, onClose, anchor, children }) {
  return (
    <Drawer open={open} onClose={onClose} anchor={anchor}>
      {children}
    </Drawer>
  );
}
