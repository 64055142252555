import axios from "axios";
import config from "./AuthUser";

const API_BASE_URL = config.apiBaseUrl;

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});
/** Leave Settings --> ***/
export const leaveSettingsApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/addLeads", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getAllLeaveTypes");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  getUserData: async () => {
    try {
      const response = await apiClient.get("/getUserData");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  convertedbyId: async (id) => {
    try {
      const response = await apiClient.post(`/convertlead/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  viewMoreById: async (id) => {
    try {
      const response = await apiClient.post(`/getDriverDataForPortal/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
};
/** Leads --> leads***/
export const leadsApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/addLeads", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getallLeads");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  getUserData: async () => {
    try {
      const response = await apiClient.get("/getUserData");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  convertedbyId: async (id) => {
    try {
      const response = await apiClient.post(`/convertlead/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  viewMoreById: async (id) => {
    try {
      const response = await apiClient.post(`/getDriverDataForPortal/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
  followUpbyId: async (id, formData) => {
    try {
      const response = await apiClient.post(`/assignfollowup/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
  updatebyId: async (id, formData) => {
    try {
      const response = await apiClient.post(`/updateLeads/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
  changeStatus: async (id, formData) => {
    try {
      const response = await apiClient.post(`/changestatus/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
};
/** Leads --> Sales***/
export const salesApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/adduserfronportal", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getSalesData");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },

  followUpbyId: async (id, formData) => {
    try {
      const response = await apiClient.post(`/assignfollowup/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  viewMoreById: async (id) => {
    try {
      const response = await apiClient.post(`/getDriverDataForPortal/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
  changeStatus: async (id, formData) => {
    try {
      const response = await apiClient.post(`/changestatus/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
};

/** Driver Leave Requests ***/
export const driverLeaveApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/AddCorpclient", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getalldriverleaves");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  leaveViewMoreId: async (id) => {
    try {
      const response = await apiClient.get(`/getDriverLeaveById/${id}`);
      console.log("API response:", response); 
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  remarkById: async (id, formData) => {
    try {
      const response = await apiClient.post(
        `/approveDriverLeave/${id}`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
};
/** Membership Page***/
export const membershipApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/addMemberShip", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  addDetails: async (formData) => {
    try {
      const response = await apiClient.post("/AddMembershiplist", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getMemberShips");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  addIndustry: async (formData) => {
    try {
      const response = await apiClient.post("/addIndustry", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  industryAll: async () => {
    try {
      const response = await apiClient.get("/getIndustry");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  membershipViewMoreId: async (id) => {
    try {
      const response = await apiClient.get(`/getMembershiplistByID/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  updatebyId: async (id, formData) => {
    try {
      const response = await apiClient.post(
        `/updateMemberShip/${id}`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  changeStatus: async (id, formData) => {
    try {
      const response = await apiClient.post(`/changestatus/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
};
/** Login Page***/
export const loginApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/login", formData);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
  getAll: async (id) => {
    try {
      const response = await apiClient.post(`/getcontacts/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  deleteById: async (id) => {
    try {
      const response = await apiClient.delete(`/deleteContact/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  SavebyId: async (id, formData) => {
    try {
      const response = await apiClient.post(`/AddCorpDrivers/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
};
/** Client Contact***/
export const clientContactApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/addcontacts", formData);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
  getAll: async (id) => {
    try {
      const response = await apiClient.post(`/getcontacts/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  deleteById: async (id) => {
    try {
      const response = await apiClient.delete(`/deleteContact/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  editById: async (id) => {
    try {
      const response = await apiClient.post(`/getContactByid/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  updateById: async (id, formData) => {
    try {
      const response = await apiClient.post(`/updateContact/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
};
/** Client Invoice***/
export const clientInvoiceApis = {
  add: async (item) => {
    try {
      const response = await apiClient.post("/createInvoice", item);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getInvoice");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  clientDriverById: async (id) => {
    try {
      const response = await apiClient.post(`/getCorpDriver/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  driverById: async (id) => {
    try {
      const response = await apiClient.post(`/getDriverDataForPortal/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  deleteById: async (id) => {
    try {
      const response = await apiClient.delete(`/deleteContact/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  SavebyId: async (id, formData) => {
    try {
      const response = await apiClient.post(`/AddCorpDrivers/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
};
/** Client Drivers***/
export const clientDriversApis = {
  viewMoreDriver: async (id) => {
    try {
      const response = await apiClient.post(`/getDriverDataForPortal/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getDriver: async (id) => {
    try {
      const response = await apiClient.post(`/getUserByIdOrPhone/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  viewMoreAttendance: async (id, formData) => {
    try {
      const response = await apiClient.post(
        `/getDriverAttendance/${id}`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
  getAll: async (id) => {
    try {
      const response = await apiClient.post(`/getCorpDriver/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },

  SavebyId: async (id, formData) => {
    try {
      const response = await apiClient.post(`/AddCorpDrivers/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  changeStatus: async (id, formData) => {
    try {
      const response = await apiClient.post(`/updateStatus/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
  RelievebyId: async (id, formData) => {
    try {
      const response = await apiClient.post(`/relieveDriver/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
};
/****** Clients******/

export const clientApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/AddCorpclient", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getAllClient");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  addIndustry: async (formData) => {
    try {
      const response = await apiClient.post("/addIndustry", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  industryAll: async () => {
    try {
      const response = await apiClient.get("/getIndustry");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  clientViewMoreId: async (id) => {
    try {
      const response = await apiClient.post(`/getClientbyID/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  getbyId: async (id) => {
    try {
      const response = await apiClient.post(`/getOwnerDataByUserId/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  changeStatus: async (id, formData) => {
    try {
      const response = await apiClient.post(`/changestatus/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
  clientDriverAttendance: async (user_id, finalTime) => {
    try {
      const response = await apiClient.get(
        `/getDriverAttendanceByClientId?client_id=${user_id}&month=${finalTime}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
};
/****** Users******/
/** Crane Operator***/
export const craneOperatorApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/adduserfronportal", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getmech");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  getbyId: async (id) => {
    try {
      const response = await apiClient.post(`/getOwnerDataByUserId/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  changeStatus: async (id, formData) => {
    try {
      const response = await apiClient.post(`/changestatus/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
};
/** Crane Owner***/
export const craneOwnerApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/adduserfronportal", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getmech");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  getbyId: async (id) => {
    try {
      const response = await apiClient.post(`/getOwnerDataByUserId/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  changeStatus: async (id, formData) => {
    try {
      const response = await apiClient.post(`/changestatus/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
};
/** Mechanic***/
export const mechanicApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/adduserfronportal", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getmech");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  getbyId: async (id) => {
    try {
      const response = await apiClient.post(`/getOwnerDataByUserId/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  changeStatus: async (id, formData) => {
    try {
      const response = await apiClient.post(`/changestatus/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
};
/** Dhaba***/
export const dhabaApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/adduserfronportal", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getdhaba");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  getbyId: async (id) => {
    try {
      const response = await apiClient.post(`/getOwnerDataByUserId/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  changeStatus: async (id, formData) => {
    try {
      const response = await apiClient.post(`/changestatus/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
};
/** fleetOwner***/
export const fleetOwnerApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/adduserfronportal", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getOwners");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  getbyId: async (id) => {
    try {
      const response = await apiClient.post(`/getOwnerDataByUserId/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  changeStatus: async (id, formData) => {
    try {
      const response = await apiClient.post(`/changestatus/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
};
/** Driver***/
export const DriverApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/adduserfronportal", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.post("/getDrivers");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },

  getbyId: async (id) => {
    try {
      const response = await apiClient.post(`/getDriverDataForPortal/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  suspendReason: async (id, formData) => {
    try {
      const response = await apiClient.post(`/suspenduser/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
  changeStatus: async (id, formData) => {
    try {
      const response = await apiClient.post(`/changestatus/${id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
};
//Setting of dashboard
/*****Company Settings ******/
export const companySettingsApi = {
  getAll: async () => {
    try {
      const response = await apiClient.get("/getGeneralSettings");
      return response.data;
    } catch (error) {
      console.error("Error fetching company settings:", error);
      throw error;
    }
  },

  post: async (formData) => {
    try {
      const response = await apiClient.post("/updateCompanySettings", formData);
      return response.data;
    } catch (error) {
      console.error("Error updating company settings:", error);
      throw error;
    }
  },

  AppSettingsUpdate: async (formData) => {
    try {
      const response = await apiClient.post("/AppSettingsUpdate", formData);
      return response.data;
    } catch (error) {
      console.error("Error updating company settings:", error);
      throw error;
    }
  },

  PaymentSettingsUpdate: async (formData) => {
    try {
      const response = await apiClient.post("/updateSettingsPayment", formData);
      return response.data;
    } catch (error) {
      console.error("Error updating company settings:", error);
      throw error;
    }
  },
};

// Finance Settings

export const financeSettingsApi = {
  getAll: async () => {
    try {
      const response = await apiClient.get("/getfinancesettings");
      return response.data;
    } catch (error) {
      console.error("Error fetching finance settings:", error);
      throw error;
    }
  },

  updateinvoice: async (formData) => {
    try {
      const response = await apiClient.post("/updateinvoicesettings", formData);
      return response.data;
    } catch (error) {
      console.error("Error updating finance settings:", error);
      throw error;
    }
  },

  updateprefix: async (formData) => {
    try {
      const response = await apiClient.post("/updateprefixsettings", formData);
      return response.data;
    } catch (error) {
      console.error("Error updating finance settings:", error);
      throw error;
    }
  },

  getAllInvoicePaymenst: async () => {
    try {
      const response = await apiClient.get("/getallpaymentsettings");
      return response.data;
    } catch (error) {
      console.error("Error fetching finance settings:", error);
      throw error;
    }
  },

  addpaymentsettings: async (formData) => {
    try {
      const response = await apiClient.post("/addpaymentsettings", formData);
      return response.data;
    } catch (error) {
      console.error("Error updating finance settings:", error);
      throw error;
    }
  },

  updatepaymentsettings: async (id, formData) => {
    try {
      const response = await apiClient.post(
        `/updatepaymentsettings/${id}`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating finance settings:", error);
      throw error;
    }
  },

  deletepaymentsettings: async (id, formData) => {
    try {
      const response = await apiClient.delete(
        `/deletepaymentsettings/${id}`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating finance settings:", error);
      throw error;
    }
  },

  getAllCurrency: async () => {
    try {
      const response = await apiClient.get("/getAllCurrency");
      return response.data;
    } catch (error) {
      console.error("Error fetching finance settings:", error);
      throw error;
    }
  },

  addCurrency: async (formData) => {
    try {
      const response = await apiClient.post("/addCurrency", formData);
      return response.data;
    } catch (error) {
      console.error("Error updating finance settings:", error);
      throw error;
    }
  },
  updateDefaultCurrency: async (id, formData) => {
    try {
      const response = await apiClient.post(
        `/updateDefaultCurrency/${id}`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating finance settings:", error);
      throw error;
    }
  },

  deleteDefaultCurrency: async (id, formData) => {
    try {
      const response = await apiClient.delete(
        `/deleteDefaultCurrency/${id}`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating finance settings:", error);
      throw error;
    }
  },
};

// Profile Settings

export const profileSettingsApi = {
  getById: async (empid) => {
    try {
      const response = await apiClient.post(`/getEmployeesbyID/${empid}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching employee data:", error);
      throw error;
    }
  },
  update: async (empid, formData) => {
    try {
      const response = await apiClient.post(
        `/updateEmployeedata/${empid}`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating employee data:", error);
      throw error;
    }
  },
};

/*****App Settings ******/
export const AppSettingsApi = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/addVehicleBrands", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding vehicle brand:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getVehicleBrands");

      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brands:", error);
      throw error;
    }
  },
  deletebyId: async (id) => {
    try {
      const response = await apiClient.delete(`/deleteVehicleBrandsByID/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brand by id:", error);
      throw error;
    }
  },
};
/*****Business Settings ******/
export const BusinessSettingsApi = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/addBusinesslocation", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding vehicle brand:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/listBusinessLocations");

      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brands:", error);
      throw error;
    }
  },

  update: async (id, formData) => {
    try {
      const response = await apiClient.post(
        `/updateBusinessLocation/${id}`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
  edit: async (id) => {
    try {
      const response = await apiClient.get(`/businesslocationget/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
};
// Vehicle Brands API
export const vehicleBrandsApi = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/addVehicleBrands", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding vehicle brand:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getVehicleBrands");

      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brands:", error);
      throw error;
    }
  },
  deletebyId: async (id) => {
    try {
      const response = await apiClient.delete(`/deleteVehicleBrandsByID/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brand by id:", error);
      throw error;
    }
  },
};

// Vehicle  model API
export const VehicleModelsApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/addVehicleModel", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding vehicle brand:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getVehicleModels");
      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brands:", error);
      throw error;
    }
  },
  deletebyId: async (id) => {
    try {
      const response = await apiClient.delete(`/deleteVehicleModelById/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brand by id:", error);
      throw error;
    }
  },
};
//Department api
export const DepartmentApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/addDepartment", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getDepartments");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  deletebyId: async (id) => {
    try {
      const response = await apiClient.delete(`/deleteDepartment/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  update: async (id, formData) => {
    try {
      const response = await apiClient.post(
        `/updateDepartment/${id}`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
};
//Designation api
export const DesignationApis = {
  add: async (formData) => {
    try {
      const response = await apiClient.post("/addDesignation", formData);
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await apiClient.get("/getDesignation");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      throw error;
    }
  },
  deletebyId: async (id) => {
    try {
      const response = await apiClient.delete(`/deleteDesignation/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching department by id:", error);
      throw error;
    }
  },
  update: async (id, formData) => {
    try {
      const response = await apiClient.post(
        `/updateDesignation/${id}`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating department:", error);
      throw error;
    }
  },
};
//Jobs api
export const JobsApis = {
  getAll: async () => {
    try {
      const response = await apiClient.get("/getJobDetails");
      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brands:", error);
      throw error;
    }
  },
  closebyId: async (id) => {
    try {
      const response = await apiClient.get(`/changestatusofjob/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle brand by id:", error);
      throw error;
    }
  },
};

// Ticket APIs


// Live Tracking API

export const liveTrackingApi = {
  startTracking: (userId, callback) => {
    let keepTracking = true; 

    const track = async () => {
      while (keepTracking) {
        try {
          const data = await liveTrackingApi.getLatest(userId);
          callback(data); 
        } catch (error) {
          console.error("Error fetching live tracking data:", error);
        }
        await new Promise((resolve) => setTimeout(resolve, 5000)); 
      }
    };

    track(); 

    return () => {
      keepTracking = false;
    };
  },

  getLatest: async (userId) => {
    try {
      const response = await apiClient.get(`/getLatestLocation/${userId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

