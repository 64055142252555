import {
  Box,
  Grid,
  TextField,
  createTheme,
  ThemeProvider,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import Slide from "@mui/material/Slide";

import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";

import { getKeysAndValues } from "../../../utils/getKeysAndValues";
import StatusAlert from "../../../StatusAlertList/StatusAlert";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import AgentsDailog from "./AgentsDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TicketAgents() {
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const [update, setUpdate] = React.useState(false);
  const [selection, setSelection] = useState("Enabled");
  const [selectionNew, setSelectionNew] = useState("Legal");
  const [selectedRow, setSelectedRow] = React.useState({
    keys: [],
    values: [],
    open: false,
  });

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };

  const handleViewMore = (id) => {
    const rowData = businesslocation.filter((row) => row.id === id);

    const { keys, values } = getKeysAndValues(rowData[0]);

    setSelectedRow({ keys, values, open: true });
    // setOpenDialog(true);
  };

  const [type, setType] = useState("");

  const [status, setStatus] = useState("");

  // Function Starts Here

  const [updatetype, setUpdateType] = useState("");
  const [updatelocation, setUpdateLocation] = useState("");
  const [updateaddress, setUpdateAddress] = useState("");
  const [updatecountry, setUpdateCountry] = useState("");
  const [updatemanager, setUpdateManager] = useState("");
  const [updatephone, setUpdatePhone] = useState("");
  const [updateemail, setUpdateEmail] = useState("");
  const [updateofficecode, setUpdateOfficecode] = useState("");
  const [updatedetails, setUpdateDetails] = useState("");

  const [data, SetData] = useState([]);

  const item = {
    type,
  };

  async function SaveDate() {
    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/addBusinesslocation",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      }
    );

    result = await result.json();

    showAlert("Business location Added successfully", "success");

    getData();
    setOpen(false);
  }

  // ends Here

  // Table Starts Here
  const handleChangeSelected = (event, newValue) => {
    setSelection(newValue);
  };
  const handleChangeSelectedNew = (event, newValue) => {
    setSelectionNew(newValue);
  };
  const [businesslocation, setBusinesslocation] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/listBusinessLocations"
    );

    const response = await results.json();
    setBusinesslocation(response);
  }

  const columns = [
    {
      name: "officecode",
      label: "Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Name
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "location",
      label: "Group",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Group
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Select
                value={selectionNew}
                onChange={handleChangeSelectedNew}
                displayEmpty
                // Use renderValue to show placeholder text when value is empty
                renderValue={(selected) => {
                  if (selected === "Legal") {
                    return (
                      <MenuItem
                        value="Legal"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        Legal
                      </MenuItem>
                    ); // Placeholder text
                  }
                  return selected; // Show selected value
                }}
                sx={{
                  width: "210px",

                  border: "none",
                  color: "#000 ",
                  bgcolor: "#fff",
                  "& .MuiSelect-select": {
                    padding: 0,
                  },
                }}
              >
                <MenuItem
                  value="Legal"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Legal
                </MenuItem>
                <MenuItem
                  value="Installation"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Installation
                </MenuItem>
              </Select>
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Select
                value={selection}
                onChange={handleChangeSelected}
                displayEmpty
                // Use renderValue to show placeholder text when value is empty
                renderValue={(selected) => {
                  if (selected === "Enabled") {
                    return (
                      <MenuItem
                        value="Enabled"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        Enabled
                      </MenuItem>
                    ); // Placeholder text
                  }
                  return selected; // Show selected value
                }}
                sx={{
                  width: "110px",

                  border: "none",
                  color: "#000 ",
                  bgcolor: "#fff",
                  "& .MuiSelect-select": {
                    padding: 0,
                  },
                }}
              >
                <MenuItem
                  value="Enabled"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Enabled
                </MenuItem>
                <MenuItem
                  value="Disabled"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Disabled
                </MenuItem>
              </Select>
            </div>
          );
        },
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "70px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                paddingRight: "20px", // Add padding for starting point
              }}
            >
              <Button variant="outlined">
                <div
                  onClick={() => handleDelete(value)}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <DeleteForeverIcon /> Delete
                </div>
              </Button>
            </div>
          </>
        ),
      },
    },
  ];

  async function handleEdit(id) {
    let result = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/businesslocationget/" + id
    );
    result = await result.json();

    SetData(result);
    handleClickUpdate();
  }

  // Update Values Start

  useEffect(() => {
    setUpdateOfficecode(data.name);
  }, [data.name]);

  useEffect(() => {
    setUpdateCountry(data.country);
  }, [data.country]);

  useEffect(() => {
    setUpdateLocation(data.location);
  }, [data.location]);

  useEffect(() => {
    setUpdateManager(data.manager);
  }, [data.manager]);

  useEffect(() => {
    setUpdatePhone(data.phone);
  }, [data.phone]);

  useEffect(() => {
    setUpdateEmail(data.email);
  }, [data.email]);

  useEffect(() => {
    setUpdateAddress(data.address);
  }, [data.address]);

  useEffect(() => {
    setUpdateType(data.type);
  }, [data.type]);

  useEffect(() => {
    setUpdateDetails(data.details);
  }, [data.details]);

  useEffect(() => {
    setStatus(data.status);
  }, [data.status]);

  //Update Values Ends
  // Editing Starts here

  async function UpdateData() {
    const formData = new FormData();

    formData.append("officecode", updateofficecode);
    formData.append("country", updatecountry);
    formData.append("location", updatelocation);
    formData.append("address", updateaddress);
    formData.append("manager", updatemanager);
    formData.append("phone", updatephone);
    formData.append("email", updateemail);
    formData.append("type", updatetype);
    formData.append("details", updatedetails);
    formData.append("status", status);

    try {
      let response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/updateBusinessLocation/${data.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      showAlert("Business location updated successfully", "success");

      handleUpdateClose();
      getData(); // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      showAlert("Failed to update business location", "error");
    }
  }

  // Editing Ends Here

  // Delete

  async function handleDelete(id) {
    const response = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/businesslocationdelete/" + id,
      {
        method: "DELETE",
      }
    );
    const result = await response.json(); // Assigning to a new variable

    getData();
    showAlert("Business location Deleted successfully", "warning");
  }

  // end delete

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          // maxWidth: "1000px",
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  // Table Ends Here

  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />

      <Box>
        <Dialog
          fullWidth
          fitWidth="sm"
          open={update}
          onClose={handleUpdateClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleUpdateClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                Update Business Location
              </Typography>
            </Toolbar>
          </AppBar>

          <Box>
            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Choose Agents
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="RPTPL001"
                      value={updateofficecode}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setUpdateOfficecode(newValue);
                      }}
                      inputProps={{
                        style: {
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8} sm={3}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Assign Group
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="India"
                        value={updatecountry}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setUpdateCountry(newValue);
                        }}
                        inputProps={{
                          style: {
                            color: "black",
                            border: "1px solid black",
                            borderRadius: "5px",
                          },
                        }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              autoFocus
              color="inherit"
              onClick={UpdateData}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                margin: "25px",
              }}
            >
              Update
            </Button>
          </Box>
        </Dialog>
      </Box>

      <List style={{ background: "none", padding: 5, width: "100%" }}>
        <ListItemText style={{ background: "none", margin: 5 }}>
          <AgentsDailog />
        </ListItemText>

        <ListItemText style={{ background: "white", margin: 0, width: "100%" }}>
          <ThemeProvider theme={getTheme()}>
            <MUIDataTable
              title={
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    color: "#333",
                  }}
                >
                  Ticket Agents Lists
                </span>
              }
              data={businesslocation}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default TicketAgents;
