import React, { useState, forwardRef, useEffect } from "react";
import { ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import loginbackground from "../../assets/loginbackground.png";
import backloginfo from "../../assets/backlog.png";
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Container,
  Avatar,
  Checkbox,
  FormControlLabel,
  Stack,
} from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MuiAlert from "@mui/material/Alert";
import { useLocalStorageContext } from "../../Context/LocalProvider";

import { useMode } from "../../theme";
import { useSnackbar } from "../../Context/SnackbarContext";
import { loginApis } from "../../Data/axios";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const lighttheme = createTheme({
  palette: {
    mode: "light",
  },
});

const center = {
  position: "relative",
  top: ".2rem",
  margin: "auto",
};

function LoginPag() {
  const { showSnackbar } = useSnackbar();
  // const [open, setOpen] = useState(false);
  const [remember, setRemember] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const { data, saveData } = useLocalStorageContext();

  const [theme = {}, colorMode] = useMode();

  const breakpoint = theme.breakpoints.down("md");

  const isDown = useMediaQuery(breakpoint);
  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    maxHeight: "70%",
    boxSizing: "border-box",

    bgcolor: isDown ? "" : "background.paper",
    boxShadow: 24,
  };
  useEffect(() => {
    if (data) {
      navigate("/rptpl");
    }
  }, [data, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!email || !password) {
      showSnackbar("Enter the email or password", "error");

      return;
    }
    submitForm();
  };
  let item = { email, password };
  async function submitForm() {
    const formData = new FormData();

    // Append each item to the FormData object
    for (const key in item) {
      if (item[key] !== undefined && item[key] !== null) {
        formData.append(key, item[key]);
      }
    }

    let result = await loginApis.add(formData);

    if (result.error === "201") {
      showSnackbar("Invalid data. Please check your credentials.", "error");
    } else {
      const newResult = {
        name: result.name,
        email: result.email,
        empid: result.empid,
      };
      showSnackbar("Welcome to Road Pilot!!", "success");

      saveData(newResult, 6 * 60 * 60000);
      navigate("/rptpl");
    }
  }

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${loginbackground})`,
          backgroundSize: "cover",
          height: "100vh",
          color: "#f5f5f5",
          boxSizing: "border-box",
        }}
      >
        <Box sx={boxStyle}>
          <Grid container>
            {!isDown && (
              <Grid item xs={12} sm={12} lg={6}>
                <Box
                  sx={{
                    backgroundImage: `url(${backloginfo})`,
                    backgroundSize: "cover",

                    height: "100%",
                    color: "#f5f5f5",
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} lg={6}>
              <Box
                sx={{
                  backgroundColor: "#C0B476",
                  maxHeight: "100%",
                  padding: "1rem",
                }}
              >
                <ThemeProvider theme={lighttheme}>
                  <Container>
                    <Box height={10} />
                    <Box sx={center}>
                      <Avatar
                        sx={{
                          ml: "35px",
                          mb: "4px",
                          color: "#000000",
                          margin: "auto",
                        }}
                      >
                        <LockOpenIcon />
                      </Avatar>
                      <Typography
                        component="h1"
                        variant="h4"
                        sx={{ color: "black", textAlign: "center" }}
                      >
                        Sign In
                      </Typography>
                    </Box>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 2, bgcolor: "white" }}
                    >
                      <Grid container spacing={1} sx={{ padding: "2rem" }}>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            id="email"
                            margin="normal"
                            label="Username"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            autoFocus
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Stack direction="row" spacing={2}>
                            <FormControlLabel
                              sx={{ width: "60%" }}
                              style={{ color: "black" }}
                              onClick={() => setRemember(!remember)}
                              control={<Checkbox checked={remember} />}
                              label="Remember me"
                            />
                            <Typography
                              variant="body1"
                              component="span"
                              onClick={() => {
                                navigate("/reset-password");
                              }}
                              sx={{
                                mt: "10px",
                                cursor: "pointer",
                                color: "black",
                              }}
                            >
                              Forgot password?
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{
                              borderRadius: 28,
                              color: "#ffffff",
                              padding: ".5rem 3rem",
                              backgroundColor: "#FF9A01",
                            }}
                          >
                            Sign in
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack direction="row" spacing={2}>
                            <Typography
                              variant="body1"
                              component="span"
                              sx={{ mt: "10px", color: "black" }}
                            >
                              Any Problem in Login?{" "}
                              <span
                                style={{ color: "orange", cursor: "pointer" }}
                                onClick={() => {
                                  navigate("/Login-help");
                                }}
                              >
                                Click Here
                              </span>
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Container>
                </ThemeProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default LoginPag;
