import {
  Box,
  Grid,
  TextField,
  createTheme,
  ThemeProvider,
  Stack,
  Select,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";
import { DepartmentApis } from "../../../Data/axios";

import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import StatusAlert from "../../../StatusAlertList/StatusAlert";
import HeaderDepartment from "./HeaderDepartment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function Department() {
  const [open, setOpen] = React.useState(false);

  const [name, setName] = React.useState("");

  const [parent, setParent] = React.useState("");

  const [updateName, setUpdateName] = React.useState("");
  const [updateParentDepartment, setUpdateParentDepartment] =
    React.useState("");

  const [data, SetData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [update, setUpdate] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleClickUpdate = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
    setUpdateName("");
    setUpdateParentDepartment("");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName("");
    setParent("");
  };

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const results = await DepartmentApis.getAll();

    setDesignationData(results);
  }

  const columns = [
    {
      name: "department",
      label: "Department",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Department
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "parent",
      label: "Parent Department",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center", // Aligns header to the left
                // Add padding for starting point
                borderBottom: "1px solid #ddd",
              }}
            >
              Parent Department
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center", // Aligns content to the left
                alignItems: "center",
                // Add padding for starting point
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right", // Aligns header to the left
                paddingRight: "70px", // Add padding for starting point
                borderBottom: "1px solid #ddd",
              }}
            >
              Actions
            </th>
          );
        },
        customBodyRender: (value) => (
          <div
            key={value}
            style={{
              display: "flex",
              justifyContent: "flex-end", // Aligns content to the left
              alignItems: "center",
              paddingLeft: "20px", // Add padding for starting point
            }}
          >
            <Stack
              spacing={2}
              direction="row"
              sx={{
                justifyContent: "flex-end",
                "& .MuiButton-root": {
                  margin: "0 4px",
                },
              }}
            >
              <Button variant="outlined" onClick={() => handleEdit(value)}>
                <EditNoteIcon /> Update
              </Button>
              <Button variant="outlined" onClick={() => handleDelete(value)}>
                <DeleteForeverIcon /> Delete
              </Button>
            </Stack>
          </div>
        ),
      },
    },
  ];
  async function handleEdit(id) {
    const result = designationData.filter((item) => item.id === id);

    SetData(result[0]);

    handleClickUpdate();
  }
  useEffect(() => {
    setUpdateName(data.department);
  }, [data.department]);

  useEffect(() => {
    setUpdateParentDepartment(data.parent);
  }, [data.parent]);

  async function updateDepartment() {
    const formData = new FormData();

    formData.append("department", updateName);
    formData.append("parent", updateParentDepartment);

    try {
      await DepartmentApis.update(data.id, formData);

      handleUpdateClose();

      getData();
      setName("");
      setParent("");
      showAlert("  Updated successfully", "success");
    } catch (error) {
      console.error(error);
      showAlert("Failed to update", "error");
    }
  }

  async function handleDelete(id) {
    await DepartmentApis.deletebyId(id);
    getData();
    showAlert(" Deleted Successfully!", "warning");
  }

  async function AddDepartment() {
    const formData = new FormData();

    const creator_name = localStorage.getItem("empid");
    formData.append("department", name);
    formData.append("parent", parent);
    formData.append("creator_id", creator_name);

    await DepartmentApis.add(formData);

    showAlert(" Added successfully", "success");

    getData();
    handleClose();
  }

  const options = {
    selectableRows: "none",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
        fontWeight: 600,
        justifyContent: "flex-start", // Aligns header items to the left
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "start", // Aligns row items to the left
      },
      selectableRowsOnClick: true,
    },
  };
  const Marketing = "Marketing";
  const Sales = "Sales";
  const HumanResources = "Human Resource";
  const Research = "Research";
  const Finance = "Finance";
  const PublicRelations = "Public Relations";

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <HeaderDepartment />
      <React.Fragment>
        <Dialog
          fullWidth
          maxWidth="md"
          open={update}
          onClose={handleUpdateClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <Box sx={{ background: "#fff", color: "#000" }}>
            <DialogTitle>
              <span
                style={{
                  background: "blue",
                  color: "#fff",
                  padding: "15px",
                  borderRadius: "5px",
                }}
              >
                Update Department
              </span>
            </DialogTitle>
            <Grid
              container
              spacing={2}
              sx={{
                padding: { xs: "10px", sm: "20px" },
              }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <Stack direction="column" spacing={2}>
                  <Typography>Name</Typography>
                  <TextField
                    autoFocus
                    margin="dense"
                    placeholder="Designation Name"
                    type="text"
                    fullWidth
                    value={updateName}
                    onChange={(e) => setUpdateName(e.target.value)}
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Stack direction="column" spacing={2}>
                  <Typography>Parent</Typography>
                  <Select
                    value={updateParentDepartment}
                    autoFocus
                    fullWidth
                    onChange={(e) => setUpdateParentDepartment(e.target.value)}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    sx={{
                      border: "1px solid black",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>---</em>
                    </MenuItem>
                    <MenuItem value={Marketing}>Marketing</MenuItem>
                    <MenuItem value={Sales}>Sales</MenuItem>
                    <MenuItem value={HumanResources}>Human Resources</MenuItem>
                    <MenuItem value={PublicRelations}>
                      Public Relations
                    </MenuItem>
                    <MenuItem value={Research}>Research</MenuItem>
                    <MenuItem value={Finance}>Finance</MenuItem>
                  </Select>
                </Stack>
              </Grid>

              <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                <DialogActions>
                  <Button onClick={handleUpdateClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={updateDepartment}
                    color="primary"
                    sx={{
                      background: "blue",
                      color: "#fff",
                      "&:hover": {
                        background: "#000",
                      },
                    }}
                  >
                    Add
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      </React.Fragment>
      <List style={{ background: "none", padding: 5 }}>
        <StatusAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          severity={alertSeverity}
        />
        <ListItemText style={{ background: "none", margin: "10px 0 5px 0px" }}>
          <React.Fragment>
            <Stack direction="row" spacing={2}>
              <Box
                onClick={handleClickOpen}
                sx={{
                  backgroundColor: "#1d82f5",
                  "&:hover": { backgroundColor: "orange" },
                  textAlign: "center",
                  padding: "10px",
                  marginLeft: "10px",
                  height: "40px",
                  width: "155px",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  borderRadius: "4px",
                  fontSize: "15px",
                  fontFamily: "sans-serif",
                  cursor: "pointer",
                }}
              >
                <AddIcon />
                Add Department
              </Box>
            </Stack>
            <Dialog
              fullWidth
              maxWidth="md"
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
              PaperProps={{
                style: {
                  position: "absolute",
                  background: "#141b2d",
                  top: "0",
                  backgroundColor: "white",
                  maxHeight: "95%",
                  margin: "auto",
                  overflowX: "hidden",
                  zIndex: 1200,
                },
              }}
            >
              <Box sx={{ background: "#fff", color: "#000" }}>
                <DialogTitle>
                  <span
                    style={{
                      background: "blue",
                      color: "#fff",
                      padding: "15px",
                      borderRadius: "5px",
                    }}
                  >
                    Add New Department
                  </span>
                </DialogTitle>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    padding: { xs: "10px", sm: "20px" },
                  }}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <Stack direction="column" spacing={2}>
                      <Typography>Name</Typography>
                      <TextField
                        autoFocus
                        margin="dense"
                        placeholder="Designation Name"
                        type="text"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{
                          style: { color: "black" },
                        }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Stack direction="column" spacing={2}>
                      <Typography>Parent</Typography>
                      <Select
                        value={parent}
                        autoFocus
                        fullWidth
                        onChange={(e) => setParent(e.target.value)}
                        displayEmpty
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        sx={{
                          border: "1px solid black",
                          boxSizing: "border-box",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black !important",
                            "&:hover fieldset": {
                              borderColor: "black !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black !important",
                            },
                          },
                          color: "black",
                        }}
                      >
                        <MenuItem disabled value="">
                          <em>---</em>
                        </MenuItem>
                        <MenuItem value={Marketing}>Marketing</MenuItem>
                        <MenuItem value={Sales}>Sales</MenuItem>
                        <MenuItem value={HumanResources}>
                          Human Resources
                        </MenuItem>
                        <MenuItem value={PublicRelations}>
                          Public Relations
                        </MenuItem>
                        <MenuItem value={Research}>Research</MenuItem>
                        <MenuItem value={Finance}>Finance</MenuItem>
                      </Select>
                    </Stack>
                  </Grid>

                  <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Cancel
                      </Button>
                      <Button
                        onClick={AddDepartment}
                        color="primary"
                        sx={{
                          background: "blue",
                          color: "#fff",
                          "&:hover": {
                            background: "#000",
                          },
                        }}
                      >
                        Add
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </Box>
            </Dialog>
          </React.Fragment>
        </ListItemText>
        <Divider />
        <ListItemText style={{ background: "white", margin: 0, width: "100%" }}>
          <ThemeProvider theme={getTheme()}>
            <MUIDataTable
              title={
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    color: "#333",
                  }}
                >
                  Departments
                </span>
              }
              data={designationData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default Department;
