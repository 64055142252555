import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function ProjectRadioSetting() {
  return (
    <FormControl>
      <FormLabel
        id="demo-row-radio-buttons-group-label"
        sx={{ color: "black", fontSize: "1rem", marginBottom: 1 }}
      >
        Send Reminder To
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          value="Administrators"
          control={
            <Radio
              sx={{
                color: "gray",
                "&.Mui-checked": {
                  color: "blue",
                },
              }}
            />
          }
          label="Administrators"
        />
        <FormControlLabel
          value="Project Members"
          control={
            <Radio
              sx={{
                color: "gray",
                "&.Mui-checked": {
                  color: "blue",
                },
              }}
            />
          }
          label="Project Members"
        />
        <FormControlLabel
          value="All"
          control={
            <Radio
              sx={{
                color: "gray",
                "&.Mui-checked": {
                  color: "blue",
                },
              }}
            />
          }
          label="All"
        />
      </RadioGroup>
    </FormControl>
  );
}
