import {
  Box,
  Grid,
  TextField,
  createTheme,
  ThemeProvider,
  Menu,
  Stack,
  Select,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  CssBaseline,
  Autocomplete,
  FormLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ReactQuill from "react-quill";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";

import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import StatusAlert from "../../StatusAlertList/StatusAlert";
import { getKeysAndValues } from "../../utils/getKeysAndValues";
import { useNavigate } from "react-router-dom";
import config from "../../Data/AuthUser";
import { format } from "date-fns";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Convoy() {
  const [member, setMember] = React.useState("");
  const [leaveType, setLeaveType] = React.useState("");
  const [optionsAgent, setOptionsAgent] = useState([]);
  const [duration, setDuration] = React.useState("");
  const [date, setDate] = useState(null); // Initialize state for the date
  const [memberError, setMemberError] = React.useState(false);
  const [reason, setReason] = useState("");
  const [fileName, setFileName] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openNotes, setOpenNotes] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [followup, setFollowup] = React.useState(false);
  const [follow_agent, setFollow_agent] = useState("");
  const [follow_date, setFollow_date] = useState("");
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const navigate = useNavigate();

  const [update, setUpdate] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({
    keys: [],
    values: [],
    open: false,
  });

  const handleUpdateClose = () => {
    setUpdate(false);
  };
  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleViewMore = () => {
    const rowData = convoyData.filter((row) => row.id === openMenuId);

    navigate(`/Convoy/${openMenuId}`, { state: rowData[0] });

    const { keys, values } = getKeysAndValues(rowData[0]);

    setSelectedRow({ keys, values, open: true });
    // setOpenDialog(true);
  };

  // Function Starts Here

  const [updateMember, setUpdateMember] = React.useState("");
  const [updateLeaveType, setUpdateLeaveType] = React.useState("");

  const [updateDuration, setUpdateDuration] = React.useState("");
  const [updateDate, setUpdateDate] = useState(null); // Initialize state for the date
  const [updateMemberError, setUpdateMemberError] = React.useState(false);
  const [updateReason, setUpdateReason] = useState("");
  const [updateFileName, setUpdateFileName] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [data, SetData] = useState([]);
  const [convoyData, setConvoyData] = useState([]);
  const [openMenuId, setOpenMenuId] = useState(null);
  const handleUpdateCloseFollow = () => {
    setFollowup(false);
  };
  const handleClickFollowup = () => {
    setFollowup(true);
  };
  const handleFollowup = () => {
    handleClickFollowup();
  };
  const item = {
    member,
    memberError,
    reason,
    duration,
    date,
    leaveType,
    fileName,
  };
  async function Savefollowup() {
    const formData = new FormData();
    const time = new Date();
    const empid = localStorage.getItem("empid");
    formData.append("follow_agent", follow_agent);
    formData.append("follow_date", follow_date);
    formData.append("assigned_by", empid);
    formData.append("assigned_at", time);

    try {
      let response = await fetch(
        ` https://rptpl.roadpilot.co.in/public/api/assignfollowup/${openMenuId}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      showAlert(" Followup updated successfully", "success");

      handleUpdateCloseFollow();
      getData(); // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      showAlert("Failed to update leads", "error");
    }
  }

  // ends Here

  // Table Starts Here
  const handleChangeSelected = async (event, ticketId) => {
    const selectedValue = event.target.value;

    // Perform any state update or action based on selectedValue and ticketId
    const itemStatus = {
      status: selectedValue,
    };

    // Create a new FormData object
    const formData = new FormData();

    for (const key in itemStatus) {
      if (itemStatus[key] !== undefined && itemStatus[key] !== null) {
        formData.append(key, itemStatus[key]);
      }
    }

    let result = await fetch(
      `https://rptpl.roadpilot.co.in/public/api/changestatus/${ticketId}`,
      {
        method: "POST",
        body: formData,
      }
    );

    await result.json();

    showAlert("updated successfully", "success");

    getData();
  };
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let results = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/ConvoyList"
    );

    const response = await results.json();
    setConvoyData(response.data);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      name: "app_user",
      label: "Creator",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Creator
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0].user_id;
          const fullRecord = convoyData.find(
            (item) => item.app_user.user_id === ticketId
          );

          if (!fullRecord) {
            return <div>Loading...</div>;
          }

          const imageUrl = `${config.apiImageUrl}${fullRecord.app_user.mage}`;
          const userName = fullRecord.app_user.username;

          return (
            <div
              key={value}
              style={{
                display: "flex",
              }}
            >
              <img
                src={imageUrl}
                alt="requester"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: "10px",
                }}
              />
              <div>
                <div style={{ fontWeight: "bold" }}>
                  {userName || "Unknown"}
                </div>
                <div style={{ fontSize: "12px", color: "#888" }}>
                  {ticketId || "N/A"}
                </div>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "start_location",
      label: "Convoy",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Convoy
            </th>
          );
        },

        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0].user_id;
          const fullRecord = convoyData.find(
            (item) => item.app_user.user_id === ticketId
          );

          if (!fullRecord) {
            return <div>Loading...</div>;
          }

          const finalLocation = fullRecord.end_location;

          return (
            <div
              key={value}
              style={{
                display: "flex",
              }}
            >
              <div>
                <div style={{ fontSize: "12px", color: "#333" }}>
                  Start Location: {value || "N/A"}
                </div>
                <div style={{ fontSize: "12px", color: "#333" }}>
                  End Location: {finalLocation || "N/A"}
                </div>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Time",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Time
            </th>
          );
        },
        customBodyRender: (value) => {
          const date = new Date(value);
          const formattedDate = format(date, "dd/MM/yyyy, HH:mm:ss");
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {formattedDate || " N/A"}
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0];
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Select
                value={value}
                onChange={(event) => handleChangeSelected(event, ticketId)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === "1") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Open
                      </MenuItem>
                    ); // Placeholder text
                  }
                  if (selected === "2") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Suspended
                      </MenuItem>
                    ); // Placeholder text
                  }
                  if (selected === "0") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Closed
                      </MenuItem>
                    ); // Placeholder text
                  }
                  return selected; // Show selected value
                }}
                sx={{
                  width: "110px",

                  border: "none",
                  color: "#000 ",
                  bgcolor: "#fff",
                  "& .MuiSelect-select": {
                    padding: 0,
                  },
                }}
              >
                <MenuItem
                  value={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Open
                </MenuItem>
                <MenuItem
                  value={0}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Closed
                </MenuItem>
                <MenuItem
                  value={2}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Suspended
                </MenuItem>
              </Select>
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "70px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                paddingRight: "20px", // Add padding for starting point
              }}
            >
              <div style={{ paddingRight: "20px" }}>
                <Button
                  id="basic-button"
                  aria-controls={openDropMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div onClick={() => handleOpenMenu(value)}>
                    <MoreHorizTwoToneIcon />
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleViewMore(tableMeta.rowData)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <RemoveRedEyeIcon /> View More
                    </div>
                  </MenuItem>

                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={handleFollowup}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <AssignmentIndIcon /> Follow Up
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleNotes(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EditNoteIcon /> Notes
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleDelete(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <DeleteForeverIcon /> Close
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </>
        ),
      },
    },
  ];

  const handleNotes = () => {
    setOpenNotes(true);
  };

  // Update Values Start

  useEffect(() => {
    setUpdateMember(data.member);
  }, [data.member]);

  useEffect(() => {
    setUpdateDate(data.date);
  }, [data.date]);

  useEffect(() => {
    setUpdateDuration(data.duration);
  }, [data.duration]);

  useEffect(() => {
    setUpdateMemberError(data.managerError);
  }, [data.managerError]);

  useEffect(() => {
    setUpdateFileName(data.fileName);
  }, [data.fileName]);

  useEffect(() => {
    setUpdateReason(data.reason);
  }, [data.reason]);

  useEffect(() => {
    setUpdateLeaveType(data.leaveType);
  }, [data.leaveType]);

  useEffect(() => {
    setUpdateStatus(data.status);
  }, [data.status]);
  const handleCloseNotes = () => {
    setOpenNotes(false);
  };

  const itemNotes = {
    leaveType,
  };

  async function SaveNotes() {
    let result = await fetch(
      "https://rptpl.roadpilot.co.in/public/api/addBusinesslocation",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      }
    );

    result = await result.json();

    showAlert("Notes Added successfully", "success");

    getData();
    setOpenNotes(false);
  }
  async function UpdateData() {
    const formData = new FormData();

    formData.append("member", updateMember);
    formData.append("memberError", updateMemberError);
    formData.append("fileName", updateFileName);
    formData.append("date", updateDate);
    formData.append("reason", updateReason);
    formData.append("duration", updateDuration);
    formData.append("leaveType", updateLeaveType);
    formData.append("status", updateStatus);
    try {
      let response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/updateBusinessLocation/${data.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      showAlert("Leave updated successfully", "success");

      handleUpdateClose();
      getData(); // Assuming these functions are defined elsewhere in your code
    } catch (error) {
      console.error(error);
      showAlert("Failed to update business location", "error");
    }
  }

  // Editing Ends Here

  // Delete

  async function handleDelete(id) {
    const response = await fetch(
      " https://rptpl.roadpilot.co.in/public/api/businesslocationdelete/" + id,
      {
        method: "DELETE",
      }
    );
    const result = await response.json(); // Assigning to a new variable

    getData();
    showAlert("Leave Deleted successfully", "warning");
  }

  // end delete

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };
  const theme = createTheme({
    components: {
      MuiPickersDay: {
        styleOverrides: {
          root: {
            backgroundColor: "lightblue", // Change this to your desired color
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          root: {
            backgroundColor: "lightblue", // Change this to your desired color
          },
        },
      },
    },
  });
  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  // Table Ends Here

  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />
      <Dialog
        fullWidth
        maxWidth="md"
        open={openNotes}
        onClose={handleCloseNotes}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            position: "absolute",
            background: "#141b2d",
            top: "0",
            backgroundColor: "white",
            maxHeight: "95%",
            margin: "auto",
            overflowX: "hidden",
            zIndex: 1200,
          },
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseNotes}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} component="div">
              Add Convoy Note
            </Typography>
          </Toolbar>
        </AppBar>
        <Box>
          <Grid
            container
            spacing={2}
            style={{ color: "black", margin: "10px", zIndex: "4000" }}
          >
            <Grid item xs={10} sm={8} md={6}>
              <Typography variant="p" gutterBottom style={{ color: "black" }}>
                Note Title
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={10} sm={8} md={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    placeholder="Title "
                    onChange={(e) => setFileName(e.target.value)}
                    inputProps={{
                      style: {
                        color: "black",
                        border: "1px solid black",
                        borderRadius: "5px",
                      },
                    }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={10} sm={8} md={6}>
              <FormControl>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  sx={{
                    color: "black",

                    padding: "4px",
                    borderRadius: "4px",
                  }}
                >
                  Note Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Public"
                    control={
                      <Radio
                        sx={{
                          color: "black",
                          "&.Mui-checked": { color: "gray" },
                        }}
                      />
                    }
                    label="Public"
                  />
                  <FormControlLabel
                    value="Private"
                    control={
                      <Radio
                        sx={{
                          color: "black",
                          "&.Mui-checked": { color: "gray" },
                        }}
                      />
                    }
                    label="Private"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container sx={{ padding: "20px" }}>
            <Stack direction="column" sx={{ width: "100%" }}>
              <Typography variant="p" gutterBottom style={{ color: "black" }}>
                Notes Detail<span style={{ color: "red" }}>*</span>
              </Typography>

              <ReactQuill
                value={date}
                onChange={() => {}}
                // modules={modules}
                // theme="snow"
                style={{
                  // border: '1px solid black',
                  // borderRadius: '4px',
                  //padding: '10px',
                  width: "100%",
                  boxSizing: "border-box",
                  color: "black",
                  borderBlockColor: "black",
                  // minHeight: '100px' // Set your desired minimum height
                }}
              />
            </Stack>
          </Grid>
          <br />
          <br />

          <Button
            variant="contained"
            autoFocus
            color="inherit"
            onClick={SaveNotes}
            sx={{
              backgroundColor: "blue",
              "&:hover": { backgroundColor: "orange" },
              textAlign: "center",
              margin: "25px",
            }}
          >
            Save
          </Button>
        </Box>
      </Dialog>

      {/* Follow up box  */}
      <Box sx={{ zIndex: 11000 }}>
        <Dialog
          open={followup}
          fullWidth
          maxWidth="md"
          onClose={handleUpdateCloseFollow}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleUpdateCloseFollow}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                Follow Up
              </Typography>
            </Toolbar>
          </AppBar>

          <Box sx={{ backgroundColor: "white" }}>
            <Grid
              container
              spacing={2}
              style={{
                color: "black",
                margin: "10px",
              }}
            >
              <Grid item xs={8} sm={4}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Follow Agent
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Autocomplete
                      options={optionsAgent}
                      sx={{ border: "1px solid gray", borderRadius: "5px" }}
                      value={follow_agent}
                      onChange={(event, newValue) => {
                        setFollow_agent(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          placeholder="ex. abc"
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              color: "black",
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: "black",
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black",
                              "&:hover fieldset": {
                                borderColor: "black",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              "&::placeholder": { color: "gray" },
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8} sm={4}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Follow Date
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Date"
                          PaperProps={{
                            sx: {
                              "& .MuiPickersCalendarHeader-root": {
                                backgroundColor: "lightblue", // Change this to your desired color
                              },
                              "& .MuiPickersDay-root": {
                                backgroundColor: "lightblue", // Change this to your desired color
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </ThemeProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              autoFocus
              color="inherit"
              onClick={Savefollowup}
              sx={{
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                margin: "25px",
              }}
            >
              Update
            </Button>
          </Box>
        </Dialog>
      </Box>
      <List
        style={{
          background: "none",
          // padding: 5,
        }}
      >
        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <Box
              sx={{
                maxWidth: "100%", // Ensure it doesn't exceed the parent container
              }}
            >
              <MUIDataTable
                title={
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "1.5rem",
                      color: "#333",
                    }}
                  >
                    Convoy Lists
                  </span>
                }
                // data={convoyData}
                columns={columns}
                options={options}
              />
            </Box>
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default Convoy;
