import React, { useState } from "react";
import "./Message.css";
import {
  AppBar,
  Box,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Search, ChatBubble, Add, Close } from "@mui/icons-material";
import ReactQuill from "react-quill";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Message = () => {
  const [open, setOpen] = React.useState(false);
  const [member, setMember] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  const handleFileChange = (event) => {
    setUploadFile(event.target.files[0]);
  };
  const SaveData = () => {};
  const handleCancel = () => {};
  return (
    <Box className="mainSide">
      <Box className="leftSide">
        <Box className="topLeftSide">
          <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    sx={{
                      height: "40px",
                      color: "#000",
                      padding: 0,
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "#000",
                      },
                    }}
                  >
                    <Search fontSize="small" />
                  </IconButton>
                </InputAdornment>
              }
              placeholder="Start typing to search"
              sx={{
                width: "260px",
                height: "40px",
                margin: ".4rem",

                border: ".1px solid gray",
                borderRadius: "5px",
                color: "#000 ",
                bgcolor: "#eff1f1",

                boxSizing: "border-box",
              }}
            />
          </FormControl>
        </Box>
        <Box className="bottomLeftSide">
          <p>
            <ChatBubble />
          </p>
          <p>- No conversation found. -</p>
        </Box>
      </Box>
      <Box className="rightSide">
        <Box className="topRightSide">
          <Stack
            direction="row"
            spacing={2}
            sx={{
              margin: "10px 10px 5px 2px",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Box
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#1d82f5",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                padding: "10px",
                marginLeft: "10px",
                height: "40px",
                width: "170px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: "4px",
                fontSize: "15px",
                fontFamily: "sans-serif",
                cursor: "pointer",
              }}
            >
              <Add />
              New Conversation
            </Box>
          </Stack>
          <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                position: "absolute",
                background: "#141b2d",
                top: "0",
                backgroundColor: "white",
                maxHeight: "95%",
                margin: "auto",
                overflowX: "hidden",
                zIndex: 1200,
              },
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <Close />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                  New Conversation
                </Typography>
              </Toolbar>
            </AppBar>
            <Box
              sx={{
                backgroundColor: "white",
              }}
            >
              <Grid container sx={{ marginTop: "20px", padding: "20px" }}>
                <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    sx={{ paddingRight: { xs: 0, sm: 2 } }}
                  >
                    <Stack direction="column">
                      <Typography
                        variant="p"
                        gutterBottom
                        style={{ color: "black" }}
                      >
                        Chose Member <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <Select
                        value={member}
                        autoFocus
                        onChange={(e) => setMember(e.target.value)}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "3px",
                          boxSizing: "border-box",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black !important",
                            "&:hover fieldset": {
                              borderColor: "black !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black !important",
                            },
                          },
                          color: "black",
                        }}
                      >
                        <MenuItem disabled value="">
                          <em>---</em>
                        </MenuItem>
                        <MenuItem value={"Gold"}>Gold</MenuItem>
                        <MenuItem value={"Silver"}>Silver</MenuItem>
                        <MenuItem value={"Bronze"}>Bronze</MenuItem>
                      </Select>
                      {/* {awardError && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          Please select an award
                        </Typography>
                      )} */}
                    </Stack>
                  </Grid>
                </Stack>
              </Grid>

              <Grid container sx={{ padding: "20px" }}>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Message<span style={{ color: "red" }}>*</span>
                  </Typography>
                  {/* <CustomToolbar style={{ color: 'black' }} /> Render custom emoji toolbar */}
                  <ReactQuill
                    value={member}
                    onChange={setMember}
                    modules={modules}
                    theme="snow"
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      color: "black",
                      borderBlockColor: "black",
                    }}
                  />
                </Stack>
              </Grid>
              <Grid
                container
                sx={{
                  padding: {
                    xs: "60px 20px 10px 20px",
                    sm: "40px 20px 10px 20px",
                    md: "20px 0 10px 20px",
                  },
                }}
              >
                <Stack direction="column" sx={{ width: "100%" }}>
                  <br></br>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Add File
                  </Typography>
                  <label
                    htmlFor="file-upload"
                    style={{
                      cursor: "pointer",
                      color: "black",
                      border: "1px solid gray",
                      borderRadius: "4px",
                      padding: "10px",
                      width: "100%",
                      boxSizing: "border-box",
                      minHeight: "100px", // Set your desired minimum height
                      backgroundColor: "#fff", // Change background color to light gray
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Choose a File
                  </label>
                  <input
                    onChange={handleFileChange}
                    type="file"
                    id="file-upload"
                    style={{ display: "none" }}
                  />
                  {uploadFile && (
                    <div style={{ color: "black" }}>{uploadFile.name}</div>
                  )}
                </Stack>
              </Grid>

              <Grid container sx={{ padding: "10px 10px 20px 10px" }}>
                <Grid item xs={11} className="messageFooter">
                  <Box className="response" onClick={SaveData}>
                    Save
                  </Box>
                  <Box className="cancel" onClick={handleCancel}>
                    Cancel
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Dialog>
        </Box>
        <Box className="bottomRightSide">
          <p>
            <ChatBubble />
          </p>
          <p>- Select a conversation to send a message -</p>
        </Box>
      </Box>
    </Box>
  );
};

export default Message;
