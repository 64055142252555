import { createTheme, ThemeProvider, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";

import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import Slide from "@mui/material/Slide";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import MUIDataTable from "mui-datatables";
import { Paper, styled } from "@mui/material";

import { DesignationApis } from "../../../Data/axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function LeaveReport() {
  const [open, setOpen] = React.useState(false);
  const [designationList, setDesignationList] = useState([]);
  const [name, setName] = React.useState("");

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const [parent, setParent] = React.useState("");

  const [updateName, setUpdateName] = React.useState("");
  const [updateParentDesignation, setUpdateParentDesignation] =
    React.useState("");

  const [data, SetData] = useState([]);
  const [designationData, setDesignationData] = useState([]);

  const handleUpdateClose = () => {
    setUpdateName("");
    setUpdateParentDesignation("");
  };
  const handleViewMore = () => {};
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName("");
    setParent("");
  };

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const results = await DesignationApis.getAll();
    setDesignationData(results);
  }

  const columns = [
    {
      name: "",
      label: "Employee",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Employee
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },

    {
      name: "",
      label: "Approved",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Approved
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Pending",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Pending
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "30px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Actions
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <div
            key={value}
            style={{
              display: "flex",
              justifyContent: "flex-end", // Aligns content to the left
              alignItems: "center",
              // paddingLeft: "20px", // Add padding for starting point
            }}
          >
            <Stack spacing={2} direction="row">
              <Button variant="outlined" onClick={() => handleViewMore(value)}>
                {" "}
                <RemoveRedEyeIcon /> View
              </Button>
            </Stack>
          </div>
        ),
      },
    },
  ];
  const fetchDataDesignation = async () => {
    try {
      const response = await fetch(
        "https://rptpl.roadpilot.co.in/public/api/getDesignation"
      );
      const data = await response.json();

      setDesignationList(data);
    } catch (err) {
      throw new Error("Failed to fetch data. Please try again later.");
    }
  };
  useEffect(() => {
    fetchDataDesignation();
  }, []);

  async function handleEdit(id) {
    const result = designationData.filter((item) => item.id === id);

    SetData(result[0]);
  }
  useEffect(() => {
    setUpdateName(data.designation);
  }, [data.designation]);

  useEffect(() => {
    setUpdateParentDesignation(data.parent);
  }, [data.parent]);

  async function handleDelete(id) {
    await DesignationApis.deletebyId(id);

    getData();
  }

  async function AddDepartment() {
    const creator_name = localStorage.getItem("empid");
    const formData = new FormData();

    formData.append("designation", name);
    formData.append("parent", parent);

    formData.append("creator", creator_name);

    await DesignationApis.add(formData);

    getData();
    handleClose();
  }

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <>
      <List>
        <Divider />
        <ListItemText style={{ background: "white", margin: 0, width: "100%" }}>
          <ThemeProvider theme={getTheme()}>
            <MUIDataTable
              title={
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    color: "#333",
                  }}
                >
                  Leave Report Lists
                </span>
              }
              data={designationData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default LeaveReport;
