import { Box } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import './FieldTimeline.css';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Stack } from '@mui/material';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function FieldTimeline() {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };




  return (
    <Box display="flex" flexDirection="column" >
      <Box sx={{ flexGrow: 1 }}>
        <div className='table'>
          <div className='stack'>
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-name-label">Name</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={personName}
                  onChange={handleChange}
                  input={<OutlinedInput label="Name" />}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, personName, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DesktopDatePicker',
                  ]}
                >
                  <DemoItem>
                    <DesktopDatePicker defaultValue={dayjs('16 Feb 2024')} />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
          <Stack component="div" direction="row">
            <div>
              <div  className='timeline'>
                <div><h3 style={{ marginTop: "10px" }}>Timeline</h3></div>
                <div>
                  <FormControl component="fieldset" style={{ marginLeft: "170px", marginTop: "5px" }}>
                  <FormGroup>
                    <FormControlLabel
                      value="end"
                      control={<Switch color="secondary" />}
                      label="Activity"
                      labelPlacement="end"
                    />
                  </FormGroup>
                  </FormControl>
                </div>
              </div>
              {/* <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            {[0, 1, 2, 3].map((value) => {
                                const labelId = `checkbox-list-label-${value}`;

                                return (
                                    <ListItem
                                        key={value}
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="comments">
                                                <CommentIcon />
                                            </IconButton>
                                        }
                                        disablePadding
                                    >
                                        <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(value) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={`Line item ${value + 1}`} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List> */}
              <div className='list'>
                <List>
                  {/* Data */}
                  <ListItem>
                    <ListItemText>
                      <Typography>07Apr2024</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography>Tracked</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography>4.5 Km</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography>2h 40m</Typography>
                    </ListItemText>
                  </ListItem>

                  {/* Sub-items */}
                  <ListItem>
                    <ListItemText>
                      <Typography>10:20AM</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography><LocationSearchingIcon></LocationSearchingIcon>Tracking Started</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography>0 Km</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography>0h 0m</Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem>
                    <ListItemText>
                      <Typography>11:20AM</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography><HourglassTopIcon></HourglassTopIcon>Stop</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography>0.2 Km</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography>1h 0m</Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem>
                    <ListItemText>
                      <Typography>11:50AM</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography><DirectionsWalkIcon></DirectionsWalkIcon>Walk</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography>2 Km</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography>0h 30m</Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem>
                    <ListItemText>
                      <Typography>12:20PM</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography><LocalShippingIcon></LocalShippingIcon>Drive</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography>2.3 Km</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography>0h 30m</Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem>
                    <ListItemText>
                      <Typography>01:00PM</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography><HourglassTopIcon></HourglassTopIcon>Stop</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography>0.2 Km</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography>0h 40m</Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem>
                    <ListItemText>
                      <Typography>01:00PM</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography><LocationSearchingIcon></LocationSearchingIcon>Tracking Ended</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography>0 Km</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography>0h 40m</Typography>
                    </ListItemText>
                  </ListItem>
                </List></div>
            </div>
            <div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3562.6962717628603!2d80.92190367521856!3d26.75406687674198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfb53706b7d85%3A0xa2330944dc4c56e4!2sRoad%20Pilot%20technologies%20pvt%20ltd!5e0!3m2!1sen!2sin!4v1712893020203!5m2!1sen!2sin"
                style={{ border: "0", height: "400px", width: "800px", marginTop: "20px" }}
                allowFullScreen=""
                loading="lazy"
              >
              </iframe>
            </div>
          </Stack>
        </div>

      </Box>
    </Box>
  );
}

export default FieldTimeline;