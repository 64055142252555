import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Select,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogTitle,
  Slide,
  Typography,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router-dom";
import StatusAlert from "../../StatusAlertList/StatusAlert";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const EditNotice = () => {
  const [updateDescription, setUpdateDescription] = useState("");
  const [updateHeading, setUpdateHeading] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");

  const [updateDepartments, setUpdateDepartments] = useState("");
  const [updateRadio, setUpdateRadio] = useState("Client");
  const [radioButton, setRadioButton] = useState(false);

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const creator = localStorage.getItem("name");

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  const navigate = useNavigate();
  const location = useLocation();
  const rowData = location.state;

  const { id, title, notice_to, description, user_type } = rowData;
  let valueToSend;
  if (updateRadio === "Client") {
    valueToSend = 1;
  } else if (updateRadio === "Employee") {
    valueToSend = 2;
  } else if (updateRadio === "App User") {
    valueToSend = 3;
  }
  async function UpdateData() {
    const formData = new FormData();

    formData.append("title", updateHeading);
    formData.append("description", updateDescription);
    formData.append("notice_to", valueToSend);
    formData.append("user_type", updateDepartments);
    formData.append("creator", creator);

    try {
      let response = await fetch(
        `https://rptpl.roadpilot.co.in/public/api/updateNotice/${id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      showAlert("updated successfully", "success");

      handleUpdateClose();
      navigate("/notice");
    } catch (error) {
      console.error(error);
      showAlert("Failed to update", "error");
    }
  }
  const handleUpdateClose = () => {
    setUpdateDepartments("");
    setUpdateDescription("");
    setUpdateHeading("");
    setUpdateRadio("");
  };
  useEffect(() => {
    setUpdateHeading(title);
  }, [title]);

  useEffect(() => {
    setUpdateRadio(notice_to);
  }, [notice_to]);

  useEffect(() => {
    setUpdateDescription(description);
  }, [description]);

  useEffect(() => {
    setUpdateDepartments(user_type);
  }, [user_type]);
  const cancelNotice = () => {
    navigate("/notice");
  };
  useEffect(() => {
    if (updateRadio === "App User") {
      setRadioButton(true);
    } else {
      setRadioButton(false);
    }
  }, [updateRadio]);
  return (
    <>
      {" "}
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />
      <Box>
        <DialogTitle
          sx={{
            background: "#fff",
            color: "#000",
            borderBottom: "1px solid gray",
            fontWeight: 550,
          }}
        >
          Update Notice
        </DialogTitle>
      </Box>
      <Box sx={{ background: "#fff" }}>
        <Grid
          container
          spacing={2}
          sx={{
            padding: { xs: "20px", sm: "20px" },
            color: "#000",
          }}
        >
          <Grid item xs={11} sm={5} md={5}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={updateRadio}
                onChange={(e) => setUpdateRadio(e.target.value)}
              >
                <FormControlLabel
                  value="Client"
                  control={
                    <Radio
                      sx={{
                        color: "black",
                        "&.Mui-checked": { color: "#1d82f5" },
                      }}
                    />
                  }
                  label="Client"
                />
                <FormControlLabel
                  value="Employee"
                  control={
                    <Radio
                      sx={{
                        color: "black",
                        "&.Mui-checked": { color: "#1d82f5" },
                      }}
                    />
                  }
                  label="Employee"
                />
                <FormControlLabel
                  value="App User"
                  control={
                    <Radio
                      sx={{
                        color: "black",
                        "&.Mui-checked": { color: "#1d82f5" },
                      }}
                    />
                  }
                  label="App User"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {radioButton && (
            <Grid item xs={11} sm={5} md={5}>
              <Typography sx={{ color: "#000" }}>User</Typography>
              <Select
                value={updateDepartments}
                autoFocus
                fullWidth
                onChange={(e) => setUpdateDepartments(e.target.value)}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                sx={{
                  border: "1px solid black",
                  boxSizing: "border-box",
                  "& .MuiOutlinedInput-root": {
                    borderColor: "black !important",
                    "&:hover fieldset": {
                      borderColor: "black !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black !important",
                    },
                  },
                  color: "black",
                }}
              >
                <MenuItem disabled value="">
                  <em>---</em>
                </MenuItem>
                <MenuItem value={0}>Driver</MenuItem>
                <MenuItem value={1}>Owner</MenuItem>
                <MenuItem value={2}>Dhaba</MenuItem>
                <MenuItem value={3}>Crane Owner</MenuItem>
                <MenuItem value={4}>Crane Operator</MenuItem>
              </Select>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            padding: { xs: "20px", sm: "20px" },
          }}
        >
          <Grid item xs={12} sm={5.5} md={5.5}>
            <Stack direction="column" spacing={2}>
              <Typography sx={{ color: "#000" }}>Notice Heading</Typography>
              <TextField
                autoFocus
                margin="dense"
                placeholder="Notice Heading Name"
                type="text"
                fullWidth
                value={updateHeading}
                onChange={(e) => setUpdateHeading(e.target.value)}
                inputProps={{ style: { color: "black" } }}
                InputLabelProps={{
                  style: { color: "black" },
                }}
                sx={{
                  border: "1px solid black",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-root": {
                    borderColor: "black",
                    "&:hover fieldset": {
                      borderColor: "black",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    "&::placeholder": { color: "gray" },
                  },
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={5.5} md={5.5}>
            <Stack direction="column" spacing={2}>
              <Typography sx={{ color: "#000" }}>Department</Typography>
              <Select
                value={updateDepartments}
                autoFocus
                fullWidth
                onChange={(e) => setUpdateDepartments(e.target.value)}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                sx={{
                  border: "1px solid black",
                  boxSizing: "border-box",
                  "& .MuiOutlinedInput-root": {
                    borderColor: "black !important",
                    "&:hover fieldset": {
                      borderColor: "black !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black !important",
                    },
                  },
                  color: "black",
                }}
              >
                <MenuItem disabled value="">
                  <em>---</em>
                </MenuItem>
                <MenuItem value={0}>Trainee</MenuItem>
                <MenuItem value={1}>Junior</MenuItem>
                <MenuItem value={2}>Senior</MenuItem>
                <MenuItem value={3}>Team Lead</MenuItem>
                <MenuItem value={4}>Project Manager</MenuItem>
              </Select>
            </Stack>
          </Grid>
        </Grid>

        <Grid container sx={{ padding: "20px" }}>
          <Stack direction="column" sx={{ width: "100%" }}>
            <Typography variant="p" gutterBottom style={{ color: "black" }}>
              Notice Details<span style={{ color: "red" }}>*</span>
            </Typography>

            <ReactQuill
              value={updateDescription}
              onChange={setUpdateDescription}
              modules={modules}
              theme="snow"
              style={{
                width: "100%",
                boxSizing: "border-box",
                color: "black",
                borderBlockColor: "black",
              }}
            />
          </Stack>
        </Grid>
        <Grid
          container
          sx={{
            padding: "20px",
            display: "flex",

            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            autoFocus
            color="inherit"
            onClick={UpdateData}
            sx={{
              backgroundColor: "#1d82f5",
              "&:hover": { backgroundColor: "orange" },
              textAlign: "center",

              marginLeft: "25px",
              marginTop: { xs: "70px", sm: "50px", md: "40px" },
              marginBottom: "30px",
            }}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            autoFocus
            color="inherit"
            onClick={cancelNotice}
            sx={{
              backgroundColor: "#333",
              "&:hover": { backgroundColor: "orange" },
              textAlign: "center",

              marginLeft: "10px",
              marginTop: { xs: "70px", sm: "50px", md: "40px" },
              marginBottom: "30px",
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default EditNotice;
