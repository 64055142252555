import TicketSettings from "./TicketSettings/TicketSettings";
import SettingPageLayout from "./SettingPageLayout";

function MainTicketSettings({ isDown }) {
  return (
    <SettingPageLayout>
      <TicketSettings isDown={isDown} />
    </SettingPageLayout>
  );
}

export default MainTicketSettings;
