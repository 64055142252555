import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import loginbackground from "../../assets/loginbackground.png";
import backloginfo from "../../assets/backlog.png";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { useState, forwardRef, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";

import { useMode } from "../../theme";
import { useMediaQuery } from "@mui/material";

export default function ForgotPassword() {
  const [open, setOpen] = useState(false);

  const vertical = "top";
  const horizontal = "right";
  const navigate = useNavigate();
  const [theme = {}, colorMode] = useMode();

  const breakpoint = theme.breakpoints.down("md");

  const isDown = useMediaQuery(breakpoint);
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const lightTheme = createTheme({
    palette: {
      mode: "light",
    },
  });

  const boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    maxHeight: "60%",
    bgcolor: isDown ? "" : "background.paper",
    boxShadow: 24,
  };

  const center = {
    position: "relative",
    top: "0",
    margin: "auto",
  };

  const handleSubmit = async (event) => {
    setOpen(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  useEffect(() => {
    if (localStorage.getItem("user-info")) {
      navigate("/rptpl");
    }
  });

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionComponent={TransitionLeft}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Failed! Enter correct username and password.
        </Alert>
      </Snackbar>
      <Box
        sx={{
          backgroundImage: `url(${loginbackground})`,
          backgroundSize: "cover",
          height: "100vh",
          color: "#f5f5f5",
        }}
      >
        <Box sx={boxstyle}>
          <Grid container sx={{ maxHeight: "100%" }}>
            {!isDown && (
              <Grid item xs={12} sm={12} lg={6}>
                <Box
                  sx={{
                    backgroundImage: `url(${backloginfo})`,
                    backgroundSize: "cover",

                    height: "100%",
                    color: "#f5f5f5",
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} lg={6}>
              <Box
                sx={{
                  backgroundColor: "#C0B476",
                  padding: "1.5rem",
                  maxHeight: "100%",
                }}
              >
                <ThemeProvider theme={lightTheme}>
                  <Container>
                    <Box height={35} />
                    <Box sx={center}>
                      <Avatar
                        sx={{
                          ml: "85px",
                          mb: "4px",
                          color: "#000000",
                          margin: "auto",
                        }}
                      >
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography
                        component="h1"
                        variant="h4"
                        sx={{ color: "black", textAlign: "center" }}
                      >
                        Reset Password
                      </Typography>
                    </Box>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 2, bgcolor: "white" }}
                    >
                      <Grid container spacing={1} sx={{ padding: "2rem" }}>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{
                              borderRadius: 28,
                              color: "#ffffff",
                              padding: isDown ? ".4rem 1rem" : ".5rem 3rem",
                              backgroundColor: "#FF9A01",
                              maxWidth: "100%",
                            }}
                          >
                            Send Reset Link
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack direction="row" spacing={2}>
                            <Typography
                              variant="body1"
                              component="span"
                              style={{ marginTop: "10px", color: "black" }}
                            >
                              Login to your Account.
                              <span
                                style={{ color: "orange", cursor: "pointer" }}
                                onClick={() => {
                                  navigate("/login");
                                }}
                              >
                                {" "}
                                Sign In
                              </span>
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Container>
                </ThemeProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
