import React, { useEffect, useState } from "react";
import "tailwindcss/tailwind.css";
import "./CraneBooking.css";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FormControl from "@mui/material/FormControl";
import PageLayout from "../global/PageLayout";

function CraneBooking() {
  const [craneBooking, setCraneBooking] = useState([]);
  const [leads, setLeads] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleViewClick = () => {
    // Implement logic to handle view action
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  // useEffect(() => {
  //   fetch("https://dummyjson.com/CraneBooking")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       let local = data?.craneBooking?.map((user) => ({
  //         ...user,
  //         name: user?.firstName + " " + user?.lastName,
  //         // status: "inactive"
  //       }));

  //       setCraneBooking(local);
  //     });
  // });
  //   useEffect(() => {
  //     getData();
  //   }, []);

  //   async function getData() {
  //     try {

  //       const response = await fetch('https://roadpilot.co.in/server/public/api/SalesDatalist');
  //       const data = await response.json();
  //       setLeads(data);
  //     } catch (error) {
  //       console.error('Error fetching leads data:', error);
  //     }
  //   }

  const columns = [
    { name: "date", label: "Date" },
    { name: "orderBy", label: "OrderBy" },
    { name: "operator", label: "Operator" },
    { name: "pickupPoint", label: "Pickup Point" },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <FormControl>
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleViewClick}>View</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Remark</MenuItem>
            </Menu>
          </FormControl>
        ),
      },
    },
  ];

  const options = {
    //selectableRows: false,
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5px",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  return (
    <div className="py-10 min-h-screen grid place-items-center">
      <br />
      <div className="w-10/12 max-w-4xl">
        <ThemeProvider theme={getTheme()}>
          <MUIDataTable
            title="Crane Bookings"
            data={craneBooking}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </div>
    </div>
  );
}

export default CraneBooking;
