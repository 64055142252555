import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Stack } from "@mui/material";
import PrivateDash from "./dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

function DashboardTopBar() {
  const user = JSON.parse(localStorage.getItem("user-info"));

  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDateTime(new Date());
    }, 1000); // Update every second

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Format time in HH:MM AM/PM format
  const formattedTime = dateTime.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  // Get day of the week
  const dayOfWeek = dateTime.toLocaleString("en-US", { weekday: "long" });

  return (
    <Box sx={{ width: "100%" }}>
      <Box display="flex" justifyContent="space-between">
        {/* SEARCH BAR */}

        <Box
          display="flex"
          variant="h1"
          fontWeight="bold"
          sx={{ mb: "5px", color: "#fff" }}
        >
          <Stack direction="row" spacing={85}>
            <Typography sx={{ fontSize: "40px", color: "#000" }}>
              Welcome {user.name}
            </Typography>
            <Stack direction="row" spacing={3} className="rightItem">
              <Typography
                sx={{
                  fontSize: "15px",
                  color: "#000",
                }}
              >
                {formattedTime}
                <br />
                {dayOfWeek}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#6420AA",
                  "&:hover": { backgroundColor: "blue" },

                  height: "50px",
                }}
              >
                <ExitToAppIcon />
                Clock In
              </Button>
            </Stack>
          </Stack>
        </Box>

        {/* ICONS */}
      </Box>
      <PrivateDash></PrivateDash>
    </Box>
  );
}

export default DashboardTopBar;
