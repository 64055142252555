import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField, Checkbox, FormControlLabel, Button, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { financeSettingsApi } from '../../../Data/axios'; 
import StatusAlert from "../../../StatusAlertList/StatusAlert";

function InvoiceSettings() {
  const [reminderType, setReminderType] = useState('before'); 
  const [days, setDays] = useState(''); 
  const [dueAfter, setDueAfter] = useState(''); 
  const [showTaxNumber, setShowTaxNumber] = useState(false); 
  const [showHsnSacCode, setShowHsnSacCode] = useState(false);
  const [showTaxCalculation, setShowTaxCalculation] = useState(false); 
  const [termsValue, setTermsValue] = useState(''); 
  const [otherInfo, setOtherInfo] = useState(''); 
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await financeSettingsApi.getAll();
        const settings = response[0]; 

        
        setDueAfter(settings.due_after || '');
        setReminderType(settings.send_reminder === 1 ? 'before' : 'every');
        setDays(settings.send_reminder_days || '');
        setShowTaxNumber(settings.tax_number === 1);
        setShowHsnSacCode(settings.hsn_sac_code === 1);
        setShowTaxCalculation(settings.tax_calculation === 1);
        setTermsValue(settings.Terms || 'Thank you for your business.');
        setOtherInfo(settings.other || '');
      } catch (error) {
        console.error("Error fetching settings:", error);
        showAlert("Failed to fetch settings!", "error");
      }
    };

    fetchSettings();
  }, []);

  const handleReminderTypeChange = (event) => {
    setReminderType(event.target.value);
    setDays('');
  };

  const handleCheckboxChange = (setter) => (event) => {
    setter(event.target.checked);
  };

  const handleSubmit = async () => {
    const formData = {
      due_after: dueAfter,
      reminder: reminderType === 'before' ? 1 : 2,
      send_reminder: reminderType === 'before' ? 1 : 2,
      send_reminder_days: days,
      tax_number: showTaxNumber ? 1 : 0,
      hsn_sac_code: showHsnSacCode ? 1 : 0,
      tax_calculation: showTaxCalculation ? 1 : 0,
      Terms: termsValue,
      other: otherInfo,
    };

    try {
      await financeSettingsApi.updateinvoice(formData);
      showAlert("Settings updated successfully!", "success");
    } catch (error) {
      console.error("Error updating settings:", error);
      showAlert("Failed to update settings!", "error");
    }
  };

  return (
    <Box sx={{ p: 3, maxWidth: 1200, mx: 'auto', color: 'black' }}>
      <StatusAlert open={alertOpen} setOpen={setAlertOpen} message={alertMessage} severity={alertSeverity} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Due after"
            variant="outlined"
            type="number"
            required
            value={dueAfter}
            onChange={(e) => setDueAfter(e.target.value)}
            InputLabelProps={{ style: { color: 'black' } }}
            InputProps={{
              endAdornment: <Typography sx={{ color: 'black' }}>Day(s)</Typography>,
              sx: {
                color: 'black',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black', 
                }
              }
            }}
          />
        </Grid>

       
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" sx={{ '& .MuiOutlinedInput-notchedOutline': { borderColor: 'black' } }}>
                <InputLabel sx={{ color: 'black' }}>Reminder Type</InputLabel>
                <Select
                  value={reminderType}
                  onChange={handleReminderTypeChange}
                  label="Reminder Type"
                  sx={{
                    color: 'black',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'black', 
                    }
                  }}
                >
                  <MenuItem value="before">Send Reminder Before</MenuItem>
                  <MenuItem value="every">Send Reminder Every</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={`Number of Days`}
                variant="outlined"
                type="number"
                value={days}
                onChange={(e) => setDays(e.target.value)}
                InputLabelProps={{ style: { color: 'black' } }}
                InputProps={{
                  endAdornment: <Typography sx={{ color: 'black' }}>Day(s)</Typography>,
                  sx: {
                    color: 'black',
                    borderColor: 'black', 
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'black', 
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={<Checkbox checked={showTaxNumber} onChange={handleCheckboxChange(setShowTaxNumber)} sx={{ color: 'black' }} />}
            label="Show Tax number on invoice"
            sx={{ color: 'black' }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={<Checkbox checked={showHsnSacCode} onChange={handleCheckboxChange(setShowHsnSacCode)} sx={{ color: 'black' }} />}
            label="Hsn/Sac Code Show"
            sx={{ color: 'black' }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={<Checkbox checked={showTaxCalculation} onChange={handleCheckboxChange(setShowTaxCalculation)} sx={{ color: 'black' }} />}
            label="Show tax calculation message"
            sx={{ color: 'black' }}
          />
        </Grid>

        
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Terms and Conditions"
            variant="outlined"
            multiline
            rows={3}
            value={termsValue}
            onChange={(e) => setTermsValue(e.target.value)}
            InputLabelProps={{ style: { color: 'black' } }}
            InputProps={{
              sx: {
                color: 'black',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black', 
                },
                '& input': {
                  color: 'black', 
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Other Information"
            variant="outlined"
            multiline
            rows={3}
            value={otherInfo}
            onChange={(e) => setOtherInfo(e.target.value)}
            placeholder="Enter Other Information"
            InputLabelProps={{ style: { color: 'black' } }}
            InputProps={{
              sx: {
                color: 'black',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black', 
                },
                '& input': {
                  color: 'black', 
                },
              }
            }}
          />
        </Grid>

        {/* Save Button */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            sx={{ backgroundColor: 'blue', color: 'white', '&:hover': { backgroundColor: '#333' } }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default InvoiceSettings;
